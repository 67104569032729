import { omit } from 'lodash';

import reduxAnalyticsEvents from './reduxAnalyticsEvents';
import { sendProjectEvent } from './sendAvroEvent';
import * as Tags from './tags';

/**
 * List of item properties to remove from analytics events,
 * mainly due to their size
 */
const itemBlockList = [
  'preview_urls',
  'resolutions',
  'source',
  'thumbnail',
  'thumbnail_url',
];

/**
 * Generic wrapper around sendProjectEvent that provides logic to
 * trim down unnecessary properties from item objects.
 *
 * @param {string} tag - event tag
 * @param {object} payload - the object that's passed to the "value" property
 * @returns Promise | null
 */
export function trackEvent(tag, payload) {
  if (!tag) {
    // TODO: should we even show a message here?
    console.warn('No tag provided for event, skipping');
    return;
  }

  return sendProjectEvent(tag, {
    value: omit(payload, itemBlockList),
  });
}

export function getItemEditProps(item, key, newValue) {
  return {
    id: item.id,
    mediaType: item.mediaType,
    property: key,
    oldValue: item[key],
    newValue,
  };
}

/** OLD EVENTS */

export function trackUploads(uploads = []) {
  uploads.forEach(trackUpload);
}

export function trackUpload(upload = {}, mediaType) {
  const value = {
    id: upload.id,
    basename: upload.basename,
    ext: upload.ext,
    duration: upload.meta?.duration,
    width: upload.meta?.width,
    height: upload.meta?.height,
    size: upload.size,
    type: upload.type,
    mediaType,
  };
  sendProjectEvent(Tags.UPLOAD, { value });
}

export function trackUploadDelete(upload) {
  return sendProjectEvent(Tags.UPLOAD_DELETE, { value: upload });
}

export function trackProjectTeamAccess(teamAccess, projectId) {
  const tag = teamAccess ? Tags.TEAM_PROJECT_SHARE : Tags.TEAM_PROJECT_UNSHARE;
  return sendProjectEvent(tag, { projectId });
}

export function trackUpsellGoToAccount() {
  return sendProjectEvent(Tags.UPSELL_GO_TO_ACCOUNT);
}

export function trackUpsellDialogOpen() {
  return sendProjectEvent(Tags.UPSELL_MODAL_OPEN);
}

export function trackUpsellSignUp(isEnterprise = false) {
  return sendProjectEvent(
    isEnterprise ? Tags.UPSELL_SIGN_UP_ENT : Tags.UPSELL_SIGN_UP
  );
}

export function trackReduxEvent({ type, payload }) {
  const tag = reduxAnalyticsEvents[type];
  if (!tag) {
    return null;
  }

  return sendProjectEvent(tag, { value: payload });
}

export function trackLegacyNonReduxEvent({ type: tag, payload }) {
  return sendProjectEvent(tag, { value: payload });
}

export function trackStarterExportUpgrade(isExportLimitMet = false) {
  return sendProjectEvent(Tags.UPSELL_EXPORT_ALERT, {
    value: { isExportLimitMet },
  });
}

export function trackCopyShareLinkOnSharePage(isLoggedIn = false) {
  return sendProjectEvent(Tags.SHARE_COPY_SHARE_LINK, {
    value: { isLoggedIn },
  });
}

export function trackCreateVideo(isLoggedIn = false) {
  return sendProjectEvent(Tags.SHARE_CREATE_VIDEO, { value: { isLoggedIn } });
}

export function trackApplyStylesClick({ styles }) {
  return sendProjectEvent(Tags.APPLY_STYLES, { value: styles });
}

export function trackSetAsDefaultStylesClick({ styles }) {
  return sendProjectEvent(Tags.SET_DEFAULT_STYLES, { value: styles });
}

export function trackStylesTypographyChange(typography) {
  return sendProjectEvent(Tags.CHANGE_STYLES_TYPEFACE, { value: typography });
}

export function trackStylesPrimaryTextColor(color) {
  return sendProjectEvent(Tags.CHANGE_STYLES_PRIMARY_TEXT_COLOR, {
    value: color,
  });
}

export function trackStylesSecondaryTextColor(color) {
  return sendProjectEvent(Tags.CHANGE_STYLES_SECONDARY_TEXT_COLOR, {
    value: color,
  });
}

export function trackStylesVideoColorOverlay(color) {
  return sendProjectEvent(Tags.CHANGE_STYLES_VIDEO_COLOR_OVERLAY, {
    value: color,
  });
}

export function trackStylesImageColorOverlay(color) {
  return sendProjectEvent(Tags.CHANGE_STYLES_IMAGE_COLOR_OVERLAY, {
    value: color,
  });
}

export function trackActiveTabOnProjectsPage(showMyProjects) {
  if (showMyProjects) {
    return sendProjectEvent(Tags.MY_PROJECT_TAB);
  } else {
    return sendProjectEvent(Tags.TEAM_PROJECT_TAB);
  }
}
