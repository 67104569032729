import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { LAYER_LIMIT } from '../../constants/Storyboard';
import { selectLayerTotal } from '../../slices/storyboardSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  layerLimit: {
    padding: theme.spacing(0.25, 0.75),
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.red[500],
  },
}));

export default function LayerLimit() {
  const classes = useStyles();
  const layerTotal = useSelector(selectLayerTotal);

  return (
    <div className={classes.container}>
      <div className={classes.layerLimit}>
        {`${layerTotal} / ${LAYER_LIMIT} Layers`}
      </div>
    </div>
  );
}
