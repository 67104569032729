import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import { PROPERTIES_PANEL_WIDTH } from '../../constants/Constants';
import { BRAND_COLOR_PICKER_SECTION } from '../../constants/TestSelectors';
import useBrandSelect from '../../hooks/useBrandSelect';
import useBrands from '../../hooks/useBrands';
import ColorGrid from './ColorGrid';

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: '100%',
  },
  menuItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // A convenient width to prevent the dropdown from getting huge when displaying long brand names
    maxWidth: PROPERTIES_PANEL_WIDTH,
  },
  select: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(0.5, 1, 0.5, 0),
    backgroundColor: 'transparent',
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectIcon: {
    fill: theme.palette.common.black,
    maxWidth: theme.spacing(2),
  },
}));

export default function BrandPicker({ onChange }) {
  const classes = useStyles();

  // Determine context within the app based on route
  const { brandUid, projectId } = useParams();
  const { brands = [], isLoading: brandsIsLoading } = useBrands();

  // Sort brands alphabetically for a more organized presentation.
  const sortedBrands = brands?.sort((a, b) =>
    a.detail.name.localeCompare(b.detail.name)
  );

  const [selectedBrandUid, setSelectedBrandUid] = useBrandSelect({
    brands: sortedBrands,
    brandsIsLoading,
    projectId,
    brandUid,
  });

  const getBrandColors = () => {
    const brand = brands.find((brand) => brand.uid === selectedBrandUid);
    const { colors } = brand?.detail?.styles || {};
    return (colors || []).map((color) => tinycolor(color).toRgbString());
  };

  const handleBrandChange = (event) => {
    setSelectedBrandUid(event.target.value);
  };

  return selectedBrandUid ? (
    <>
      <FormControl
        className={classes.formControl}
        data-testid={BRAND_COLOR_PICKER_SECTION}
      >
        <Select
          displayEmpty
          disableUnderline
          value={selectedBrandUid}
          onChange={handleBrandChange}
          className={classes.select}
          classes={{ root: classes.selectRoot }}
          inputProps={{
            classes: {
              icon: classes.selectIcon,
            },
          }}
        >
          {sortedBrands.map((brand) => (
            <MenuItem value={brand.uid} key={brand.uid}>
              <div className={classes.menuItemText}>{brand.detail.name}</div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ColorGrid onChange={onChange} colors={getBrandColors()} />
    </>
  ) : null;
}

BrandPicker.propTypes = {
  onChange: PropTypes.func,
};
