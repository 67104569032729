import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

import toUpperHexString from '../../utils/toUpperHexString';
import { checkerboardBackground } from '../colorPicker/checkerboardBackground';

const useStyles = makeStyles((theme) => ({
  background: {
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    minWidth: theme.typography.pxToRem(26),
    aspectRatio: 1,
    padding: theme.typography.pxToRem(1),
    borderRadius: ({ roundedSwatches }) =>
      roundedSwatches ? '9999px' : theme.shape.borderRadius,
  },
  backgroundTransparency: {
    backgroundImage: checkerboardBackground,
    backgroundPosition: 'center',
  },
  swatchPicker: {
    display: 'inline-grid',
    gridTemplateColumns: ({ itemsPerRow }) => `repeat(${itemsPerRow}, 1fr)`,
    gridAutoRows: '1fr',
    gap: theme.spacing(0.5),
  },
  swatch: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 'inherit',
    cursor: 'pointer',
    padding: 0,
    minWidth: theme.typography.pxToRem(24),
    aspectRatio: 1,
  },
  swatchSelected: {
    cursor: 'default',
    outline: '3px solid rgba(89, 108, 120, 1)',
  },
}));

export default function ColorGrid({
  colors = [],
  id = '',
  itemsPerRow = 7,
  onChange = () => {},
  roundedSwatches = false,
  selectedColor = undefined,
}) {
  const classes = useStyles({ itemsPerRow, roundedSwatches });

  return (
    <div className={classes.swatchPicker} data-testid={id}>
      {colors.map((color, idx) => (
        <div
          className={clsx(classes.background, {
            [classes.swatchSelected]: tinycolor.equals(selectedColor, color),
          })}
          key={`${color}_${idx}`}
        >
          <Button
            className={clsx(classes.swatch, {
              [classes.backgroundTransparency]:
                tinycolor(color).getAlpha() !== 1,
            })}
            aria-label={toUpperHexString(color)}
            style={{
              backgroundColor: color,
            }}
            onClick={() => onChange(tinycolor(color).toRgb())}
          />
        </div>
      ))}
    </div>
  );
}

ColorGrid.propTypes = {
  onChange: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.string),
};
