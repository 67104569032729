import { useEffect, useRef } from 'react';
import useSWR from 'swr';

/**
 * Our version of swr seems to have a bug with conditional requests.
 * Wrap it to work around that.
 *
 * @param key
 * @param fetcher
 * @param hook
 * @param {[boolean]} skip
 * @return {SWRResponse<any, any>}
 */
export default function useSWRSkippable({
  key,
  fetcher,
  hook = useSWR,
  skip = false,
}) {
  const hookValuesRef = useRef({});

  const hookArgs = skip ? [null, () => null] : [key, fetcher];
  const hookValues = hook(...hookArgs);

  useEffect(() => {
    if (!skip) {
      hookValuesRef.current = hookValues;
    }
  }, [skip, hookValues]);

  return skip ? hookValuesRef.current : hookValues;
}
