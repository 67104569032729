import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parse, stringify } from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { RATIO_16_9, RATIO_1_1, RATIO_9_16 } from '../constants/AspectRatios';
import { GRID_CELL, GRID_COL } from '../constants/TestSelectors';
import { EDIT_PATH } from '../constants/Urls';
import { trackEvent } from '../events/sendEvents';
import { TEMPLATE, TEMPLATES } from '../events/tags';
import { openVideoPreviewDialog } from '../features/ui/uiSlice';
import useTemplates from '../hooks/useTemplates';
import columnSplit from '../utils/columnSplit';
import parseAspectRatio from '../utils/parseAspectRatio';
import TemplatePreview from './TemplatePreview';

const useStyles = makeStyles((theme) => ({
  cell: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  column: {
    width: '25%',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  masonryGrid: {
    display: 'flex',
    margin: theme.spacing(2, 0, 4),
    width: '100%',
  },
  noMatch: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(32),
    textAlign: 'center',
    color: theme.palette.grey[700],
  },
}));

export default function TemplateMasonryGrid(props) {
  const { filterByPublished } = props;
  const [selectedRatio, setSelectedRatio] = useState();
  const { orgAgnosticTemplates, isLoading } = useTemplates();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  let templates = orgAgnosticTemplates ?? [];

  if (filterByPublished) {
    templates = templates?.filter((template) => template.published_at);
  }
  if (selectedRatio) {
    templates = templates?.filter(
      (template) => template.ratio === selectedRatio
    );
  }
  const columns = columnSplit(templates);

  const handleRatioChange = (event) => {
    trackEvent(TEMPLATES.FILTER_SELECT, {
      property: 'ratio',
      oldValue: selectedRatio,
      newValue: event.target.value,
    });
    setSelectedRatio(event.target.value);
  };

  const handleTemplatePreview = useCallback(
    (template) => {
      const { uid, preview_url } = template;
      trackEvent(TEMPLATE.PREVIEW, { templateId: uid });
      dispatch(
        openVideoPreviewDialog({
          src: preview_url,
          primaryButtonText: 'Create Project from Template',
          onClickPrimary: () => {
            history.push({
              pathname: EDIT_PATH,
              search: stringify({
                ...parse(search),
                fromTemplateId: uid,
              }),
            });
          },
        })
      );
    },
    [dispatch, history, search]
  );

  const handleTemplateSelect = useCallback(
    (template) => {
      const { uid } = template;
      history.push({
        pathname: EDIT_PATH,
        search: `${stringify({
          ...parse(search),
          fromTemplateId: uid,
        })}`,
      });
    },
    [history, search]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={3}>
        <div>
          <Typography component="h2" variant="h4">
            Ready-to-use Templates from Storyblocks
          </Typography>
        </div>
        <FormControl variant="outlined">
          <Select
            displayEmpty
            value={selectedRatio ?? ''}
            onChange={handleRatioChange}
          >
            <MenuItem value="">All Ratios</MenuItem>
            <MenuItem value={RATIO_16_9}>Wide</MenuItem>
            <MenuItem value={RATIO_1_1}>Square</MenuItem>
            <MenuItem value={RATIO_9_16}>Vertical</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {!isLoading && templates.length > 0 && (
        <div className={classes.masonryGrid}>
          {columns.map((column, i) => (
            <div className={classes.column} key={i} data-testid={GRID_COL}>
              {column.map((template) => {
                return (
                  <div
                    className={classes.cell}
                    key={template.id}
                    data-testid={GRID_CELL}
                  >
                    <TemplatePreview
                      aspectRatio={parseAspectRatio(template.ratio)}
                      name={template.name}
                      onPreview={() => handleTemplatePreview(template)}
                      onSelect={() => handleTemplateSelect(template)}
                      previewUrl={template.preview_url}
                      thumbnailUrl={template.thumbnail_url}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
      {!isLoading && templates.length === 0 && (
        <div className={classes.noMatch}>
          Sorry, we couldn&apos;t find any templates that match your selected
          criteria.
        </div>
      )}
    </>
  );
}
