import { maxBy, sum } from 'lodash';

export function getStageCompletion(stageProgress = {}) {
  const { completedAt, total, completed = 0 } = stageProgress;

  if (completedAt) {
    return 1;
  }

  if (total) {
    return completed / Math.floor(total);
  }

  return 0;
}

export function findCurrentStage(exportProgress) {
  const { stages = {} } = exportProgress;

  const [stage, { startedAt }] = maxBy(
    Object.entries(stages),
    ([, { startedAt = 0, completedAt = 0 }]) => Math.max(startedAt, completedAt)
  ) || [null, {}];

  if (startedAt === undefined) {
    return null;
  }

  return stage;
}

export function getCompletionByStage(stages = {}) {
  return Object.fromEntries(
    Object.entries(stages).map(([name, progress]) => [
      name,
      getStageCompletion(progress),
    ])
  );
}

const stageWeights = {
  asset: 2,
  frame: 20,
  audio: 1,
  upload: 1,
};

// This is the percent progress we show when
// a lambda first picks up an event
// but hasn't done anything else yet
export const startedPercent = 2;

export function getOverallPercent(exportProgress = {}) {
  if (exportProgress.startedAt === undefined) {
    return 0;
  }

  const { stages = {} } = exportProgress;
  const completionByStage = getCompletionByStage(stages);

  const weightsSum = sum(Object.values(stageWeights));
  const weightedStageCompletion =
    sum(
      Object.entries(completionByStage).map(
        ([name, completion]) => completion * (stageWeights[name] ?? 0)
      )
    ) / weightsSum;

  return (
    startedPercent +
    Math.round(weightedStageCompletion * (100 - startedPercent))
  );
}
