import { Tooltip } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { DraggableCore } from 'react-draggable';

import { MINIMUM_ITEM_DURATION } from '../constants/Storyboard';
import {
  TRIM_DRAG_HANDLE_LEFT,
  TRIM_DRAG_HANDLE_RIGHT,
} from '../constants/TestSelectors';
import formatTimestamp from '../utils/formatTimestamp';
import FilmStrip from './FilmStrip';
import Waveform from './Waveform';

const TRIM_BAR_WIDTH = 440;
const TRIM_BAR_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  backgroundContainer: {
    position: 'absolute',
    zIndex: 0,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    position: 'relative',
    margin: '0 11px',
    width: TRIM_BAR_WIDTH,
    height: TRIM_BAR_HEIGHT,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
    userSelect: 'none',
  },
  cursor: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    width: '1px',
    backgroundColor: theme.palette.red[500],
  },
  duration: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(0.25, 1),
    fontFamily: 'sans-serif',
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.7),
    pointerEvents: 'none',
    userSelect: 'none',
  },
  handle: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    bottom: 0,
    width: '11px',
    backgroundColor: theme.palette.blue[500],
    cursor: 'col-resize',
    '&[data-action="trim-left"]': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      transform: 'translateX(-100%)',
    },
    '&[data-action="trim-right"]': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
      width: '3px',
      height: '60%',
      borderLeft: `1px solid ${theme.palette.common.white}`,
      borderRight: `1px solid ${theme.palette.common.white}`,
    },
  },
  leftOverlay: {
    position: 'absolute',
    left: 0,
    height: '100%',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  rightOverlay: {
    position: 'absolute',
    right: 0,
    height: '100%',
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  section: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    bottom: 0,
    borderTop: `3px solid ${theme.palette.blue[500]}`,
    borderBottom: `3px solid ${theme.palette.blue[500]}`,
    cursor: 'move',
    overflow: 'hidden',
  },
  tooltip: {
    fontFamily: 'sans-serif',
    fontSize: theme.typography.pxToRem(12),
  },
}));

function TrimBar(props) {
  const { currentTime, isVideo, onTrim, onTrimStart, selectedClip, source } =
    props;

  const [action, setAction] = useState('');
  const classes = useStyles();
  const dragStart = useRef(null);

  const timeToPx = (timestamp) =>
    (timestamp * TRIM_BAR_WIDTH) / selectedClip.maxDuration;

  const left = timeToPx(selectedClip.trimStart);
  const sectionWidth = timeToPx(selectedClip.duration);
  const right = left + sectionWidth;
  const cursorPos = timeToPx(currentTime);

  const showCursor = !action && Math.abs(left - cursorPos) > 1;

  const leftTrim = (delta) => {
    const trimEnd = dragStart.current.trimStart + dragStart.current.duration;
    let trimStart = dragStart.current.trimStart + delta;
    trimStart = Math.max(trimStart, 0);
    trimStart = Math.min(trimStart, trimEnd - MINIMUM_ITEM_DURATION);
    const duration = trimEnd - trimStart;
    onTrim(trimStart, duration, action);
  };

  const rightTrim = (delta) => {
    let duration = dragStart.current.duration + delta;
    duration = Math.max(duration, MINIMUM_ITEM_DURATION);
    duration = Math.min(
      duration,
      selectedClip.maxDuration - selectedClip.trimStart
    );
    onTrim(selectedClip.trimStart, duration, action);
  };

  const moveTrim = (delta) => {
    let trimStart = dragStart.current.trimStart + delta;
    trimStart = Math.max(trimStart, 0);
    trimStart = Math.min(
      trimStart,
      selectedClip.maxDuration - selectedClip.duration
    );
    onTrim(trimStart, selectedClip.duration, action);
  };

  const computeDragDelta = (screenX) => {
    const delta = screenX - dragStart.current.screenX;
    return (delta * selectedClip.maxDuration) / TRIM_BAR_WIDTH;
  };

  const handleStart = (event) => {
    const { screenX } = event;
    const { action } = event.target.dataset;
    const { trimStart, duration } = selectedClip;
    dragStart.current = { trimStart, duration, screenX };
    setAction(action);
    onTrimStart();
  };

  const handleDrag = (event) => {
    const delta = computeDragDelta(event.screenX);

    if (action === 'trim-left') {
      leftTrim(delta);
    }
    if (action === 'trim-right') {
      rightTrim(delta);
    }
    if (action === 'trim-move') {
      moveTrim(delta);
    }
  };

  const handleStop = () => {
    dragStart.current = null;
    setAction('');
  };

  const onError = useCallback(
    (error) => {
      log.warn('Trimbar item is not valid; showing it in an error state', {
        error,
        source,
      });
    },
    [source]
  );

  return (
    <DraggableCore
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <div className={classes.container}>
        <div className={classes.backgroundContainer}>
          {isVideo ? (
            <FilmStrip
              width={TRIM_BAR_WIDTH}
              height={TRIM_BAR_HEIGHT}
              source={source}
              onError={onError}
            />
          ) : (
            <Waveform
              width={TRIM_BAR_WIDTH}
              height={TRIM_BAR_HEIGHT}
              sourceUrl={source}
              onError={onError}
            />
          )}
        </div>
        {showCursor && (
          <div className={classes.cursor} style={{ left: cursorPos }} />
        )}
        <div className={classes.leftOverlay} style={{ width: left }} />
        <div
          className={classes.section}
          style={{ left, width: sectionWidth }}
          data-action="trim-move"
        >
          {action && (
            <div className={classes.duration}>
              {selectedClip.duration?.toFixed(1)}s
            </div>
          )}
        </div>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={`${formatTimestamp(selectedClip.trimStart, { showMs: true })}`}
          open={action === 'trim-left'}
        >
          <div
            className={classes.handle}
            style={{ left }}
            data-action="trim-left"
            data-testid={TRIM_DRAG_HANDLE_LEFT}
          />
        </Tooltip>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={`${formatTimestamp(
            selectedClip.trimStart + selectedClip.duration,
            true
          )}`}
          open={action === 'trim-right'}
        >
          <div
            className={classes.handle}
            style={{ left: right }}
            data-action="trim-right"
            data-testid={TRIM_DRAG_HANDLE_RIGHT}
          />
        </Tooltip>
        <div
          className={classes.rightOverlay}
          style={{ width: TRIM_BAR_WIDTH - right }}
        />
      </div>
    </DraggableCore>
  );
}

TrimBar.propTypes = {
  currentTime: PropTypes.number,
  isVideo: PropTypes.bool,
  onTrim: PropTypes.func,
  onTrimStart: PropTypes.func,
  selectedClip: PropTypes.shape({
    duration: PropTypes.number,
    maxDuration: PropTypes.number,
    trimStart: PropTypes.number,
  }),
  source: PropTypes.string,
};

TrimBar.defaultProps = {
  onTrim: () => {},
  onTrimStart: () => {},
};

export default TrimBar;
