import { omit } from 'lodash';

export default function extractThumbnails(results) {
  if (!results.thumbnailed) return results;

  const thumbnails = {};
  results.thumbnailed.forEach((thumbnailData) => {
    thumbnails[thumbnailData.basename] = thumbnailData;
  });

  results.video.forEach((videoData) => {
    videoData.thumbnail = thumbnails[videoData.basename];
  });
  return omit(results, 'thumbnailed');
}
