import { Button, makeStyles, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { selectIsEnterprise } from '../../selectors/user';

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.pink[500],
  },
  activeButton: {
    fontWeight: 600,
  },
  activeIndicator: {
    backgroundColor: theme.palette.pink[500],
    height: '100%',
    width: theme.typography.pxToRem(4),
    position: 'absolute',
  },
  button: {
    lineHeight: theme.typography.pxToRem(28),
    marginLeft: theme.spacing(3),
    padding: 0,
    justifyContent: 'flex-start',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
  buttonIcon: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
    color: ({ isActive }) =>
      isActive ? theme.palette.pink[500] : theme.palette.grey[800],
  },
  hidden: {
    display: 'none',
  },
  row: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(1, 0),
    height: theme.typography.pxToRem(32),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  teamsLabel: {
    fontSize: theme.typography.pxToRem(8),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.blue[800],
    fontWeight: 700,
    backgroundColor: theme.palette.blue[100],
    borderRadius: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(20),
    minWidth: theme.typography.pxToRem(40),
    textAlign: 'center',
    marginRight: theme.typography.pxToRem(16),
    alignSelf: 'center',
  },
}));

export default function SideNavLink({
  icon,
  isActive,
  isTeamsLink = false,
  startIcon,
  to,
  title,
  ...rest
}) {
  const classes = useStyles({ isActive });
  const isEnterprise = useSelector(selectIsEnterprise);

  if (isTeamsLink && !isEnterprise) return null;

  return (
    <div className={classes.row}>
      <div
        className={clsx(classes.activeIndicator, {
          [classes.active]: isActive,
          [classes.hidden]: !isActive,
        })}
      ></div>
      <Button
        className={classes.button}
        component={NavLink}
        to={to}
        startIcon={<SvgIcon className={classes.buttonIcon} component={icon} />}
        fullWidth
        {...rest}
      >
        <span className={clsx({ [classes.activeButton]: isActive })}>
          {title}
        </span>
      </Button>
      {isTeamsLink && <div className={classes.teamsLabel}>TEAMS</div>}
    </div>
  );
}
