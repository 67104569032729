import { MediaTypes } from '@videoblocks/jelly-renderer';

import createAnimationObject from './createAnimationObject';
import createAudioObject from './createAudioObject';
import createImageObject from './createImageObject';
import createRatioBasedTextObject from './createRatioBasedTextObject';
import createVideoObject from './createVideoObject';

export default async function createObject(item = {}, ratio = '') {
  switch (item.mediaType) {
    case MediaTypes.ANIMATION:
      return createAnimationObject(item);
    case MediaTypes.TEXT:
      return createRatioBasedTextObject(ratio, item);
    case MediaTypes.AUDIO:
      return await createAudioObject(item);
    case MediaTypes.IMAGE:
      return createImageObject(item);
    case MediaTypes.VIDEO:
      return await createVideoObject(item);
    default:
      return {};
  }
}
