import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';

import { FAQ_LINK, QUICK_LINKS } from '../constants/Urls';
import { trackEvent } from '../events/sendEvents';
import { TUTORIALS } from '../events/tags';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(0.5, 0),
  },
  quickLink: {
    display: 'inline-block',
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    lineHeight: theme.typography.pxToRem(48),
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
    whiteSpace: 'nowrap',
    '& svg.sb-icon': {
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(1),
    },
  },
}));

export default function QuickLinks() {
  const classes = useStyles();

  return (
    <Box>
      <Grid justifyContent="space-between" align="center" container spacing={4}>
        <Grid item>
          <Typography component="h2" variant="h4">
            Quick Links
          </Typography>
        </Grid>

        <Grid item>
          <Link
            color="secondary"
            className={classes.link}
            href={FAQ_LINK.href}
            onClick={() =>
              trackEvent(TUTORIALS.QUICK_LINKS, { link: FAQ_LINK.title })
            }
            target="_blank"
            rel="noopener"
          >
            View All FAQ
          </Link>
        </Grid>
      </Grid>

      <Grid justifyContent="space-between" align="center" container spacing={3}>
        {QUICK_LINKS.map((quickLink) => (
          <Grid item xs={12} lg={4} key={quickLink.title}>
            <Button
              startIcon={<SvgIcon component={quickLink.icon} />}
              className={classes.quickLink}
              href={quickLink.href}
              onClick={() =>
                trackEvent(TUTORIALS.QUICK_LINKS, { link: quickLink.title })
              }
              target="_blank"
              rel="noopener"
            >
              <Typography component="span" variant="body2">
                {quickLink.title}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
