import { Button, IconButton, makeStyles } from '@material-ui/core';
import { VideoLibrary } from '@material-ui/icons';

import { VIDEO_LIBRARY_ICON } from '../../constants/TestSelectors';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: (props) => props.color?.bgColor ?? theme.palette.primary,
    color: (props) => props.color?.color ?? theme.palette.common.black,
    '&:hover': {
      backgroundColor: (props) =>
        props.color?.bgColorHover ?? theme.palette.primary.dark,
    },
  },
}));

export function NavActionButton(props) {
  const {
    color = {},
    iconButton = false,
    buttonText = '',
    onClick = () => {},
  } = props;
  const classes = useStyles({ color });

  if (iconButton) {
    return (
      <IconButton
        className={classes.button}
        aria-label={buttonText}
        onClick={onClick}
        size="small"
      >
        <VideoLibrary data-testid={VIDEO_LIBRARY_ICON} alt={buttonText} />
      </IconButton>
    );
  }

  return (
    <Button
      className={classes.button}
      variant="contained"
      size="small"
      startIcon={<VideoLibrary />}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
}

export default NavActionButton;
