import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  DropShadow,
  Fonts,
  getStoryboardDuration,
  MediaTypes,
  Renderer,
} from '@videoblocks/jelly-renderer';
import { VERSION } from '@videoblocks/jelly-scripts';
import { filter, find, isEmpty, map, without } from 'lodash';

import {
  CLEAR_SELECTED_PROJECT,
  LOAD_PROJECT_COMPLETED,
  LOAD_PROJECT_FAILED,
  LOAD_PROJECT_REQUESTED,
} from '../constants/ActionTypes';
import {
  DEFAULT_PROJECT_NAME,
  DEFAULT_PROJECT_RATIO,
  LAYER_LIMIT,
  MAX_STORYBOARD_DURATION,
} from '../constants/Storyboard';
import { getAnimationByValue } from '../constants/TextAnimations';
import decimalTruncate from '../utils/decimalTruncate';
import {
  getFontForTextVariantFromStyles,
  getStylePropertiesFromFont,
} from '../utils/fonts';
import parseAspectRatio from '../utils/parseAspectRatio';
import {
  addItem,
  getItemSeekTime,
  mapMediaToLayer,
  sanitizeFadeOptions,
  scaleText,
  splitItem,
} from '../utils/storyboardUtils';

const itemsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.startTime - b.startTime,
});
const layersAdapter = createEntityAdapter();
const customFontsAdapter = createEntityAdapter({
  selectId: (font) => font.name,
});

export const initialState = {
  name: DEFAULT_PROJECT_NAME,
  ratio: DEFAULT_PROJECT_RATIO,
  styles: {},
  items: itemsAdapter.getInitialState(),
  layers: layersAdapter.getInitialState(),
  customFonts: customFontsAdapter.getInitialState(),
  version: VERSION,
};

export const storyboardSlice = createSlice({
  name: 'storyboard',
  initialState,
  reducers: {
    itemAdded(state, action) {
      const { layerId, layerIndex, ...item } = action.payload;

      if (item) {
        const { startTime, duration, trimStart } = item;
        if (startTime != null) item.startTime = decimalTruncate(startTime);
        if (duration != null) item.duration = decimalTruncate(duration);
        if (trimStart != null) item.trimStart = decimalTruncate(trimStart);
      }

      const font = getFontForTextVariantFromStyles(
        state.styles || {},
        item.textVariant
      );

      if (item.mediaType === MediaTypes.TEXT && font != null) {
        updateCustomFontUsages(state, font, item.id);
      }

      addItemToLayer(state, item, layerId, layerIndex);

      const seekTime = getItemSeekTime(
        _itemsSelectors.selectById(state, item.id)
      );
      Renderer.getInstance().seek(seekTime);
    },
    itemDuplicated(state, action) {
      const { id: itemId, newItemId } = action.payload;

      const layer = _selectLayerByItemId(state, itemId);
      const item = _itemsSelectors.selectById(state, itemId);

      const startTime = item.startTime + item.duration;
      const duplicate = { ...item, id: newItemId, startTime };

      addItemToLayer(state, duplicate, layer.id);
    },
    itemMoved(state, action) {
      let { id: itemId, toLayerId, toLayerIndex, changes } = action.payload;

      if (changes) {
        const { startTime, duration, trimStart } = changes;
        if (startTime != null) changes.startTime = decimalTruncate(startTime);
        if (duration != null) changes.duration = decimalTruncate(duration);
        if (trimStart != null) changes.trimStart = decimalTruncate(trimStart);
      }

      const item = _itemsSelectors.selectById(state, itemId);
      const sanitizedFadeOptions = sanitizeFadeOptions(item, changes);
      itemsAdapter.updateOne(state.items, {
        id: itemId,
        changes: sanitizedFadeOptions,
      });

      // if toLayerId is not specified, we assume the item is moving
      // within the same layer that it already exists in
      if (!toLayerId) {
        const layer = _selectLayerByItemId(state, itemId);
        toLayerId = layer.id;
      }

      removeItemFromLayer(state, itemId);
      addItemToLayer(
        state,
        { ...item, ...sanitizedFadeOptions },
        toLayerId,
        toLayerIndex
      );
      removeEmptyLayers(state);
    },
    itemRemoved(state, action) {
      const itemId = action.payload;
      removeItemFromLayer(state, itemId);
      removeEmptyLayers(state);
    },
    itemReplaced(state, action) {
      const { id: itemId, replacement } = action.payload;

      const layer = _selectLayerByItemId(state, itemId);

      removeItemFromLayer(state, itemId);
      addItemToLayer(state, replacement, layer.id);
    },
    itemSplit(state, action) {
      const { id: itemId, time, newItemId } = action.payload;

      const layer = _selectLayerByItemId(state, itemId);
      const item = _itemsSelectors.selectById(state, itemId);

      if (time < item.startTime || time > item.startTime + item.duration) {
        // trim time exists outside of item, ignore split
        return;
      }

      const [left, right] = splitItem(item, time, newItemId);

      const leftItem = sanitizeFadeOptions({}, { ...left, fadeOut: 0 });
      const rightItem = sanitizeFadeOptions({}, { ...right, fadeIn: 0 });

      removeItemFromLayer(state, itemId);
      addItemToLayer(state, leftItem, layer.id);
      addItemToLayer(state, rightItem, layer.id);
    },
    itemUpdated(state, action) {
      itemsAdapter.updateOne(state.items, action.payload);
    },
    fontRemoved(state, action) {
      const fontFamily = action.payload;
      const textItems = _selectItemsByMediaType(state, MediaTypes.TEXT);
      const itemsToUpdate = textItems.reduce((itemsToUpdate, textItem) => {
        if (textItem.style?.fontFamily === fontFamily) {
          const fallbackFont = Fonts.FALLBACK_FONT_BOLD; //TODO; when TextItems have a weight property, we can do smarter fallbacks
          itemsToUpdate.push({
            id: textItem.id,
            changes: {
              style: {
                ...textItem.style,
                ...getStylePropertiesFromFont(fallbackFont),
              },
            },
          });
        }
        return itemsToUpdate;
      }, []);

      itemsAdapter.updateMany(state.items, itemsToUpdate);
      customFontsAdapter.removeOne(state.customFonts, fontFamily);
    },
    nameUpdated(state, action) {
      if (action.payload) {
        state.name = action.payload;
      }
    },
    ratioUpdated(state, action) {
      // Re-scales text item font sizes
      const newAspectRatio = parseAspectRatio(action.payload);
      const oldAspectRatio = parseAspectRatio(state.ratio);
      const scale = newAspectRatio / oldAspectRatio;
      const textItems = _selectItemsByMediaType(state, MediaTypes.TEXT);
      const updates = textItems.map((item) => ({
        id: item.id,
        changes: { fontSize: scaleText(item.fontSize, scale) },
      }));

      state.ratio = action.payload;
      itemsAdapter.updateMany(state.items, updates);
    },
    stylesApplied(state, action) {
      const styles = action.payload;

      const videoItemsWithTint = filter(
        _selectItemsByMediaType(state, MediaTypes.VIDEO),
        (item) => item.filters?.tint != null
      );
      const videoItemChanges = videoItemsWithTint.map((item) => ({
        id: item.id,
        changes: { filters: { tint: styles.videoTint } },
      }));

      const imageItemsWithTint = filter(
        _selectItemsByMediaType(state, MediaTypes.IMAGE),
        (item) => item.filters?.tint != null
      );
      const imageItemChanges = imageItemsWithTint.map((item) => ({
        id: item.id,
        changes: { filters: { tint: styles.imageTint } },
      }));

      itemsAdapter.updateMany(state.items, [
        ...videoItemChanges,
        ...imageItemChanges,
      ]);

      const textItems = _selectItemsByMediaType(state, MediaTypes.TEXT);
      textItems.forEach((item) => {
        const font = getFontForTextVariantFromStyles(styles, item.textVariant);
        font && updateCustomFontUsages(state, font, item.id);
      });
      const textItemChanges = textItems.map((item) => ({
        id: item.id,
        changes: getTextItemChangesForStyles(item, styles),
      }));
      itemsAdapter.updateMany(state.items, textItemChanges);

      // Update styles
      state.styles = styles;
    },
    stylesUpdated(state, action) {
      state.styles = action.payload;
    },
    textItemUpdated(state, action) {
      const { id, changes, font = null } = action.payload;
      font && updateCustomFontUsages(state, font, id);
      itemsAdapter.updateOne(state.items, { id, changes });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LOAD_PROJECT_REQUESTED, () => {
        return initialState;
      })
      .addCase(LOAD_PROJECT_COMPLETED, (state, action) => {
        return action.payload.storyboard;
      })
      .addCase(LOAD_PROJECT_FAILED, () => {
        return initialState;
      })
      .addCase(CLEAR_SELECTED_PROJECT, () => {
        return initialState;
      });
  },
});

/* UTILITIES */

function addItemToLayer(state, item, layerId, layerIndex) {
  const layerExists = !!_layersSelectors.selectById(state, layerId);
  if (!layerExists) {
    createLayer(state, layerId, layerIndex, item.mediaType);
  }

  const itemsByLayer = _selectItemsByLayerId(state, layerId);
  const items = addItem(itemsByLayer, item);

  itemsAdapter.setMany(state.items, items);
  layersAdapter.updateOne(state.layers, {
    id: layerId,
    changes: { itemIds: map(items, 'id') },
  });
}

function createLayer(state, layerId, index, mediaType) {
  const type = mapMediaToLayer(mediaType);
  state.layers.ids.splice(index, 0, layerId);
  state.layers.entities[layerId] = { id: layerId, type, itemIds: [] };
}

function removeEmptyLayers(state) {
  const layerIdsForRemoval = map(
    filter(state.layers.entities, (layer) => isEmpty(layer.itemIds)),
    'id'
  );
  layersAdapter.removeMany(state.layers, layerIdsForRemoval);
}

function removeItemFromLayer(state, itemId) {
  const layer = _selectLayerByItemId(state, itemId);

  itemsAdapter.removeOne(state.items, itemId);
  layersAdapter.updateOne(state.layers, {
    id: layer.id,
    changes: { itemIds: without(layer.itemIds, itemId) },
  });
}

function updateCustomFontUsages(state, font, itemId) {
  // Remove from previous font if exists
  const item = _itemsSelectors.selectById(state, itemId);
  const currentFont = _customFontSelectors.selectById(
    state,
    item?.style.fontFamily
  );
  if (!!currentFont && currentFont.name !== font.name) {
    customFontsAdapter.updateOne(state.customFonts, {
      id: currentFont.name,
      changes: { itemIds: without(currentFont.itemIds, itemId) },
    });
  }

  // If not custom font (i.e. does not have "url" property), return
  if (!font.url) {
    return;
  }

  // If item uses custom font, track its usage in customFonts
  const newFont = _customFontSelectors.selectById(state, font.name);
  customFontsAdapter.upsertOne(state.customFonts, {
    ...font,
    itemIds: [...(newFont?.itemIds || []), itemId],
  });
}

/**
 * @param {TextItem} textItem
 * @param {Styles} styles
 * @return {TextItem}
 */
export function getTextItemChangesForStyles(textItem, styles = {}) {
  const fontToApply = getFontForTextVariantFromStyles(
    styles,
    textItem.textVariant
  );
  const animation = getAnimationByValue(textItem.animationPreset);
  const changes = {
    style: {
      ...textItem.style,
      ...getStylePropertiesFromFont(fontToApply),
    },
    primaryColor: styles.primaryTextColor,
    secondaryColor: animation?.showSecondaryColor
      ? styles.secondaryTextColor
      : undefined,
    dropShadow: animation?.showDropShadow
      ? fontToApply?.dropShadow
      : DropShadow.NONE,
  };
  // Only change the highight color if a highlight effect is currently present
  if (animation?.showHighlight && !!textItem.highlightColor) {
    changes.highlightColor = styles.secondaryTextColor;
  }
  return changes;
}

/* ACTIONS */

export const actions = storyboardSlice.actions;
export const {
  itemAdded,
  itemDuplicated,
  itemMoved,
  itemRemoved,
  itemReplaced,
  itemSplit,
  itemUpdated,
  fontRemoved,
  nameUpdated,
  ratioUpdated,
  stylesApplied,
  stylesUpdated,
  textItemUpdated,
} = storyboardSlice.actions;

/* LOCAL SELECTORS */

const _itemsSelectors = itemsAdapter.getSelectors((state) => state.items);
const _layersSelectors = layersAdapter.getSelectors((state) => state.layers);
const _customFontSelectors = customFontsAdapter.getSelectors(
  (state) => state.customFonts
);

function _selectItemsByLayerId(state, layerId) {
  const layer = _layersSelectors.selectById(state, layerId);
  const itemIds = layer?.itemIds || [];
  return itemIds.map((id) => _itemsSelectors.selectById(state, id));
}

function _selectItemsByMediaType(state, mediaType) {
  const items = _itemsSelectors.selectAll(state);
  return filter(items, { mediaType });
}

function _selectLayerByItemId(state, itemId) {
  const layers = _layersSelectors.selectAll(state);
  return find(layers, (layer) => layer.itemIds.includes(itemId));
}

/* GLOBAL SELECTORS */

export const {
  selectAll: selectAllItems,
  selectById: selectItemById,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal: selectItemTotal,
} = itemsAdapter.getSelectors((state) => state.storyboard.present.items);
export const {
  selectAll: selectAllLayers,
  selectById: selectLayerById,
  selectEntities: selectLayerEntities,
  selectIds: selectLayerIds,
  selectTotal: selectLayerTotal,
} = layersAdapter.getSelectors((state) => state.storyboard.present.layers);
export const {
  selectAll: selectAllCustomFonts,
  selectById: selectCustomFontById,
  selectEntities: selectCustomFontEntities,
  selectIds: selectCustomFontIds,
  selectTotal: selectCustomFontTotal,
} = customFontsAdapter.getSelectors(
  (state) => state.storyboard.present.customFonts
);

// this selector for the editor slice is defined here to prevent a circular dependency
export const selectActiveItemId = (state) => state.editor.activeItemId;

export const selectStoryboard = (state) => state.storyboard.present;

export const selectName = (state) => state.storyboard.present.name;

export const selectRatio = (state) => state.storyboard.present.ratio;

export const selectStyles = (state) => state.storyboard.present.styles;

export const selectCanUndo = (state) => state.storyboard.past.length > 0;

export const selectCanRedo = (state) => state.storyboard.future.length > 0;

export const selectIsActiveItem = createSelector(
  [selectActiveItemId, (state, itemId) => itemId],
  (activeItemId, itemId) => activeItemId === itemId
);

export function selectItemsByLayerId(state, layerId) {
  const layer = selectLayerById(state, layerId);
  return layer.itemIds.map((itemId) => selectItemById(state, itemId));
}

export const makeSelectItemsByLayerId = () => {
  const selectItemsByLayerId = createSelector(
    [selectLayerEntities, selectItemEntities, (state, layerId) => layerId],
    (layers, items, layerId) =>
      layers[layerId].itemIds.map((itemId) => items[itemId])
  );

  return selectItemsByLayerId;
};

export function selectItemsByMediaType(state, mediaType) {
  const storyboard = selectStoryboard(state);
  return _selectItemsByMediaType(storyboard, mediaType);
}

export const selectAllLayersWithItems = createSelector(
  selectAllLayers,
  selectItemEntities,
  (layers, items) => {
    return layers.map((layer) => ({
      ...layer,
      items: layer.itemIds.map((itemId) => items[itemId]),
    }));
  }
);

export const selectActiveItem = createSelector(
  selectItemEntities,
  selectActiveItemId,
  (items, activeItemId) => items[activeItemId]
);

export const selectIsAtLayerLimit = createSelector(
  selectLayerTotal,
  (total) => total >= LAYER_LIMIT
);

export const selectIsNearLayerLimit = createSelector(
  selectLayerTotal,
  (total) => total >= LAYER_LIMIT - 5
);

export const selectIsStoryboardEmpty = createSelector(
  selectItemTotal,
  (itemTotal) => itemTotal === 0
);

export const selectStoryboardDuration = createSelector(
  selectItemEntities,
  (itemEntities) => getStoryboardDuration(itemEntities)
);

export const selectIsOverMaxDuration = createSelector(
  selectStoryboardDuration,
  (total) => total >= MAX_STORYBOARD_DURATION
);

export default storyboardSlice.reducer;
