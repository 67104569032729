import { floor } from 'lodash';

/**
 * Returns a number that is truncated to the specified decimal place.
 * @param {number} value
 * @param {number} decimalPlace Defaults to 3.
 * @returns {number} Truncated value
 */

export default function decimalTruncate(value, decimalPlace = 3) {
  const truncated = floor(value, decimalPlace);

  if (isNaN(truncated)) {
    console.warn('Passed in value must be a number');
    return value;
  }

  return truncated;
}
