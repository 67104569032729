import PropTypes from 'prop-types';
import { memo } from 'react';

import useProjects from '../../hooks/useProjects';
import { useRecentColors } from '../colorPicker/useRecentColors';
import ConfirmationDialog from './ConfirmationDialog';

function ProjectDeleteDialog(props) {
  const { currentPage, onClose, open, projectId } = props;
  const { deleteProject } = useProjects(currentPage);
  const { removeRecentColors } = useRecentColors();

  const handleConfirm = async () => {
    await deleteProject(projectId);
    removeRecentColors(projectId);
  };

  return (
    <ConfirmationDialog
      title="Delete Project?"
      text="You won't be able to recover it later."
      confirmButtonText="Delete"
      danger
      showCancel
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
    />
  );
}

ProjectDeleteDialog.propTypes = {
  currentPage: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  projectId: PropTypes.string,
};

export default memo(ProjectDeleteDialog);
