import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { Alert as MUIAlert } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
    borderLeftWidth: theme.spacing(1),
    borderLeftStyle: 'solid',
    boxShadow: ({ shadow }) => shadow && theme.shadows[3],
  },
  error: {
    color: theme.palette.error.dark,
    borderLeftColor: theme.palette.error.main,
  },
  info: {
    color: theme.palette.info.dark,
    borderLeftColor: theme.palette.info.main,
  },
  success: {
    color: theme.palette.success.dark,
    borderLeftColor: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.dark,
    borderLeftColor: theme.palette.warning.main,
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: '1.5rem',
  },
  action: {
    alignItems: 'flex-start',
    marginRight: 0,
    paddingLeft: theme.spacing(3.5),
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

const iconMapping = {
  error: <ErrorRoundedIcon fontSize="inherit" />,
  info: <InfoRoundedIcon fontSize="inherit" />,
  success: <CheckCircleRoundedIcon fontSize="inherit" />,
  warning: <WarningRoundedIcon fontSize="inherit" />,
};

const Alert = forwardRef((props, ref) => {
  const { className, onClose, shadow, ...rest } = props;
  const classes = useStyles({ shadow });
  return (
    <MUIAlert
      className={clsx(className, classes.alert)}
      classes={{
        standardError: classes.error,
        standardInfo: classes.info,
        standardSuccess: classes.success,
        standardWarning: classes.warning,
        icon: classes.icon,
        action: classes.action,
      }}
      iconMapping={iconMapping}
      action={
        onClose && (
          <IconButton
            className={classes.close}
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseRoundedIcon fontSize="inherit" />
          </IconButton>
        )
      }
      {...rest}
      ref={ref}
    />
  );
});

Alert.propTypes = {
  onClose: PropTypes.func,
  shadow: PropTypes.bool,
};

export default Alert;
