import { Button, IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BinIcon from '@videoblocks/react-icons/Bin';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ColorPickerPopover from '../../../components/colorPicker/ColorPickerPopover';
import { DEFAULT_COLOR_OVERLAY_TINT } from '../../../constants/StylesConstants';
import { getItemEditProps, trackEvent } from '../../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../../events/tags';
import { itemUpdated, selectStyles } from '../../../slices/storyboardSlice';
import { checkMediaItem } from '../../../utils/mediaUtils';
import PropertiesCard from './PropertiesCard';

const useStyles = makeStyles((theme) => ({
  removeButton: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(1.25),
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function FilterPropertiesCard({ activeItem }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const overlayStyles = useSelector(selectStyles);
  const { isImage } = checkMediaItem(activeItem);

  const tint = activeItem.filters?.tint;
  const tintProperty = isImage ? 'imageTint' : 'videoTint';

  const handleAddButtonClick = () => {
    const tint = overlayStyles?.[tintProperty] ?? DEFAULT_COLOR_OVERLAY_TINT;

    dispatch(
      itemUpdated({ id: activeItem.id, changes: { filters: { tint } } })
    );

    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'filters', { tint })
    );
  };

  const handleColorChange = (tint, isBranded, isRecent) => {
    dispatch(
      itemUpdated({ id: activeItem.id, changes: { filters: { tint } } })
    );

    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'filters', { tint, isBranded, isRecent })
    );
  };

  const handleRemoveButtonClick = () => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { filters: {} } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'filters', { tint: null })
    );
  };

  return (
    <PropertiesCard title="Filter">
      {tint ? (
        <>
          <ColorPickerPopover color={tint} onChange={handleColorChange} />
          <IconButton
            className={classes.removeButton}
            aria-label="remove"
            onClick={handleRemoveButtonClick}
          >
            <SvgIcon component={BinIcon} fontSize="small" />
          </IconButton>
        </>
      ) : (
        <Button variant="outlined" fullWidth onClick={handleAddButtonClick}>
          Add color overlay
        </Button>
      )}
    </PropertiesCard>
  );
}

FilterPropertiesCard.propTypes = {
  activeItem: PropTypes.object.isRequired,
};
