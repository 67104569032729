import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import * as ContentTypes from '../../constants/ContentTypes';
import { CommonFilters } from '../../constants/SearchFilters';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  changeContentType,
  changeFilters,
  removeFilter,
  resetContentType,
  selectContentType,
  selectDrawer,
  selectFilters,
  selectKeywords,
} from '../../features/assetDrawer/assetDrawerSlice';
import useVideoCategories from '../../hooks/useVideoCategories';
import { findLabel } from '../../utils/searchUtils';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingBottom: theme.spacing(1),
  },
  chips: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const contentTypeLabels = [
  { id: ContentTypes.FOOTAGE, name: 'Footage' },
  { id: ContentTypes.MOTION_BACKGROUND, name: 'Backgrounds' },
];

export default function VideoSearchFilters(props) {
  const dispatch = useDispatch();
  const contentType = useSelector(selectContentType);
  const drawer = useSelector(selectDrawer);
  const filters = useSelector(selectFilters);
  const keywords = useSelector(selectKeywords);

  const { editable } = props;
  const { category = '' } = filters;
  const { categories = [], contentTypes = [] } = useVideoCategories();
  const classes = useStyles();
  const mediaType = MediaTypes.VIDEO;

  const handleChange = (filterName) => (event) => {
    const oldValue = filters[filterName];
    const filterValue = event.target.value.toString();
    dispatch(changeFilters({ ...filters, [filterName]: filterValue }));
    if (filterValue !== oldValue) {
      trackEvent(SIDE_DRAWER.FILTER_SELECT, {
        drawer,
        filterName,
        filterValue,
        mediaType,
      });
      trackEvent(SIDE_DRAWER.SEARCH_FILTERS, {
        text: keywords,
        ...filters,
        [filterName]: filterValue,
        contentType,
        mediaType,
        drawer,
      });
    }
  };

  const handleContentTypeChange = (event) => {
    const oldValue = contentType;
    const filterValue = event.target.value.toString();
    dispatch(changeContentType(filterValue));
    if (filterValue !== oldValue) {
      trackEvent(SIDE_DRAWER.FILTER_SELECT, {
        drawer,
        filterName: 'contentType',
        filterValue,
        mediaType,
      });
      trackEvent(SIDE_DRAWER.SEARCH_FILTERS, {
        text: keywords,
        ...filters,
        //contentType has not been updated to the filterValue at this point
        contentType: filterValue,
        mediaType,
        drawer,
      });
    }
  };

  const handleChipDelete = (filterName) => {
    const oldValue = filters[filterName];
    dispatch(removeFilter(filterName));
    trackEvent(SIDE_DRAWER.FILTER_REMOVE, {
      drawer,
      filterName,
      filterValue: oldValue,
      mediaType,
    });
  };

  const handleContentTypeDelete = () => {
    const oldValue = contentType;
    dispatch(resetContentType());
    trackEvent(SIDE_DRAWER.FILTER_REMOVE, {
      drawer,
      filterName: 'contentType',
      filterValue: oldValue,
      mediaType,
    });
  };

  const handleOpen = (filterName) => () => {
    trackEvent(SIDE_DRAWER.FILTER_OPEN, {
      drawer,
      filterName,
      filterValue: filters[filterName] || '',
      mediaType,
    });
  };

  const handleContentTypeOpen = () => {
    trackEvent(SIDE_DRAWER.FILTER_OPEN, {
      drawer,
      filterName: 'contentType',
      filterValue: contentType || '',
      mediaType,
    });
  };

  if (!editable) {
    if (!contentType && !category) {
      return null;
    }
    return (
      <div className={classes.chips}>
        {contentType && (
          <Chip
            label={findLabel(contentTypeLabels, contentType)}
            onDelete={() => handleContentTypeDelete()}
          />
        )}
        {category && (
          <Chip
            label={findLabel(categories, category)}
            onDelete={() => handleChipDelete(CommonFilters.CATEGORY)}
          />
        )}
      </div>
    );
  }

  return (
    <Grid className={classes.grid} container spacing={1}>
      <Grid item xs={6}>
        <InputLabel id="media-type-label">Media Type</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="media-type-label"
            color="secondary"
            displayEmpty
            onChange={handleContentTypeChange}
            value={contentType}
            onOpen={handleContentTypeOpen}
          >
            <MenuItem value="">All</MenuItem>
            {contentTypes.map((contentType) => (
              <MenuItem value={contentType} key={contentType}>
                {findLabel(contentTypeLabels, contentType) || contentType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="category-label">Category</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="category-label"
            color="secondary"
            displayEmpty
            onChange={handleChange(CommonFilters.CATEGORY)}
            value={category}
            onOpen={handleOpen(CommonFilters.CATEGORY)}
          >
            <MenuItem value="">None</MenuItem>
            {categories.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

VideoSearchFilters.propTypes = {
  editable: PropTypes.bool,
};
