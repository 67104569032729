import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import LoaderContainer from '../components/LoaderContainer';
import { HOME_PATH } from '../constants/Urls';
import {
  selectIsAuthenticated,
  selectIsLoading,
  selectIsStoryblocksAdmin,
} from '../selectors/user';

function AdminRoute(props) {
  const { children, ...rest } = props;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isStoryblocksAdmin = useSelector(selectIsStoryblocksAdmin);
  const isLoading = useSelector(selectIsLoading);
  const { search } = useLocation();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return <LoaderContainer />;
        }

        if (isAuthenticated && isStoryblocksAdmin) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: HOME_PATH,
              search,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default AdminRoute;
