import { useSelector } from 'react-redux';

import {
  selectIsEnterprise,
  selectIsLimitedStarterBundle,
} from '../../selectors/user';
import { selectIsOverMaxDuration } from '../../slices/storyboardSlice';
import Alert from '../Alert';
import DefaultPlanAlerts from './DefaultPlanAlerts';
import EnterprisePlanAlerts from './EnterprisePlanAlerts';
import LimitedStarterPlanAlerts from './LimitedStarterPlanAlerts';

export default function ExportAlerts() {
  const isOverMaxDuration = useSelector(selectIsOverMaxDuration);
  const isEnterprise = useSelector(selectIsEnterprise);
  const isLimitedStarterBundle = useSelector(selectIsLimitedStarterBundle);

  return (
    <>
      {isOverMaxDuration && (
        <Alert severity="warning">
          Project exceeds recommended length of 5 minutes and may experience
          export errors
        </Alert>
      )}
      {isEnterprise ? (
        <EnterprisePlanAlerts />
      ) : isLimitedStarterBundle ? (
        <LimitedStarterPlanAlerts />
      ) : (
        <DefaultPlanAlerts />
      )}
    </>
  );
}
