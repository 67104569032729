import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openUpsellConfirmationDialog } from '../features/ui/uiSlice';
import { selectUpsellSuccess } from '../selectors/user';

export default function useBillingConfirmation() {
  const dispatch = useDispatch();
  const upsellSuccess = useSelector(selectUpsellSuccess);

  useEffect(() => {
    if (upsellSuccess) {
      dispatch(openUpsellConfirmationDialog());
    }
  }, [dispatch, upsellSuccess]);
}
