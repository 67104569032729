import PropTypes from 'prop-types';

import { DEFAULT_BODY_FONT, FONT_SIZES } from '../../../constants/FontStyles';
import PropertiesSelect from '../PropertiesSelect';

function FontSizePicker({ onChange, value, label = 'Size' }) {
  const prettyFontSize = Math.round(value);

  return (
    <PropertiesSelect
      defaultValue={DEFAULT_BODY_FONT.size}
      label={label}
      hiddenOptions={[
        {
          label: `${prettyFontSize}pt`,
          value,
        },
      ]}
      id="size"
      onChange={onChange}
      options={[
        ...FONT_SIZES.map((size) => ({
          label: `${size}pt`,
          value: size,
        })),
      ]}
      value={value}
    />
  );
}

FontSizePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default FontSizePicker;
