import {
  Card,
  CardMedia,
  Container,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Switch,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import MediaPreview from '../components/MediaPreview';
import { NAVBAR_HEIGHT } from '../constants/Constants';
import useTemplate from '../hooks/useTemplate';
import parseAspectRatio from '../utils/parseAspectRatio';

export function nowTimestamp() {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  },
  card: {
    maxWidth: 200,
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 0,
  },
  storyboard: {
    padding: theme.spacing(2),
    height: theme.spacing(64),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    overflow: 'scroll',
  },
}));

export default function AdminTemplatePage() {
  const classes = useStyles();
  const { templateId } = useParams();
  const { template, editTemplate } = useTemplate(templateId);

  const {
    published_at: publishedAt,
    storyboard = {},
    thumbnail_url: thumbnailUrl,
    preview_url: previewUrl,
    ...rest
  } = template;
  const { name, ratio, items } = storyboard;

  const aspectRatio = parseAspectRatio(ratio);
  const duration = getStoryboardDuration(items?.entities);

  const handlePublishedChange = () => {
    template.published_at = template.published_at ? null : nowTimestamp();
    editTemplate(template);
  };

  return (
    <Container className={classes.container} maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="body1">Thumbnail</Typography>
              {thumbnailUrl ? (
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image={thumbnailUrl}
                    style={{ paddingTop: `${100 / aspectRatio}%` }}
                  />
                </Card>
              ) : (
                <Typography variant="body1">{'<none>'}</Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Preview</Typography>
              {previewUrl ? (
                <Card className={classes.card}>
                  <MediaPreview
                    mediaType="video"
                    objectFit="contain"
                    previewUrl={previewUrl}
                    title={name}
                  />
                </Card>
              ) : (
                <Typography variant="body1">{'<none>'}</Typography>
              )}
            </Grid>
          </Grid>
          <List>
            <ListItem>
              <strong>duration</strong>: {duration}
            </ListItem>
            {Object.entries(rest).map(([key, value]) => (
              <ListItem key={key}>
                <strong>{key}</strong>: {value ?? '<none>'}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Storyboard
          </Typography>
          <pre className={classes.storyboard}>
            {JSON.stringify(storyboard, null, 2)}
          </pre>
          <FormControlLabel
            className={classes.label}
            control={
              <Switch
                checked={!!publishedAt}
                onChange={handlePublishedChange}
              />
            }
            label="Published"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
