import { Fonts } from '@videoblocks/jelly-renderer';

export const FONT_SIZES = [16, 24, 32, 48, 64, 72, 96, 128];

export const MIN_FONT_SIZE = 10;
export const DEFAULT_DISPLAY_FONT_SIZE = 16;

export const DROP_SHADOW = {
  //These are estimates for the PIXI.js canvas equivalents in jelly-renderer
  none: '',
  light: '4px 4px 4px #A9A9A9',
  medium: '8px 8px 8px #777678',
  heavy: '12px 12px 12px #1E1E1E',
};

// #6D7683 - Not in color palette b/c existing grays do not have sufficient
// contrast against both black and white backgrounds.
const DEFAULT_TEXT_PRIMARY_COLOR = { r: 109, g: 118, b: 131, a: 1 };
const DEFAULT_TEXT_SECONDARY_COLOR = { r: 252, g: 220, b: 0, a: 1 };

export const DEFAULT_HEADER_FONT = {
  family: Fonts.FALLBACK_FONT_BOLD.family,
  name: Fonts.FALLBACK_FONT_BOLD.name,
  weight: Fonts.FALLBACK_FONT_BOLD.weight,
  size: 32,
  dropShadow: DROP_SHADOW.none,
  primaryColor: DEFAULT_TEXT_PRIMARY_COLOR,
  secondaryColor: DEFAULT_TEXT_SECONDARY_COLOR,
};
export const DEFAULT_BODY_FONT = {
  ...DEFAULT_HEADER_FONT,
  size: 24,
};

export const TEXT_VARIANT = {
  HEADER: 'header',
  BODY: 'body',
};
