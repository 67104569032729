import { Device } from '@videoblocks/events-ts/lib/storyblocks/common/Device';
import { Urls } from '@videoblocks/events-ts/lib/storyblocks/common/Urls';
import { User } from '@videoblocks/events-ts/lib/storyblocks/common/User';
import { Browser } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/Browser';
import { Navigation } from '@videoblocks/events-ts/lib/storyblocks/common/frontend/Navigation';
import { PageViewEvent } from '@videoblocks/events-ts/lib/storyblocks/navigation/PageViewEvent';
import { Project } from '@videoblocks/events-ts/lib/storyblocks/templateEditor/Project';
import { TemplateEditorErrorEvent } from '@videoblocks/events-ts/lib/storyblocks/templateEditor/TemplateEditorErrorEvent';
import { TemplateEditorEvent } from '@videoblocks/events-ts/lib/storyblocks/templateEditor/TemplateEditorEvent';
import { EventFactory } from '@videoblocks/events-ts/lib/util/EventFactory';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import { isEmpty, isString } from 'lodash';
import log from 'loglevel';
import UAParser from 'ua-parser-js';

import flags from '../features/flags/flags';
import store from '../store';
import TelemetryClient from './TelemetryClient';

function stringify(value) {
  return isString(value) ? value : JSON.stringify(value);
}

/**
 * Public senders to Kafka. Avro is the term for the serialization
 * strategy Kafka uses.
 *
 * Usages don't need to await. The application
 * process should continue whether these fail or succeed.
 */

/**
 * @param {string} tag
 * @param {Object} [options]
 * @returns {Promise<void>}
 */
export async function sendProjectEvent(tag, options = {}) {
  return TelemetryClient.sendEvent(
    buildTemplateEditorEvent({ tag, ...options })
  ).catch((error) => {
    log.error('Unable to send analytics project event', {
      tag,
      options,
      error,
    });
  });
}

/**
 * @param {Object} [options]
 * @returns {Promise<void>}
 * Allow this function to throw an error if it fails. This is necessary for
 * handling in analyticsErrorTransport and avoiding an infinite loop.
 */
export async function sendErrorEvent(options) {
  return TelemetryClient.sendEvent(buildTemplateEditorErrorEvent(options));
}

/**
 * @param {Object} [options]
 * @returns {Promise<void>}
 */
export async function sendPageViewEvent(options) {
  return TelemetryClient.sendEvent(await buildPageViewEvent(options)).catch(
    (error) => {
      log.error('Unable to send analytics page view event', {
        options,
        error,
      });
    }
  );
}

/* Helpers. Only exported for testing. */

export function getUserAvro() {
  const user = new User();
  const state = store.getState();
  user.userId = state?.user?.userId || null;
  user.subscriptionId = state?.user?.subscriptionId || null;
  user.visitorCookieId = state?.user?.visitorId || null;
  user.organizationId = state?.user?.organizationId || null;
  return user;
}

export function getTemplateIdFromStore() {
  const id = store.getState()?.storyboard?.present?.fromTemplateId;
  return isNaN(id) ? null : id;
}

/**
 * Get the serialization of the project.
 * @param {string} [projectId]
 * @returns {Project} the project avro
 */
export function getProjectAvro(projectId) {
  const project = new Project();
  const state = store.getState();

  //FIXME: MKR-321 ProjectId actually holds a string projectUid, and should be displayed as such to analytics.
  project.projectUid = state?.workspace?.selectedProjectId ?? projectId;
  if (!project.projectUid) {
    return null;
  }
  const { items } = state?.storyboard?.present || {};
  project.duration = getStoryboardDuration(items?.entities);

  return project;
}

/**
 * Build an analytics event from the tag and payload.
 * @param {AnalyticsPayload} payload
 * @returns {TemplateEditorEvent} event
 */
export function buildTemplateEditorEvent(payload) {
  const { tag, value } = payload;
  const { projectId, exportId } = value || {};
  const state = store.getState();

  const event = new TemplateEditorEvent();

  event.planId = state?.user?.user?.planId;
  event.enabledFeatureFlags = Object.entries(state?.flags ?? {})
    .filter(([flagKey, value]) => flags[flagKey]?.ffsKey && value)
    .map(([flagKey]) => flags[flagKey].ffsKey);
  event.project = getProjectAvro(projectId);
  event.exportId = exportId ? parseInt(exportId) : null;
  event.tag = tag;
  event.value = stringify(value);
  event.user = getUserAvro();
  event.meta = EventFactory.meta(TelemetryClient.getOrigin());

  return event;
}

export function buildTemplateEditorErrorEvent({
  errorContext = null,
  errorMessage = null,
  errorStackTrace = null,
  errorType,
}) {
  const event = new TemplateEditorErrorEvent();

  event.project = getProjectAvro();

  /**
   * TODO MKR-321 re-evaluate templateId as a top level property.
   * If still desired, switch data type to string in store and events.ts.
   */
  event.templateId = getTemplateIdFromStore();

  if (!isEmpty(errorContext)) event.errorContext = stringify(errorContext);
  event.errorMessage = errorMessage;
  event.errorStackTrace = errorStackTrace;
  event.errorType = errorType;

  event.user = getUserAvro();
  event.meta = EventFactory.meta(TelemetryClient.getOrigin());

  return event;
}

export function getDeviceAvro({ referrer }) {
  const device = new Device();

  const uaParser = new UAParser(navigator.userAgent);
  device.browser = new Browser();
  device.browser.userAgent = navigator.userAgent;
  device.browser.language = navigator.language;
  device.browser.platform = uaParser.getOS().name ?? '';
  device.browser.name = uaParser.getBrowser().name ?? '';

  device.navigation = new Navigation();
  device.navigation.referrerUrl = referrer;

  return device;
}

export function getUrlsAvro({ pathname, referrer }) {
  const urls = new Urls();
  urls.currentUrl = pathname;
  urls.referrerUrl = referrer;
  return urls;
}

export async function buildPageViewEvent(options) {
  const event = new PageViewEvent();

  /**
   * UTM tracking was removed from Maker in MKR-392. The event pipeline requires
   * a UtmParameter to be included with all PageViewEvents, but its properties
   * can fall back to a combo of nulls and empty strings.
   */
  const nullUtmAvro = Object.freeze({
    source: '',
    medium: '',
    campaign: '',
    version: null,
    content: '',
    vertical: '',
    language: '',
    mechanism: '',
    offer: '',
    campaign_date: null,
    google_campaign_name: '',
  });

  // TODO: missing trace, do we care?
  event.meta = EventFactory.meta(TelemetryClient.getOrigin(), null);
  event.user = getUserAvro();
  event.device = getDeviceAvro(options);
  event.urls = getUrlsAvro(options);
  event.utmParameters = nullUtmAvro;
  event.statusCode = 200;
  event.ipAddress = '';

  // Unable to match up with backend without an api call, not needed
  event.geo = null;

  // Normally pulled from SGUID & SSLID cookies, not needed
  event.searchIdentifiers = null;

  return event;
}
