import { useEffect } from 'react';

// Exported for testing purposes.
export const _thumbnailCache = new Map();

export function cacheKey(src, position, width) {
  return [src, position, width].join();
}

/**
 * Retrieve thumbnail data urls that might have been previously cached.
 * @param src
 * @param position
 * @param width
 * @returns {string|undefined}
 */
export function useGetFromThumbnailCache(src, position, width) {
  // An improvement to this approach could be to use indexeddb to store the data urls.
  return _thumbnailCache.get(cacheKey(src, position, width));
}

/**
 * Adds the loaded thumbnail to the cache.
 * @param {HTMLVideoElement} video
 * @param {string} src
 * @param {number} position
 * @param {number} width
 * @param {number} height
 */
export function useAddToThumbnailCache(video, src, position, width, height) {
  // An improvement to this approach could be to use indexeddb to store the data urls.

  useEffect(() => {
    if (!(video instanceof HTMLVideoElement)) {
      return;
    }

    let timeout;
    function onSeeked() {
      // Wait a moment after the seeked event.
      // This seems to work around safari not actually completing the seek when the event has been fired.
      // Also it removes a bit of resource usage from the moment all the thumbnails are getting rendered.
      timeout = setTimeout(() => {
        const key = cacheKey(src, position, width);
        if (_thumbnailCache.has(key)) return;

        try {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext('2d');
          context.drawImage(video, 0, 0, width, height);
          _thumbnailCache.set(key, canvas.toDataURL());
        } catch (e) {
          // Swallow caching failures.
          console.error(e);
        }
      }, 3000);
    }

    video?.addEventListener('seeked', onSeeked);

    return () => {
      clearTimeout(timeout);
      video?.removeEventListener('seeked', onSeeked);
    };
  }, [video, src, position, width, height]);
}
