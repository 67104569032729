export const selectIsProjectLoaded = (state) => state.workspace.isProjectLoaded;

export const selectAreFontsAvailable = (state) =>
  state.workspace.areFontsAvailable;

export const selectErrorMessage = (state) => state.workspace.errorMessage;

export const selectLastAutoSavedAt = (state) => state.workspace.lastAutoSavedAt;

export const selectAutoSavePending = (state) => state.workspace.autoSavePending;

// FIXME: try to replace usages of this with const { projectId, templateId } = useParams(), for more specificity
export const selectWorkspaceProjectId = (state) =>
  state.workspace.selectedProjectId;
