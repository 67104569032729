export const CommonFilters = {
  CATEGORY: 'category',
};

export const AudioFilters = {
  MOOD: 'mood',
  GENRE: 'genre',
  INSTRUMENT: 'instrument',
};

export const ImageFilters = {
  ORIENTATION: 'orientation',
};
