// Content types used by the Storyblocks Content API

// Audio
export const MUSIC = 'music';
export const SFX = 'sfx';

// Image
export const ILLUSTRATION = 'illustration';
export const PHOTO = 'photo';
export const VECTOR = 'vector';

// Video
export const FOOTAGE = 'footage';
export const MOTION_BACKGROUND = 'motionbackgrounds';
export const TEMPLATE = 'templates';
export const VR = 'vr360';

export function getContentType(contentType) {
  switch (contentType) {
    case SFX:
    case MOTION_BACKGROUND:
    case MUSIC:
    case ILLUSTRATION:
    case PHOTO:
    case VECTOR:
    case FOOTAGE:
    case TEMPLATE:
    case VR:
      return contentType;
    case 'sound-effects':
      return SFX;
    case 'motion-backgrounds':
    case 'motionbackground':
      return MOTION_BACKGROUND;
    default:
      return undefined;
  }
}
