import { isEmpty, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';

import {
  ASSET_DRAWER_WIDTH,
  ASSET_RAIL_WIDTH,
  NAVBAR_HEIGHT,
  PLAYBACK_CONTROLS_HEIGHT,
  PREVIEW_WIDTH_MARGIN,
  PREVIEW_MIN_WIDTH,
  PREVIEW_MIN_HEIGHT,
  PREVIEW_TOP_MARGIN,
  PROPERTIES_PANEL_WIDTH,
  TIMELINE_DEFAULT_HEIGHT,
} from '../../constants/Constants';
import { DEFAULT_PROJECT_RATIO } from '../../constants/Storyboard';
import useActiveItem from '../../hooks/useActiveItem';
import parseAspectRatio from '../../utils/parseAspectRatio';
import { selectOpen } from '../assetDrawer/assetDrawerSlice';

export function useUnoccupiedPreviewDimensions(
  timelineHeight = TIMELINE_DEFAULT_HEIGHT
) {
  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const occupiedWidth = sum([
    ASSET_RAIL_WIDTH,
    useSelector(selectOpen) ? ASSET_DRAWER_WIDTH : 0,
    isEmpty(useActiveItem()) ? 0 : PROPERTIES_PANEL_WIDTH,
    PREVIEW_WIDTH_MARGIN,
  ]);

  const occupiedHeight = sum([
    NAVBAR_HEIGHT,
    PREVIEW_TOP_MARGIN,
    PLAYBACK_CONTROLS_HEIGHT,
    timelineHeight,
  ]);

  return {
    height: Math.max(windowHeight - occupiedHeight, 0),
    width: Math.max(windowWidth - occupiedWidth, 0),
  };
}

export function calculatePreviewDimensions(props) {
  const height = Math.max(props?.height, PREVIEW_MIN_HEIGHT);
  const width = Math.max(props?.width, PREVIEW_MIN_WIDTH);
  const aspectRatio = props?.aspectRatio || DEFAULT_PROJECT_RATIO;
  const sourceWidth = parseAspectRatio(aspectRatio);
  const sourceHeight = 1;

  const verticalScale = height / sourceHeight;
  const horizontalScale = width / sourceWidth;
  const scale = Math.min(verticalScale, horizontalScale);

  return {
    height: sourceHeight * scale,
    width: sourceWidth * scale,
  };
}

export default function usePreviewDimensions(aspectRatio, timelineHeight) {
  const { height, width } = useUnoccupiedPreviewDimensions(timelineHeight);

  return calculatePreviewDimensions({ height, width, aspectRatio });
}
