import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import {
  selectAutoSavePending,
  selectLastAutoSavedAt,
} from '../../selectors/workspace';

const useStyles = makeStyles((theme) => ({
  container: {
    width: theme.spacing(8), // reserve enough space for the longer of the two inner htmls
    marginLeft: theme.spacing(2),
  },
}));

export default function AutosaveNotification() {
  const classes = useStyles();

  const lastAutoSavedAt = useSelector(selectLastAutoSavedAt);
  const autoSavePending = useSelector(selectAutoSavePending);

  return (
    <span className={classes.container}>
      {autoSavePending ? 'Saving...' : lastAutoSavedAt && 'Saved'}
    </span>
  );
}
