import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-use';

import parseFlags from './parse';
import { setFlags } from './slice';
import useFFSFlags from './useFFSFlags';

/**
 * Load feature flags into redux store
 */
export default function FlagLoader() {
  const dispatch = useDispatch();
  const location = useLocation();
  const ffsFlags = useFFSFlags();

  useEffect(() => {
    const query = queryString.parse(location.search);
    const queryFlags = parseFlags(query.flags);

    // query params override FFS flags
    const featureFlags = {
      ...ffsFlags,
      ...queryFlags,
    };
    if (!isEmpty(featureFlags)) {
      dispatch(setFlags(featureFlags));
    }
  }, [dispatch, ffsFlags, location.search]);

  return null;
}
