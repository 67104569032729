import PropTypes from 'prop-types';
import { memo } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

function BrandUnsavedDialog(props) {
  const { brandName, open, onClose, onConfirm } = props;

  return (
    <ConfirmationDialog
      title="Exit Without Saving?"
      text={`You will lose any edits that you’ve made to “${brandName}.”`}
      confirmButtonText="Exit"
      cancelButtonText="Continue Editing"
      showCancel
      showClose
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
    />
  );
}

BrandUnsavedDialog.propTypes = {
  brandName: PropTypes.string,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

export default memo(BrandUnsavedDialog);
