import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectEmail, selectUserId } from '../selectors/user';

export default function SentryScoper() {
  const id = useSelector(selectUserId);
  const email = useSelector(selectEmail);
  useEffect(
    () => Sentry.configureScope((scope) => scope.setUser({ id, email })),
    [id, email]
  );
  return null;
}
