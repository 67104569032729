import { IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RatingStar from '@videoblocks/react-icons/RatingStar';
import RatingStarFilled from '@videoblocks/react-icons/RatingStarFilled';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[700],
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    opacity: 0.8,
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
      opacity: 1,
    },
  },
}));

export default function FeaturedButton(props) {
  const { isFeatured, ...rest } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.root, {
        'featured-button-persist': isFeatured,
        'featured-button-on-hover': !isFeatured,
      })}
      {...rest}
    >
      <SvgIcon
        component={isFeatured ? RatingStarFilled : RatingStar}
        fontSize="inherit"
      />
    </IconButton>
  );
}
