import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  resultsGrid: {
    display: 'grid',
    gridTemplateColumns: ({ columns }) => `repeat(${columns}, 1fr)`,
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    userSelect: 'none',
  },
}));

export default function ResultsGrid({ columns = 2, className, ...props }) {
  const classes = useStyles({ columns });
  return <div className={clsx(classes.resultsGrid, className)} {...props} />;
}
