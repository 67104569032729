import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LAYER_HEIGHT, LAYER_TALL_HEIGHT } from '../../constants/Constants';
import { selectZoom } from '../../slices/editorSlice';
import { checkMediaItem } from '../../utils/mediaUtils';
import { secondsToPixels } from '../../utils/timelineUtils';
import FilmStrip from '../FilmStrip';
import Waveform from '../Waveform';

const useStyles = makeStyles((theme) => ({
  media: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none',
  },
  offsetContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  imageThumbs: {
    height: '100%',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'repeat-x',
  },
}));

export default function TimelineItemMedia(props) {
  const { item, onError, startTimeOffset } = props;
  const { isAudio, isImage, isVideo, isVisualMedia } = checkMediaItem(item);
  const zoom = useSelector(selectZoom);
  const theme = useTheme();
  const classes = useStyles();

  const width = secondsToPixels(item.maxDuration, zoom);
  const height = isVisualMedia ? LAYER_TALL_HEIGHT : LAYER_HEIGHT;

  // negative offset only applies to trimmed media (audio & video)
  const left = -secondsToPixels(item.trimStart + startTimeOffset, zoom);
  const offsetStyle = { width, left };

  useEffect(() => {
    let img;
    if (isImage) {
      img = new Image();
      img.onerror = onError;
      img.src = item.source;
    }
  }, [isImage, item.source, onError]);

  return (
    <div className={classes.media}>
      {isAudio && (
        <div className={classes.offsetContainer} style={offsetStyle}>
          <Waveform
            width={width}
            height={height}
            sourceUrl={item.source}
            color={theme.palette.grey[500]}
            onError={onError}
          />
        </div>
      )}
      {isVideo && (
        <div className={classes.offsetContainer} style={offsetStyle}>
          <FilmStrip
            width={width}
            height={height}
            source={item.resolutions?._180p || item.source}
            onError={onError}
          />
        </div>
      )}
      {isImage && (
        <div
          className={classes.imageThumbs}
          style={{ backgroundImage: `url(${item.source})` }}
        />
      )}
    </div>
  );
}
