import { SvgIcon } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles((theme) => ({
  volumeIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: theme.spacing(2.5),
    display: 'flex',
    margin: theme.spacing('auto', 0),
    padding: 2,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.5),
    pointerEvents: 'none',
  },
}));

export default function TimelineItemVolumeIndicator({ volume = 0 }) {
  const classes = useStyles();
  return (
    <div className={classes.volumeIndicator}>
      {volume === 0 ? (
        <SvgIcon component={VolumeOffIcon} fontSize="inherit" />
      ) : (
        <SvgIcon component={VolumeUpIcon} fontSize="inherit" />
      )}
    </div>
  );
}
