import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { DND_ZONES } from '../../constants/Constants';
import isSupportedContentType from '../../utils/isSupportedContentType';
import AddIconButton from '../AddIconButton';
import Draggable from '../Draggable';
import MediaPreview from '../MediaPreview';
import PositionWrapper, { Positions } from '../PositionWrapper';
import DragPreview from './DragPreview';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  empty: {
    padding: theme.spacing(8, 4),
    textAlign: 'center',
    color: theme.palette.grey[400],
  },
  itemWrapper: {
    '&:not(:hover) .add-button': {
      display: 'none',
    },
  },
}));

export default function FolderDetail(props) {
  const {
    isLoading,
    items = [],
    mediaType = MediaTypes.VIDEO,
    onItemClick = () => {},
  } = props;
  const filteredItems = items.filter((item) => item.mediaType === mediaType);
  const classes = useStyles();

  if (isLoading) {
    return null;
  }

  if (isEmpty(items)) {
    return <div className={classes.empty}>This folder is empty.</div>;
  }

  if (isEmpty(filteredItems)) {
    return (
      <div className={classes.empty}>
        This folder contains no {mediaType} items.
      </div>
    );
  }

  // Returns the default or lowest resolution preview since
  // this is for thumbnail preview playback on hover
  const getPreview = (item) =>
    item.preview_url ||
    item.preview_urls?.['_180p'] ||
    item.preview_urls?.['_360p'] ||
    item.preview_urls?.['_480p'] ||
    item.preview_urls?.['_720p'];

  return (
    <ResultsGrid>
      {filteredItems.map((item) => {
        const isSupported = isSupportedContentType(item.contentType);
        const disabled = !isSupported;

        const handleClick = (event) => isSupported && onItemClick(event, item);

        return (
          <Box
            key={item.id}
            position="relative"
            className={classes.itemWrapper}
          >
            <Draggable
              data={{
                item,
                overlayElement: () => (
                  <DragPreview
                    mediaType={item.mediaType}
                    previewUrl={item.preview_url}
                    thumbnailUrl={item.thumbnail_url}
                  />
                ),
                zone: DND_ZONES.DRAWER,
              }}
              disabled={disabled}
              id={item.id}
            >
              <MediaPreview
                disabled={disabled}
                duration={item.duration}
                mediaType={item.mediaType}
                previewUrl={getPreview(item)}
                thumbnailUrl={item.thumbnail_url}
                title={item.title}
              />
            </Draggable>
            {!disabled && (
              <PositionWrapper position={Positions.BOTTOM_RIGHT}>
                <AddIconButton
                  aria-label={`Add ${item.mediaType}`}
                  onClick={handleClick}
                />
              </PositionWrapper>
            )}
          </Box>
        );
      })}
    </ResultsGrid>
  );
}

FolderDetail.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array,
  mediaType: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]),
  onItemClick: PropTypes.func,
};
