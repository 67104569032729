import { ActionTypes as UndoActionTypes } from 'redux-undo';

import { actions as StoryboardActions } from '../slices/storyboardSlice';

const storyboardActionTypes = Object.values(StoryboardActions).map(
  (action) => action().type
);

/**
 * These actions should all trigger an auto-save of the project in the editor.
 *
 * @type {*[]}
 */
export const AUTO_SAVE_ACTIONS = [
  ...storyboardActionTypes,
  UndoActionTypes.REDO,
  UndoActionTypes.UNDO,
];
