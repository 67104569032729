import { LayerTypes, MediaTypes } from '@videoblocks/jelly-renderer';

export function checkMediaItem(mediaItem) {
  return checkMediaType(mediaItem?.mediaType);
}

export function checkMediaType(mediaType) {
  const isAnimation = mediaType === MediaTypes.ANIMATION;
  const isAudio = mediaType === MediaTypes.AUDIO;
  const isImage = mediaType === MediaTypes.IMAGE;
  const isText = mediaType === MediaTypes.TEXT;
  const isVideo = mediaType === MediaTypes.VIDEO;

  // "interactive" means the media can be clicked on and interacted with via the canvas
  const isInteractive = isImage || isText || isVideo;

  // visual "media" is separate from other visual elements (like text and animations)
  const isVisualMedia = isImage || isVideo;

  return {
    isAnimation,
    isAudio,
    isImage,
    isText,
    isVideo,
    isInteractive,
    isVisualMedia,
  };
}

export function checkLayerType(layerType) {
  const isAudio = layerType === LayerTypes.AUDIO;
  const isText = layerType === LayerTypes.TEXT;
  const isVideo = layerType === LayerTypes.VIDEO;

  return { isAudio, isText, isVideo };
}
