import { Renderer } from '@videoblocks/jelly-renderer';
import { useEffect, useState } from 'react';

export default function useRenderer() {
  const renderer = Renderer.getInstance();
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    renderer.on(Renderer.TIMECHANGE, setTimestamp);
    return () => renderer.off(Renderer.TIMECHANGE, setTimestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { timestamp, renderer };
}
