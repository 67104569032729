import { Box, Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import clsx from 'clsx';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

import toUpperHexString from '../../utils/toUpperHexString';
import ColorPicker from './ColorPicker';
import ColorPreview from './ColorPreview';
import { useRecentColors } from './useRecentColors';

const useStyles = makeStyles((theme) => ({
  colorInput: {
    padding: theme.spacing(0.875, 2),
    fontWeight: theme.typography.fontWeightRegular,
    borderWidth: 1,
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
    '&.Mui-disabled': {
      color: theme.palette.common.black,
    },
  },
  endIcon: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  colorIcon: {
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(10),
    },
  },
}));

export default function ColorPickerPopover({
  color,
  disabled = false,
  showNoneOption = true,
  onChange = () => {},
}) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'color-picker-popover',
  });
  const hex = toUpperHexString(color);
  const rgba = tinycolor(color).toRgbString();
  const classes = useStyles();
  const colorIcon =
    hex === 'None' ? (
      <FormatColorResetIcon />
    ) : (
      <ColorPreview color={color} rounded />
    );

  const { addRecentColor } = useRecentColors();

  const onColorChange = (color, isBranded = false, isRecent = false) => {
    if (color === undefined) {
      popupState.close();
    }
    onChange(color, isBranded, isRecent);
  };

  const endIconClass =
    hex === 'None' ? classes.endIcon : clsx(classes.endIcon, classes.colorIcon);

  return (
    <Box width="100%">
      <Button
        disabled={disabled}
        className={classes.colorInput}
        classes={{ disabled: classes.disabled, endIcon: endIconClass }}
        variant="outlined"
        fullWidth
        endIcon={colorIcon}
        data-testid={`popover-trigger-${hex}`}
        {...bindTrigger(popupState)}
      >
        {hex}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionProps={{
          onExit: () => addRecentColor(rgba),
        }}
      >
        <ColorPicker
          color={color}
          onChange={onColorChange}
          showNoneOption={showNoneOption}
        />
      </Popover>
    </Box>
  );
}

ColorPickerPopover.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  showNoneOption: PropTypes.bool,
  onChange: PropTypes.func,
};
