import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DND_ZONES } from '../../constants/Constants';
import { MINIMUM_ITEM_DURATION } from '../../constants/Storyboard';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { TIMELINE } from '../../events/tags';
import { selectZoom, setActiveItemId } from '../../slices/editorSlice';
import {
  selectIsActiveItem,
  selectItemById,
  itemMoved,
} from '../../slices/storyboardSlice';
import { checkMediaItem } from '../../utils/mediaUtils';
import { findSnappableOffset } from '../../utils/snappingUtils';
import { pixelsToSeconds, secondsToPixels } from '../../utils/timelineUtils';
import Draggable from '../Draggable';
import TimelineItemDragPreview from './TimelineItemDragPreview';
import TimelineItemTrimControls from './TimelineItemTrimControls';
import TimelineItemView from './TimelineItemView';

/**
 * Controls are minimized for timeline items below this width (px)
 */
const MIN_CONTROLS_WIDTH = 64;

function TimelineItem(props) {
  const {
    id,
    layerGaps = null,
    layerId,
    snappableOffsets = [],
    setSnappedTo = () => {},
  } = props;
  const item = useSelector((state) => selectItemById(state, id));
  const selected = useSelector((state) => selectIsActiveItem(state, id));
  const zoom = useSelector(selectZoom);
  const dispatch = useDispatch();

  const { isAudio, isVideo } = checkMediaItem(item);
  const precedingGap = useMemo(
    () =>
      layerGaps?.find((gap) => gap.startTime + gap.duration === item.startTime),
    [item.startTime, layerGaps]
  );

  const [startTimeOffset, setStartTimeOffset] = useState(0);
  const [endTimeOffset, setEndTimeOffset] = useState(0);

  const handleTrimLeft = (timeDelta) => {
    const currentPixelOffset = secondsToPixels(
      item.startTime + timeDelta,
      zoom
    );
    const snappableOffset = findSnappableOffset(
      snappableOffsets,
      currentPixelOffset,
      {
        isLowerBound: true,
        excludeId: id,
      }
    );

    if (snappableOffset != null) {
      setStartTimeOffset(
        pixelsToSeconds(snappableOffset.offset, zoom) - item.startTime
      );
      setSnappedTo(snappableOffset);
    } else {
      setStartTimeOffset(timeDelta);
      setSnappedTo(null);
    }
  };

  const handleTrimRight = (timeDelta) => {
    const itemEndTime = item.startTime + item.duration;
    const currentPixelOffset = secondsToPixels(itemEndTime + timeDelta, zoom);
    const snappableOffset = findSnappableOffset(
      snappableOffsets,
      currentPixelOffset,
      {
        isUpperBound: true,
        excludeId: id,
      }
    );

    if (snappableOffset != null) {
      setEndTimeOffset(
        pixelsToSeconds(snappableOffset.offset, zoom) - itemEndTime
      );
      setSnappedTo(snappableOffset);
    } else {
      setEndTimeOffset(timeDelta);
      setSnappedTo(null);
    }
  };

  const trim = (startDelta, endDelta) => {
    const maxDuration = item.maxDuration || Number.POSITIVE_INFINITY;

    let duration = item.duration + endDelta - startDelta;
    // keep playback duration over the minimum duration
    duration = Math.max(duration, MINIMUM_ITEM_DURATION);
    // keep playback duration under the total media duration
    duration = Math.min(duration, maxDuration);

    const startTime = Math.max(item.startTime + startDelta, 0);

    const changes = { duration, startTime };

    if (isAudio || isVideo) {
      let trimStart = item.trimStart + startDelta;
      // seconds trimmed from start plus playback duration can't exceed total media duration
      trimStart = Math.min(trimStart, maxDuration - duration);
      // keep the number of seconds trimmed from the start above 0
      trimStart = Math.max(trimStart, 0);

      changes.trimStart = trimStart;
    }

    if (startDelta) {
      trackEvent(
        TIMELINE.ITEM_EDIT,
        getItemEditProps(item, 'trimStart', startDelta)
      );
    } else {
      trackEvent(
        TIMELINE.ITEM_EDIT,
        getItemEditProps(item, 'duration', endDelta)
      );
    }

    return dispatch(itemMoved({ id, changes }));
  };

  const handleTrimStart = (event) => {
    event.stopPropagation();
    dispatch(setActiveItemId(item.id));
  };

  const handleTrimStop = () => {
    if (startTimeOffset || endTimeOffset) {
      trim(startTimeOffset, endTimeOffset);
    }
    setEndTimeOffset(0);
    setStartTimeOffset(0);
    setSnappedTo(null);
  };

  const width = secondsToPixels(
    item.duration - startTimeOffset + endTimeOffset,
    zoom
  );
  const offset = secondsToPixels(item.startTime + startTimeOffset, zoom);

  const minimized = width < MIN_CONTROLS_WIDTH;

  return (
    <Draggable
      data={{
        item,
        layerId,
        overlayElement: () => (
          <TimelineItemDragPreview
            item={item}
            minimized={minimized}
            offset={offset}
            width={width}
          />
        ),
        zone: DND_ZONES.TIMELINE,
      }}
      hideOnDrag
      id={id}
      tabIndex={undefined}
    >
      <TimelineItemView
        item={item}
        minimized={minimized}
        offset={offset}
        selected={selected}
        startTimeOffset={startTimeOffset}
        width={width}
      >
        <TimelineItemTrimControls
          item={item}
          minimized={minimized}
          onStart={handleTrimStart}
          onStop={handleTrimStop}
          onTrimLeft={handleTrimLeft}
          onTrimRight={handleTrimRight}
          precedingGap={precedingGap}
        />
      </TimelineItemView>
    </Draggable>
  );
}

TimelineItem.propTypes = {
  id: PropTypes.string,
  layerId: PropTypes.string,
};

export default memo(TimelineItem);
