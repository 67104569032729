import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LayersBackIcon from '@videoblocks/react-icons/LayersBack';
import LayoutDashboard from '@videoblocks/react-icons/LayoutDashboard';
import TextIcon from '@videoblocks/react-icons/TextFormat';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectIsOrgAdmin } from '../selectors/user';
import CreateNewBrandButton from './CreateNewBrandButton';

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    maxWidth: theme.spacing(66),
  },
  bodyBrandPage: {
    '@media (max-width: 1248px)': {
      maxWidth: theme.spacing(62),
    },
  },
  bodyDrawer: {
    maxWidth: theme.spacing(52),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  },
  featureIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(24),
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1.75),
  },
  features: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(3.25),
  },
  headline: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    marginBottom: theme.spacing(3),
    '@media (max-width: 1248px)': {
      maxWidth: theme.spacing(52),
    },
  },
  headlineBrandPage: {
    maxWidth: theme.spacing(64),
  },
  tagline: {
    color: theme.palette.grey[900],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: '0.14em',
    marginBottom: theme.spacing(3),
  },
}));

export default function BrandsEmptyState(props) {
  const { navigateToBrand = () => {}, isBrandsDrawer = false } = props;
  const classes = useStyles();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  return (
    <div className={classes.container}>
      <div className={classes.tagline}>UNLOCK EFFICIENCY WITH BRANDS</div>
      <div
        className={clsx(classes.headline, {
          [classes.headlineBrandPage]: !isBrandsDrawer,
        })}
      >
        Add branding to videos in one click
      </div>
      <div
        className={clsx(classes.body, {
          [classes.bodyBrandPage]: !isBrandsDrawer,
          [classes.bodyDrawer]: isBrandsDrawer,
        })}
      >
        Create a brand that anyone in your organization can see and apply to
        their projects. Only team admins can create and edit a brand, ensuring
        consistent branding across all videos.
      </div>
      <div className={classes.features}>
        <div className={classes.featureItem}>
          <SvgIcon
            component={TextIcon}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          {`${isOrgAdmin ? 'Upload' : 'Apply'}`} custom colors, fonts, and logos
        </div>
        <div className={classes.featureItem}>
          <SvgIcon
            component={LayersBackIcon}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          {`${isOrgAdmin ? 'Share' : 'See'}`} on-brand assets{' '}
          {`${isOrgAdmin ? 'with' : 'from'}`} your team
        </div>
        <div className={classes.featureItem}>
          <SvgIcon
            component={LayoutDashboard}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          Apply a brand with one click in the editor
        </div>
      </div>
      {isOrgAdmin && (
        <CreateNewBrandButton
          navigateToBrand={navigateToBrand}
          buttonText="Create Your First Brand"
        />
      )}
    </div>
  );
}

BrandsEmptyState.propTypes = {
  navigateToBrand: PropTypes.func.isRequired,
  isBrandsDrawer: PropTypes.bool,
};
