import { Button, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Group, VideoLibrary } from '@material-ui/icons';
import PaintingPalette from '@videoblocks/react-icons/PaintingPalette';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { EDIT_TEMPLATE_PATH } from '../constants/Urls';

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.grey[900],
    fontWeight: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    maxWidth: theme.spacing(66),
    marginBottom: theme.spacing(3),
  },
  button: {
    fontSize: theme.typography.pxToRem(16),
    width: theme.spacing(32),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  featureIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(24),
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.75),
  },
  features: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(3),
  },
  headline: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    marginBottom: theme.spacing(3),
    '@media (max-width: 1248px)': {
      maxWidth: theme.spacing(52),
    },
  },
  tagline: {
    color: theme.palette.grey[900],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: '0.14em',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function TemplatesEmptyState(props) {
  const { showButton = false } = props;
  const classes = useStyles();
  const history = useHistory();

  const createNewTemplate = async () => {
    history.replace({
      pathname: `${EDIT_TEMPLATE_PATH}`,
      search: history.location.search,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.tagline}>SAVE TIME WITH TEMPLATES</div>
      <div className={classes.headline}>
        Streamline video production for you and your team
      </div>
      <div className={classes.body}>
        Create custom templates with pre-set fonts, colors, timing, and logos to
        create video faster than ever. Templates allow even non-creatives to
        make videos easily and at scale.
      </div>
      <div className={classes.features}>
        <div className={classes.featureItem}>
          <SvgIcon
            component={VideoLibrary}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          Save any project as a template
        </div>
        <div className={classes.featureItem}>
          <SvgIcon
            component={PaintingPalette}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          Easily apply Brands for consistency
        </div>
        <div className={classes.featureItem}>
          <SvgIcon
            component={Group}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          Team admins can share templates with teammates
        </div>
      </div>
      {showButton && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={createNewTemplate}
        >
          Create a Template
        </Button>
      )}
    </div>
  );
}

TemplatesEmptyState.propTypes = {
  isDrawer: PropTypes.bool,
};
