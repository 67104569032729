import { Box } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import ReplaceIcon from '@material-ui/icons/Cached';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { OFF_SCREEN } from '../../constants/Constants';
import { DEFAULT_ITEM_DURATION } from '../../constants/Storyboard';
import { selectZoom } from '../../slices/editorSlice';
import { translateX } from '../../utils/cssUtils';
import { secondsToPixels } from '../../utils/timelineUtils';

const useStyles = makeStyles((theme) => ({
  outline: {
    position: 'absolute',
    top: 0,
    height: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'currentColor',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.green[400], 0.4),
  },
}));

export default function ItemPlaceholder(props) {
  const {
    duration = DEFAULT_ITEM_DURATION,
    offset = OFF_SCREEN,
    showReplaceIcon,
  } = props;
  const zoom = useSelector(selectZoom);
  const classes = useStyles();

  const left = offset;
  const width = secondsToPixels(duration, zoom);

  const style = { transform: translateX(left), width };

  return (
    <Box position="absolute" zIndex={1} top={0} height="100%">
      <div className={classes.outline} style={style}>
        {showReplaceIcon && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <ReplaceIcon />
          </Box>
        )}
      </div>
    </Box>
  );
}

ItemPlaceholder.propTypes = {
  duration: PropTypes.number,
  offset: PropTypes.number,
  showReplaceIcon: PropTypes.bool,
};
