/*
 * Throttles a method call only after it reaches the max invokes
 * within the given interval. This allows for bursting calls
 * unlike _.throttle.
 */
export default function throttle(callback, maxInvokes = 50, perSeconds = 300) {
  let invokedCount = 0;

  return (...args) => {
    if (invokedCount >= maxInvokes) {
      // TODO: we can throw an event or just provide a callback to decide per situation
      return;
    }

    // Flush the invokedCount after a timeout
    if (invokedCount === 0) {
      setTimeout(() => {
        invokedCount = 0;
      }, perSeconds * 1000);
    }

    invokedCount++;

    callback(...args);
  };
}
