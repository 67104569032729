import { DropShadow } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import PropertiesSelect from '../PropertiesSelect';

const dropShadowOptions = [
  {
    key: DropShadow.NONE,
    title: 'None',
  },
  {
    key: DropShadow.LIGHT,
    title: 'Light',
  },
  {
    key: DropShadow.MEDIUM,
    title: 'Medium',
  },
  {
    key: DropShadow.HEAVY,
    title: 'Heavy',
  },
];

function ShadowPicker({ onChange, value, label = 'Shadow' }) {
  return (
    <PropertiesSelect
      id="shadow"
      label={label}
      onChange={onChange}
      options={dropShadowOptions.map((option) => ({
        label: option.title,
        value: option.key,
      }))}
      value={value || DropShadow.NONE}
    />
  );
}

ShadowPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ShadowPicker;
