import { makeStyles } from '@material-ui/core/styles';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNoneRounded';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import {
  selectEmail,
  selectFirstName,
  selectLastName,
  selectUserId,
} from '../../selectors/user';

function loadBeamer() {
  return new Promise((resolve, reject) => {
    if (window.Beamer) {
      resolve();
    }

    // TODO: potentially defer or pass in
    window.beamer_config = {
      product_id: 'sjdkcHFR13708',
      button: false,
    };

    const script = document.createElement('script');
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';

    document.body.appendChild(script);
  });
}

function useBeamer(init = () => {}) {
  const [beamer, setBeamer] = useState();

  useAsyncEffect(async () => {
    if (!window.Beamer) {
      await loadBeamer();
      setBeamer(window.Beamer);
      init(window.Beamer);
    }
  }, []);

  return beamer;
}

const useStyles = makeStyles((theme) => ({
  trigger: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.black,
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
}));

export default function Beamer() {
  const email = useSelector(selectEmail);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const userId = useSelector(selectUserId);
  const beamer = useBeamer();
  const classes = useStyles();

  useEffect(() => {
    if (beamer) {
      beamer.update({
        email,
        first_name: firstName,
        last_name: lastName,
        user_id: userId,
      });
    }
  }, [email, firstName, lastName, userId, beamer]);

  return (
    <a className={clsx('beamerTrigger', classes.trigger)} href="#beamerTrigger">
      <NotificationsNoneIcon
        className={classes.icon}
        titleAccess="notifications"
      />
    </a>
  );
}
