import { parse } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const { search } = useLocation();

  return useMemo(
    () => parse(search, { arrayFormat: 'comma', parseNumbers: true }),
    [search]
  );
}
