import transformUploadRequest from '../utils/transformUploadRequest';
import transformUploadResponse from '../utils/transformUploadResponse';
import AbstractAPI from './AbstractAPI';

export default class UploadAPI extends AbstractAPI {
  /**
   * get(), as defined by AbstractAPI, is preferred because we tack on a lot of query parameters
   * and useSWRInfinite needs access to the key (url) for efficient caching
   */

  postUpload(data) {
    return this.post('upload', data);
  }

  updateUpload({ uploadId, data }) {
    return this.patch(`upload/${uploadId}`, data);
  }

  deleteUpload(uploadId) {
    return this.delete(`upload/${uploadId}`);
  }

  disassociateUpload({ uploadId, brandUid, templateUid, projectUid }) {
    return this.post(`upload/${uploadId}/disassociate`, {
      brand_uid: brandUid,
      template_uid: templateUid,
      project_uid: projectUid,
    });
  }

  associateUpload({ uploadId, brandUid, templateUid, projectUid }) {
    return this.post(`upload/${uploadId}/associate`, {
      brand_uid: brandUid,
      template_uid: templateUid,
      project_uid: projectUid,
    });
  }

  transformResponse(data) {
    return transformUploadResponse(data);
  }

  transformRequest(data) {
    return transformUploadRequest(data);
  }
}
