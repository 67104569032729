import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';

import { LAYER_HEIGHT, LAYER_TALL_HEIGHT } from '../../constants/Constants';
import { translateX } from '../../utils/cssUtils';
import { checkMediaItem } from '../../utils/mediaUtils';
import FilmStripLite from '../FilmStripLite';
import Waveform from '../Waveform';
import TimelineItemAnimationOverlay from './TimelineItemAnimationOverlay';
import TimelineItemLabel from './TimelineItemLabel';

const useStyles = makeStyles((theme) => ({
  dragPreview: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    boxShadow: `0 0 2px 1px ${alpha(theme.palette.common.black, 0.2)}`,
    userSelect: 'none',
    cursor: 'grabbing',
  },
  filmstrip: {
    pointerEvents: 'none',
    borderRadius: theme.shape.borderRadius,
  },
  highlight: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    borderRadius: theme.shape.borderRadius,
    boxShadow: `${theme.palette.pink[500]} 0px 0px 0px 2px inset`,
  },
}));

export default function TimelineItemDragPreview(props) {
  const { item, minimized, offset, width } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { isAnimation, isAudio, isImage, isText, isVideo, isVisualMedia } =
    checkMediaItem(item);

  const transform = translateX(offset);
  const height = isVisualMedia ? LAYER_TALL_HEIGHT : LAYER_HEIGHT;
  const style = { transform, width, height };

  return (
    <div className={classes.dragPreview} style={style}>
      {isText && <TimelineItemLabel text={item.text} />}
      {isAnimation && <TimelineItemAnimationOverlay minimized={minimized} />}
      {isImage && (
        <FilmStripLite
          className={classes.filmstrip}
          height={height}
          thumbnail={item.source}
        />
      )}
      {isVideo && (
        <FilmStripLite
          className={classes.filmstrip}
          height={height}
          source={item.resolutions?._180p || item.source}
        />
      )}
      {isAudio && (
        <Waveform
          width={width}
          height={height}
          sourceUrl={item.source}
          color={theme.palette.grey[500]}
        />
      )}
      <div className={classes.highlight} />
    </div>
  );
}
