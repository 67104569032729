import { Box, Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function LoadMoreButton(props) {
  const { isLoadingMore, isReachingEnd, onClick } = props;

  return (
    <Box marginBottom={2.5} textAlign="center">
      {isLoadingMore && <CircularProgress color="secondary" />}
      {isReachingEnd && (
        <Box paddingY={2} color="text.secondary">
          No More Results
        </Box>
      )}
      {!isLoadingMore && !isReachingEnd && (
        <Button variant="outlined" onClick={onClick}>
          Load More
        </Button>
      )}
    </Box>
  );
}

LoadMoreButton.propTypes = {
  isLoadingMore: PropTypes.bool,
  isReachingEnd: PropTypes.bool,
  onClick: PropTypes.func,
};
