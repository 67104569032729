import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import Toast from '../components/Toast';
import VideoLoader from '../components/VideoLoader';
import CrossPostCard from '../components/exportDrawer/CrossPostCard';
import { EXPORT_PAGE } from '../events/locations';
import { trackEvent } from '../events/sendEvents';
import { EXPORT } from '../events/tags';
import useProject from '../hooks/useProject';
import useToast from '../hooks/useToast';
import { selectUserId } from '../selectors/user';
import ExportDetailsCard from './exportContainer/ExportDetailsCard';
import ShareExportCard from './exportContainer/ShareExportCard';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(6),
    flexGrow: 1,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    marginTop: theme.spacing(10),
  },
}));

export default function ExportContainer() {
  useTitle('Maker | Export');
  const classes = useStyles();
  const userId = useSelector(selectUserId);

  const { projectId, exportId } = useParams();
  const { open, message, openToast, closeToast, severity } = useToast();
  const { project, getExportById } = useProject(projectId, true);
  const { name, user_id: projectUserId } = project;

  const {
    cloudfront_url: cloudfrontUrl,
    completed_at: completedAt,
    error_at: errorAt,
    resolution,
    share_id: shareId,
    size,
    storyboard,
  } = getExportById(exportId) || {};

  const shareLink = `${window.location.origin}/share/${shareId}`;
  const isComplete = !!completedAt;
  const hasError = !!errorAt;
  const status = hasError ? 'error' : isComplete ? 'success' : 'pending';

  const handleCopyShareLink = (message, severity) => {
    openToast(message, severity);
    trackEvent(EXPORT.COPY_SHARE_LINK, { location: EXPORT_PAGE });
  };

  return (
    <Container className={classes.container}>
      <Toast open={open} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <Grid container spacing={6} alignItems="stretch">
        <Grid item xs={12} md={6} className={classes.leftColumn}>
          <ExportDetailsCard
            cloudfrontUrl={cloudfrontUrl}
            exportId={exportId}
            hasError={hasError}
            isComplete={isComplete}
            isOwner={projectUserId === userId}
            name={name}
            resolution={resolution}
            size={size}
            status={status}
            storyboard={storyboard}
          />
          <CrossPostCard eventLocation={EXPORT_PAGE} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.rightColumn}>
          <VideoLoader src={cloudfrontUrl} status={status} />
          <ShareExportCard
            isComplete={isComplete}
            onCopy={handleCopyShareLink}
            shareLink={shareLink}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
