import { nanoid } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { SOURCE_TYPES } from '../constants/Constants';
import * as MediaActions from '../constants/MediaActions';
import { trackEvent } from '../events/sendEvents';
import { KEYBOARD } from '../events/tags';
import {
  openShortcutsDialog,
  openTrimDialog,
  selectIsDialogOpen,
} from '../features/ui/uiSlice';
import {
  selectCanZoomIn,
  selectCanZoomOut,
  selectIsLoading,
  selectZoom,
  setActiveItemId,
  toggleMute,
  zoomIn,
  zoomOut,
} from '../slices/editorSlice';
import {
  itemDuplicated,
  itemRemoved,
  itemSplit,
  selectActiveItem,
  selectAllLayers,
  selectCanRedo,
  selectCanUndo,
} from '../slices/storyboardSlice';
import { HOTKEYS, SKIP_MORE_PX, SKIP_PX } from '../utils/hotkeys';
import { checkMediaItem } from '../utils/mediaUtils';
import { pixelsToSeconds } from '../utils/timelineUtils';
import useRenderer from './useRenderer';
import useUploads from './useUploads';

export default function useKeyboardShortcuts() {
  const dispatch = useDispatch();
  const { timestamp, renderer } = useRenderer();
  const { disassociateUpload } = useUploads();
  const { projectId, templateId } = useParams();

  const activeItem = useSelector(selectActiveItem);
  const canRedo = useSelector(selectCanRedo);
  const canUndo = useSelector(selectCanUndo);
  const canZoomIn = useSelector(selectCanZoomIn);
  const canZoomOut = useSelector(selectCanZoomOut);
  const isDialogOpen = useSelector(selectIsDialogOpen);
  const isLoading = useSelector(selectIsLoading);
  const layers = useSelector(selectAllLayers);
  const zoom = useSelector(selectZoom);

  const { isAudio, isVideo } = checkMediaItem(activeItem);
  const canTrim = isAudio || isVideo;

  const enabled = !isDialogOpen;

  /**
   * Item Controls
   */

  const handleDelete = useCallback(async () => {
    if (activeItem) {
      const itemLayer = layers.find((layer) =>
        layer.itemIds.includes(activeItem.id)
      );
      dispatch(itemRemoved(activeItem.id));

      if (activeItem.itemSourceType === SOURCE_TYPES.UPLOAD) {
        await disassociateUpload({
          uploadId: activeItem.itemSourceId,
          templateUid: templateId,
          projectUid: projectId,
        });
      }

      trackEvent(KEYBOARD.ITEM_DELETE, {
        ...activeItem,
        layerDeleted: (itemLayer?.itemIds.length || 0) <= 1,
      });
    }
  }, [activeItem, disassociateUpload, dispatch, layers, projectId, templateId]);
  useHotkeys(HOTKEYS.DELETE.keys, handleDelete, { enabled }, [handleDelete]);

  const handleTrim = useCallback(() => {
    if (activeItem && canTrim) {
      dispatch(
        openTrimDialog({ media: activeItem, action: MediaActions.UPDATE })
      );
      trackEvent(KEYBOARD.ITEM_TRIM, { ...activeItem });
    }
  }, [activeItem, canTrim, dispatch]);
  useHotkeys(HOTKEYS.TRIM.keys, handleTrim, { enabled }, [handleTrim]);

  const handleSplit = useCallback(() => {
    if (activeItem) {
      dispatch(
        itemSplit({ id: activeItem.id, time: timestamp, newItemId: nanoid() })
      );
      trackEvent(KEYBOARD.ITEM_SPLIT, { ...activeItem });
    }
  }, [activeItem, dispatch, timestamp]);
  useHotkeys(HOTKEYS.SPLIT.keys, handleSplit, { enabled }, [handleSplit]);

  const handleDuplicate = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (activeItem) {
        dispatch(itemDuplicated({ id: activeItem.id, newItemId: nanoid() }));
        trackEvent(KEYBOARD.ITEM_DUPLICATE, { ...activeItem });
      }
    },
    [activeItem, dispatch]
  );
  useHotkeys(HOTKEYS.DUPLICATE.keys, handleDuplicate, { enabled }, [
    handleDuplicate,
  ]);

  /**
   * Timeline Controls
   */

  const handleUndo = useCallback(() => {
    if (canUndo) {
      dispatch(UndoActionCreators.undo());
      trackEvent(KEYBOARD.UNDO);
    }
  }, [canUndo, dispatch]);
  useHotkeys(HOTKEYS.UNDO.keys, handleUndo, { enabled }, [handleUndo]);

  const handleRedo = useCallback(() => {
    if (canRedo) {
      dispatch(UndoActionCreators.redo());
      trackEvent(KEYBOARD.REDO);
    }
  }, [canRedo, dispatch]);
  useHotkeys(HOTKEYS.REDO.keys, handleRedo, { enabled }, [handleRedo]);

  const handleZoomIn = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (canZoomIn) {
        dispatch(zoomIn());
        trackEvent(KEYBOARD.ZOOM_IN);
      }
    },
    [canZoomIn, dispatch]
  );
  useHotkeys(HOTKEYS.ZOOM_IN.keys, handleZoomIn, { enabled }, [handleZoomIn]);

  const handleZoomOut = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (canZoomOut) {
        dispatch(zoomOut());
        trackEvent(KEYBOARD.ZOOM_OUT);
      }
    },
    [canZoomOut, dispatch]
  );
  useHotkeys(HOTKEYS.ZOOM_OUT.keys, handleZoomOut, { enabled }, [
    handleZoomOut,
  ]);

  const handleDeselect = useCallback(() => {
    dispatch(setActiveItemId());
  }, [dispatch]);
  useHotkeys(HOTKEYS.DESELECT.keys, handleDeselect, { enabled }, [
    handleDeselect,
  ]);

  /**
   * Media Controls
   */

  const handlePlayPause = useCallback(() => {
    if (!isLoading) {
      const playing = renderer.playing;
      renderer.togglePlayPause();
      playing ? trackEvent(KEYBOARD.PAUSE) : trackEvent(KEYBOARD.PLAY);
    }
  }, [isLoading, renderer]);
  useHotkeys(HOTKEYS.PLAY_PAUSE.keys, handlePlayPause, { enabled }, [
    handlePlayPause,
  ]);

  const handleSeekBackward = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        const duration = pixelsToSeconds(SKIP_PX, zoom);
        renderer.seekBackward(duration);
      }
    },
    [isLoading, renderer, zoom]
  );
  useHotkeys(HOTKEYS.SEEK_BACKWARD.keys, handleSeekBackward, { enabled }, [
    handleSeekBackward,
  ]);

  const handleSeekBackwardMore = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        const duration = pixelsToSeconds(SKIP_MORE_PX, zoom);
        renderer.seekBackward(duration);
      }
    },
    [isLoading, renderer, zoom]
  );
  useHotkeys(
    HOTKEYS.SEEK_BACKWARD_MORE.keys,
    handleSeekBackwardMore,
    { enabled },
    [handleSeekBackwardMore]
  );

  const handleSeekForward = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        const duration = pixelsToSeconds(SKIP_PX, zoom);
        renderer.seekForward(duration);
      }
    },
    [isLoading, renderer, zoom]
  );
  useHotkeys(HOTKEYS.SEEK_FORWARD.keys, handleSeekForward, { enabled }, [
    handleSeekForward,
  ]);

  const handleSeekForwardMore = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        const duration = pixelsToSeconds(SKIP_MORE_PX, zoom);
        renderer.seekForward(duration);
      }
    },
    [isLoading, renderer, zoom]
  );
  useHotkeys(
    HOTKEYS.SEEK_FORWARD_MORE.keys,
    handleSeekForwardMore,
    { enabled },
    [handleSeekForwardMore]
  );

  const handleSeekToStart = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        renderer.seekToStart();
      }
    },
    [isLoading, renderer]
  );
  useHotkeys(HOTKEYS.SEEK_TO_START.keys, handleSeekToStart, { enabled }, [
    handleSeekToStart,
  ]);

  const handleSeekToEnd = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isLoading) {
        renderer.seekToEnd();
      }
    },
    [isLoading, renderer]
  );
  useHotkeys(HOTKEYS.SEEK_TO_END.keys, handleSeekToEnd, { enabled }, [
    handleSeekToEnd,
  ]);

  const handleMute = useCallback(() => {
    dispatch(toggleMute());
    trackEvent(KEYBOARD.MUTE);
  }, [dispatch]);
  useHotkeys(HOTKEYS.MUTE.keys, handleMute, { enabled }, [handleMute]);

  /**
   * Misc
   */

  const handleOpenShortcuts = useCallback(() => {
    dispatch(openShortcutsDialog());
  }, [dispatch]);
  useHotkeys(HOTKEYS.OPEN_SHORTCUTS.keys, handleOpenShortcuts, { enabled }, [
    handleOpenShortcuts,
  ]);
}
