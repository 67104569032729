import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default function TimelineItemLabel({ text }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center" px={3} height="100%">
        <div className={classes.label}>{text}</div>
      </Box>
    </div>
  );
}
