import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import tinycolor from 'tinycolor2';

import { DROP_SHADOW, TEXT_VARIANT } from '../../constants/FontStyles';
import { trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import {
  getTextItemChangesForStyles,
  textItemUpdated,
} from '../../slices/storyboardSlice';

const useStyles = ({ primaryColor, highlightColor, font = {} }) =>
  makeStyles((theme) => ({
    brandName: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    button: {
      padding: 0,
      width: '100%',
      borderRadius: 0,
    },
    exampleText: {
      color:
        tinycolor(primaryColor).toHexString() ?? theme.palette.common.black,
      fontWeight: font.weight,
      fontSize: theme.typography.pxToRem(18),
      fontFamily: font.family,
      backgroundColor: highlightColor
        ? tinycolor(highlightColor).toHexString()
        : undefined,
      textShadow: DROP_SHADOW[font.dropShadow],
      padding: '0 2px',
    },
    fontName: {
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightNormal,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    nameContainer: {
      width: '100%',
      overflow: 'hidden',
      textAlign: 'left',
      margin: theme.spacing(1),
    },
    textPreview: {
      background: `linear-gradient(215deg, ${theme.palette.grey[300]} 37%, ${theme.palette.grey[400]} 92%)`,
      minWidth: theme.spacing(6),
      height: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(1, 1, 1, 2),
      borderRadius: theme.shape.borderRadius,
    },
  }));

export default function ApplyBrandMenuItem({ brand, closeMenu = () => {} }) {
  const dispatch = useDispatch();
  const activeItem = useActiveItem();

  const { name, styles } = brand?.detail || {};
  const { primaryTextColor, secondaryTextColor, fonts, font } = styles || {};
  const { textVariant = TEXT_VARIANT.HEADER } = activeItem || {};
  const fontToApply = fonts?.[textVariant] ?? font;

  const classes = useStyles({
    primaryColor: primaryTextColor,
    highlightColor: activeItem.highlightColor
      ? secondaryTextColor ?? activeItem.highlightColor
      : undefined,
    font: fontToApply,
  })();

  const applyBrandStyles = () => {
    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: getTextItemChangesForStyles(activeItem, styles),
      })
    );

    trackEvent(PROPERTIES_PANEL.BRAND_APPLIED, {
      brandUid: brand.uid,
      id: activeItem.id,
      mediaType: activeItem.mediaType,
    });

    closeMenu();
  };

  return (
    <Button className={classes.button} onClick={applyBrandStyles}>
      <div className={classes.textPreview}>
        <span className={classes.exampleText}>Aa</span>
      </div>
      <div className={classes.nameContainer}>
        <div className={classes.brandName}>{name}</div>
        <div className={classes.fontName}>{fontToApply.name}</div>
      </div>
    </Button>
  );
}
