import { isFunction } from 'lodash';
import log from 'loglevel';

class Logger {
  transports = [];

  constructor() {
    this._consoleLevel = log.levels.SILENT;

    const that = this;
    const originalFactory = log.methodFactory;
    log.methodFactory = (methodName, logLevel, loggerName) => {
      const level = log.levels[methodName.toUpperCase()];

      const rawMethod =
        level >= that._consoleLevel
          ? originalFactory(methodName, logLevel, loggerName)
          : () => {};

      const levelTransports = that.transports
        .filter(({ shouldLog }) => shouldLog(level))
        .map(({ transport }) => transport);

      return (name, extras) => {
        const args = !!extras ? [name, extras] : [name];

        // in the spirit of the loglevel package,
        // filtering logs by level is precomputed in the methodFactory
        rawMethod(...args);

        levelTransports.forEach((transport) =>
          transport({ level, name, extras })
        );
      };
    };
    log.setLevel(log.levels.TRACE);
  }

  set consoleLevel(level) {
    this._consoleLevel = level;
    log.setLevel(log.getLevel());
  }

  get consoleLevel() {
    return this._consoleLevel;
  }

  addTransport(transport, level = log.levels.TRACE) {
    const shouldLog = isFunction(level)
      ? level
      : (messageLevel) => messageLevel >= level;
    this.transports.push({
      transport,
      shouldLog,
    });
    log.setLevel(log.getLevel());
  }
}

const logger = new Logger();

export default logger;
