import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import BlueBackgroundWithShapes from '../../assets/images/blue-background-with-shapes.png';
import {
  ASPECT_RATIOS,
  ASPECT_RATIO_BOXES,
  RATIO_16_9,
} from '../../constants/AspectRatios';
import PaddedAspectBox from '../PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  ratioPreview: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BlueBackgroundWithShapes})`,
    backgroundSize: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  ratioIcon: {
    fill: theme.palette.grey[800],
    height: '100%',
    borderRadius: theme.shape.borderRadius,
  },
  ratioLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
}));

export default function RatioBox({ ratio = RATIO_16_9 }) {
  const classes = useStyles();

  const RatioIcon = ASPECT_RATIO_BOXES[ratio];

  return (
    <PaddedAspectBox aspectRatio={2}>
      <div className={classes.ratioPreview}>
        <RatioIcon className={classes.ratioIcon} />
        <div className={classes.ratioLabel}>{ratio}</div>
      </div>
    </PaddedAspectBox>
  );
}

RatioBox.propTypes = {
  ratio: PropTypes.oneOf(ASPECT_RATIOS),
};
