import { useDndContext, useDndMonitor } from '@dnd-kit/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { findIndex, inRange } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDebounce, useMeasure } from 'react-use';

import {
  DND_ZONES,
  OFF_SCREEN,
  SOURCE_TYPES,
  TIMELINE_LEFT_PADDING,
  TIMELINE_TOP_PADDING,
} from '../../constants/Constants';
import { DEFAULT_ITEM_DURATION } from '../../constants/Storyboard';
import { trackEvent } from '../../events/sendEvents';
import { getDragContext, TIMELINE } from '../../events/tags';
import useUploads from '../../hooks/useUploads';
import createObject from '../../models/createObject';
import replaceObject from '../../models/replaceObject';
import { selectZoom } from '../../slices/editorSlice';
import {
  itemAdded,
  itemMoved,
  itemReplaced,
  selectAllLayersWithItems,
} from '../../slices/storyboardSlice';
import { translate } from '../../utils/cssUtils';
import { getOrderedOffsets } from '../../utils/snappingUtils';
import {
  getItemToReplace,
  getLayerHeight,
  getTimelineGaps,
} from '../../utils/storyboardUtils';
import { layerAcceptsMediaType } from '../../utils/storyboardUtils';
import { pixelsToSeconds, secondsToPixels } from '../../utils/timelineUtils';
import LayerSuggestions from './LayerSuggestions';
import NewLayerPlaceholder from './NewLayerPlaceholder';
import TimelineLayer from './TimelineLayer';

// px value for differentiating b/w selecting and moving an item
const ITEM_SELECT_TOLERANCE = 2;
const LAYER_GAP = 8;
const SCROLL_DEBOUNCE_WAIT = 200;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: TIMELINE_TOP_PADDING,
    paddingBottom: theme.spacing(2),
    paddingLeft: TIMELINE_LEFT_PADDING,
    minHeight: '100%',
  },
  snappingGuide: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    backgroundColor: theme.palette.red[500],
    zIndex: 1,
  },
}));

function TimelineLayers(props) {
  const { scrollX, scrollY, timelineWidth, visibleHeight } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const containerRef = useRef();
  const { projectId, templateId } = useParams();
  const { associateUpload, disassociateUpload } = useUploads();

  const layers = useSelector(selectAllLayersWithItems);
  const zoom = useSelector(selectZoom);
  const [newLayerRef, { height: newLayerHeight }] = useMeasure();
  const [visibleLayers, setVisibleLayers] = useState(layers || []);
  const [snappedTo, setSnappedTo] = useState();

  useDebounce(
    () => {
      const { layersWithStartY } = layers.reduce(
        (result, layer) => {
          result.layersWithStartY.push({
            ...layer,
            startY: result.currentY,
          });
          result.currentY += getLayerHeight(layer.type) + LAYER_GAP;
          return result;
        },
        { currentY: LAYER_GAP, layersWithStartY: [] }
      );
      setVisibleLayers(
        layersWithStartY.filter(
          ({ startY = 0, type }) =>
            newLayerHeight + startY + getLayerHeight(type) > scrollY &&
            newLayerHeight + startY < scrollY + visibleHeight
        )
      );
    },
    SCROLL_DEBOUNCE_WAIT,
    [newLayerHeight, layers, scrollY, visibleHeight]
  );

  const orderedOffsets = useMemo(() => {
    return visibleLayers.reduce((result, layer, index) => {
      result[layer.id] = getOrderedOffsets(visibleLayers, index, zoom);
      return result;
    }, {});
  }, [visibleLayers, zoom]);

  // Drag and drop
  const { activatorEvent, activeNodeRect } = useDndContext();

  const startTimeOffset = useRef(0);
  const pointerAdjustment = useRef(0);
  const startingAdjustment = useRef(0);
  const startingScroll = useRef(0);
  const [elementOffset, setElementOffset] = useState(OFF_SCREEN);

  const resetDragState = () => {
    startTimeOffset.current = 0;
    startingAdjustment.current = 0;
    pointerAdjustment.current = 0;
    startingScroll.current = 0;
    setElementOffset(OFF_SCREEN);
    setSnappedTo(null);
  };

  const calculateOffset = (deltaX = 0) =>
    Math.max(
      0,
      deltaX +
        startTimeOffset.current +
        startingAdjustment.current +
        pointerAdjustment.current -
        startingScroll.current
    );

  const handleDragStart = (event) => {
    const { item, zone } = event.active.data?.current || {};
    const itemLeft = activeNodeRect?.left ?? 0;

    startTimeOffset.current = secondsToPixels(item?.startTime, zoom);
    startingScroll.current = scrollX;

    if (zone === DND_ZONES.DRAWER) {
      pointerAdjustment.current =
        (activatorEvent?.x ?? activatorEvent?.clientX) - itemLeft;
      const layerLeft = containerRef?.current?.getBoundingClientRect().x ?? 0;
      startingAdjustment.current = itemLeft - layerLeft - TIMELINE_LEFT_PADDING;
    }
    setElementOffset(calculateOffset(startingScroll.current));
  };

  const handleDragMove = (event) => {
    if (event.over) {
      setElementOffset(calculateOffset(event.delta?.x));
    } else {
      /**
       * The event.delta value does not adjust for scroll when not hovering over
       * a droppable target, which causes a slight flicker with the placeholder
       * when entering into a droppable target. This sets the offset to off screen
       * so the flicker is not in the visible portion of the screen.
       */
      setElementOffset(OFF_SCREEN);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    // we're not over any drop zone, abort
    if (!over) {
      return resetDragState();
    }

    const {
      item = {},
      layerId: fromLayerId,
      zone: fromZone,
    } = active?.data?.current || {};
    const fromLayerIndex = findIndex(layers, ['id', fromLayerId]);

    const {
      layerId: toLayerId,
      layerIndex: toLayerIndex,
      type: toLayerType,
      items: toLayerItems,
      zone: toZone,
    } = over?.data?.current || {};
    const newLayerCreated = !layers.some(({ id }) => id === toLayerId);

    const { duration = DEFAULT_ITEM_DURATION, mediaType } = item;
    const durationPixels = secondsToPixels(duration, zoom);

    // layer type and item media type are not compatible, abort
    if (!layerAcceptsMediaType(toLayerType, mediaType)) {
      trackEvent(TIMELINE.ITEM_DROP, { ...item, dropTarget: over.id });
      return resetDragState();
    }

    // we're not dragging to the timeline, abort
    if (toZone !== DND_ZONES.TIMELINE) {
      trackEvent(getDragContext(toZone)?.ITEM_DROP || 'item.drop', {
        ...item,
        dropTarget: over.id,
      });
      return resetDragState();
    }

    const draggedFromDrawer = fromZone === DND_ZONES.DRAWER;
    const draggedFromTimeline = fromZone === DND_ZONES.TIMELINE;

    const finalOffset = snappedTo
      ? snappedTo.offset - !!snappedTo.snapToEnd * durationPixels
      : calculateOffset(event.delta.x);
    const secondsOffset = Math.max(pixelsToSeconds(finalOffset, zoom), 0);

    if (draggedFromDrawer) {
      const itemToReplace = getItemToReplace(
        pixelsToSeconds(finalOffset, zoom),
        toLayerItems,
        zoom,
        mediaType
      );

      if (itemToReplace) {
        const object = await createObject(item);
        const replacement = replaceObject(itemToReplace, object);
        dispatch(itemReplaced({ id: itemToReplace.id, replacement }));

        if (item.sourceType === SOURCE_TYPES.UPLOAD) {
          await disassociateUpload({
            uploadId: itemToReplace.itemSourceId,
            templateUid: templateId,
            projectUid: projectId,
          });
        }

        trackEvent(TIMELINE.ITEM_REPLACE, {
          mediaType,
          oldId: itemToReplace.id,
          oldItemSourceId: itemToReplace.itemSourceId,
          newId: replacement.id,
          newItemSourceId: replacement.itemSourceId,
        });
      } else {
        const timelineGaps = getTimelineGaps(toLayerItems);
        const gapToInsertInto = timelineGaps.find(
          ({ startTime, duration: gapDuration }) =>
            inRange(secondsOffset, startTime, startTime + gapDuration) &&
            duration > gapDuration
        );
        const itemTiming = gapToInsertInto
          ? {
              startTime: gapToInsertInto.startTime,
              duration: Math.min(gapToInsertInto.duration, duration),
            }
          : { startTime: secondsOffset };

        const object = await createObject(item);
        const payload = {
          ...object,
          ...itemTiming,
          layerId: toLayerId,
          layerIndex: toLayerIndex,
        };
        dispatch(itemAdded(payload));
        trackEvent(TIMELINE.ITEM_ADD, {
          ...payload,
          hasSnapped: !!snappedTo,
          insertedIntoGap: !!gapToInsertInto,
          newLayerCreated,
          toLayerIndex,
          toLayerType,
        });
      }

      if (item.sourceType === SOURCE_TYPES.UPLOAD) {
        await associateUpload({
          uploadId: item.id,
          templateUid: templateId,
          projectUid: projectId,
        });
      }
    }

    if (draggedFromTimeline) {
      const offsetDiff = Math.abs(finalOffset - startTimeOffset.current);

      if (fromLayerId === toLayerId && offsetDiff < ITEM_SELECT_TOLERANCE) {
        trackEvent(TIMELINE.ITEM_SELECT, item);
        return resetDragState();
      }

      const payload = {
        id: item.id,
        toLayerId,
        toLayerIndex,
        changes: { startTime: secondsOffset },
      };

      const fromLayer = layers.find(({ id }) => id === fromLayerId);

      dispatch(itemMoved(payload));
      trackEvent(TIMELINE.ITEM_MOVE, {
        fromLayerIndex,
        hasSnapped: !!snappedTo,
        id: item.id,
        layerDeleted: (fromLayer?.itemIds.length || 0) <= 1,
        mediaType: item.mediaType,
        newLayerCreated,
        startTime: secondsOffset,
        toLayerIndex,
        toLayerType,
      });
    }

    resetDragState();
  };

  useDndMonitor({
    onDragStart: handleDragStart,
    onDragMove: handleDragMove,
    onDragEnd: handleDragEnd,
    onDragCancel: resetDragState,
  });

  let layerComponents;

  const hasOneLayer = layers.length === 1;

  if (hasOneLayer) {
    const layer = layers[0] || {};
    layerComponents = (
      <LayerSuggestions
        elementOffset={elementOffset}
        layerId={layer.id}
        layerType={layer.type}
        setSnappedTo={setSnappedTo}
        snappableOffsets={orderedOffsets[layer.id]}
      />
    );
  } else {
    layerComponents = layers.reduce((result, layer, index) => {
      const snappableOffsets = orderedOffsets[layer.id];

      if (index === 0) {
        result.push(
          <NewLayerPlaceholder index={0} key={index} ref={newLayerRef} />
        );
      }
      result.push(
        <TimelineLayer
          id={layer.id}
          layerIndex={index}
          placeholderOffset={elementOffset}
          key={layer.id}
          setSnappedTo={setSnappedTo}
          snappableOffsets={snappableOffsets}
        />
      );
      result.push(<NewLayerPlaceholder index={index + 1} key={index + 1} />);
      return result;
    }, []);
  }

  const containerStyles = { width: timelineWidth + TIMELINE_LEFT_PADDING };
  const snappingGuideStyles = snappedTo
    ? {
        height: `${visibleHeight - TIMELINE_TOP_PADDING}px`,
        transform: translate(
          snappedTo.offset + TIMELINE_LEFT_PADDING - 1,
          scrollY
        ),
      }
    : {};

  return (
    <>
      <div
        className={clsx(classes.container)}
        style={containerStyles}
        ref={containerRef}
      >
        {layerComponents}
      </div>
      {snappedTo && (
        <div className={classes.snappingGuide} style={snappingGuideStyles} />
      )}
    </>
  );
}

TimelineLayers.propTypes = {
  scrollX: PropTypes.number,
  scrollY: PropTypes.number,
  timelineWidth: PropTypes.number,
  visibleHeight: PropTypes.number,
};

export default memo(TimelineLayers);
