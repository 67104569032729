import PropTypes from 'prop-types';

import FolderPreview from './FolderPreview';
import ResultsGrid from './ResultsGrid';

export default function FolderOverview(props) {
  const { folders = [], onFolderClick = () => {} } = props;

  return (
    <ResultsGrid>
      {folders.map((folder) => (
        <FolderPreview
          itemCount={folder.itemCount}
          mediaType={folder.mediaType}
          name={folder.name}
          thumbnailUrl={folder.thumbnailUrl}
          onClick={() => onFolderClick(folder.uniqueId, folder.mediaType)}
          key={folder.uniqueId}
        />
      ))}
    </ResultsGrid>
  );
}

FolderOverview.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      itemCount: PropTypes.number,
      mediaType: PropTypes.string,
      name: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      uniqueId: PropTypes.string,
    })
  ),
  onFolderClick: PropTypes.func,
};
