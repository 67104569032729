import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import FontSelect from '../../../components/FontSelect';
import ColorPickerPopover from '../../../components/colorPicker/ColorPickerPopover';
import { getAnimationByValue } from '../../../constants/TextAnimations';
import { getItemEditProps, trackEvent } from '../../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../../events/tags';
import { selectIsEnterprise } from '../../../selectors/user';
import { textItemUpdated } from '../../../slices/storyboardSlice';
import ApplyBrandMenu from '../ApplyBrandMenu';
import AlignmentPicker from '../controls/AlignmentPicker';
import FontSizePicker from '../controls/FontSizePicker';
import PositionPicker from '../controls/PositionPicker';
import ShadowPicker from '../controls/ShadowPicker';
import PropertiesCard from './PropertiesCard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > div': {
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
  },
}));

function TextStyleCard({ activeItem }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isEnterprise = useSelector(selectIsEnterprise);

  const { showSecondaryColor, showHighlight, showDropShadow } =
    getAnimationByValue(activeItem.animationPreset) ?? {
      showSecondaryColor: false,
      showHighlight: true,
      showDropShadow: true,
    };

  const handleAlignmentChange = (event, newAlignment) => {
    const { style } = activeItem;
    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: {
          style: {
            ...style,
            align: newAlignment === null ? style.align : newAlignment,
          },
        },
      })
    );
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'style', { align: newAlignment })
    );
  };

  const handleDropShadowChange = (event) => {
    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: { dropShadow: event.target.value },
      })
    );
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'dropShadow', event.target.value)
    );
  };

  const handleFontSizeChange = (event) => {
    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: { fontSize: event.target.value },
      })
    );
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'fontSize', event.target.value)
    );
  };

  const handlePositionChange = ({ horizontal, vertical }) => {
    const position = {
      x: horizontal || activeItem.position.x,
      y: vertical || activeItem.position.y,
    };

    dispatch(textItemUpdated({ id: activeItem.id, changes: { position } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'position', position)
    );
  };

  const handlePrimaryColorChange = (primaryColor, isBranded, isRecent) => {
    dispatch(textItemUpdated({ id: activeItem.id, changes: { primaryColor } }));
    trackEvent(PROPERTIES_PANEL.ITEM_EDIT, {
      ...getItemEditProps(activeItem, 'primaryColor', primaryColor),
      isBranded,
      isRecent,
    });
  };

  const handleSecondaryColorChange = (secondaryColor, isBranded, isRecent) => {
    dispatch(
      textItemUpdated({ id: activeItem.id, changes: { secondaryColor } })
    );
    trackEvent(PROPERTIES_PANEL.ITEM_EDIT, {
      ...getItemEditProps(activeItem, 'secondaryColor', secondaryColor),
      isBranded,
      isRecent,
    });
  };

  const handleHighlightColorChange = (highlightColor, isBranded, isRecent) => {
    dispatch(
      textItemUpdated({ id: activeItem.id, changes: { highlightColor } })
    );
    trackEvent(PROPERTIES_PANEL.ITEM_EDIT, {
      ...getItemEditProps(activeItem, 'highlightColor', highlightColor),
      isBranded,
      isRecent,
    });
  };

  return (
    <PropertiesCard title="Style">
      <div className={classes.container}>
        {isEnterprise && <ApplyBrandMenu />}
        <FormControl className={classes.control}>
          <InputLabel id="typeface">Typeface</InputLabel>
          <FontSelect labelId="typeface" />
        </FormControl>
        <div>
          <InputLabel>Text Color</InputLabel>
          <ColorPickerPopover
            color={activeItem.primaryColor}
            onChange={handlePrimaryColorChange}
            showNoneOption={false}
          />
        </div>
        {showSecondaryColor ? (
          <div>
            <InputLabel>Accent Color</InputLabel>
            <ColorPickerPopover
              color={activeItem.secondaryColor}
              onChange={handleSecondaryColorChange}
            />
          </div>
        ) : showHighlight ? (
          <div>
            <InputLabel>Accent Color</InputLabel>
            <ColorPickerPopover
              color={activeItem.highlightColor}
              onChange={handleHighlightColorChange}
            />
          </div>
        ) : null}
        <div>
          <FontSizePicker
            onChange={handleFontSizeChange}
            value={activeItem.fontSize}
          />
        </div>
        {showDropShadow && (
          <div>
            <ShadowPicker
              value={activeItem.dropShadow}
              onChange={handleDropShadowChange}
            />
          </div>
        )}
        <div>
          <AlignmentPicker
            value={activeItem.style.align}
            onChange={handleAlignmentChange}
          />
        </div>
        <div>
          <PositionPicker
            value={activeItem.position}
            onChange={handlePositionChange}
          />
        </div>
      </div>
    </PropertiesCard>
  );
}

TextStyleCard.propTypes = {
  activeItem: PropTypes.object.isRequired,
};

export default TextStyleCard;
