import axios from 'axios';
import queryString from 'query-string';

import { MAKER_API_URL } from '../constants/Urls';

export default class AbstractAPI {
  constructor(authEnabled = true, headers, withCredentials = true) {
    this.api = axios.create({
      auth: authEnabled ? {} : undefined,
      baseURL: this.getBaseURL(),
      headers,
      paramsSerializer: this.paramsSerializer,
      responseType: 'json',
      transformResponse: [
        this.transformResponse,
        ...axios.defaults.transformResponse,
      ],
      transformRequest: [
        this.transformRequest,
        ...axios.defaults.transformRequest,
      ],
      withCredentials,
    });
  }

  getBaseURL() {
    return MAKER_API_URL;
  }

  paramsSerializer(params) {
    return queryString.stringify(params, { arrayFormat: 'index' });
  }

  transformResponse(data) {
    return data;
  }

  transformRequest(data) {
    return data;
  }

  get(url, config) {
    return this.api.get(url, config).then((response) => response.data);
  }

  patch(url, data) {
    return this.api.patch(url, data).then((response) => response.data);
  }

  post(url, data) {
    return this.api.post(url, data).then((response) => response.data);
  }

  put(url, data) {
    return this.api.put(url, data).then((response) => response.data);
  }

  delete(url) {
    return this.api.delete(url).then((response) => response.data);
  }
}
