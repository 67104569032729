import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[700],
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    opacity: 0.8,
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      opacity: 1,
    },
  },
}));

export default function DeleteIconButton(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={clsx('delete-button', className || classes.root)}
      {...rest}
    >
      <DeleteIcon />
    </IconButton>
  );
}
