import { MediaTypes } from '@videoblocks/jelly-renderer';

export const VIDEO = 'videos';
export const IMAGE = 'images';
export const AUDIO = 'audio';

export const MEDIA_TYPE_TO_SEARCH_TYPE = {
  [MediaTypes.VIDEO]: VIDEO,
  [MediaTypes.IMAGE]: IMAGE,
  [MediaTypes.AUDIO]: AUDIO,
};
