import { makeStyles } from '@material-ui/core/styles';
import GraphicEqIcon from '@material-ui/icons/GraphicEqRounded';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AUDIO_THUMB_FALLBACK } from '../constants/TestSelectors';

const AUDIO_DELAY = 600; // ms

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: theme.typography.pxToRem(64),
  },
}));

export default function AudioPreview(props) {
  const { source, thumbnail, title = '' } = props;
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();

  const debouncedHover = useDebouncedCallback(() => {
    setIsHovered(true);
  }, AUDIO_DELAY);

  const handleMouseEnter = () => {
    debouncedHover();
  };

  const handleMouseLeave = () => {
    debouncedHover.cancel();
    setIsHovered(false);
  };

  return (
    <div
      className={classes.audioPreview}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {thumbnail ? (
        <img className={classes.thumbnail} src={thumbnail} alt={title} />
      ) : (
        <div className={classes.thumbnail} alt={title}>
          <GraphicEqIcon
            data-testid={AUDIO_THUMB_FALLBACK}
            fontSize="inherit"
          />
        </div>
      )}
      {isHovered && (
        <audio autoPlay loop>
          {/* HTTP requests from the src field in JSX have type 'media' 
          and don't apply headers required for CORS. To ensure later 
          XHR/fetch requests don't use this cached response, we'll tweak 
          the URL with a query parameter. */}
          <source src={source + '?type=media'} />
        </audio>
      )}
    </div>
  );
}
