import { useEffect, useState } from 'react';

import ExportsAPI from '../api/ExportsAPI';

export default function useSharedExport(shareId) {
  const [sharedExport, setSharedExport] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    new ExportsAPI()
      .getSharedExport(shareId)
      .then((sharedExport) => setSharedExport(sharedExport))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [shareId]);

  return { sharedExport, error, loading };
}
