import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import LoaderContainer from '../components/LoaderContainer';
import { SB_LOGIN_URL } from '../constants/Urls';
import { selectIsAuthenticated, selectIsLoading } from '../selectors/user';
import LocationListener from './LocationListener';

export default function PrivateRoute(props) {
  const { children, ...rest } = props;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = useSelector(selectIsLoading);

  // if the user is trying to access a private route and isn't logged in, send them to the log in page
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      window.location = SB_LOGIN_URL;
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Route {...rest}>
      <LocationListener />
      {isAuthenticated ? children : <LoaderContainer />}
    </Route>
  );
}
