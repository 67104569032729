import { trim } from 'lodash';

/**
 * This is a naive "formatter" for names.
 *
 * @see https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names
 *
 * @param {string|null} firstName
 * @param {string|null} lastName
 * @return {string} - a full name
 */
export default function formatFullName(firstName, lastName) {
  // first name and last name can sometimes be null
  firstName = firstName || '';
  lastName = lastName || '';

  // first name and last name are not always trimmed strings
  firstName = trim(firstName);
  lastName = trim(lastName);

  // there is no guarantee that either firstName nor lastName will be defined
  return trim(`${firstName} ${lastName}`);
}
