import * as Sentry from '@sentry/browser';

/**
 * Redux middleware to log actions as Sentry breadcrumbs
 */
export default (store) => (next) => (action) => {
  next(action);
  const { type, payload } = action;
  Sentry.addBreadcrumb({
    category: 'redux',
    message: type,
    data: payload,
  });
};
