import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';

import { ASPECT_RATIOS, ASPECT_RATIO_ICONS } from '../constants/AspectRatios';

const useStyles = makeStyles((theme) => ({
  grouped: {
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  groupedHorizontal: {
    '&:not(:first-child)': {
      margin: 0,
      borderLeft: 'none',
    },
  },
  toggleButton: {
    padding: theme.spacing(1.25),
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  toggleButtonSelected: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
  ratioIcon: {
    width: '100%',
    fill: 'transparent',
    color: theme.palette.grey[900],
  },
  ratioIconSelected: {
    fill: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
}));

export default function AspectRatioToggle({
  onChange,
  onToggleButtonEnter,
  onToggleButtonLeave,
  selectedRatio,
  ...rest
}) {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      classes={{
        grouped: classes.grouped,
        groupedHorizontal: classes.groupedHorizontal,
      }}
      aria-label="aspect-ratio-toggle"
      onChange={onChange}
      {...rest}
    >
      {ASPECT_RATIOS.map((ratio) => {
        const selected = ratio === selectedRatio;
        const Icon = ASPECT_RATIO_ICONS[ratio];
        return (
          <ToggleButton
            className={classes.toggleButton}
            classes={{ selected: classes.toggleButtonSelected }}
            aria-label={ratio}
            value={ratio}
            selected={selected}
            onMouseEnter={onToggleButtonEnter}
            onMouseLeave={onToggleButtonLeave}
            key={ratio}
          >
            <Icon
              className={clsx(classes.ratioIcon, {
                [classes.ratioIconSelected]: selected,
              })}
            />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
