import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import CloseButton from '../CloseButton';

const useStyles = makeStyles((theme) => ({
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      // TODO: Red 400 does not yet exist in our global palette (MKR-386)
      backgroundColor: '#EF482E',
    },
  },
}));

function ConfirmationDialog(props) {
  const {
    cancelButtonText = 'Cancel',
    confirmButtonText = 'OK',
    danger = false,
    denyButtonText = 'No',
    onCancel = () => {},
    onClose = () => {},
    // HACK/FIXME: for some reason onClose handlers passed do not get called, so
    // onExit fulfills the same role. Should be investigated after brands are wrapped.
    onExit = () => {},
    onConfirm = () => {},
    onDeny = () => {},
    open = false,
    showCancel = false,
    showClose = false,
    showConfirm = true,
    showDeny = false,
    text,
    title,
  } = props;
  const classes = useStyles();

  const handleConfirm = (event) => {
    onConfirm(event);
    onExit();
    onClose();
  };

  const handleDeny = (event) => {
    onDeny(event);
    onExit();
    onClose();
  };

  const handleCancel = (event) => {
    onCancel(event);
    onExit();
    onClose();
  };

  const handleClose = () => {
    onExit();
    onClose();
  };

  useHotkeys('enter', handleConfirm);

  return (
    <Dialog open={open} onClose={handleClose}>
      {title && (
        <DialogTitle disableTypography>
          <Typography component="h2" variant="h4">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <Button onClick={handleCancel}>{cancelButtonText}</Button>
        )}
        {showDeny && <Button onClick={handleDeny}>{denyButtonText}</Button>}
        {showConfirm && (
          <Button
            className={danger ? classes.danger : ''}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
      {showClose && (
        <Box position="absolute" top={0} right={0} m={2} color="grey.700">
          <CloseButton onClick={handleClose} />
        </Box>
      )}
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  danger: PropTypes.bool,
  denyButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func,
  open: PropTypes.bool,
  showCancel: PropTypes.bool,
  showClose: PropTypes.bool,
  showConfirm: PropTypes.bool,
  showDeny: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
};

export default memo(ConfirmationDialog);
