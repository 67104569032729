import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import logo from '../../assets/images/sb-logo.svg';
import { NAVBAR_HEIGHT } from '../../constants/Constants';
import {
  SB_LOGIN_URL,
  SB_MAKER_LANDING_URL,
  SB_URL,
} from '../../constants/Urls';
import { selectIsLoading } from '../../selectors/user';

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0, 3),
    height: NAVBAR_HEIGHT,
    fontWeight: 600,
    color: theme.palette.common.black,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[500],
    background: theme.palette.common.white,
  },
  logo: {
    height: theme.spacing(4),
  },
}));

function ShareNavbar(props) {
  const { authenticated, ...rest } = props;
  const classes = useStyles();
  const isLoading = useSelector(selectIsLoading);

  return (
    <header className={classes.navbar} {...rest}>
      <Box display="flex" alignItems="center" marginRight="auto">
        <a href={SB_URL}>
          <img className={classes.logo} src={logo} alt="Storyblocks Home" />
        </a>
        <Box marginTop={0.5}>
          <Box component="span" marginX={2}>
            |
          </Box>
          <a href={SB_MAKER_LANDING_URL}>Maker</a>
        </Box>
      </Box>
      {!authenticated && !isLoading && <a href={SB_LOGIN_URL}>Login</a>}
    </header>
  );
}

ShareNavbar.propTypes = {
  authenticated: PropTypes.bool,
};

export default ShareNavbar;
