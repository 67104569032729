import { NativeMediaControls } from '@videoblocks/jelly-renderer';
import log from 'loglevel';
import { useState, useRef } from 'react';
import { useRafLoop } from 'react-use';
import useAsyncEffect from 'use-async-effect';

export default function useMedia(src, isVideo) {
  const mediaRef = useRef(null);
  const controlsRef = useRef(new NativeMediaControls(null));

  const [aspectRatio, setAspectRatio] = useState();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [muted, setMuted] = useState(false);
  const [paused, setPaused] = useState(true);

  const state = {
    aspectRatio,
    currentTime,
    duration,
    loaded,
    muted,
    paused,
  };

  const [loopStop, loopStart, isLoopActive] = useRafLoop(() => {
    if (loaded && mediaRef.current) {
      setCurrentTime(mediaRef.current.currentTime);
    }
  }, [loaded]);

  if (paused && isLoopActive) {
    loopStop();
  }

  const controls = {
    mute() {
      setMuted(true);
      controlsRef.current.mute();
    },
    pause() {
      setPaused(true);
      loopStop();
      controlsRef.current.pause();
    },
    async play() {
      await controlsRef.current.play();
      setPaused(false);
      loopStart();
    },
    async seek(seconds) {
      if (!loaded) {
        log.info('Cannot seek before load is finished');
        return;
      }
      if (mediaRef.current?.seeking) {
        log.info('Seeking is already in progress, cannot seek again');
        return;
      }
      await controlsRef.current.seek(seconds);
      if (!mediaRef.current) {
        log.info('Current component was unmounted, no mediaRef seek required');
        return;
      }
      setCurrentTime(mediaRef.current.currentTime);
    },
    unmute() {
      setMuted(false);
      controlsRef.current.unmute();
    },
  };

  useAsyncEffect(
    async (isMounted) => {
      if (!src) return;

      const source = await (isVideo
        ? NativeMediaControls.preloadVideoSource(src)
        : NativeMediaControls.preloadSource(src, 'audio/mpeg'));

      if (!isMounted()) return;

      await NativeMediaControls.preloadMediaElement(mediaRef.current, source);

      if (!isMounted()) return;

      try {
        controlsRef.current = new NativeMediaControls(mediaRef.current);
        if (isVideo) {
          setAspectRatio(
            mediaRef.current.videoWidth / mediaRef.current.videoHeight
          );
        }
        setDuration(mediaRef.current.duration || 0);
        setLoaded(true);
      } catch (error) {
        log.error(`Could not load media: ${error?.message}`, { error, src });
      }
    },
    [isVideo, src]
  );

  return [mediaRef, state, controls];
}
