const swatchColors = [
  '#EF4444',
  '#F59E0B',
  '#10B981',
  '#3B82F6',
  '#6366F1',
  '#8B5CF6',
  '#EC4899',
  '#FFFFFF',
  '#D0D0D0',
  '#A1A1A1',
  '#737373',
  '#4D4D4D',
  '#262626',
  '#000000',
];

export default swatchColors;
