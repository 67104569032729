import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { selectZoom } from '../../slices/editorSlice';
import { secondsToPixels } from '../../utils/timelineUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  fade: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    background: `linear-gradient(
      90deg,
      ${theme.palette.grey[500]} 0%,
      transparent 75%)`,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  leftFade: {
    left: 0,
  },
  rightFade: {
    right: 0,
    transform: 'rotate(180deg)',
  },
}));

export default function TimelineItemFadeOverlay(props) {
  const { fadeIn, fadeOut } = props;
  const zoom = useSelector(selectZoom);
  const classes = useStyles();

  const fadeInWidth = secondsToPixels(fadeIn, zoom);
  const fadeOutWidth = secondsToPixels(fadeOut ?? 0.5, zoom);

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.fade, classes.leftFade)}
        style={{ width: fadeInWidth }}
      />
      <div
        className={clsx(classes.fade, classes.rightFade)}
        style={{ width: fadeOutWidth }}
      />
    </div>
  );
}
