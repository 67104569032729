import { Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  badge: {
    overflow: 'hidden', // Fixed a QA item, component was overflowing the grid
    height: '100%',
    width: '100%',
    '& > span': {
      transform: 'none',
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0.5),
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
}));

export default function CardBadge({
  show,
  badgeContent = '',
  vertical = 'bottom',
  horizontal = 'left',
  children,
}) {
  const classes = useStyles();

  if (show) {
    return (
      <Badge
        badgeContent={badgeContent}
        color="secondary"
        className={classes.badge}
        anchorOrigin={{ vertical, horizontal }}
      >
        {children}
      </Badge>
    );
  }
  return children;
}

CardBadge.propTypes = {
  show: PropTypes.bool,
  badgeContent: PropTypes.string,
};
