import { SvgIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronDown from '@videoblocks/react-icons/ChevronDown';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey[400],
    fontSize: '16px',
    margin: 'auto',
    marginRight: theme.spacing(2),
    top: 0,
    bottom: 0,
  },
}));

function ExpandIcon({ className }) {
  const classes = useStyles();
  return (
    <SvgIcon
      fontSize="small"
      className={clsx(className, classes.icon)}
      component={ChevronDown}
    />
  );
}

export default ExpandIcon;
