import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  imagePreview: {
    width: '100%',
    height: '100%',
    objectFit: ({ objectFit }) => objectFit,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default function ImagePreview({ source, title, objectFit = 'cover' }) {
  const classes = useStyles({ objectFit });
  return <img className={classes.imagePreview} src={source} alt={title} />;
}

ImagePreview.propTypes = {
  source: PropTypes.string,
  title: PropTypes.string,
};
