import { useDndContext, useDndMonitor, useDroppable } from '@dnd-kit/core';
import { SvgIcon } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { LayerTypes, PIXI } from '@videoblocks/jelly-renderer';
import log from 'loglevel';
import { nanoid } from 'nanoid';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as AddCircle } from '../../assets/icons/add-circle.svg';
import {
  DND_ZONES,
  PREVIEW_MIN_HEIGHT,
  PREVIEW_MIN_WIDTH,
  SOURCE_TYPES,
} from '../../constants/Constants';
import { MIN_FONT_SIZE } from '../../constants/FontStyles';
import { PREVIEW } from '../../constants/IntercomSelectors';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { CANVAS } from '../../events/tags';
import useRemoveUnavailableFonts from '../../hooks/useRemoveUnavailableFonts';
import useRenderer from '../../hooks/useRenderer';
import useUploads from '../../hooks/useUploads';
import createObject from '../../models/createObject';
import { selectOrganizationId } from '../../selectors/user';
import {
  preloadPreviewStart,
  preloadPreviewSuccess,
  selectMute,
  setActiveItemId,
} from '../../slices/editorSlice';
import {
  itemAdded,
  itemUpdated,
  selectAllLayersWithItems,
} from '../../slices/storyboardSlice';
import { checkMediaItem } from '../../utils/mediaUtils';
import {
  getInsertLayerIndex,
  mapMediaToLayer,
} from '../../utils/storyboardUtils';
import flags from '../flags/flags';
import useFlag from '../flags/useFlag';
import ProjectViewer from './ProjectViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    padding: theme.spacing(3),
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    color: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'currentColor',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.green[500], 0.4),
  },
  iconWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
      width: '60%',
      height: '60%',
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  },
  addIcon: {
    position: 'relative',
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.green[500],
  },
}));

const DROPPABLE_ID = 'project-canvas';

function PreviewContainer(props) {
  const { height = PREVIEW_MIN_HEIGHT, width = PREVIEW_MIN_WIDTH } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { timestamp } = useRenderer();
  const { projectId, templateId } = useParams();
  const { associateUpload } = useUploads();
  const mute = useSelector(selectMute);
  const layers = useSelector(selectAllLayersWithItems);
  const organizationId = useSelector(selectOrganizationId);

  const { active } = useDndContext();

  // this must be set before the first time we request a Renderer instance
  PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = !!useFlag(
    flags.DISABLE_CAVEAT
  );

  useRemoveUnavailableFonts(organizationId);

  const onClickItem = (item) => {
    const { id } = item;
    const { isInteractive } = checkMediaItem(item);

    if (isInteractive) {
      dispatch(setActiveItemId(id));
    }
  };

  const onMoveItem = (item, position) => {
    const { id } = item;
    const { isInteractive } = checkMediaItem(item);

    if (isInteractive) {
      dispatch(setActiveItemId(id));
      dispatch(itemUpdated({ id, changes: { position } }));
      trackEvent(
        CANVAS.ITEM_EDIT,
        getItemEditProps(item, 'position', position)
      );
    }
  };

  const onScaleItem = (item, scale, position) => {
    const { id, fontSize } = item;
    const { isVideo, isImage, isText } = checkMediaItem(item);

    if (isText) {
      dispatch(
        itemUpdated({ id, changes: { fontSize: fontSize * scale, position } })
      );
      trackEvent(
        CANVAS.ITEM_EDIT,
        getItemEditProps(item, 'fontSize', item.fontSize)
      );
    }

    if (isImage || isVideo) {
      dispatch(itemUpdated({ id, changes: { position, scale } }));
      trackEvent(CANVAS.ITEM_EDIT, getItemEditProps(item, 'scale', scale));
    }

    dispatch(setActiveItemId(id));
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    const { item, zone: fromZone } = active?.data?.current || {};
    const { zone: toZone } = over?.data?.current || {};

    if (item && fromZone === DND_ZONES.DRAWER && toZone === DND_ZONES.CANVAS) {
      const newItem = await createObject(item);
      const layerIndex = getInsertLayerIndex(layers, {
        ...newItem,
        startTime: timestamp,
      });
      const toLayerType = mapMediaToLayer(item.mediaType);
      const toLayerIndex = Math.max(
        layerIndex,
        toLayerType === LayerTypes.AUDIO ? layers.length : 0
      );

      dispatch(
        itemAdded({
          ...newItem,
          layerId: layers[layerIndex]?.id || nanoid(),
          layerIndex: toLayerIndex,
        })
      );

      if (item.sourceType === SOURCE_TYPES.UPLOAD) {
        await associateUpload({
          uploadId: item.id,
          templateUid: templateId,
          projectUid: projectId,
        });
      }

      trackEvent(CANVAS.ITEM_ADD, {
        ...newItem,
        toLayerIndex,
        toLayerType,
        newLayerCreated: layerIndex < 0,
      });
    }
  };

  const { isOver, setNodeRef } = useDroppable({
    id: DROPPABLE_ID,
    data: { zone: DND_ZONES.CANVAS },
  });

  useDndMonitor({ onDragEnd: handleDragEnd });

  return (
    <div
      data-intercom-target={PREVIEW}
      className={classes.root}
      ref={setNodeRef}
    >
      <ProjectViewer
        height={height}
        layers={layers}
        logoSource="/images/logo-placeholder.png"
        minFontSize={MIN_FONT_SIZE}
        mute={mute}
        onClickItem={onClickItem}
        onLoadFail={(error) => {
          // TODO handle errors differently from successes
          log.error('Preview loading failed', { error });
          dispatch(preloadPreviewSuccess());
        }}
        onLoadStart={() => dispatch(preloadPreviewStart())}
        onLoadSuccess={() => dispatch(preloadPreviewSuccess())}
        onMoveItem={onMoveItem}
        onScaleItem={onScaleItem}
        width={width}
      />
      {isOver && active?.data?.current?.zone === DND_ZONES.DRAWER && (
        <div className={classes.container} style={{ width }}>
          <div className={classes.placeholder}>
            <div className={classes.iconWrapper}>
              <SvgIcon className={classes.addIcon} component={AddCircle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(PreviewContainer);
