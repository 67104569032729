import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  border: {
    position: 'relative',
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    color: theme.palette.pink[500],
    boxSizing: 'border-box',
    border: `1px dashed ${theme.palette.grey[300]}`,
    height: theme.spacing(5),
    width: '100%',
  },
  icon: {
    fontSize: 'inherit',
    marginRight: theme.spacing(0.5),
  },
}));

export default function AddColorButton({ onClick = () => {} }) {
  const classes = useStyles();
  return (
    <IconButton size="small" onClick={onClick} className={classes.border}>
      <AddCircleIcon className={classes.icon} />
      Add Color
    </IconButton>
  );
}
