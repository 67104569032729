import { useDndContext } from '@dnd-kit/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { inRange } from 'lodash';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DND_ZONES, OFF_SCREEN } from '../../constants/Constants';
import { DEFAULT_ITEM_DURATION } from '../../constants/Storyboard';
import { TIMELINE_LAYER } from '../../constants/TestSelectors';
import { selectZoom } from '../../slices/editorSlice';
import {
  makeSelectItemsByLayerId,
  selectLayerById,
} from '../../slices/storyboardSlice';
import { getSnappableOffset } from '../../utils/snappingUtils';
import {
  getItemToReplace,
  getLayerHeight,
  getTimelineGaps,
  layerAcceptsMediaType,
} from '../../utils/storyboardUtils';
import { pixelsToSeconds, secondsToPixels } from '../../utils/timelineUtils';
import Droppable from '../Droppable';
import ItemPlaceholder from './ItemPlaceholder';
import TimelineItem from './TimelineItem';

const useStyles = makeStyles((theme) => ({
  timelineLayer: {
    position: 'relative',
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[800],
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
  placeholderItem: {
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.green[400], 0.4),
    borderColor: theme.palette.white,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'dashed',
    borderWidth: ({ itemToReplace }) => (itemToReplace ? '0' : '2px'),
    boxSizing: 'border-box',
    color: theme.palette.common.white,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
}));

function TimelineLayer(props) {
  const {
    id = '',
    layerIndex = 0,
    placeholderOffset = OFF_SCREEN,
    setSnappedTo = () => {},
    snappableOffsets = [],
  } = props;

  const selectItemsByLayerId = useMemo(makeSelectItemsByLayerId, []);

  const layer = useSelector((state) => selectLayerById(state, id));
  const items = useSelector((state) => selectItemsByLayerId(state, id));
  const zoom = useSelector(selectZoom);
  const [snapTo, setSnapTo] = useState();

  const { type, itemIds } = layer;
  const { active, over } = useDndContext();
  const { item, zone } = active?.data?.current || {};
  const { duration = DEFAULT_ITEM_DURATION, mediaType } = item || {};

  const secondsOffset = pixelsToSeconds(placeholderOffset, zoom);

  const acceptsItem = useMemo(
    () => layerAcceptsMediaType(type, mediaType),
    [type, mediaType]
  );

  const showPlaceholder = useMemo(
    () => over?.id === id && acceptsItem && placeholderOffset !== OFF_SCREEN,
    [acceptsItem, over?.id, id, placeholderOffset]
  );

  const layerGaps = useMemo(() => getTimelineGaps(items), [items]);

  const isDraggingCard = showPlaceholder && zone === DND_ZONES.DRAWER;

  const itemToReplace =
    isDraggingCard && getItemToReplace(secondsOffset, items, zoom, mediaType);

  const gapToInsertInto =
    isDraggingCard &&
    layerGaps.length > 0 &&
    layerGaps.find(
      ({ startTime, duration: gapDuration }) =>
        inRange(secondsOffset, startTime, startTime + gapDuration) &&
        duration > gapDuration
    );

  const classes = useStyles({ itemToReplace });

  useEffect(() => {
    if (showPlaceholder) {
      if (itemToReplace || gapToInsertInto) {
        setSnapTo(null);
        setSnappedTo(null);
      } else {
        const durationPixels = secondsToPixels(duration, zoom);
        const snappableOffset = getSnappableOffset(snappableOffsets, {
          id: item?.id,
          start: placeholderOffset,
          duration: durationPixels,
        });
        setSnappedTo(snappableOffset);

        if (!snappableOffset) {
          setSnapTo(placeholderOffset);
        } else {
          const { offset, snapToEnd = false } = snappableOffset;
          setSnapTo(snapToEnd ? offset - durationPixels : offset);
        }
      }
    }
  }, [
    duration,
    gapToInsertInto,
    item?.id,
    itemToReplace,
    placeholderOffset,
    setSnappedTo,
    showPlaceholder,
    snappableOffsets,
    zoom,
  ]);

  const placeholderLeft = itemToReplace
    ? secondsToPixels(itemToReplace.startTime, zoom)
    : gapToInsertInto
    ? secondsToPixels(gapToInsertInto.startTime, zoom)
    : showPlaceholder
    ? snapTo ?? placeholderOffset
    : OFF_SCREEN;

  const placeholderDuration = itemToReplace
    ? itemToReplace.duration
    : gapToInsertInto
    ? Math.min(gapToInsertInto.duration, duration)
    : duration;

  const style = {
    height: getLayerHeight(type),
  };

  return (
    <div
      className={classes.timelineLayer}
      data-testid={TIMELINE_LAYER}
      style={style}
    >
      <Droppable
        id={id}
        data={{
          id,
          layerId: id,
          layerIndex,
          type,
          items,
          zone: DND_ZONES.TIMELINE,
        }}
      >
        {itemIds.map((itemId) => (
          <TimelineItem
            id={itemId}
            key={itemId}
            layerGaps={layerGaps}
            layerId={id}
            setSnappedTo={setSnappedTo}
            snappableOffsets={snappableOffsets}
          />
        ))}
        {showPlaceholder && (
          <ItemPlaceholder
            duration={placeholderDuration}
            offset={placeholderLeft}
            showReplaceIcon={!!itemToReplace}
          />
        )}
      </Droppable>
    </div>
  );
}

TimelineLayer.propTypes = {
  id: PropTypes.string,
  placeholderOffset: PropTypes.number,
};

export default memo(TimelineLayer);
