import { useDroppable } from '@dnd-kit/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import mergeRefs from '../utils/mergeRefs';

const useStyles = makeStyles((theme) => ({
  droppableContainer: {
    position: 'relative',
    height: '100%',
  },
}));

const Droppable = forwardRef((props, ref) => {
  const {
    children,
    className,
    data,
    disabled = false,
    element: Element = 'div',
    id = 'droppable',
    isOverClassName = '',
    ...rest
  } = props;
  const classes = useStyles();

  const { isOver, setNodeRef } = useDroppable({ id, disabled, data });

  return (
    <Element
      className={clsx(
        classes.droppableContainer,
        className,
        isOver && isOverClassName
      )}
      ref={mergeRefs(setNodeRef, ref)}
      {...rest}
    >
      {children}
    </Element>
  );
});

Droppable.propTypes = {
  data: PropTypes.object,
  element: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Droppable;
