import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';

import { UNIFIED_NAVBAR_HEIGHT } from '../constants/Constants';
import { MAKER_HOME_WP_SEE_ALL_URL } from '../constants/Urls';
import useBlogs from '../hooks/useBlogs';
import Article from './Article';

const NUM_BLOGS_TO_SHOW = 5;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(3),
    minHeight: `calc(100vh - ${UNIFIED_NAVBAR_HEIGHT}px)`,
    height: '100%',
  },
  link: {
    display: 'inline-block',
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(0.5, 0),
  },
}));

export default function ArticleList() {
  const classes = useStyles();
  const { posts } = useBlogs();

  return (
    <Box className={classes.container}>
      <Grid justifyContent="space-between" align="center" container spacing={1}>
        <Grid item>
          <Typography component="h2" variant="h4">
            Articles
          </Typography>
        </Grid>

        <Grid item>
          <Link
            color="secondary"
            className={classes.link}
            href={MAKER_HOME_WP_SEE_ALL_URL}
            aria-label="See all articles"
            target="_blank"
            rel="noopener"
          >
            See All
          </Link>
        </Grid>
      </Grid>

      {posts.slice(0, NUM_BLOGS_TO_SHOW).map((post) => {
        const { title, slug, excerpt, featured_media, link } = post;
        return (
          <Article
            key={slug}
            title={title?.rendered}
            link={link}
            excerpt={excerpt?.rendered}
            featuredMediaId={featured_media}
          />
        );
      })}
    </Box>
  );
}
