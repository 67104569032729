import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon,
} from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CameraStudioIcon from '@videoblocks/react-icons/CameraStudio';
import PropTypes from 'prop-types';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
    width: theme.spacing(2.5),
  },
  errorColor: {
    color: theme.palette.common.red,
  },
}));

export default function TemplateSettingsMenu(props) {
  const {
    onPromptDelete: handlePromptDelete,
    onDuplicate: handleDuplicate,
    onSelect: handleSelect,
    onStartProject: handleStartProjectWithTemplate,
    templateOwnedByUser,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const copyText = templateOwnedByUser ? 'Duplicate' : 'Copy to My Templates';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStartProjectClick = () => {
    handleStartProjectWithTemplate();
    handleClose();
  };

  const handleEditClick = () => {
    handleSelect();
    handleClose();
  };

  const handleDuplicateClick = () => {
    handleDuplicate();
    handleClose();
  };

  const handleDeleteClick = () => {
    handlePromptDelete();
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="Template settings menu"
        aria-controls="template-settings-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="template-settings-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleStartProjectClick}>
          <ListItemIcon>
            <SvgIcon className={classes.icon} component={CameraStudioIcon} />
          </ListItemIcon>
          <ListItemText primary="Create Project from Template" />
        </MenuItem>
        {templateOwnedByUser && (
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <EditRoundedIcon className={classes.icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit Template" />
          </MenuItem>
        )}
        <MenuItem onClick={handleDuplicateClick}>
          <ListItemIcon>
            <FileCopyRoundedIcon className={classes.icon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={copyText} />
        </MenuItem>
        {templateOwnedByUser && (
          <MenuItem onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteRoundedIcon color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ color: 'error' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

TemplateSettingsMenu.propTypes = {
  onPromptDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSelect: PropTypes.func,
  templateOwnedByUser: PropTypes.bool,
};

TemplateSettingsMenu.defaultProps = {
  onPromptDelete: () => {},
  onDuplicate: () => {},
  onSelect: () => {},
  templateOwnedByUser: false,
};
