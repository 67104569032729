import { SvgIcon } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import defaultTheme from '@material-ui/core/styles/defaultTheme';
import ChevronDownIcon from '@videoblocks/react-icons/ChevronDown';
import CloseIcon from '@videoblocks/react-icons/Close';

import colors from './colors';

const palette = {
  common: {
    black: colors.black,
    white: colors.white,
  },
  primary: {
    light: colors.yellow[400],
    main: colors.yellow[500],
    dark: colors.yellow[600],
  },
  secondary: {
    light: colors.blue[400],
    main: colors.blue[500],
    dark: colors.blue[600],
  },
  error: {
    light: colors.red[100],
    main: colors.red[500],
    dark: colors.red[700],
  },
  warning: {
    light: colors.orange[100],
    main: colors.orange[500],
    dark: colors.orange[700],
  },
  success: {
    light: colors.green[100],
    main: colors.green[500],
    dark: colors.green[700],
  },
  grey: colors.gray,
  yellow: colors.yellow,
  blue: colors.blue,
  pink: colors.pink,
  red: colors.red,
  green: colors.green,
};

const typography = {
  fontFamily: 'proxima-nova, "Roboto", "Helvetica", "Arial", sans-serif',
  fontWeightNormal: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontSize: defaultTheme.typography.pxToRem(72),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.1111111111,
  },
  h2: {
    fontSize: defaultTheme.typography.pxToRem(40),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: defaultTheme.typography.pxToRem(32),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: defaultTheme.typography.pxToRem(24),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.3333333333,
  },
  h5: {
    fontSize: defaultTheme.typography.pxToRem(20),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.6,
  },
  h6: {
    fontSize: defaultTheme.typography.pxToRem(18),
    fontWeight: defaultTheme.typography.fontWeightBold,
    lineHeight: 1.7777777778,
  },
  button: {
    fontSize: defaultTheme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'none',
  },
};

const overrides = {
  MuiAccordion: {
    root: {
      boxShadow: defaultTheme.shadows[1],
      '&:not(:first-child)': {
        marginTop: defaultTheme.spacing(1),
      },
      '&::before': {
        display: 'none',
      },
      '&$expanded': {
        margin: defaultTheme.spacing(1, 0),
      },
      '& label': {
        marginBottom: defaultTheme.spacing(0.5),
        fontSize: defaultTheme.typography.pxToRem(16),
        fontWeight: typography.fontWeightBold,
        color: palette.grey[900],
      },
    },
    rounded: {
      borderRadius: defaultTheme.shape.borderRadius,
    },
  },
  MuiAccordionSummary: {
    content: {
      fontWeight: typography.fontWeightBold,
      color: defaultTheme.palette.grey[600],
    },
    expandIcon: {
      fontSize: defaultTheme.typography.pxToRem(16),
      color: defaultTheme.palette.grey[400],
    },
  },
  MuiButton: {
    root: {
      borderRadius: '9999px',
    },
    contained: {
      padding: defaultTheme.spacing(1, 4),
      color: palette.common.white,
      backgroundColor: palette.common.black,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: palette.grey[800],
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        backgroundColor: palette.grey[200],
      },
      '&$focusVisible': {
        boxShadow: `0 0 0 2px ${palette.blue[600]}`,
      },
    },
    containedSizeSmall: {
      padding: defaultTheme.spacing(1, 4),
      fontSize: defaultTheme.typography.pxToRem(14),
      lineHeight: 16 / 14,
    },
    containedSizeLarge: {
      padding: defaultTheme.spacing(1.5, 8),
      fontSize: defaultTheme.typography.pxToRem(16),
    },
    outlined: {
      padding: defaultTheme.spacing(0.75, 3.75),
      border: `2px solid ${palette.common.black}`,
      '&$disabled': {
        border: `2px solid ${defaultTheme.palette.action.disabled}`,
      },
    },
    outlinedPrimary: {
      border: `2px solid ${palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${palette.primary.main}`,
      },
    },
    outlinedSecondary: {
      border: `2px solid ${palette.secondary.main}`,
      '&:hover': {
        border: `2px solid ${palette.secondary.main}`,
      },
    },
    outlinedSizeSmall: {
      padding: defaultTheme.spacing(0.75, 3.75),
      fontSize: defaultTheme.typography.pxToRem(14),
      lineHeight: 16 / 14,
    },
    outlinedSizeLarge: {
      padding: defaultTheme.spacing(1.25, 7.75),
      fontSize: defaultTheme.typography.pxToRem(16),
    },
    text: {
      padding: defaultTheme.spacing(1, 2),
    },
    textSizeSmall: {
      padding: defaultTheme.spacing(1, 2),
      fontSize: defaultTheme.typography.pxToRem(14),
    },
    iconSizeSmall: {
      '& > *:first-child': {
        fontSize: defaultTheme.typography.pxToRem(20),
      },
    },
  },
  MuiChip: {
    root: {
      height: defaultTheme.spacing(4.5),
      fontSize: defaultTheme.typography.pxToRem(16),
      borderRadius: defaultTheme.shape.borderRadius,
      backgroundColor: palette.grey[100],
      color: palette.common.black,
    },
    deletable: {
      '&:focus': {
        backgroundColor: palette.grey[200],
      },
    },
    label: {
      paddingRight: defaultTheme.spacing(1),
      paddingLeft: defaultTheme.spacing(1),
    },
    deleteIcon: {
      width: defaultTheme.spacing(1.5),
      height: defaultTheme.spacing(1.5),
      color: palette.grey[600],
      margin: undefined, // unset margin
      marginRight: defaultTheme.spacing(1),
      '&:hover': {
        color: palette.grey[800],
      },
    },
  },
  MuiDialogActions: {
    root: {
      padding: defaultTheme.spacing(2, 4, 4),
    },
  },
  MuiDialogContent: {
    root: {
      padding: defaultTheme.spacing(2, 4, 2),
      '&:first-child': {
        paddingTop: defaultTheme.spacing(4),
      },
    },
  },
  MuiDialogContentText: {
    root: {
      marginBottom: 0,
      color: palette.common.black,
    },
  },
  MuiDialogTitle: {
    root: {
      padding: defaultTheme.spacing(4, 4, 2),
    },
  },
  MuiFormLabel: {
    root: {
      marginBottom: defaultTheme.spacing(0.5),
      fontSize: defaultTheme.typography.pxToRem(14),
      fontWeight: typography.fontWeightBold,
      lineHeight: 16 / 14,
      color: palette.common.black,
    },
  },
  MuiIconButton: {
    root: {
      padding: defaultTheme.spacing(1),
    },
    sizeSmall: {
      padding: defaultTheme.spacing(1),
      fontSize: defaultTheme.typography.pxToRem(14),
      lineHeight: 16 / 14,
    },
  },
  MuiInputBase: {
    root: {
      color: palette.common.black,
    },
    input: {
      height: undefined, // unset explicit height
    },
  },
  MuiInputLabel: {
    formControl: {
      position: 'relative',
      transform: 'none',
    },
  },
  MuiLink: {
    root: {
      fontWeight: typography.fontWeightMedium,
      transition: defaultTheme.transitions.create(
        ['color', 'text-decoration'],
        {
          duration: defaultTheme.transitions.duration.complex,
          easing: defaultTheme.transitions.easing.easeInOut,
        }
      ),
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '32px',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: defaultTheme.spacing(1, 1.5),
      lineHeight: 1.5,
      transition: defaultTheme.transitions.create('background-color', {
        duration: defaultTheme.transitions.duration.short,
        easing: defaultTheme.transitions.easing.easeInOut,
      }),
      '&::placeholder': {
        opacity: 1,
        color: palette.grey[600],
      },
      '&:hover': {
        backgroundColor: palette.grey[100],
      },
    },
    notchedOutline: {
      borderColor: palette.grey[400],
    },
    multiline: {
      padding: defaultTheme.spacing(0.25, 0.25),
    },
    inputMultiline: {
      resize: 'vertical',
      padding: defaultTheme.spacing(1, 1.25),
    },
  },
  MuiSelect: {
    outlined: {
      '&$outlined': {
        paddingRight: defaultTheme.spacing(6),
      },
    },
    iconOutlined: {
      top: `calc(50% - ${defaultTheme.spacing(1)}px)`,
      right: defaultTheme.spacing(2),
      fontSize: defaultTheme.typography.pxToRem(16),
      color: palette.grey[500],
    },
  },
  MuiSlider: {
    rail: {
      height: 4,
      borderRadius: 8,
      backgroundColor: palette.common.black,
      opacity: 0.125,
    },
    track: {
      height: 4,
      borderRadius: 8,
    },
    thumb: {
      marginTop: -6,
      marginLeft: -8,
      width: 16,
      height: 16,
    },
  },
  MuiTabs: {
    root: {
      color: palette.grey[600],
    },
    indicator: {
      display: 'none',
    },
  },
  MuiTab: {
    root: {
      fontWeight: typography.fontWeightRegular,
      '&$selected': {
        color: palette.pink[500],
      },
    },
    textColorInherit: {
      opacity: '1',
      '&$disabled': {
        color: 'inherit',
      },
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 600,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: defaultTheme.typography.pxToRem(12),
      backgroundColor: palette.grey[900],
      lineHeight: defaultTheme.typography.pxToRem(16),
    },
    arrow: {
      color: palette.grey[900],
    },
  },
};

const DeleteIcon = (props) => <SvgIcon component={CloseIcon} {...props} />;

const props = {
  MuiAccordionSummary: {
    expandIcon: <SvgIcon component={ChevronDownIcon} fontSize="inherit" />,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiChip: {
    deleteIcon: <DeleteIcon />,
  },
  MuiInputBase: {
    color: 'secondary',
  },
  MuiInputLabel: {
    color: 'secondary',
    shrink: false,
  },
  MuiSelect: {
    IconComponent: (props) => (
      <SvgIcon component={ChevronDownIcon} {...props} />
    ),
  },
  MuiTooltip: {
    arrow: true,
    enterDelay: 500,
    placement: 'top',
  },
};

const themeName = 'Storyblocks';

export default createTheme({
  overrides,
  palette,
  props,
  themeName,
  typography,
});
