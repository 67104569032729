import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { unloadProject, setWorkspaceProjectId } from '../actions/projects';
import { resetAssetDrawer } from '../features/assetDrawer/assetDrawerSlice';

export default function LocationListener() {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  useEffect(() => {
    if (projectId) {
      dispatch(setWorkspaceProjectId(projectId));
    } else {
      dispatch(unloadProject());
      dispatch(resetAssetDrawer());
    }
  }, [dispatch, projectId]);

  return null;
}
