import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import { stringify } from 'query-string';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { DEFAULT_BRAND_NAME } from '../../constants/StylesConstants';
import { trackEvent } from '../../events/sendEvents';
import { BRANDS } from '../../events/tags';
import {
  selectBrandName,
  selectBrandUid,
  selectIsBrandUnsaved,
  setBrandUid,
  setIsBrandUnsaved,
} from '../../features/assetDrawer/assetDrawerSlice';
import {
  closeDialog,
  openBrandDeleteDialog,
  openBrandUnsavedDialog,
  openConfirmationDialog,
  openToast,
} from '../../features/ui/uiSlice';
import useBrands from '../../hooks/useBrands';
import useProject from '../../hooks/useProject';
import useQuery from '../../hooks/useQuery';
import useTemplate from '../../hooks/useTemplate';
import { stylesApplied } from '../../slices/storyboardSlice';
import BrandDetailsDrawer from './BrandDetailsDrawer';
import BrandsOverview from './BrandsOverview';

const useStyles = makeStyles((theme) => ({
  backButton: {
    color: theme.palette.grey[900],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(3, 2, 1, 2),
    alignSelf: 'start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'clip',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.grey[900],
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

export default function BrandsDrawer({ onSelect = () => {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { flags } = useQuery();
  const { projectId, templateId } = useParams();
  const { project, setProjectBrand } = useProject(projectId);
  const { template, setTemplateBrand } = useTemplate(templateId);
  const brandName = useSelector(selectBrandName);
  const brandUid = useSelector(selectBrandUid);
  const [isNewBrand, setIsNewBrand] = useState(false);
  const isBrandUnsaved = useSelector(selectIsBrandUnsaved);

  const appliedBrandUid = !!projectId ? project.brand_uid : template.brand_uid;

  const { deleteBrand } = useBrands();

  const handleSaveNewBrand = async () => {
    setIsNewBrand(false);
  };

  const navigateToBrand = (uid, isNewBrand = false, isEditingBrand = false) => {
    setIsNewBrand(isNewBrand);
    history.replace({
      search: stringify(
        isNewBrand || isEditingBrand ? { flags, isEditing: true } : { flags }
      ),
    });
    dispatch(setBrandUid(uid));
  };

  const handleBackToBrands = () => {
    if (isBrandUnsaved) {
      dispatch(
        openBrandUnsavedDialog({
          onConfirm: () => {
            dispatch(setBrandUid(null));
            if (isNewBrand) {
              deleteBrand(brandUid);
            }
            setIsNewBrand(false);
            dispatch(setIsBrandUnsaved(false));
          },
          brandName,
        })
      );
    } else {
      dispatch(setBrandUid(null));
    }
  };

  const handleApply = (brand) => {
    trackEvent(BRANDS.APPLY_START, { uid: brand.uid, projectId });

    dispatch(
      openConfirmationDialog({
        title: 'Apply Brand?',
        text: (
          <>
            This will re-style current and future elements in your{' '}
            {templateId ? 'template' : 'project'}.
          </>
        ),
        confirmButtonText: 'Apply Brand',
        showCancel: true,
        onConfirm: () => {
          if (projectId) {
            setProjectBrand(projectId, brand.uid);
          } else if (templateId) {
            setTemplateBrand(templateId, brand.uid);
          }
          dispatch(
            openToast(
              `"${brand?.detail?.name || DEFAULT_BRAND_NAME}" styles applied.`
            )
          );
          dispatch(stylesApplied(brand?.detail?.styles));
          dispatch(closeDialog());
          trackEvent(BRANDS.APPLY_CONFIRM, { brandUid, projectId });
        },
      })
    );
  };

  const handleDelete = async (uid) => {
    dispatch(
      openBrandDeleteDialog({
        brandUid: uid,
        onSuccess: () => dispatch(setBrandUid(null)),
      })
    );
  };

  return (
    <div className={classes.drawer}>
      {brandUid && (
        <Button
          className={classes.backButton}
          onClick={handleBackToBrands}
          startIcon={<ChevronLeft />}
        >
          Back to Brands
        </Button>
      )}
      <div className={classes.content}>
        {brandUid ? (
          <BrandDetailsDrawer
            onApply={handleApply}
            navigateToBrand={navigateToBrand}
            onDelete={handleDelete}
            onSaveNewBrand={handleSaveNewBrand}
            onLogoSelect={onSelect}
            isNewBrand={isNewBrand}
            appliedBrandUid={appliedBrandUid}
          />
        ) : (
          <BrandsOverview
            onApply={handleApply}
            onDelete={handleDelete}
            navigateToBrand={navigateToBrand}
            appliedBrandUid={appliedBrandUid}
          />
        )}
      </div>
    </div>
  );
}
