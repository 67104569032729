import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { TIMELINE_LEFT_PADDING } from '../../constants/Constants';
import { MAX_STORYBOARD_DURATION } from '../../constants/Storyboard';
import { secondsToPixels } from '../../utils/timelineUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 40,
    top: theme.spacing(4),
    bottom: 0,
    left: 0,
    width: 3,
    backgroundColor: theme.palette.warning.main,
    pointerEvents: 'auto',
  },
  handle: {
    fill: theme.palette.warning.main,
    // fills in the '!' without extending to the edge of the icon
    background:
      'radial-gradient(circle, white 0%, white 50%, transparent 50%, transparent 100%)',
    transform: 'translate(calc(-50% + 1px), calc(-50% - 2px))',
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.fontSize,
    maxWidth: 260,
    padding: theme.spacing[1],
  },
}));

function TimelineMaxMarker(props) {
  const { duration = 0, scrollX = 0, zoom = 0 } = props;
  const classes = useStyles();

  if (duration < MAX_STORYBOARD_DURATION) {
    return null;
  }

  const offset =
    secondsToPixels(MAX_STORYBOARD_DURATION, zoom) -
    scrollX +
    TIMELINE_LEFT_PADDING;
  const transform = `translateX(${offset}px)`;
  const tooltipText =
    'Projects that exceed the recommended length of 5 minutes may experience errors during the export process.';

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="right"
      title={tooltipText}
    >
      <div className={classes.root} style={{ transform }}>
        <ErrorRoundedIcon className={classes.handle} />
      </div>
    </Tooltip>
  );
}

TimelineMaxMarker.propTypes = {
  duration: PropTypes.number,
  scrollX: PropTypes.number,
  zoom: PropTypes.number,
};

export default memo(TimelineMaxMarker);
