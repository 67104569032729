/**
 * Checks if n is between start and end (inclusive).
 * If end is not specified, it is set to start with start then set to 0.
 *
 * @param {number} number - The number to check.
 * @param {number} [start=0] - The start of the range.
 * @param {number} end - The end of the range.
 * @return {boolean} - Returns true if number is in the range, else false.
 */
export default function inRange(number, start, end) {
  if (!end) {
    end = start;
    start = 0;
  }
  return number >= start && number <= end;
}
