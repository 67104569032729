import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  inCell: {
    display: 'inline-block',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  inline: {
    borderRadius: 0,
    padding: 0,
    textAlign: 'left',
  },
  link: {
    color: theme.palette.blue[700],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightNormal,
    textDecoration: 'underline',
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

/**
 * Button that looks and acts like a link. May be used in a button group,
 * inline with text, or in a table cell.
 *
 * @param props {HTMLAttributes<HTMLDivElement>}
 * @returns {JSX.Element}
 * @constructor
 */
function UnderlinedTextButton({
  children,
  inline = true,
  inCell = false,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Button
      role="link"
      className={clsx(classes.link, {
        [classes.inCell]: inCell,
        [classes.inline]: inline,
      })}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default memo(UnderlinedTextButton);
