import { textAnimations as rendererTextAnimations } from '@videoblocks/jelly-renderer';

import { ReactComponent as BoxRevealThumb } from '../assets/presets/BoxReveal-icon.svg';
import { ReactComponent as FadeThumb } from '../assets/presets/Fade-icon.svg';
import { ReactComponent as HighlightThumb } from '../assets/presets/Highlight-icon.svg';
import { ReactComponent as LowerThirdsThumb } from '../assets/presets/LowerThirds-icon.svg';
import { ReactComponent as MoveDownThumb } from '../assets/presets/MoveDown-icon.svg';
import { ReactComponent as MoveLeftThumb } from '../assets/presets/MoveLeft-icon.svg';
import { ReactComponent as MoveRightThumb } from '../assets/presets/MoveRight-icon.svg';
import { ReactComponent as MoveUpThumb } from '../assets/presets/MoveUp-icon.svg';
import { ReactComponent as NoneThumb } from '../assets/presets/None-icon.svg';
import { ReactComponent as OverUnderThumb } from '../assets/presets/OverUnder-icon.svg';
import { ReactComponent as PopThumb } from '../assets/presets/PopOut-icon.svg';
import { ReactComponent as QuoteThumb } from '../assets/presets/Quote-icon.svg';
import { ReactComponent as SandwichThumb } from '../assets/presets/Sandwich-icon.svg';
import { ReactComponent as ScrollingThumb } from '../assets/presets/Scrolling-icon.svg';
import { ReactComponent as SideLineThumb } from '../assets/presets/SideLine-icon.svg';
import { ReactComponent as SkewedThumb } from '../assets/presets/Skewed-icon.svg';
import { ReactComponent as StackedThumb } from '../assets/presets/StackedOver-icon.svg';
import { ReactComponent as TopLineThumb } from '../assets/presets/TopLine-icon.svg';
import { ReactComponent as TypewriterThumb } from '../assets/presets/Typewriter-icon.svg';
import { ReactComponent as ZoomInThumb } from '../assets/presets/ZoomIn-icon.svg';

//maxLines indicates the number of lines the animation supports. 0 indicates that there is no limit on the number of lines
export const textAnimations = [
  {
    label: 'None',
    maxLines: Infinity,
    thumbnail: NoneThumb,
    value: rendererTextAnimations.cut,
    showSecondaryColor: false,
    showHighlight: true,
    showDropShadow: true,
  },
  {
    label: 'Fade',
    maxLines: Infinity,
    thumbnail: FadeThumb,
    value: rendererTextAnimations.fade,
    showSecondaryColor: false,
    showHighlight: true,
    showDropShadow: true,
  },
  {
    label: 'Move Left',
    maxLines: Infinity,
    thumbnail: MoveLeftThumb,
    value: rendererTextAnimations.swipeleft,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Move Right',
    maxLines: Infinity,
    thumbnail: MoveRightThumb,
    value: rendererTextAnimations.swiperight,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Move Up',
    maxLines: Infinity,
    thumbnail: MoveUpThumb,
    value: rendererTextAnimations.swipeup,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Move Down',
    maxLines: Infinity,
    thumbnail: MoveDownThumb,
    value: rendererTextAnimations.swipedown,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Zoom In',
    maxLines: Infinity,
    thumbnail: ZoomInThumb,
    value: rendererTextAnimations.zoom,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Sandwich',
    maxLines: Infinity,
    thumbnail: SandwichThumb,
    value: rendererTextAnimations.wordblock,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Highlight',
    maxLines: Infinity,
    thumbnail: HighlightThumb,
    value: rendererTextAnimations.highlighter,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Skewed',
    maxLines: Infinity,
    thumbnail: SkewedThumb,
    value: rendererTextAnimations.gradientbox,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Box Reveal',
    maxLines: Infinity,
    thumbnail: BoxRevealThumb,
    value: rendererTextAnimations.boxreveal,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Scrolling',
    maxLines: Infinity,
    thumbnail: ScrollingThumb,
    value: rendererTextAnimations.scrolling,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Typewriter',
    maxLines: Infinity,
    thumbnail: TypewriterThumb,
    value: rendererTextAnimations.cutwords,
    showSecondaryColor: false,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Over/Under',
    maxLines: 2,
    thumbnail: OverUnderThumb,
    value: rendererTextAnimations.overunder,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Lower Thirds',
    maxLines: 2,
    thumbnail: LowerThirdsThumb,
    value: rendererTextAnimations.lowerthirds,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Side Line',
    maxLines: 2,
    thumbnail: SideLineThumb,
    value: rendererTextAnimations.sideline,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Top Line',
    maxLines: Infinity,
    thumbnail: TopLineThumb,
    value: rendererTextAnimations.topline,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Stacked Over',
    maxLines: 4,
    thumbnail: StackedThumb,
    value: rendererTextAnimations.stacked,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: false,
  },
  {
    label: 'Pop Out',
    maxLines: Infinity,
    thumbnail: PopThumb,
    value: rendererTextAnimations.pop,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: true,
  },
  {
    label: 'Quote',
    maxLines: Infinity,
    thumbnail: QuoteThumb,
    value: rendererTextAnimations.quote,
    showSecondaryColor: true,
    showHighlight: false,
    showDropShadow: true,
  },
];

export const getAnimationByValue = (animation) =>
  textAnimations.find(({ value }) => value === animation);
