import { cloneDeep } from 'lodash';

/**
 * Both animation and text items use the `animationPreset` property.
 * Break out into separate functions if the animation and text
 * items diverge further.
 *
 * @param {Text | Animation} oldItem - preset item being replaced
 * @param {Text | Animation} newItem - preset item being replaced with
 * @returns {Text | Animation}
 */
export default function replacePresetObject(oldItem, newItem) {
  return {
    ...cloneDeep(oldItem),
    id: newItem.id,
    animationPreset: newItem.animationPreset,
    ...(newItem.fontSize ? { fontSize: newItem.fontSize } : {}),
  };
}
