import { filter, groupBy } from 'lodash';
import useSWR from 'swr';

import StockAPI from '../api/StockAPI';
import * as ContentTypes from '../constants/ContentTypes';
import useContentToken from './useContentToken';

const fetcher = async (url, token) => {
  try {
    const response = await new StockAPI(token).get(url);
    return response.map((category) => ({
      ...category,
      content_type: ContentTypes.getContentType(category.content_type),
    }));
  } catch (error) {
    return [];
  }
};

export default function useAudioCategories() {
  const { contentToken } = useContentToken();
  const { data = [] } = useSWR(
    contentToken ? ['/audio/stock-item/categories', contentToken] : null,
    fetcher
  );

  const musicCategories = filter(data, ['content_type', ContentTypes.MUSIC]);
  const groups = groupBy(musicCategories, 'category_group');
  const { mood = [], genre = [], instrument = [] } = groups;
  return { mood, genre, instrument };
}
