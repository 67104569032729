import { Renderer } from '@videoblocks/jelly-renderer';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';

import TelemetryClient from '../../events/TelemetryClient';
import useRenderer from '../../hooks/useRenderer';
import RendererCanvas from './RendererCanvas';
import usePreviewSize from './usePreviewSize';

/**
 * Render a project using a jelly-renderer.
 *
 * The meat and potatoes of working with the render engine from React.
 * ProjectViewer is not used for encoding, which handles pixi.js in a very specific way
 */
function ProjectViewer({
  height,
  layers,
  logoSource = null,
  minFontSize,
  mute = false,
  onClickItem = () => {},
  onLoadFail = () => {},
  onLoadStart = () => {},
  onLoadSuccess = () => {},
  onMoveItem = () => {},
  onScaleItem = () => {},
  width,
}) {
  const { renderer } = useRenderer();

  const handleMute = () => {
    if (mute) {
      renderer.mute();
    } else {
      renderer.unmute();
    }
  };

  useEffect(
    () => {
      // with an empty dependency list, the returned function is used as an unmount callback
      return () => {
        renderer.clear();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    renderer.on(Renderer.INFO, log.info);
    renderer.on(Renderer.WARN, log.warn);
    renderer.on(Renderer.ERROR, log.error);
    renderer.on(Renderer.CLICK_ITEM, onClickItem);
    renderer.on(Renderer.MOVE_ITEM, onMoveItem);
    renderer.on(Renderer.SCALE_ITEM, onScaleItem);
    return () => {
      renderer.off(Renderer.INFO, log.info);
      renderer.off(Renderer.WARN, log.warn);
      renderer.off(Renderer.ERROR, log.error);
      renderer.off(Renderer.CLICK_ITEM, onClickItem);
      renderer.off(Renderer.MOVE_ITEM, onMoveItem);
      renderer.off(Renderer.SCALE_ITEM, onScaleItem);
    };
  }, [renderer, onClickItem, onMoveItem, onScaleItem]);

  const [pixiWidth, pixiHeight] = usePreviewSize();
  useEffect(() => {
    // If a storyboard has already been drawn to the canvas, set reloading so that
    // our position on the Timeline is not cleared
    if (!!renderer.projectDuration) {
      renderer.reloading = true;
    }
    renderer.resize(pixiWidth, pixiHeight);
  }, [pixiWidth, pixiHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onLoadStart();
    TelemetryClient.startTimer('project.load');

    renderer
      .loadProjectLayers(layers, {
        minFontSize,
        logoSource,
      })
      .then(() => {
        handleMute();
        onLoadSuccess();
        TelemetryClient.stopTimer('project.load');
      })
      .catch(onLoadFail)
      .finally(() => (renderer.reloading = false));
  }, [layers, logoSource, pixiWidth, pixiHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(handleMute, [mute]); // eslint-disable-line react-hooks/exhaustive-deps

  return <RendererCanvas style={{ height, width }} />;
}

ProjectViewer.propTypes = {
  height: PropTypes.number.isRequired,
  logoSource: PropTypes.string,
  minFontSize: PropTypes.number,
  mute: PropTypes.bool,
  onClickItem: PropTypes.func,
  onLoadFail: PropTypes.func,
  onLoadStart: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  onMoveItem: PropTypes.func,
  onScaleItem: PropTypes.func,
  width: PropTypes.number.isRequired,
};

export default memo(ProjectViewer);
