import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-use';

import { authLoadUser } from '../actions/user';

/**
 * Update user auth info every time the route changes
 */
export default function UserLoader() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authLoadUser());
  }, [location.pathname, dispatch]);
  return null;
}
