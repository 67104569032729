import log from 'loglevel';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import BrandsAPI from '../api/BrandsAPI';
import { selectIsEnterprise, selectIsOrgAdmin } from '../selectors/user';

const brandsUrl = '/brands'; // no pagination needed at this time

export default function useBrands() {
  const isEnterprise = useSelector(selectIsEnterprise);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  const fetcher = async (url, isEnterprise) => {
    if (!isEnterprise) {
      return [];
    }
    try {
      return await new BrandsAPI().get(url);
    } catch (error) {
      log.error(`Error loading brands: ${error?.message}`, { error });
      return [];
    }
  };

  const { data: brandsData, mutate: mutateBrands } = useSWR(brandsUrl, (url) =>
    fetcher(url, isEnterprise)
  );

  const createBrand = async (detail, isDraft) => {
    if (isEnterprise && isOrgAdmin) {
      // update local data immediately, but disable the revalidation
      brandsData.push({ detail });
      mutateBrands(brandsData, false);

      // send a request to the API to update the source
      const result = await new BrandsAPI().postBrand(detail, isDraft);

      // trigger a revalidation (refetch) to make sure our local data is correct
      mutateBrands();

      return result; // Return the UID
    }
    return null;
  };

  const deleteBrand = async (uid) => {
    if (isEnterprise && isOrgAdmin) {
      // update local data immediately, but disable the revalidation
      mutateBrands(
        brandsData.filter((b) => b.uid !== uid),
        false
      );

      // send a request to the API to update the source
      await new BrandsAPI().deleteBrand(uid);

      // trigger a revalidation (refetch) to make sure our local data is correct
      mutateBrands();
    }
  };

  return {
    createBrand,
    deleteBrand,
    isLoading: !brandsData,
    brands: brandsData?.sort((a, b) => (a.is_default ? -1 : 1)), // allow undefined to be passed when loading, sort default to top
  };
}
