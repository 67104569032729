import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme) => ({
  error: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.error.dark,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#5A2929',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
  },
  icon: {
    margin: theme.spacing(0, 1, 0, 2.5),
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default function TimelineItemErrorOverlay(props) {
  const { mediaType = 'content' } = props;
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <SvgIcon component={ErrorIcon} className={classes.icon} />
      {capitalize(mediaType)} not found
    </div>
  );
}
