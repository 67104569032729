import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import clsx from 'clsx';
import { bindHover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_TEMPLATE_NAME } from '../../constants/Storyboard';
import useBrand from '../../hooks/useBrand';
import useProject from '../../hooks/useProject';
import useTemplates from '../../hooks/useTemplates';
import { selectIsOrgAdmin } from '../../selectors/user';
import { selectWorkspaceProjectId } from '../../selectors/workspace';
import { selectName } from '../../slices/storyboardSlice';
import CloseButton from '../CloseButton';
import BrandCard from '../cards/BrandCard';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
  brandCard: {
    width: 200,
  },
  brandContainer: {
    pointerEvents: 'none',
  },
  teamAccessLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  teamAccessTooltipIcon: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(1),
  },
}));

function SaveAsTemplateDialog(props) {
  const { onClose, onConfirm, open } = props;

  const classes = useStyles();

  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const projectId = useSelector(selectWorkspaceProjectId);
  const name = useSelector(selectName);

  const { project } = useProject(projectId);
  const { brand_uid: brandUid } = project ?? {};
  const { brand } = useBrand(brandUid);
  const { createTemplateFromProject } = useTemplates();

  const [teamAccess, setTeamAccess] = useState(false);
  const [templateName, setTemplateName] = useState(
    name || DEFAULT_TEMPLATE_NAME
  );

  const teamAccessTooltipState = usePopupState({
    variant: 'popover',
    popupId: 'team-access-popover',
  });

  const handleConfirm = () => {
    const newName = templateName || DEFAULT_TEMPLATE_NAME;
    createTemplateFromProject(project, {
      name: newName,
      team_access: teamAccess,
      brand_uid: brandUid,
    });
    onClose();
    onConfirm(newName);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography component="h1" variant="h4">
          Save as a template
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div>
          <Typography component="h2" variant="h6">
            Template Name
          </Typography>
          <TextField
            value={templateName}
            onChange={(event) => setTemplateName(event.target.value)}
            data-testid="new-custom-template-name"
            variant="outlined"
            fullWidth
          />
        </div>
        {isOrgAdmin && (
          <div className={classes.marginTop}>
            <div className={classes.teamAccessLabelContainer}>
              <Typography component="h2" variant="h6">
                Team Access?
              </Typography>
              <Tooltip title="Allow everyone in your organization to view, use, and make a copy of your template">
                <SvgIcon
                  className={classes.teamAccessTooltipIcon}
                  component={QuestionCircleIcon}
                  {...bindHover(teamAccessTooltipState)}
                />
              </Tooltip>
            </div>
            <Switch
              name="team-access-switch"
              size="small"
              checked={teamAccess}
              onChange={(event) => setTeamAccess(event.target.checked)}
            />
          </div>
        )}
        {brandUid && (
          <div className={clsx(classes.brandContainer, classes.marginTop)}>
            <Typography component="h2" variant="h6">
              Brand
            </Typography>
            <Typography variant="body1">
              Whenever your template is used, the brand styles will be
              automatically applied.
            </Typography>
            <BrandCard
              brand={brand}
              className={clsx(classes.brandCard, classes.marginTop)}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Save Template
        </Button>
      </DialogActions>
      <Box position="absolute" top={0} right={0} m={2} color="grey.700">
        <CloseButton onClick={onClose} />
      </Box>
    </Dialog>
  );
}

SaveAsTemplateDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

SaveAsTemplateDialog.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
  open: false,
};

export default memo(SaveAsTemplateDialog);
