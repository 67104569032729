import axios from 'axios';
import useSWR from 'swr';

import { MAKER_HOME_WP_POSTS_URL } from '../constants/Urls';

const fetcher = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return [];
  }
};

export default function useBlogs() {
  const { data: posts = [] } = useSWR(MAKER_HOME_WP_POSTS_URL, fetcher);

  return { posts };
}
