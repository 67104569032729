import { Fonts } from '@videoblocks/jelly-renderer';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomCompareEffect } from 'react-use';

import { fontsAvailable } from '../actions/projects';
import useFonts from '../hooks/useFonts';
import { selectOrganizationId } from '../selectors/user';
import { loadGoogleFonts, loadUploadedFonts } from '../utils/fonts';

/**
 * @typedef {Object} Fontish
 * @property {string} fileName
 */

/**
 * Convert an array of fonts into concatenated filenames. This is used to see when the fonts have changed.
 * @param {Fontish[]} fonts
 * @return {string}
 */
function hashFontArray(fonts) {
  return (fonts || [])
    .map((f) => f?.fileName)
    .filter(Boolean)
    .sort()
    .join();
}

/**
 * Compare one font dependency array against another.
 * @param {Fontish[]} prevFonts
 * @param {Fontish[]} nextFonts
 * @return {boolean} True if they are the same.
 */
function customDependencyCompare([prevFonts], [nextFonts]) {
  return hashFontArray(prevFonts) === hashFontArray(nextFonts);
}

/**
 * Preload fonts so they're ready before the user opens the Font Select or views them rendered in a project.
 */
function FontLoader() {
  const dispatch = useDispatch();

  useEffect(() => loadGoogleFonts(Fonts.GOOGLE_FONTS), []);

  const organizationId = useSelector(selectOrganizationId);
  const { userFonts, orgFonts, isLoadingMoreAllFonts } = useFonts({
    organizationId,
  });

  // Stash the promises indicating when user and org fonts have been added to the document.
  /** @type {React.MutableRefObject<Promise<void>>} */
  const userFontsLoadRef = useRef();
  /** @type {React.MutableRefObject<Promise<void>>} */
  const orgFontsLoadRef = useRef();

  // Load uploaded fonts when we get a fresh array of uploads.
  // Only reload when the contents of the array have meaningfully changed.
  useCustomCompareEffect(
    () => {
      userFontsLoadRef.current = loadUploadedFonts(userFonts);
    },
    [userFonts],
    customDependencyCompare
  );
  useCustomCompareEffect(
    () => {
      orgFontsLoadRef.current = loadUploadedFonts(orgFonts);
    },
    [orgFonts],
    customDependencyCompare
  );

  // Dispatch an action indicating that custom fonts are available after we know that all
  // font uploads have been fetched and all corresponding fonts have been added to the document.
  useEffect(() => {
    if (!isLoadingMoreAllFonts) {
      Promise.all([userFontsLoadRef.current, orgFontsLoadRef.current]).then(
        () => {
          dispatch(fontsAvailable());
        }
      );
    }
  }, [isLoadingMoreAllFonts, dispatch]);

  return null;
}

export default FontLoader;
