import { kebabCase } from 'lodash';

import flags from './flags';

function parseFlagValue(value) {
  // TODO add parsing for non-string values as needed
  const falseValues = new Set(['false', 'f']);
  if (falseValues.has(value)) {
    return false;
  }
  return value ?? true;
}

const flagIds = new Set(
  Object.values(flags).map((flag) => flag.id ?? flag.ffsKey ?? flag)
);

export default function parseFlags(flagsQuery = '') {
  const flagStrings = flagsQuery.split(',');
  const flagEntries = flagStrings.map((flagString) => {
    const [flagName, flagValue] = flagString.split(':');
    return [kebabCase(flagName), parseFlagValue(flagValue)];
  });
  const knownFlagEntries = flagEntries.filter(([flag]) => flagIds.has(flag));
  const featureFlags = Object.fromEntries(knownFlagEntries);
  return featureFlags;
}
