import { Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { selectUpgradePath, shouldWatermark } from '../../selectors/user';
import Alert from '../Alert';

export default function EnterprisePlanAlerts() {
  const isWatermarked = useSelector(shouldWatermark);
  const upgradePath = useSelector(selectUpgradePath);

  return (
    isWatermarked && (
      <Alert severity="info">
        <>
          Your video will export with watermarks. To export videos without a
          watermark, please
          <br />
          <Link
            color="secondary"
            href={upgradePath}
            target="_blank"
            rel="noopener"
          >
            <strong>Contact your Customer Success Manager to upgrade.</strong>
          </Link>
        </>
      </Alert>
    )
  );
}
