import PageViewTracker from '../events/PageViewTracker';
import TelemetryLoader from '../events/TelemetryLoader';
import FlagLoader from '../features/flags/FlagLoader';
import LoggerLoader from '../logger/LoggerLoader';
import SentryScoper from '../logger/SentryScoper';
import FontLoader from './FontLoader';
import UserLoader from './UserLoader';

/**
 * A home for our loaders
 *
 * A "Loader" is a React component that:
 * - sets up state for other components.
 * - does not render anything to the DOM.
 * - is added to the application root
 */
export default function GlobalLoader() {
  return (
    <>
      <FontLoader />
      <FlagLoader />
      <LoggerLoader />
      <SentryScoper />
      <TelemetryLoader />
      <UserLoader />
      <PageViewTracker />
    </>
  );
}
