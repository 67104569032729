export const BASE_PX_PER_SECOND = 40;
export const ZOOM_RATE = 1.25;

// an extra buffer region (in seconds) at the end of the timeline
const TIMELINE_ENDZONE = 2;

/**
 * Calculates a zoom factor (pixels per second) based on a scalar zoom level
 *
 * @param {number} [zoom=0] - The scalar zoom
 * @returns {number} - Returns the zoom factor
 */
function getZoomFactor(zoom = 0) {
  return BASE_PX_PER_SECOND * Math.pow(ZOOM_RATE, zoom);
}

/**
 * Determines the interval at which to show number labels on the ruler
 *
 * @param {number} zoom - The zoom level
 * @returns {number} - Returns an interval (in seconds)
 */
export function getInterval(zoom) {
  if (zoom <= -7) {
    return 30;
  }
  if (zoom <= -5) {
    return 20;
  }
  if (zoom <= -3) {
    return 10;
  }
  if (zoom <= 0) {
    return 5;
  }
  if (zoom <= 3) {
    return 4;
  }
  if (zoom <= 5) {
    return 2;
  }
  return 1;
}

/**
 * Calculates the minimal duration for the timeline based on current
 * project length and window width. Ensures that the duration is evenly
 * divisible by the current interval (based on zoom).
 *
 * @param {number} projectDuration - The duration of the current project (in seconds)
 * @param {number} windowWidth - The current window width (in pixels)
 * @param {number} zoom - The zoom level
 * @returns {number} - Returns the adjusted timeline duration (in seconds)
 */
export function getTimelineDuration(projectDuration, windowWidth, zoom) {
  const windowWidthInSeconds = pixelsToSeconds(windowWidth, zoom);
  const interval = getInterval(zoom);
  const numIntervals = Math.ceil(
    (Math.max(projectDuration, windowWidthInSeconds) + TIMELINE_ENDZONE) /
      interval
  );
  return numIntervals * interval;
}

/**
 * Converts a pixel length to a duration based on zoom level
 *
 * @param {number} [pixels=0] - The length of pixels
 * @param {number} zoom - The zoom level
 * @returns {number} - Returns the duration (in seconds)
 */
export function pixelsToSeconds(pixels = 0, zoom) {
  const zoomFactor = getZoomFactor(zoom);
  return pixels / zoomFactor;
}

/**
 * Converts a duration to a pixel length based on zoom level
 *
 * @param {number} [seconds=0] - The duration
 * @param {number} zoom - The zoom level
 * @returns {number} - Returns the length (in pixels)
 */
export function secondsToPixels(seconds = 0, zoom) {
  const zoomFactor = getZoomFactor(zoom);
  return seconds * zoomFactor;
}
