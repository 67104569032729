import storage from 'redux-persist/lib/storage';

/**
 * Configuration object for redux persist
 */
export const REDUX_PERSIST_CONFIG = {
  key: 'root',
  whitelist: ['editor'],
  storage,
};
