import { levels } from 'loglevel';
import { useEffect } from 'react';

import { IS_PROD } from '../constants/Constants';
import analyticsErrorTransport from './analyticsErrorTransport';
import logger from './logger';
import sentryTransport from './sentryTransport';

if (IS_PROD) logger.addTransport(analyticsErrorTransport, levels.ERROR);

logger.addTransport(sentryTransport, levels.ERROR);

const defaultLevel = IS_PROD ? levels.SILENT : levels.WARN;

export default function LoggerLoader() {
  // Flag is deleted from FFS but maybe this is worth keeping anyway
  const isDebug = false;
  // useFlag(flags.DEBUG); on a new line to keep prettier happy

  useEffect(() => {
    logger.consoleLevel = isDebug ? levels.DEBUG : defaultLevel;
  }, [isDebug]);

  return null;
}
