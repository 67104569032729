import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes pulse': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 0,
    },
  },
  pulser: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 'inherit',
    backgroundColor: theme.palette.common.white,
    animation: `$pulse 2.5s infinite alternate ease-in-out`,
  },
}));

export default function Pulser() {
  const classes = useStyles();
  return <div className={classes.pulser} />;
}
