import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUserId, selectVisitorId } from '../selectors/user';
import TelemetryClient from './TelemetryClient';

export default function TelemetryLoader() {
  const userId = useSelector(selectUserId);
  const visitorCookieId = useSelector(selectVisitorId);
  useEffect(() => {
    TelemetryClient.initTelemetry({ userId, visitorCookieId });
  }, [userId, visitorCookieId]);
  return null;
}
