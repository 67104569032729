import { Origin } from '@videoblocks/events-ts/lib/storyblocks/common/OriginEnum';
import Client, { Telemetry } from '@videoblocks/kafka-rest-client';
import log from 'loglevel';

import { KAFKA_EVENTS_ENABLED } from '../constants/Constants';
import { KAFKA_EVENTS_URL } from '../constants/Urls';

export default class TelemetryClient {
  static _instance = null;
  static _timers = {};
  static _is_logging_enabled = false;

  static getInstance() {
    if (TelemetryClient._instance === null) {
      TelemetryClient._instance = new Client(
        KAFKA_EVENTS_URL,
        Origin.TEMPLATE_EDITOR,
        false
      );
    }
    return this._instance;
  }

  static initTelemetry(user = {}) {
    Telemetry.init(TelemetryClient.getInstance(), user);
    if (!KAFKA_EVENTS_ENABLED) {
      Telemetry.mute();
    }
  }

  static startTimer(timerName) {
    if (KAFKA_EVENTS_ENABLED) {
      // Start the timer and store the callback for stoppage
      const callback = Telemetry.timer(timerName);
      TelemetryClient._timers[timerName] = callback;
    }
  }

  static async stopTimer(timerName) {
    if (KAFKA_EVENTS_ENABLED && TelemetryClient._timers[timerName]) {
      // Try the callback, then clear it
      let runtime = null;
      try {
        runtime = await TelemetryClient._timers[timerName]();
        delete TelemetryClient._timers[timerName];
      } catch (e) {
        log.warn(`Unable to stop a timer: ${timerName}`);
      }
      return runtime;
    }
    return null;
  }

  static setIsLoggingEnabled(value) {
    this._is_logging_enabled = value;
  }

  static getOrigin() {
    return TelemetryClient.getInstance().getOrigin();
  }

  /**
   * Allow this function to throw an error if it fails. This is necessary for
   * handling in analyticsErrorTransport and avoiding an infinite loop.
   */
  static async sendEvent(event) {
    if (this._is_logging_enabled) {
      const { tag, value, ...rest } = event;
      const tagStyle = 'color: #FF0189';
      if (!tag && !value && !!rest.meta) {
        console.log('%c[page-view]', tagStyle, rest);
      } else {
        console.log(`%c[${tag}]`, tagStyle, value);
      }
    }

    if (!event) {
      log.warn('Unable to send analytics event: No event provided');
      return null;
    }

    if (!KAFKA_EVENTS_ENABLED) {
      // This is ok in lower environments
      log.info('Unable to send analytics event: Kafka is not enabled', {
        event,
      });
      return null;
    }

    return await TelemetryClient.getInstance().produce(event);
  }
}
