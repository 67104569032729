import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { LayerTypes, MediaTypes } from '@videoblocks/jelly-renderer';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';

import { SOURCE_TYPES } from '../../constants/Constants';
import * as MediaActions from '../../constants/MediaActions';
import {
  TRIM_ADD_CLIP,
  TRIM_DIALOG as TRIM_DIALOG_TEST_ID,
} from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { TRIM_DIALOG } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import useMedia from '../../hooks/useMedia';
import useRenderer from '../../hooks/useRenderer';
import useUploads from '../../hooks/useUploads';
import {
  itemAdded,
  itemMoved,
  selectAllLayersWithItems,
} from '../../slices/storyboardSlice';
import formatTimestamp from '../../utils/formatTimestamp';
import { checkMediaItem } from '../../utils/mediaUtils';
import {
  getInsertLayerIndex,
  mapMediaToLayer,
} from '../../utils/storyboardUtils';
import PaddedAspectBox from '../PaddedAspectBox';
import PlayPauseButton from '../PlayPauseButton';
import TrimBar from '../TrimBar';

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  timestamp: {
    flex: '1 1',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: theme.spacing(12),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontFamily: 'sans-serif',
    textAlign: 'center',
  },
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  buttonContainer: {
    marginLeft: 'auto',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function TrimDialog(props) {
  const {
    action = MediaActions.ADD,
    media = {},
    onClose,
    open,
    projectId,
    templateId,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const layersWithItems = useSelector(selectAllLayersWithItems);
  const { timestamp } = useRenderer();
  const { associateUpload } = useUploads();
  const activeItem = useActiveItem();

  const { isAudio, isVideo } = checkMediaItem(media);

  const [mediaRef, mediaState, mediaControls] = useMedia(media.source, isVideo);
  const {
    aspectRatio,
    currentTime,
    duration: maxDuration,
    loaded,
    paused,
  } = mediaState;
  const { pause, play, seek } = mediaControls;

  const [selectedClip, setSelectedClip] = useState({
    aspectRatio: media.aspectRatio,
    duration: media.duration || 1,
    maxDuration: media.maxDuration,
    trimStart: media.trimStart,
    volume: media.volume,
    includesAudio: media.includesAudio,
  });

  const initSelectedClip = () => {
    // update aspectRatio and maxDuration to match actual content
    // duration from the API is a rounded value, we want the actual value
    const duration = Math.min(
      action === MediaActions.ADD ? maxDuration : selectedClip.duration,
      maxDuration
    );
    const trimStart = Math.min(selectedClip.trimStart, maxDuration - duration);
    seek(trimStart);
    setSelectedClip({
      ...selectedClip,
      aspectRatio,
      duration,
      maxDuration,
      trimStart,
    });
  };

  useEffect(() => {
    if (loaded) {
      initSelectedClip();
    }
  }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  // media preview repeats when it reaches the end of the selected portion
  useEffect(() => {
    if (paused) {
      return;
    }
    const trimEnd = selectedClip.trimStart + selectedClip.duration;
    if (currentTime >= trimEnd) {
      seek(selectedClip.trimStart);
    }
  }, [
    currentTime,
    paused,
    seek,
    selectedClip.duration,
    selectedClip.trimStart,
  ]);

  const handlePlayPause = useCallback(() => {
    if (!loaded) return;

    if (paused) {
      play();
    } else {
      pause();
    }
  }, [loaded, paused]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTrimStart = () => {
    pause();
    seek(selectedClip.trimStart);
  };

  const handleTrim = (trimStart, duration, action) => {
    setSelectedClip({ ...selectedClip, trimStart, duration });

    if (action === 'trim-right') {
      seek(trimStart + duration);
    } else {
      seek(trimStart);
    }
  };

  const handleConfirm = useCallback(
    async (event) => {
      event.preventDefault();
      const item = {
        ...media,
        aspectRatio: selectedClip.aspectRatio,
        duration: selectedClip.duration,
        maxDuration: selectedClip.maxDuration,
        trimStart: selectedClip.trimStart,
        volume: selectedClip.volume,
        includesAudio: selectedClip.includesAudio,
      };

      if (isVideo && !item.includesAudio) {
        item.volume = 0;
      }

      if (action === MediaActions.ADD) {
        if (isAudio) {
          const insertIndex = layersWithItems.length;
          dispatch(
            itemAdded({ ...item, layerId: nanoid(), layerIndex: insertIndex })
          );
          trackEvent(TRIM_DIALOG.ITEM_ADD, {
            ...item,
            toLayerIndex: insertIndex,
            toLayerType: LayerTypes.AUDIO,
            newLayerCreated: true,
          });
        } else {
          const layerIndex = getInsertLayerIndex(layersWithItems, {
            ...item,
            startTime: timestamp,
          });
          const toLayerIndex = Math.max(layerIndex, 0);

          dispatch(
            itemAdded({
              ...item,
              layerId: layersWithItems[layerIndex]?.id || nanoid(),
              layerIndex: toLayerIndex,
            })
          );
          trackEvent(TRIM_DIALOG.ITEM_ADD, {
            ...item,
            toLayerIndex,
            toLayerType: mapMediaToLayer(item.mediaType),
            newLayerCreated: layerIndex < 0,
          });
        }

        if (item.itemSourceType === SOURCE_TYPES.UPLOAD) {
          associateUpload({
            uploadId: item.itemSourceId,
            templateUid: templateId,
            projectUid: projectId,
          });
        }
      }
      if (action === MediaActions.UPDATE) {
        dispatch(itemMoved({ id: item.id, changes: item }));
        trackEvent(TRIM_DIALOG.ITEM_EDIT, item);
      }

      onClose();
    },
    [
      action,
      associateUpload,
      dispatch,
      isAudio,
      isVideo,
      layersWithItems,
      media,
      onClose,
      projectId,
      selectedClip.aspectRatio,
      selectedClip.duration,
      selectedClip.includesAudio,
      selectedClip.maxDuration,
      selectedClip.trimStart,
      selectedClip.volume,
      timestamp,
      templateId,
    ]
  );

  const toggleMute = () => {
    setSelectedClip({ ...selectedClip, volume: selectedClip.volume ? 0 : 1 });
  };

  const handleClose = () => {
    trackEvent(TRIM_DIALOG.CLOSE, activeItem);
    onClose();
  };

  useHotkeys('space', handlePlayPause, [handlePlayPause]);

  useHotkeys('enter', handleConfirm, [handleConfirm]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      disableRestoreFocus
      data-testid={TRIM_DIALOG_TEST_ID}
    >
      <Box padding={4}>
        {isVideo ? (
          <PaddedAspectBox aspectRatio={16 / 9}>
            <video
              className={classes.video}
              ref={mediaRef}
              muted={!selectedClip.volume}
              // prevents right-click save
              onContextMenu={(event) => event.preventDefault()}
            />
          </PaddedAspectBox>
        ) : (
          <audio ref={mediaRef} />
        )}
        <Box display="flex" alignItems="center" marginTop={2} marginBottom={1}>
          <PlayPauseButton
            isLoading={!loaded}
            isPlaying={!paused}
            size="lg"
            onClick={handlePlayPause}
          />
          <div className={classes.timestamp}>
            {`${formatTimestamp(currentTime)} /
            ${formatTimestamp(selectedClip.maxDuration)}`}
          </div>
          <TrimBar
            currentTime={currentTime}
            isVideo={isVideo}
            onTrim={handleTrim}
            onTrimStart={handleTrimStart}
            selectedClip={selectedClip}
            source={media.resolutions?._180p || media.source}
          />
        </Box>
        <div className={classes.description}>
          Slide the two handles to trim.
        </div>
        <div className={classes.actions}>
          {isVideo && selectedClip.includesAudio && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!selectedClip.volume}
                  onChange={toggleMute}
                />
              }
              label="Mute Audio"
            />
          )}
          <div className={classes.buttonContainer}>
            <Button onClick={handleClose}>Back</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConfirm}
              startIcon={action === MediaActions.ADD ? <AddCircleIcon /> : null}
              data-testid={TRIM_ADD_CLIP}
              disabled={!loaded}
            >
              {action === MediaActions.ADD ? 'Add Clip' : 'Trim Clip'}
            </Button>
          </div>
        </div>
      </Box>
    </Dialog>
  );
}

TrimDialog.propTypes = {
  action: PropTypes.oneOf([MediaActions.ADD, MediaActions.UPDATE]),
  media: PropTypes.shape({
    aspectRatio: PropTypes.number,
    duration: PropTypes.number,
    maxDuration: PropTypes.number,
    mediaType: PropTypes.oneOf([MediaTypes.AUDIO, MediaTypes.VIDEO]),
    source: PropTypes.string,
    trimStart: PropTypes.number,
  }),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  projectId: PropTypes.string,
  templateId: PropTypes.string,
};
