import Bowser from 'bowser';

/**
 * When making changes to this file, also update README.md
 * for visibility to CS and other stakeholders.
 */
export const evaluateUserAgent = (uaString, touchEnabled) => {
  const uaParser = Bowser.getParser(uaString);

  const isBrowserSupported = uaParser.satisfies({
    chrome: '>=65',
    edge: '>=79',
    firefox: '>=59',
    opera: '>=51',
    safari: '>=11.1',
  });

  /*
   * iOS with Request Desktop Site set to on (default for iPads on iOS 13+)
   * returns macOS as the user agent. If OS name is contains mac, check whether
   * touch is enabled to see if it's a mobile device. Taken from top answer here:
   * https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
   */
  const isIosRequestingDesktop =
    uaParser.getOSName()?.includes('mac') && touchEnabled;
  const isMobile = !uaParser.is('desktop') || isIosRequestingDesktop;
  const isChromebook = uaParser.is('Chrome OS');
  const isPlatformSupported = !isMobile || isChromebook;

  return {
    browserName: uaParser.getBrowserName(),
    browserVersion: uaParser.getBrowserVersion(),
    isBrowserSupported,
    isChromebook,
    isIosRequestingDesktop,
    isMobile,
    isPlatformSupported,
    osName: uaParser.getOSName(),
    osVersion: uaParser.getOSVersion(),
    touchEnabled,
  };
};

const result = evaluateUserAgent(
  window.navigator.userAgent,
  'ontouchend' in document
);
export const IS_BROWSER_SUPPORTED = result.isBrowserSupported;
export const IS_SUPPORTED_PLATFORM = result.isPlatformSupported;
