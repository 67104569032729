import { getStoryboardDuration } from '@videoblocks/jelly-renderer';

import {
  storeProjectCompleted,
  storeProjectFailed,
  storeProjectRequested,
} from '../actions/projects';
import CustomTemplatesAPI from '../api/CustomTemplatesAPI';
import ProjectsAPI from '../api/ProjectsAPI';
import { AUTO_SAVE_ACTIONS } from '../constants/AutoSaveActions';
import { trackEvent } from '../events/sendEvents';
import { EDITOR } from '../events/tags';
import { getLayersProperties } from '../utils/storyboardUtils';

let autoSaveTimeout;
const autoSaveDelayInMilliseconds = 5000;

/**
 * Redux Middleware function that handles back off of auto-save functionality by
 * throttling edit actions.
 *
 * @param store
 * @returns {function(*): Function}
 */
export default (store) => (next) => (action) => {
  next(action);

  if (AUTO_SAVE_ACTIONS.includes(action.type)) {
    const storyboard = store.getState().storyboard.present;
    const { ratio, layers = {}, items = {} } = storyboard;
    const rightNow = new Date();
    const lastSave = store.getState().workspace.lastAutoSavedAt;
    const millisecondsPassed = rightNow - lastSave;
    const autoSavePending = store.getState().workspace.autoSavePending;
    // TODO: Find a way to remove this last usage of editor.isEditingTemplate
    const isEditingTemplate = store.getState().editor.isEditingTemplate;
    const brandUid = store.getState().workspace.brandUid;
    const uid = store.getState().workspace.selectedProjectId;

    const save = async () => {
      store.dispatch(storeProjectRequested());

      let result;
      try {
        result = uid
          ? isEditingTemplate
            ? await new CustomTemplatesAPI().putTemplate({ uid, storyboard })
            : await new ProjectsAPI().putProject({ uid, storyboard })
          : isEditingTemplate
          ? await new CustomTemplatesAPI().postTemplate({ storyboard })
          : await new ProjectsAPI().postProject({ storyboard });
        store.dispatch(storeProjectCompleted(uid || result));
      } catch (error) {
        store.dispatch(storeProjectFailed(error));
      }

      trackEvent(EDITOR.AUTOSAVE, {
        templateId: isEditingTemplate ? uid : undefined,
        projectId: isEditingTemplate ? undefined : uid,
        brandUid,
        ratio,
        projectDuration: getStoryboardDuration(items.entities),
        ...getLayersProperties(Object.values(layers.entities)),
      });

      return result;
    };

    if (millisecondsPassed >= autoSaveDelayInMilliseconds && !autoSavePending) {
      // if the auto save delay has passed and there are no saves pending, go ahead and save
      save();
    } else {
      // otherwise buffer the save by delaying by however much time there is left in the delay or the full delay
      let timeout = autoSaveDelayInMilliseconds - millisecondsPassed;
      timeout = timeout >= 0 ? timeout : autoSaveDelayInMilliseconds;
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => {
        save();
      }, timeout);
    }
  }
};
