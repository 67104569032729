import { Button, IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PencilIcon from '@videoblocks/react-icons/Pencil';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SB_MEMBER_FOLDERS_URL } from '../../constants/Urls';
import {
  changeKeywords,
  selectKeywords,
  setFolderId,
} from '../../features/assetDrawer/assetDrawerSlice';
import MediaTypeTabs from './MediaTypeTabs';
import SearchInput from './SearchInput';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2, 1),
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  folderHeader: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(3.5),
    lineHeight: 28 / 16,
    '& > span': {
      cursor: 'default',
    },
  },
  name: {
    maxWidth: theme.spacing(32),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  itemCount: {
    flexShrink: 0,
    marginLeft: 'auto',
    color: theme.palette.grey[600],
  },
}));

function formatItemCount(count = 0) {
  return `${count} Item${count === 1 ? '' : 's'}`;
}

export default function FolderHeader(props) {
  const {
    folderId,
    folderItemCount,
    folderName,
    mediaType = MediaTypes.VIDEO,
    onMediaTypeChange = () => {},
  } = props;

  const keywords = useSelector(selectKeywords);
  const classes = useStyles();
  const dispatch = useDispatch();

  const folderUrl = folderId && `${SB_MEMBER_FOLDERS_URL}/${folderId}`;

  const handleMediaTypeTabsChange = (event, value) => {
    onMediaTypeChange(value);
  };

  const handleSearchSubmit = (event, value) => {
    dispatch(changeKeywords(value));
  };

  const handleBackButtonClick = () => {
    dispatch(setFolderId());
  };

  if (folderId) {
    return (
      <div className={classes.container}>
        <div>
          <Button
            size="small"
            startIcon={<ChevronLeftIcon />}
            onClick={handleBackButtonClick}
          >
            Back to Folders
          </Button>
        </div>
        <div className={classes.folderHeader}>
          {folderName && <span className={classes.name}>{folderName}</span>}
          {folderUrl && (
            <IconButton
              className={classes.iconButton}
              href={folderUrl}
              target="_blank"
            >
              <SvgIcon component={PencilIcon} fontSize="inherit" />
            </IconButton>
          )}
          {folderItemCount >= 0 && (
            <span className={classes.itemCount}>
              {formatItemCount(folderItemCount)}
            </span>
          )}
        </div>
        <MediaTypeTabs onChange={handleMediaTypeTabsChange} value={mediaType} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <SearchInput onSubmit={handleSearchSubmit} value={keywords} />
    </div>
  );
}

FolderHeader.propTypes = {
  folderId: PropTypes.string,
  folderItemCount: PropTypes.number,
  folderName: PropTypes.string,
  mediaType: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]),
  onMediaTypeChange: PropTypes.func,
};
