import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stringify, parse } from 'query-string';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 2),
    color: 'inherit',
    fontSize: 'inherit',
  },
}));

export default function NavLink(props) {
  const { path, title } = props;

  const { search, pathname: currentPathname } = useLocation();
  // remove any editor-specific queries
  const { isEditing, firstTimeUser, ...rest } = parse(search);
  const classes = useStyles();

  // Don't show this NavLink if you're already on the route it takes you to
  if (currentPathname === path) {
    return null;
  }

  return (
    <Button
      className={classes.button}
      component={RouterLink}
      to={{ pathname: path, search: stringify(rest) }}
      {...props}
    >
      {title}
    </Button>
  );
}
