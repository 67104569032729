import { Box, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { EXPORT_DRAWER_WIDTH } from '../../constants/Constants';
import { EXPORT_DRAWER } from '../../events/locations';
import CrossPostCard from './CrossPostCard';
import ExportAlerts from './ExportAlerts';
import ExportCard from './ExportCard';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: EXPORT_DRAWER_WIDTH + 1,
    padding: theme.spacing(5, 4, 4),
  },
  drawerContents: {
    display: 'flex',
    gap: theme.spacing(4),
    flexDirection: 'column',
  },
}));

export default function ExportDrawer(props) {
  const { onClose, open } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.drawerContents}>
        <Typography variant="h3">Export Preferences</Typography>
        <ExportAlerts />
        <ExportCard closeExportDrawer={onClose} />
        <CrossPostCard eventLocation={EXPORT_DRAWER} />
      </Box>
    </Drawer>
  );
}

ExportDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ExportDrawer.defaultProps = {
  onClose: () => {},
  open: false,
};
