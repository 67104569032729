import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';

import emptyStateImage from '../assets/images/brands-empty-state.png';
import BrandsEmptyState from '../components/BrandsEmptyState';
import CreateNewBrandButton from '../components/CreateNewBrandButton';
import LoaderContainer from '../components/LoaderContainer';
import Toast from '../components/Toast';
import ResultsGrid from '../components/assetDrawer/ResultsGrid';
import BrandCard from '../components/cards/BrandCard';
import { NEW_BRAND_UID } from '../constants/StylesConstants';
import { BRANDS_PATH } from '../constants/Urls';
import { trackEvent } from '../events/sendEvents';
import { BRANDS } from '../events/tags';
import { openBrandDeleteDialog } from '../features/ui/uiSlice';
import useBrands from '../hooks/useBrands';
import useQuery from '../hooks/useQuery';
import useToast from '../hooks/useToast';
import { selectIsOrgAdmin } from '../selectors/user';

const useStyles = makeStyles((theme) => ({
  brandsList: {
    marginTop: theme.spacing(3),
    width: '100%',
    gap: theme.spacing(4),
  },
  card: {
    minWidth: theme.spacing(28),
  },
  emptyGrid: {
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      minWidth: theme.spacing(52),
    },
  },
  emptyImage: {
    maxWidth: 664,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(67),
      marginBottom: theme.spacing(8),
      marginLeft: 0,
    },
  },
  pageHeader: {
    margin: theme.spacing(3, 0, 2),
  },
  subHeader: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtitle: {
    lineHeight: theme.typography.pxToRem(28),
  },
}));

export default function BrandsContainer() {
  useTitle('Maker | Brands');
  const history = useHistory();
  const { search, state: locationState } = useLocation();
  const { flags } = useQuery();
  const dispatch = useDispatch();
  const { open, message, openToast, closeToast, severity } = useToast();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const classes = useStyles();

  const { brands, isLoading } = useBrands();
  const hasBrands = brands && brands.length > 0;

  if (locationState?.toastMessage && !open) {
    openToast(locationState.toastMessage);
    history.replace({
      search,
      state: { toastMessage: null },
    });
  }

  const navigateToBrand = (brandUid, isNewBrand = false, isEditing = false) => {
    history.push({
      pathname: `${BRANDS_PATH}/${brandUid}${
        isNewBrand ? `/${NEW_BRAND_UID}` : ''
      }`,
      search: stringify(isEditing ? { flags, isEditing: true } : { flags }),
    });
  };

  const handleSelectBrand = (brandUid, edit = false) => {
    trackEvent(BRANDS.DETAIL, { isOrgAdmin, brandUid });
    navigateToBrand(brandUid, false, edit);
  };

  const handleDeleteBrand = (brandUid, brandName) => {
    dispatch(
      openBrandDeleteDialog({
        brandUid,
        onSuccess: () => {
          openToast(`"${brandName}" Deleted`);
        },
      })
    );
  };

  const buildBrandCard = (brand) => {
    return (
      <BrandCard
        key={brand.uid}
        brand={brand}
        className={classes.card}
        isDefault={!!brand.is_default}
        isOrgAdmin={isOrgAdmin}
        onClick={() => handleSelectBrand(brand.uid)}
        onViewDetails={() => handleSelectBrand(brand.uid)}
        onEdit={() => handleSelectBrand(brand.uid, true)}
        onDelete={() => handleDeleteBrand(brand.uid, brand.detail.name)}
      />
    );
  };

  return (
    <Container>
      <Toast open={open} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <div className={classes.pageHeader}>
        <Typography variant="h2" element="h1">
          Brands
        </Typography>
        <div className={classes.subHeader}>
          <span className={classes.subtitle}>
            Save font styles, colors, and logos to make video delegation easy.
          </span>
          {isOrgAdmin && hasBrands && (
            <CreateNewBrandButton
              navigateToBrand={navigateToBrand}
              width="narrow"
            />
          )}
        </div>
      </div>
      {isLoading && <LoaderContainer />}
      {!isLoading && hasBrands && (
        <ResultsGrid className={classes.brandsList} columns={3}>
          {brands.map(buildBrandCard)}
        </ResultsGrid>
      )}
      {!isLoading && !hasBrands && (
        <div className={classes.emptyGrid}>
          <BrandsEmptyState navigateToBrand={navigateToBrand} />
          <div className={classes.emptyImage}>
            <img alt="Example brand kit elements" src={emptyStateImage} />
          </div>
        </div>
      )}
    </Container>
  );
}
