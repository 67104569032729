import { Box, Button, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { ASPECT_RATIOS } from '../../constants/AspectRatios';
import { formatCardRatioSocialIconMap } from '../../constants/SocialMedia';
import RatioBox from './RatioBox';

const useStyles = makeStyles((theme) => ({
  formatCard: {
    padding: theme.spacing(2),
    minWidth: theme.spacing(28),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 2px 8px rgba(18, 18, 19, 0.2)',
    userSelect: 'none',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),

    '&:hover': {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
      transform: 'scale(1.1)',
    },

    '&:hover $projectButton': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
  socialIconContainer: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(0.5),
    },
  },
  socialIcon: {
    width: theme.spacing(3),
    color: theme.palette.grey[600],
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  projectButton: {}, // noop: used by parent `formatCard` to style child
  templateButton: {
    color: theme.palette.blue[700],
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.blue[600],
    },
  },
}));

export default function FormatCard(props) {
  const {
    isEnterprise = false,
    onProjectClick = () => {},
    onTemplateClick = () => {},
    ratio,
  } = props;
  const classes = useStyles();

  const socialIcons = formatCardRatioSocialIconMap[ratio] || [];

  return (
    <Card className={classes.formatCard} data-testid={`format-card-${ratio}`}>
      <RatioBox ratio={ratio} />
      <Box mt={2} color="grey.900" lineHeight={28 / 16}>
        This format is ideal for:
      </Box>
      <div className={classes.socialIconContainer}>
        {socialIcons.map((SocialIcon, key) => (
          <SocialIcon className={classes.socialIcon} key={key} />
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.projectButton}
          variant="outlined"
          fullWidth
          onClick={(event) => onProjectClick(event, ratio)}
        >
          Create a Project
        </Button>
        {isEnterprise && (
          <Button
            className={classes.templateButton}
            variant="text"
            fullWidth
            color="secondary"
            onClick={(event) => onTemplateClick(event, ratio)}
          >
            or Create a Template
          </Button>
        )}
      </div>
    </Card>
  );
}

FormatCard.propTypes = {
  isEnterprise: PropTypes.bool,
  onProjectClick: PropTypes.func,
  onTemplateClick: PropTypes.func,
  ratio: PropTypes.oneOf(ASPECT_RATIOS),
};
