import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '&:not(:first-child)': {
      marginLeft: -2,
    },
  },
  buttonGroupButton: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      borderWidth: 2,
    },
  },
  activeButton: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default function ResolutionToggle(props) {
  const { onChange, value, ...rest } = props;
  const classes = useStyles();

  return (
    <ButtonGroup
      classes={{ grouped: classes.buttonGroup }}
      color="secondary"
      fullWidth
      {...rest}
    >
      {[480, 720, 1080].map((resolution) => (
        <Button
          className={classes.buttonGroupButton}
          classes={{ contained: classes.activeButton }}
          variant={resolution === value ? 'contained' : 'outlined'}
          onClick={() => onChange(resolution)}
          key={resolution}
        >
          {resolution}p
        </Button>
      ))}
    </ButtonGroup>
  );
}

ResolutionToggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

ResolutionToggle.defaultProps = {
  onChange: () => {},
  value: 480,
};
