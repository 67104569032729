import { Box, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';

import { DND_ZONES } from '../../constants/Constants';
import {
  DEFAULT_DISPLAY_FONT_SIZE,
  DROP_SHADOW,
  TEXT_VARIANT,
} from '../../constants/FontStyles';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import { selectBrandUid } from '../../features/assetDrawer/assetDrawerSlice';
import FontSizePicker from '../../features/propertiesPanel/controls/FontSizePicker';
import ShadowPicker from '../../features/propertiesPanel/controls/ShadowPicker';
import useRenderer from '../../hooks/useRenderer';
import createRatioBasedTextObject from '../../models/createRatioBasedTextObject';
import {
  itemAdded,
  selectAllLayersWithItems,
  selectRatio,
} from '../../slices/storyboardSlice';
import {
  getInsertLayerIndex,
  mapMediaToLayer,
} from '../../utils/storyboardUtils';
import AddIconButton from '../AddIconButton';
import Draggable from '../Draggable';
import FontSelectGrouped from '../FontSelectGrouped';
import PositionWrapper, { Positions } from '../PositionWrapper';
import DragPreview from './DragPreview';

const useStyles = (currentStyles, editMode, isDrawer, textVariant) =>
  makeStyles((theme) => ({
    customizeText: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      color: theme.palette.grey[800],
      textAlign: 'end',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',
    },
    customizeControls: {
      paddingBottom: theme.spacing(4),
    },
    draggableWrapper: {
      width: '100%',
      cursor: !editMode && isDrawer ? 'grab' : 'default',
    },
    previewBox: {
      background: `linear-gradient(257deg, ${theme.palette.grey[200]} 3%, ${theme.palette.grey[300]} 98%)`,
      borderRadius: theme.shape.borderRadius,
      minWidth: theme.spacing(8),
      padding: theme.spacing(1, 2),
      textTransform: 'none',
      overflow: 'clip',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }),
      width: '100%',
    },
    styleLabel: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      color: theme.palette.grey[800],
    },
    textButtonContainer: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      position: 'relative',
      '&:not(:hover) .add-button': {
        display: 'none',
      },
    },
    textPreview: {
      color:
        tinycolor(currentStyles?.primaryTextColor).toHexString() ??
        theme.palette.common.black,
      fontWeight: currentStyles?.font?.weight,
      fontSize: currentStyles?.font?.size,
      fontFamily: currentStyles?.font?.family,
      textShadow: DROP_SHADOW[currentStyles?.font?.dropShadow],
      maxWidth: '70%',
    },
    textPreviewWrapper: {
      width: '100%',
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
  }));

export default function FontPreview({
  isDrawer = false,
  previewText = 'Header',
  textVariant = TEXT_VARIANT.HEADER,
  styles = {},
  editMode = false,
  onStylesUpdate = () => {},
}) {
  const dispatch = useDispatch();

  const ratio = useSelector(selectRatio);
  const brandUid = useSelector(selectBrandUid);
  const classes = useStyles(styles, editMode, isDrawer, textVariant)();
  const layersWithItems = useSelector(selectAllLayersWithItems);
  const { timestamp } = useRenderer();
  const [showEditOptions, setShowEditOptions] = useState(false);

  if (!editMode && showEditOptions) {
    setShowEditOptions(false);
  }

  const toggleShowEditOptions = () => {
    if (editMode) {
      setShowEditOptions(!showEditOptions);
    }
  };

  const addTextItem = (options = {}) => {
    const item = createRatioBasedTextObject(ratio, {
      textVariant,
      ...styles,
      ...options,
    });

    const layerIndex = getInsertLayerIndex(layersWithItems, {
      ...item,
      startTime: timestamp,
    });
    const toLayerIndex = Math.max(layerIndex, 0);

    dispatch(
      itemAdded({
        ...item,
        layerId: layersWithItems[layerIndex]?.id || nanoid(),
        layerIndex: toLayerIndex,
      })
    );
    trackEvent(SIDE_DRAWER.ITEM_ADD, {
      ...item,
      toLayerIndex,
      toLayerType: mapMediaToLayer(item.mediaType),
      newLayerCreated: layerIndex < 0,
    });
  };

  const handleFontChange = (newFont) => {
    onStylesUpdate(Object.assign({}, styles.font, newFont));
  };

  const handleFontSizeChange = (event) => {
    onStylesUpdate(
      Object.assign({}, styles.font, { size: event.target.value })
    );
  };

  const handleDropShadowChange = (event) => {
    onStylesUpdate(
      Object.assign({}, styles.font, { dropShadow: event.target.value })
    );
  };

  return (
    <div className={classes.textButtonContainer}>
      <Draggable
        disabled={editMode}
        className={classes.draggableWrapper}
        data={{
          item: {
            ...styles,
            textVariant: textVariant,
            fontSize: styles.font?.size,
            mediaType: MediaTypes.TEXT,
          },
          overlayElement: () => (
            <DragPreview>
              <Box className={classes.previewBox}>
                <span className={classes.textPreview}>{previewText}</span>
              </Box>
            </DragPreview>
          ),
          zone: DND_ZONES.DRAWER,
        }}
        id={`add-text-${textVariant}`}
      >
        <Box className={classes.previewBox}>
          <div
            className={classes.textPreviewWrapper}
            onClick={toggleShowEditOptions}
            data-testid={`font-preview-${textVariant}`}
          >
            <span className={classes.textPreview}>{previewText}</span>
            {editMode && (
              <span className={classes.customizeText}>
                {showEditOptions ? 'Hide' : 'Customize'}
              </span>
            )}
            {!editMode && (
              <span className={classes.customizeText}>
                <p>{styles.font?.name}</p>
                <p>
                  {styles?.font?.size}pt,{' '}
                  {tinycolor(styles?.primaryTextColor)
                    .toHexString()
                    .toUpperCase()}
                </p>
              </span>
            )}
          </div>
          {showEditOptions && (
            <Grid
              container
              item
              spacing={2}
              className={classes.customizeControls}
            >
              <Grid item xs={12}>
                <InputLabel
                  className={classes.styleLabel}
                  htmlFor="styles-font"
                  id="styles-font-label"
                >
                  Font
                </InputLabel>
                <FontSelectGrouped
                  value={{
                    ...styles.font,
                    size: DEFAULT_DISPLAY_FONT_SIZE,
                  }}
                  onChange={handleFontChange}
                  id="styles-font"
                  labelId="styles-font-label"
                  brandUid={brandUid}
                  isBrandsForm={true}
                  aria-labelledby="styles-font-label"
                  aria-describedby="styles-font-label"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  className={classes.styleLabel}
                  htmlFor="size"
                  id="styles-fontSize-label"
                >
                  Size
                </InputLabel>
                <FontSizePicker
                  onChange={handleFontSizeChange}
                  value={styles.font.size}
                  className={classes.styleLabel}
                  label=""
                  aria-labelledby="styles-fontSize-label"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  className={classes.styleLabel}
                  htmlFor="shadow"
                  id="styles-shadow-label"
                >
                  Shadow
                </InputLabel>
                <ShadowPicker
                  value={styles.font?.dropShadow}
                  onChange={handleDropShadowChange}
                  className={classes.styleLabel}
                  label=""
                  aria-labelledby="styles-shadow-label"
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Draggable>
      {!editMode && isDrawer && (
        <PositionWrapper position={Positions.RIGHT}>
          <AddIconButton
            aria-label={`Add ${textVariant} text`}
            onClick={() =>
              addTextItem({
                fontSize: styles.font.size,
              })
            }
          />
        </PositionWrapper>
      )}
    </div>
  );
}
