import { IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronUpIcon from '@videoblocks/react-icons/ChevronUp';
import FilterIcon from '@videoblocks/react-icons/Funnel';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  changeKeywords,
  changeMediaType,
  selectContentType,
  selectDrawer,
  selectFolderId,
  selectKeywords,
  selectMediaType,
  DRAWERS,
  selectFilters,
} from '../../features/assetDrawer/assetDrawerSlice';
import { checkMediaType } from '../../utils/mediaUtils';
import AudioSearchFilters from './AudioSearchFilters';
import ImageSearchFilters from './ImageSearchFilters';
import MediaTypeDisplay from './MediaTypeDisplay';
import MediaTypeTabs from './MediaTypeTabs';
import SearchInput from './SearchInput';
import VideoSearchFilters from './VideoSearchFilters';

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    position: 'relative',
    padding: theme.spacing(2, 2, 1),
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  search: {
    flex: 1,
  },
  mediaTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterButton: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[600],
    padding: theme.spacing(1.375),
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
  },
  filterButtonActive: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

export default function AssetDrawerHeader() {
  const dispatch = useDispatch();
  const contentType = useSelector(selectContentType);
  const drawer = useSelector(selectDrawer);
  const mediaType = useSelector(selectMediaType);
  const keywords = useSelector(selectKeywords);
  const folderId = useSelector(selectFolderId);
  const filters = useSelector(selectFilters);
  const classes = useStyles();
  const [editable, setEditable] = useState(false);

  const handleMediaTypeTabsChange = (event, value) => {
    dispatch(changeMediaType(value));
    setEditable(false);
    trackEvent(SIDE_DRAWER.MEDIA_TYPE_SELECT, { drawer, mediaType: value });
  };

  const handleSearchSubmit = (event, value) => {
    dispatch(changeKeywords(value));
    trackEvent(SIDE_DRAWER.SEARCH_TEXT, {
      text: value,
      ...filters,
      mediaType,
      contentType,
      drawer,
    });
  };

  const handleFilterButtonClick = () => {
    if (editable) {
      trackEvent(SIDE_DRAWER.FILTERS_HIDE, { drawer, mediaType });
    } else {
      trackEvent(SIDE_DRAWER.FILTERS_SHOW, { drawer, mediaType });
    }
    setEditable(!editable);
  };

  const shouldDisplaySearch =
    [DRAWERS.FOLDERS, DRAWERS.STOCK].includes(drawer) && !folderId;
  const shouldDisplayTabs = [
    DRAWERS.FOLDERS,
    DRAWERS.UPLOADS,
    DRAWERS.STOCK,
  ].includes(drawer);

  const isStockDrawer = drawer === DRAWERS.STOCK;
  const { isAudio, isImage, isVideo } = checkMediaType(mediaType);

  const shouldDisplayAudioFilter = isAudio && isStockDrawer;
  const shouldDisplayImageFilter = isImage && isStockDrawer;
  const shouldDisplayVideoFilter = isVideo && isStockDrawer;

  const shouldDisplayFilterButton = isStockDrawer;

  const shouldDisplayMediaType = drawer === DRAWERS.LOGOS;
  const placeholder =
    drawer === DRAWERS.STOCK ? 'Search our entire library of stock' : 'Search…';

  return (
    <div className={classes.drawerHeader}>
      {shouldDisplayTabs && (
        <MediaTypeTabs value={mediaType} onChange={handleMediaTypeTabsChange} />
      )}
      {shouldDisplayMediaType && (
        <div className={classes.mediaTypeContainer}>
          <MediaTypeDisplay value={mediaType} />
        </div>
      )}
      {shouldDisplaySearch && (
        <div className={classes.container}>
          <SearchInput
            className={classes.search}
            onSubmit={handleSearchSubmit}
            value={keywords}
            placeholder={placeholder}
          />
          {shouldDisplayFilterButton && (
            <IconButton
              className={clsx(classes.filterButton, {
                [classes.filterButtonActive]: editable,
              })}
              aria-label="filter"
              onClick={handleFilterButtonClick}
            >
              {editable ? (
                <SvgIcon component={ChevronUpIcon} fontSize="inherit" />
              ) : (
                <SvgIcon component={FilterIcon} fontSize="inherit" />
              )}
            </IconButton>
          )}
        </div>
      )}
      {shouldDisplayAudioFilter && <AudioSearchFilters editable={editable} />}
      {shouldDisplayVideoFilter && <VideoSearchFilters editable={editable} />}
      {shouldDisplayImageFilter && <ImageSearchFilters editable={editable} />}
    </div>
  );
}
