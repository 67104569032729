import { trackReduxEvent } from '../events/sendEvents';

/**
 * @deprecated
 * eventPersistor is a redux middleware to send a copy of redux actions as analytics events.
 *
 * To be retired in favor of explicitly sending analytics events from React components.
 */
export default (store) => (next) => (action) => {
  next(action);
  trackReduxEvent(action);
};
