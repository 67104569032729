export default function parseStoryboardResponse(data) {
  if (!data) {
    return undefined;
  }

  data = JSON.parse(data);

  // parse single item
  if (data?.storyboard) {
    data.storyboard = JSON.parse(data.storyboard);
  }
  // parse multiple items
  if (data?.data) {
    data.data = data.data.map((item) => ({
      ...item,
      storyboard: JSON.parse(item.storyboard),
    }));
  }

  return data;
}
