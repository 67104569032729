import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import SliderField from './SliderField';

const DEFAULT_VALUE = 0;
const FADE_STEP = 0.1;
const MAX_FADE_DURATION = 3;

const formatValue = (value = 0) => `${parseFloat(value).toFixed(1)}s`;

function FadeControls(props) {
  const { duration, fadeIn, fadeOut, onFadeInChange, onFadeOutChange } = props;
  const maxDuration = !!duration
    ? Math.min(Math.floor(duration), MAX_FADE_DURATION)
    : MAX_FADE_DURATION;

  return (
    <Box width="100%">
      <SliderField
        formatValue={formatValue}
        id="fade-in-slider"
        label="In"
        onChange={onFadeInChange}
        max={maxDuration}
        step={FADE_STEP}
        value={fadeIn ?? DEFAULT_VALUE}
      />

      <SliderField
        formatValue={formatValue}
        id="fade-out-slider"
        label="Out"
        onChange={onFadeOutChange}
        max={maxDuration}
        step={FADE_STEP}
        value={fadeOut ?? DEFAULT_VALUE}
      />
    </Box>
  );
}

FadeControls.propTypes = {
  duration: PropTypes.number,
  fadeIn: PropTypes.number,
  fadeOut: PropTypes.number,
  onFadeInChange: PropTypes.func,
  onFadeOutChange: PropTypes.func,
};

FadeControls.defaultProps = {
  fadeIn: 0,
  fadeOut: 0,
  onFadeInChange: () => {},
  onFadeOutChange: () => {},
};

export default FadeControls;
