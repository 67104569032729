import { migrate } from '@videoblocks/jelly-scripts';
import log from 'loglevel';
import useSWR from 'swr';

import CustomTemplatesAPI from '../api/CustomTemplatesAPI';
import { TEMPLATES_PATH } from '../constants/Urls';

const fetcher = async (url) => {
  try {
    const response = await new CustomTemplatesAPI().get(url);
    response.storyboard = migrate(response.storyboard);
    return response;
  } catch (error) {
    log.error(`Error loading template: ${error?.message}`, { error });
    return {};
  }
};

/**
 * @param {number} templateId
 * @returns {{template:Template}}
 */
export default function useTemplate(templateId) {
  const { data: template = {}, mutate } = useSWR(
    templateId ? `${TEMPLATES_PATH}/${templateId}` : null,
    fetcher
  );

  const editTemplate = async (template) => {
    const response = await new CustomTemplatesAPI().putTemplate(template);
    mutate();
    return response;
  };

  const setTemplateBrand = async (id, brandUid) => {
    const response = await new CustomTemplatesAPI().setTemplateBrand(
      id,
      brandUid
    );
    mutate();
    return response;
  };

  return { template, editTemplate, setTemplateBrand };
}
