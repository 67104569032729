import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { NAVBAR_HEIGHT } from '../constants/Constants';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    padding: theme.spacing(5),
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.75,
    objectFit: 'cover',
  },
  title: {
    fontSize: theme.typography.pxToRem(72),
    lineHeight: theme.typography.pxToRem(80),
    fontWeight: 600,
  },
  text: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(32),
  },
}));

function PageNotFound() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <video
        className={classes.video}
        poster="https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/N5i3Ndpjgil8aaruw/slow-motion-shot-of-a-confused-man-looking-for-someone-in-the-city-square_r4b38y1p__S0013.jpg"
        controlsList="nodownload"
        autoPlay
        loop
        muted
      >
        <source
          src="https://d2v9y0dukr6mq2.cloudfront.net/video/preview/GTYSdDW/slow-motion-shot-of-a-confused-man-looking-for-someone-in-the-city-square_r4b38y1p__PM.mp4"
          type="video/mp4"
        />
      </video>
      <Box position="relative" color="white">
        <h1 className={classes.title}>Page Not Found</h1>
        <p className={classes.text}>
          Oops, the page you&apos;re looking for does not exist.
        </p>
        <Button
          component={RouterLink}
          variant="contained"
          color="primary"
          startIcon={<ArrowBackRoundedIcon />}
          to="/"
        >
          Go to home page
        </Button>
      </Box>
    </div>
  );
}

export default memo(PageNotFound);
