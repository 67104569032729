import { makeStyles } from '@material-ui/core/styles';
import AnimationIcon from '@material-ui/icons/TollRounded';

const useStyles = makeStyles((theme) => ({
  animation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: theme.typography.pxToRem(24),
    borderRadius: theme.shape.borderRadius,
    background: `linear-gradient(
      45deg,
      ${theme.palette.yellow[500]} 0%,
      ${theme.palette.pink[500]} 100%)`,
    pointerEvents: 'none',
  },
}));

export default function TimelineItemAnimationOverlay({ minimized }) {
  const classes = useStyles();
  return (
    <div className={classes.animation}>
      {!minimized && <AnimationIcon fontSize="inherit" />}
    </div>
  );
}
