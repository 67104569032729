import { Button, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupsIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import { parse, stringify } from 'query-string';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { EDIT_PATH, EDIT_TEMPLATE_PATH } from '../../constants/Urls';
import { trackEvent } from '../../events/sendEvents';
import { TEMPLATE } from '../../events/tags';
import { openTemplateDeleteDialog } from '../../features/ui/uiSlice';
import useTemplates from '../../hooks/useTemplates';
import { selectIsOrgAdmin } from '../../selectors/user';
import Alert from '../Alert';
import TemplatesEmptyState from '../TemplatesEmptyState';
import TemplateCard from '../cards/TemplateCard';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  button: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflowY: 'scroll',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  grid: {
    gap: theme.spacing(2.5),
    marginTop: theme.spacing(3),
  },
  header: {
    marginTop: theme.spacing(2),
  },
  inactiveButton: {
    color: theme.palette.grey[600],
  },
  tab: {
    marginRight: theme.spacing(1.5),
  },
  tabs: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
  },
}));

export default function TemplatesDrawer() {
  const classes = useStyles();
  const [showMyTemplates, setShowMyTemplates] = useState(true);
  const { isLoading, myTemplates, orgTemplates, duplicateTemplate } =
    useTemplates();
  const history = useHistory();
  const { search } = useLocation();
  const { templateId } = useParams();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const dispatch = useDispatch();

  const templates = showMyTemplates ? myTemplates : orgTemplates;

  const handleStartProject = (id) => {
    history.push({
      pathname: EDIT_PATH,
      search: `${stringify({
        ...parse(search),
        fromTemplateId: id,
      })}`,
    });
  };

  const handleEdit = (uid) => {
    trackEvent(TEMPLATE.START_EDIT, { templateId: uid, isOrgAdmin });
    history.push({
      pathname: `${EDIT_TEMPLATE_PATH}/${uid}`,
      search,
    });
  };

  const handleDuplicate = (template) => {
    duplicateTemplate(template);
    setShowMyTemplates(true);
  };

  const handlePromptDelete = useCallback(
    (templateId) => {
      dispatch(openTemplateDeleteDialog({ templateId }));
    },
    [dispatch]
  );

  return (
    <div className={classes.drawer}>
      <Typography variant="h3" element="h2" className={classes.header}>
        Templates
      </Typography>
      <div className={classes.tabs}>
        <Button
          className={clsx(classes.tab, classes.button, {
            [classes.activeButton]: showMyTemplates,
            [classes.inactiveButton]: !showMyTemplates,
          })}
          startIcon={<PersonIcon />}
          onClick={() => setShowMyTemplates(true)}
        >
          My Templates
        </Button>
        <Button
          className={clsx(classes.tab, classes.button, {
            [classes.activeButton]: !showMyTemplates,
            [classes.inactiveButton]: showMyTemplates,
          })}
          startIcon={<GroupsIcon />}
          onClick={() => setShowMyTemplates(false)}
        >
          Team Templates
        </Button>
      </div>
      {!isLoading && templates?.length > 0 ? (
        <ResultsGrid className={classes.grid}>
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              onPreviewTemplateClick={null /* TODO MKRTMS-157 */}
              onStartProjectClick={handleStartProject}
              onEditClick={
                template.uid === templateId
                  ? null
                  : () => handleEdit(template.uid)
              }
              onDuplicateClick={() => handleDuplicate(template)}
              onDeleteClick={() => handlePromptDelete(template.uid)}
            />
          ))}
        </ResultsGrid>
      ) : (
        <>
          <Collapse in={!showMyTemplates && !isOrgAdmin}>
            <Alert severity="info" shadow className={classes.alert}>
              Only Admins can create Team Templates.
            </Alert>
          </Collapse>
          <TemplatesEmptyState showButton={isOrgAdmin || showMyTemplates} />
        </>
      )}
    </div>
  );
}
