import { Box, Grid, Typography } from '@material-ui/core';
import { parse, stringify } from 'query-string';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  RATIO_16_9,
  RATIO_1_1,
  RATIO_4_5,
  RATIO_9_16,
} from '../../constants/AspectRatios';
import { EDIT_PATH } from '../../constants/Urls';
import { selectIsEnterprise } from '../../selectors/user';
import FormatCard from './FormatCard';

const ratios = [RATIO_16_9, RATIO_9_16, RATIO_1_1, RATIO_4_5];

export default function TemplateAspectRatioSelect() {
  const isEnterprise = useSelector(selectIsEnterprise);
  const history = useHistory();
  const { search } = useLocation();

  const handleProjectClick = useCallback(
    (event, ratio) => {
      history.push({
        pathname: EDIT_PATH,
        search: `${stringify({ ...parse(search), ratio })}`,
      });
    },
    [history, search]
  );

  const handleTemplateClick = useCallback(
    (event, ratio) => {
      history.push({
        pathname: '/edit/template',
        search: `${stringify({ ...parse(search), ratio })}`,
      });
    },
    [history, search]
  );

  return (
    <>
      <Box py={2}>
        <Typography variant="h4" component="h2">
          Choose a Format
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {ratios.map((ratio) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={ratio}>
            <FormatCard
              isEnterprise={isEnterprise}
              onProjectClick={handleProjectClick}
              onTemplateClick={handleTemplateClick}
              ratio={ratio}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
