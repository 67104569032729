import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon,
} from '@material-ui/core';
import { Check, Palette } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Bin from '@videoblocks/react-icons/Bin';
import CheckBadge from '@videoblocks/react-icons/CheckBadge';
import Pencil from '@videoblocks/react-icons/Pencil';
import RemoveBadge from '@videoblocks/react-icons/RemoveBadge';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { BRAND_CARD_OPTIONS } from '../constants/TestSelectors';
import { trackEvent } from '../events/sendEvents';
import { BRANDS } from '../events/tags';
import { selectBrandUid } from '../features/assetDrawer/assetDrawerSlice';
import useBrand from '../hooks/useBrand';
import useToast from '../hooks/useToast';
import { selectIsOrgAdmin } from '../selectors/user';
import Toast from './Toast';

const useStyles = makeStyles((theme) => ({
  button: {
    aspectRatio: 1,
    height: theme.spacing(5),
    color: theme.palette.common.black,
    float: 'right', // Important for Safari
  },
  errorIcon: {
    color: theme.palette.common.red,
    width: theme.spacing(2.5),
  },
  icon: {
    color: theme.palette.common.black,
    width: theme.spacing(2.5),
  },
}));

export default function BrandSettingsMenu({
  isDefault = false,
  isDrawer = false,
  onApply = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onViewDetails = () => {},
  outlined = false,
  brandUid,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { open, message, openToast, closeToast, severity } = useToast();

  const selectedBrandUid = useSelector(selectBrandUid);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const { brand, editBrand } = useBrand(brandUid);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleApplyClick = () => {
    onApply();
    onClose();
  };

  const handleViewDetailsClick = () => {
    onViewDetails();
    onClose();
  };

  const handleEditClick = () => {
    onEdit();
    onClose();
  };

  const handleToggleDefaultClick = () => {
    if (!!isDefault) {
      openToast(
        `${brand.detail.name} will no longer be applied to projects by default.`
      );
      trackEvent(BRANDS.UNSET_DEFAULT_BRAND, {
        brandUid,
        organizationId: brand.organization_id,
      });
    } else {
      openToast(
        `${brand.detail.name} is now the default brand for your organization.`,
        'success'
      );
      trackEvent(BRANDS.SET_DEFAULT_BRAND, {
        brandUid,
        organizationId: brand.organization_id,
      });
    }
    editBrand({
      isDefault: !isDefault,
    });
    onClose();
  };

  const handleDeleteClick = () => {
    onDelete();
    onClose();
  };

  return (
    <>
      <Toast open={open} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <IconButton
        className={classes.button}
        aria-label="Brand settings menu"
        aria-controls="brand-settings-menu"
        aria-haspopup="true"
        data-testid={BRAND_CARD_OPTIONS}
        onClick={handleClick}
        style={outlined ? { border: 'solid 2px', padding: 1 } : {}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="brand-settings-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {isDrawer && !selectedBrandUid && (
          <MenuItem onClick={handleApplyClick}>
            <ListItemIcon>
              <Check className={classes.icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Apply" />
          </MenuItem>
        )}
        {!selectedBrandUid && (
          <MenuItem onClick={handleViewDetailsClick}>
            <ListItemIcon>
              <Palette className={classes.icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Details" />
          </MenuItem>
        )}
        {isOrgAdmin && [
          <MenuItem key="brand-settings-edit" onClick={handleEditClick}>
            <ListItemIcon>
              <Pencil className={classes.icon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>,
          <MenuItem
            key="brand-settings-set-default"
            onClick={handleToggleDefaultClick}
          >
            <ListItemIcon>
              {isDefault ? (
                <RemoveBadge className={classes.icon} />
              ) : (
                <CheckBadge className={classes.icon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={isDefault ? 'Remove as Default' : 'Set as Default'}
            />
          </MenuItem>,
          <MenuItem key="brand-settings-delete" onClick={handleDeleteClick}>
            <ListItemIcon>
              <SvgIcon
                component={Bin}
                className={classes.errorIcon}
                color="error"
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ color: 'error' }}
            />
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
}
