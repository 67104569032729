import { makeStyles } from '@material-ui/core/styles';

import {
  NAVBAR_HEIGHT,
  UNIFIED_NAVBAR_HEIGHT,
} from '../../constants/Constants';
import AccountMenu from './AccountMenu';
import Beamer from './Beamer';
import FaqButton from './FaqButton';

const useStyles = makeStyles((theme) => ({
  navbar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: (props) => (props.tall ? UNIFIED_NAVBAR_HEIGHT : NAVBAR_HEIGHT),
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    padding: theme.spacing(0, 2, 0, 3),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
}));

function Navbar(props) {
  const { children, tall, ...rest } = props;
  const classes = useStyles({ tall });

  return (
    <header className={classes.navbar} {...rest}>
      {children}
      <FaqButton />
      <AccountMenu />
      <Beamer />
    </header>
  );
}

export default Navbar;
