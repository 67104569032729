import { Button, makeStyles } from '@material-ui/core';
import AddIcon from '@videoblocks/react-icons/Add';
import BookPages from '@videoblocks/react-icons/BookPages';
import CameraStudioIcon from '@videoblocks/react-icons/CameraStudio';
import FilterVideoIcon from '@videoblocks/react-icons/FilterVideo';
import HouseIcon from '@videoblocks/react-icons/House';
import PaintingPaletteIcon from '@videoblocks/react-icons/PaintingPalette';
import { parse, stringify } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { SIDE_NAV_WIDTH } from '../../constants/Constants';
import { DEFAULT_PROJECT_RATIO } from '../../constants/Storyboard';
import {
  BRANDS_PATH,
  EDIT_PATH,
  HOME_PATH,
  PROJECTS_PATH,
  TEMPLATES_PATH,
  TUTORIALS_PATH,
} from '../../constants/Urls';
import SideNavLink from './SideNavLink';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      margin: theme.spacing(2),
    },
    buttonIcon: {
      width: theme.typography.pxToRem(16),
      height: theme.typography.pxToRem(16),
    },
    container: {
      minWidth: `${SIDE_NAV_WIDTH}px`,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.grey[400]}`,
    },
  };
});

export default function SideNav() {
  const classes = useStyles();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const handleNewProjectClick = () => {
    history.push({
      pathname: EDIT_PATH,
      search: `${stringify({
        ...parse(search),
        ratio: DEFAULT_PROJECT_RATIO, // overwrite any existing ration
      })}`,
    });
  };

  const isActive = (toPath) => toPath === pathname;

  return (
    <div className={classes.container}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleNewProjectClick}
        startIcon={<AddIcon className={classes.buttonIcon} />}
      >
        New Project
      </Button>
      <SideNavLink
        title="Maker Home"
        isActive={isActive(HOME_PATH)}
        to={{ pathname: HOME_PATH, search }}
        icon={HouseIcon}
      />
      <SideNavLink
        title="Projects"
        isActive={isActive(PROJECTS_PATH)}
        to={{ pathname: PROJECTS_PATH, search }}
        icon={CameraStudioIcon}
      />
      <SideNavLink
        title="Templates"
        isTeamsLink
        isActive={isActive(TEMPLATES_PATH)}
        to={{ pathname: TEMPLATES_PATH, search }}
        icon={FilterVideoIcon}
      />
      <SideNavLink
        title="Brands"
        isTeamsLink
        isActive={isActive(BRANDS_PATH)}
        to={{ pathname: BRANDS_PATH, search }}
        icon={PaintingPaletteIcon}
      />
      <SideNavLink
        title="Tutorials"
        isActive={isActive(TUTORIALS_PATH)}
        to={{ pathname: TUTORIALS_PATH, search }}
        icon={BookPages}
      />
    </div>
  );
}
