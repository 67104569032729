import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import useRenderer from '../../hooks/useRenderer';

const useStyles = makeStyles((theme) => ({
  canvasWrapper: {
    margin: theme.spacing(0, 'auto'),
    boxShadow:
      '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  },
}));

/**
 * RendererCanvas gives a way to display and modify the canvas for a jelly-renderer
 *
 * Note that this is manipulating the DOM directly outside of React.
 */
function RendererCanvas({ style = {} }) {
  const { renderer } = useRenderer();
  const canvas = renderer.canvas;
  const classes = useStyles();

  const divRef = useRef();

  // fit canvas to wrapper
  useEffect(() => {
    canvas.setAttribute('style', 'height: 100%; outline: none;');
    canvas.setAttribute('tabindex', '-1');
  }, [canvas]);

  // add event listeners
  useEffect(() => {
    const onInteraction = () => {
      renderer.pause();
    };

    canvas.addEventListener('touchstart', onInteraction);
    canvas.addEventListener('mousedown', onInteraction);

    return () => {
      canvas.removeEventListener('touchstart', onInteraction);
      canvas.removeEventListener('mousedown', onInteraction);
    };
  }, [canvas, renderer]);

  // mount the canvas
  useEffect(() => {
    const divElement = divRef.current;
    divElement?.appendChild(canvas); // eslint-disable-line no-unused-expressions
    return () => divElement?.removeChild(canvas);
  }, [canvas]);

  // the div here is a wrapper for us to mount the canvas to
  return <div className={classes.canvasWrapper} style={style} ref={divRef} />;
}

RendererCanvas.propTypes = {
  style: PropTypes.object,
};

export default RendererCanvas;
