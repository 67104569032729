import {
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
  pointerWithin,
} from '@dnd-kit/core';
import { makeStyles } from '@material-ui/core/styles';
import { clamp } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure, useTitle } from 'react-use';

import {
  DND_MIN_DISTANCE,
  DND_ZONES,
  NAVBAR_HEIGHT,
  STAGE_MIN_HEIGHT,
  TIMELINE_DEFAULT_HEIGHT,
  TIMELINE_MIN_HEIGHT,
} from '../../constants/Constants';
import { trackEvent } from '../../events/sendEvents';
import { getDragContext } from '../../events/tags';
import PropertiesPanel from '../../features/propertiesPanel/PropertiesPanel';
import useKeyboardShortcuts from '../../hooks/useKeyboardShortcuts';
import { setActiveItemId } from '../../slices/editorSlice';
import { selectActiveItem, selectName } from '../../slices/storyboardSlice';
import Droppable from '../Droppable';
import AssetDrawer from '../assetDrawer/AssetDrawer';
import Timeline from '../timeline/Timeline';
import TimelineResizeBar from '../timeline/TimelineResizeBar';
import AspectRatioPreviewProvider from './AspectRatioPreviewProvider';
import StageArea from './StageArea';

const useStyles = makeStyles((theme) => ({
  editor: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: `
      'sidebar stage properties'
      'sidebar timeline timeline'
    `,
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    cursor: 'auto',
  },
  sidebarArea: {
    gridArea: 'sidebar',
    zIndex: 2,
  },
  stageArea: {
    gridArea: 'stage',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stageControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 'auto', 1),
  },
  propertiesArea: {
    gridArea: 'properties',
    overflowY: 'scroll',
  },
  timelineArea: {
    gridArea: 'timeline',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Editor() {
  const dispatch = useDispatch();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: DND_MIN_DISTANCE },
    })
  );
  const projectName = useSelector(selectName);
  const activeItem = useSelector(selectActiveItem);
  useTitle(`Maker | ${projectName}`);

  const [editorRef, { height: editorHeight }] = useMeasure();
  const [stageRef, { height: stageHeight }] = useMeasure();

  const [zone, setZone] = useState();
  const [OverlayElement, setOverlayElement] = useState();

  const startingHeight = useRef();
  const startingY = useRef();
  const [timelineHeight, setTimelineHeight] = useState(TIMELINE_DEFAULT_HEIGHT);

  const classes = useStyles({ zone });

  const handleDragStart = (event) => {
    const { item, overlayElement, zone } = event.active?.data?.current || {};
    // Note: https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
    setOverlayElement(() => overlayElement);
    setZone(zone);

    trackEvent(getDragContext(zone).ITEM_START_DRAG, item);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!activeItem) {
      dispatch(setActiveItemId(active.id));
    }
    setOverlayElement(null);
    setZone(null);
    if (!over) {
      const { dragItemType, item } = active?.data?.current || {};
      trackEvent(
        getDragContext(dragItemType)?.ITEM_DROP || 'item.drop',
        item,
        true
      );
    }
  };

  const handleDragCancel = (event) => {
    setOverlayElement(null);
    setZone(null);

    const { item, zone } = event.active?.data?.current || {};
    trackEvent(getDragContext(zone)?.ITEM_DROP || 'item.drop', item);
  };

  const sidebarStyles = {
    pointerEvents: zone != null ? 'none' : 'auto',
    zIndex: zone === DND_ZONES.DRAWER ? 2 : 'unset',
  };

  const handleResizeStart = (event) => {
    startingY.current = event.screenY;
    startingHeight.current = timelineHeight;
  };

  const handleResize = (event) => {
    const delta = event.screenY - startingY.current;
    const newTimelineHeight = clamp(
      startingHeight.current - delta,
      TIMELINE_MIN_HEIGHT,
      editorHeight - STAGE_MIN_HEIGHT
    );
    setTimelineHeight(newTimelineHeight);
  };

  const handleResizeStop = () => {
    startingY.current = null;
    startingHeight.current = null;
  };

  useEffect(() => {
    if (editorHeight > 0) {
      setTimelineHeight(
        clamp(
          timelineHeight,
          TIMELINE_MIN_HEIGHT,
          editorHeight - STAGE_MIN_HEIGHT
        )
      );
    }
  }, [editorHeight, stageHeight, timelineHeight]);

  useKeyboardShortcuts();

  const editorStyles = {
    gridTemplateRows: `1fr ${timelineHeight}px`,
  };

  return (
    <DndContext
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
    >
      <div className={classes.editor} ref={editorRef} style={editorStyles}>
        <div className={classes.sidebarArea} style={sidebarStyles}>
          {/*
            This drop zone functions as a buffer on top of the timeline
            when it is scrolled left (behind the asset drawer). This
            prevents users from accidentally adding content to the project.
          */}
          <Droppable id="asset-drawer">
            <AssetDrawer />
          </Droppable>
        </div>
        <div className={classes.stageArea} ref={stageRef}>
          <AspectRatioPreviewProvider>
            <StageArea timelineHeight={timelineHeight} />
          </AspectRatioPreviewProvider>
        </div>
        <div className={classes.propertiesArea}>
          <PropertiesPanel />
        </div>
        <div className={classes.timelineArea}>
          <TimelineResizeBar
            onStart={handleResizeStart}
            onDrag={handleResize}
            onStop={handleResizeStop}
          />
          <Timeline />
        </div>
        <DragOverlay dropAnimation={null}>
          {OverlayElement && <OverlayElement />}
        </DragOverlay>
      </div>
    </DndContext>
  );
}
