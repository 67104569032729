import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { TUTORIALS_AND_FAQS } from '../../constants/Urls';
import { trackEvent } from '../../events/sendEvents';
import { NAVBAR } from '../../events/tags';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(0, 2),
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}));

export default function FaqButton() {
  const classes = useStyles();

  return (
    <Link
      color="inherit"
      className={classes.link}
      href={TUTORIALS_AND_FAQS}
      target="_blank"
      rel="noreferrer"
      aria-label="Frequently asked questions and tutorials"
      onClick={() => trackEvent(NAVBAR.FAQ_CLICK)}
    >
      <HelpOutlineIcon />
    </Link>
  );
}
