import { Box, Container, makeStyles } from '@material-ui/core';
import { parse, stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import LoaderContainer from '../components/LoaderContainer';
import SideNav from '../components/sidenav/SideNav';
import { NAVBAR_HEIGHT } from '../constants/Constants';
import { EDIT_PATH, HOME_PATH } from '../constants/Urls';
import LocationListener from '../routes/LocationListener';
import {
  selectHasVisitedMaker,
  selectIsEnterprise,
  selectIsLoading,
} from '../selectors/user';

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: 'flex',
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  },
}));

export default function HomeContainer({
  children,
  enterpriseOnly = false,
  maxWidth = 'lg',
}) {
  const classes = useStyles();
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);
  const isEnterprise = useSelector(selectIsEnterprise);
  const hasVisitedMaker = useSelector(selectHasVisitedMaker);

  return (
    <>
      {!hasVisitedMaker && (
        <Redirect
          to={{
            pathname: EDIT_PATH,
            search: `${stringify({
              ...parse(history.location.search),
              ratio: '16:9',
              firstTimeUser: true,
            })}`,
          }}
        />
      )}
      {enterpriseOnly && !isEnterprise && (
        <Redirect
          to={{ pathname: HOME_PATH, search: history.location.search }}
        />
      )}
      <LocationListener />
      {isLoading ? (
        <LoaderContainer />
      ) : (
        <Box className={classes.homeContainer} bgcolor="white">
          <SideNav />
          <Container maxWidth={maxWidth} disableGutters>
            {children}
          </Container>
        </Box>
      )}
    </>
  );
}
