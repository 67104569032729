import { RATIO_16_9 } from './AspectRatios';

/**
 * The default name for new projects started from scratch
 */
export const DEFAULT_PROJECT_NAME = 'Untitled Project';

/**
 * The default name for new templates started from scratch
 */
export const DEFAULT_TEMPLATE_NAME = 'Untitled Template';

/**
 * The default aspect ratio for new projects started from scratch
 */
export const DEFAULT_PROJECT_RATIO = RATIO_16_9;

/**
 * The maximum (suggested) duration for a project
 */
export const MAX_STORYBOARD_DURATION = 60 * 5; // 5 minutes

/**
 * The minimum duration (in seconds) for an item on the timeline
 */
export const MINIMUM_ITEM_DURATION = 0.01;

/**
 * The default item duration when adding an item to a project, in seconds
 */
export const DEFAULT_ITEM_DURATION = 3;

/**
 * The maximum number of layers a user can have
 */
export const LAYER_LIMIT = 40;
