import {
  FontSize,
  Keyboard,
  VideoPlayerAdjust,
} from '@videoblocks/react-icons';
import { get } from 'lodash';

import { BACKEND_CONTEXT } from './Constants';

/**
 * Site link URLs
 */

export const SB_URL = get(
  {
    production: 'https://www.storyblocks.com',
    staging: 'https://sb-staging.videoblocksdev.com',
  },
  BACKEND_CONTEXT,
  'https://storyblocks.local'
);

const SB_URL_HOST = new URL(SB_URL).host;

export const SB_VIDEO_URL = `${SB_URL}/video`;
export const SB_AUDIO_URL = `${SB_URL}/audio`;
export const SB_IMAGES_URL = `${SB_URL}/images`;
export const SB_MAKER_LANDING_URL = `${SB_URL}/maker`;
export const SB_MAKER_UNSUPPORTED_URL = `${SB_URL}/unsupported-device`;

export const SB_LOGIN_URL = `${SB_URL}/login?redirect=maker`;
export const SB_LOGOUT_URL = `${SB_URL}/member/logout`;
export const SB_MEMBER_FOLDERS_URL = `${SB_URL}/member/folders`;
export const SB_MEMBER_PROFILE_URL = `${SB_URL}/member/profile`;
export const SB_MEMBER_BILLING_URL = `${SB_URL}/member/billing`;
export const SB_SIGN_UP_URL = `${SB_URL}/join/become-user/maker`;
export const SB_SUPPORT_URL = `${SB_URL}/member/support`;

/**
 * Intercom URLs
 */

export const TUTORIALS_AND_FAQS =
  'https://intercom.help/storyblocks-9aa34255171d/en/collections/2570990';

/**
 * API URLs
 */

export const SB_API_URL = get(
  {
    production: `https://api.${SB_URL_HOST.replace('www.', '')}/api/v2`,
  },
  BACKEND_CONTEXT,
  `${SB_URL}/api/v2`
);
export const GET_API_TOKEN_BY_SEED_URL = `${SB_API_URL}/tokens`;

export const MAKER_API_URL = `${SB_URL}/api/jellystone/v1`;

export const CONTENT_TOKEN_URL = `${MAKER_API_URL}/content-token`;

export const KAFKA_EVENTS_URL =
  process.env.REACT_APP_KAFKA_EVENTS_URL || 'https://storyblocks.local:8088';

export const INTERCOM_EXPORT_FAQ_URL =
  'https://intercom.help/storyblocks-9aa34255171d/en/articles/4490511-why-am-i-having-trouble-exporting-my-video-from-maker';

/**
 * Tutorial URLs
 */
const WP_API = 'https://blog.storyblocks.com/wp-json/wp/v2';
export const WP_MEDIA_URL = `${WP_API}/media`;

const MAKER_HOME_WP_TAG_ID = 861;
const MAKER_HOME_WP_TAG_SLUG = 'maker-tutorials';
export const MAKER_HOME_WP_POSTS_URL = `${WP_API}/posts?tags=${MAKER_HOME_WP_TAG_ID}`;
export const MAKER_HOME_WP_SEE_ALL_URL = `https://blog.storyblocks.com/tag/${MAKER_HOME_WP_TAG_SLUG}`;

export const TUTORIAL_VIDEOS = [
  {
    title: 'Getting Started With Maker',
    excerpt: 'Learn how to use Maker to create video faster and at scale',
    youtubeId: 'KScy2TEUmx8',
    duration: '3:47',
  },
  {
    title: 'Tutorial: How to record your screen in Maker',
    excerpt:
      'With the ability to capture your own audio, screen, and video recording all in one place, the possibilities for your content are endless.',
    youtubeId: 'Yaj5C3nZD9A',
    duration: '3:44',
  },
  {
    title: "The Beginner's Guide to Creating Video",
    excerpt:
      'For when you want to create effective, scroll-stopping videos at scale, but don’t know where to start',
    youtubeId: 'X-v0ePUadmo',
    duration: '2:22',
  },
  {
    title: 'Get to Know Your Audience to Make Effective Video Content',
    excerpt:
      'Learn who your audience is and what they respond to – with Shannon Beveridge and music artist Zolita',
    youtubeId: 'c2IQ1g_Q3WM',
    duration: '4:36',
  },
  {
    title: 'How to Set Realistic Goals for Video Creation',
    excerpt:
      'Before you pick up the camera, think about exactly what you want to achieve and where your video will live',
    youtubeId: 'eJBAkCynDe0',
    duration: '4:46',
  },
  {
    title: 'Best Practices for Creating a Video Concept',
    excerpt:
      'How to determine the core message of your video and communicate that message through a centralized concept',
    youtubeId: 'sRU0bUOW-yQ',
    duration: '5:44',
  },
  {
    title: 'Basics of Video Editing Everyone Should Know',
    excerpt:
      'So, you’ve recorded your content and collected your B-roll, now what?',
    youtubeId: 'P9kLDerNW9s',
    duration: '5:48',
  },
  {
    title: 'Stop the Scroll: Tips for More Engaging Video Content',
    excerpt:
      'Shannon Beveridge and Zolita show you how to make your content engaging enough to hook your audience',
    youtubeId: 'QPoJ30QV3RM',
    duration: '5:59',
  },
  {
    title: 'Your Visual Aesthetic: Creating Shortcuts for Brand Consistency',
    excerpt: 'Learn how Chrystopher Rhodes creates a visual style',
    youtubeId: 'jcM4rdCOOsQ',
    duration: '5:42',
  },
  {
    title: 'How to Test and Optimize Your Video Creative',
    excerpt:
      'Learn how to use the post-launch stage to test out what works for your audience, and optimize to inform your future best practices',
    youtubeId: 'AsGSQuim-CE',
    duration: '5:16',
  },
  {
    title: 'Scale Your Video Production and Make More Videos',
    excerpt:
      'Find out how to scale your video productions and make more videos without sacrificing quality from Jordy Vandeput of Cinecom',
    youtubeId: 'LGF43nZAkNk',
    duration: '6:26',
  },
  {
    title: 'Building a Small Business Brand with Video',
    excerpt: 'How to start creating video that celebrates your brand',
    youtubeId: 'ddRtfQjCeP0', // Note: youtubeId comes from the "Share" url on youtube.com
    duration: '4:50',
  },
  {
    title: 'How to Grow Your Audience on YouTube and Beyond',
    excerpt: "Get motivated to build your channel's discovery",
    youtubeId: 'rZUKwrajzCA', // Note: youtubeId comes from the "Share" url on youtube.com
    duration: '6:44',
  },
];

export const FAQ_LINK = {
  title: 'View all FAQ',
  href: 'https://help.storyblocks.com/en/collections/2570990-maker-tutorials-and-frequently-asked-questions',
};

export const QUICK_LINKS = [
  {
    title: 'Keyboard shortcuts',
    href: 'https://help.storyblocks.com/en/articles/6424753-keyboard-shortcuts-for-maker',
    icon: Keyboard,
  },
  {
    title: 'Adding custom fonts',
    href: 'https://help.storyblocks.com/en/articles/5520635-how-do-i-add-new-typefaces-fonts-in-maker',
    icon: FontSize,
  },
  {
    title: 'Exporting your video',
    href: 'https://help.storyblocks.com/en/articles/5485524-how-do-i-export-and-download-a-video-in-maker',
    icon: VideoPlayerAdjust,
  },
];

/**
 * Maker Paths
 * Base paths to Maker routes
 */

export const ADMIN_PATH = '/admin';
export const BRANDS_PATH = '/brands';
export const EDIT_PATH = '/edit';
export const EDIT_TEMPLATE_PATH = '/edit/template';
export const HOME_PATH = '/';
export const PROJECTS_PATH = '/projects';
export const SHARE_PATH = '/share';
export const TEMPLATES_PATH = '/templates';
export const TUTORIALS_PATH = '/tutorials';
