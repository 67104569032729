import { makeStyles } from '@material-ui/core/styles';
import { LayerTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import { checkLayerType } from '../../utils/mediaUtils';
import EmptyLayerPlaceholder from './EmptyLayerPlaceholder';
import NewLayerPlaceholder from './NewLayerPlaceholder';
import TimelineLayer from './TimelineLayer';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function LayerSuggestions(props) {
  const {
    elementOffset = 0,
    layerId,
    layerType,
    setSnappedTo = () => {},
    snappableOffsets = [],
  } = props;
  const classes = useStyles();

  const { isAudio, isText, isVideo } = checkLayerType(layerType);

  if (isText) {
    return (
      <>
        <NewLayerPlaceholder index={0} />
        <div className={classes.container}>
          <TimelineLayer
            id={layerId}
            placeholderOffset={elementOffset}
            setSnappedTo={setSnappedTo}
            snappableOffsets={snappableOffsets}
          />
          <EmptyLayerPlaceholder
            index={1}
            layerType={LayerTypes.VIDEO}
            placeholderOffset={elementOffset}
          />
          <EmptyLayerPlaceholder
            index={1}
            layerType={LayerTypes.AUDIO}
            placeholderOffset={elementOffset}
          />
        </div>
        <NewLayerPlaceholder index={1} />
      </>
    );
  }

  if (isVideo) {
    return (
      <>
        <NewLayerPlaceholder index={0} />
        <div className={classes.container}>
          <EmptyLayerPlaceholder
            index={0}
            layerType={LayerTypes.TEXT}
            placeholderOffset={elementOffset}
          />
          <TimelineLayer
            id={layerId}
            placeholderOffset={elementOffset}
            setSnappedTo={setSnappedTo}
            snappableOffsets={snappableOffsets}
          />
          <EmptyLayerPlaceholder
            index={1}
            layerType={LayerTypes.AUDIO}
            placeholderOffset={elementOffset}
          />
        </div>
        <NewLayerPlaceholder index={1} />
      </>
    );
  }

  if (isAudio) {
    return (
      <>
        <NewLayerPlaceholder index={0} />
        <div className={classes.container}>
          <EmptyLayerPlaceholder
            index={0}
            layerType={LayerTypes.TEXT}
            placeholderOffset={elementOffset}
          />
          <EmptyLayerPlaceholder
            index={0}
            layerType={LayerTypes.VIDEO}
            placeholderOffset={elementOffset}
          />
          <TimelineLayer
            id={layerId}
            placeholderOffset={elementOffset}
            setSnappedTo={setSnappedTo}
            snappableOffsets={snappableOffsets}
          />
        </div>
        <NewLayerPlaceholder index={1} />
      </>
    );
  }
}

LayerSuggestions.propTypes = {
  elementOffset: PropTypes.number,
  layerId: PropTypes.string,
  layerType: PropTypes.oneOf(Object.values(LayerTypes)),
  setSnappedTo: PropTypes.func,
  snappableOffsets: PropTypes.arrayOf(PropTypes.object),
};
