import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import PaddedAspectBox from './PaddedAspectBox';
import VideoPreview from './VideoPreview';

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    height: theme.spacing(9),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
  },
  buttonGroup: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    visibility: 'hidden',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    margin: theme.spacing(0, 1),
  },
  template: {
    minWidth: 200, // this is not ideal for responsivness, but it keeps the long CTA text from wrapping to 3 lines
    textAlign: 'center',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover, &:focus': {
      boxShadow:
        '0px 2px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.14)',
      transform: 'translateY(-2px)',
      '& $buttonGroup': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  preview: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

function TemplatePreview(props) {
  const { aspectRatio, name, onPreview, onSelect, previewUrl, thumbnailUrl } =
    props;
  const classes = useStyles();

  const handleSelect = (event) => {
    event.stopPropagation();
    onSelect();
  };

  const handleEnter = (event, onEnter) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onEnter();
    }
  };

  return (
    <div
      className={classes.template}
      onKeyDown={(event) => handleEnter(event, onSelect)}
      tabIndex={0}
      onClick={onPreview}
    >
      <PaddedAspectBox aspectRatio={aspectRatio}>
        <VideoPreview
          className={classes.preview}
          thumbnail={thumbnailUrl}
          source={previewUrl}
        />
      </PaddedAspectBox>
      <div className={classes.body}>
        {name}
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            tabIndex={-1}
            variant="outlined"
            onClick={handleSelect}
          >
            Create Project from Template
          </Button>
        </div>
      </div>
    </div>
  );
}

TemplatePreview.propTypes = {
  aspectRatio: PropTypes.number,
  name: PropTypes.string,
  onPreview: PropTypes.func,
  onSelect: PropTypes.func,
  previewUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
};

export default TemplatePreview;
