import { SvgIcon, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import AudioIcon from '@videoblocks/react-icons/Audio';
import ImageIcon from '@videoblocks/react-icons/Image';
import VideoIcon from '@videoblocks/react-icons/Video';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: theme.spacing(4),
  },
  tab: {
    padding: theme.spacing(0.5, 1),
    minWidth: 'auto',
    minHeight: 'auto',
    lineHeight: 28 / 16,
    borderRadius: theme.shape.borderRadius,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  selected: {
    backgroundColor: theme.palette.grey[100],
  },
  labelIcon: {
    '& $wrapper > *:first-child': {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  wrapper: {
    flexDirection: 'row',
  },
}));

export default function MediaTypeTabs(props) {
  const { disabled, onChange, value, ...rest } = props;
  const classes = useStyles();

  const tabs = [
    { label: 'Video', icon: VideoIcon, value: MediaTypes.VIDEO },
    { label: 'Audio', icon: AudioIcon, value: MediaTypes.AUDIO },
    { label: 'Images', icon: ImageIcon, value: MediaTypes.IMAGE },
  ];

  return (
    <Tabs
      className={classes.tabs}
      onChange={onChange}
      value={value || tabs[0].value}
      {...rest}
    >
      {tabs.map((tab) => (
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.selected,
            labelIcon: classes.labelIcon,
            wrapper: classes.wrapper,
          }}
          disabled={disabled}
          icon={<SvgIcon component={tab.icon} fontSize="inherit" />}
          label={tab.label}
          value={tab.value}
          key={tab.value}
        />
      ))}
    </Tabs>
  );
}

MediaTypeTabs.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]),
};
