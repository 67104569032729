import { ReactComponent as FacebookIconMono } from '../assets/images/social-facebook-mono.svg';
import { ReactComponent as FacebookIcon } from '../assets/images/social-facebook.svg';
import { ReactComponent as InstagramIconMono } from '../assets/images/social-instagram-mono.svg';
import { ReactComponent as InstagramIcon } from '../assets/images/social-instagram.svg';
import { ReactComponent as LinkedinIconMono } from '../assets/images/social-linkedin-mono.svg';
import { ReactComponent as LinkedinIcon } from '../assets/images/social-linkedin.svg';
import { ReactComponent as SnapchatIconMono } from '../assets/images/social-snapchat-mono.svg';
import { ReactComponent as SnapchatIcon } from '../assets/images/social-snapchat.svg';
import { ReactComponent as TiktokIconMono } from '../assets/images/social-tiktok-mono.svg';
import { ReactComponent as TiktokIcon } from '../assets/images/social-tiktok.svg';
import { ReactComponent as TwitterIconMono } from '../assets/images/social-twitter-mono.svg';
import { ReactComponent as TwitterIcon } from '../assets/images/social-twitter.svg';
import { ReactComponent as YouTubeIconMono } from '../assets/images/social-youtube-mono.svg';
import { ReactComponent as YouTubeIcon } from '../assets/images/social-youtube.svg';
import {
  RATIO_16_9,
  RATIO_1_1,
  RATIO_2_3,
  RATIO_4_5,
  RATIO_9_16,
} from './AspectRatios';

export const FACEBOOK = 'facebook';
export const INSTAGRAM = 'instagram';
export const LINKEDIN = 'linkedin';
export const SNAPCHAT = 'snapchat';
export const TIKTOK = 'tiktok';
export const TWITTER = 'twitter';
export const YOUTUBE = 'youtube';

export const socialPlatformDisplayOrder = [
  FACEBOOK,
  YOUTUBE,
  INSTAGRAM,
  TWITTER,
  LINKEDIN,
  SNAPCHAT,
  TIKTOK,
];

export default {
  [FACEBOOK]: {
    icon: FacebookIcon,
    iconMono: FacebookIconMono,
    name: 'Facebook',
    ratios: [RATIO_16_9, RATIO_9_16, RATIO_1_1, RATIO_4_5, RATIO_2_3],
  },
  [INSTAGRAM]: {
    icon: InstagramIcon,
    iconMono: InstagramIconMono,
    name: 'Instagram',
    ratios: [RATIO_9_16, RATIO_1_1, RATIO_4_5],
  },
  [LINKEDIN]: {
    icon: LinkedinIcon,
    iconMono: LinkedinIconMono,
    name: 'LinkedIn',
    ratios: [RATIO_16_9, RATIO_1_1, RATIO_4_5],
  },
  [SNAPCHAT]: {
    icon: SnapchatIcon,
    iconMono: SnapchatIconMono,
    name: 'Snapchat',
    ratios: [RATIO_9_16],
  },
  [TIKTOK]: {
    icon: TiktokIcon,
    iconMono: TiktokIconMono,
    name: 'TikTok',
    ratios: [RATIO_9_16],
  },
  [TWITTER]: {
    icon: TwitterIcon,
    iconMono: TwitterIconMono,
    name: 'Twitter',
    ratios: [RATIO_16_9, RATIO_9_16, RATIO_1_1],
  },
  [YOUTUBE]: {
    icon: YouTubeIcon,
    iconMono: YouTubeIconMono,
    name: 'YouTube',
    ratios: [RATIO_16_9, RATIO_9_16],
  },
};

export const formatCardRatioSocialIconMap = {
  [RATIO_16_9]: [
    YouTubeIconMono,
    FacebookIconMono,
    TwitterIconMono,
    LinkedinIconMono,
  ],
  [RATIO_9_16]: [
    YouTubeIconMono,
    InstagramIconMono,
    FacebookIconMono,
    TiktokIconMono,
    SnapchatIconMono,
    TwitterIconMono,
  ],
  [RATIO_1_1]: [
    InstagramIconMono,
    FacebookIconMono,
    TwitterIconMono,
    LinkedinIconMono,
  ],
  [RATIO_4_5]: [InstagramIconMono, FacebookIconMono, LinkedinIconMono],
};
