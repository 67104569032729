import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';

import { textAnimations } from '../../constants/TextAnimations';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import { textItemUpdated } from '../../slices/storyboardSlice';
import countLines from '../../utils/countLines';

const DEBOUNCE_WAIT = 100;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: -theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    marginLeft: 0,
  },
}));

export default function TextEditorContainer() {
  const dispatch = useDispatch();
  const activeItem = useActiveItem();
  const { animationPreset, text } = activeItem;
  const [value, setValue] = useState(text);
  const [previousValue, setPreviousValue] = useState(text);
  const classes = useStyles();

  useEffect(() => {
    setValue(text);
  }, [text]);

  useDebounce(
    () => {
      if (value !== text) {
        dispatch(
          textItemUpdated({ id: activeItem.id, changes: { text: value } })
        );
      }
    },
    DEBOUNCE_WAIT,
    [value]
  );

  const handleOnBlur = (event) => {
    const newValue = event.target.value;
    if (previousValue !== newValue) {
      trackEvent(
        PROPERTIES_PANEL.ITEM_EDIT,
        getItemEditProps(activeItem, 'text', event.target.value)
      );
    }
    setPreviousValue('');
  };

  const handleOnFocus = (event) => {
    setPreviousValue(event.target.value);
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
  };

  const textAnimation = textAnimations.find(
    (animation) => animation.value === animationPreset
  );

  const maxLines = textAnimation?.maxLines || Infinity;
  const numberOfLines = countLines(text);
  const hasTooManyLines = numberOfLines > maxLines;

  return (
    <div className={classes.container}>
      <TextField
        error={hasTooManyLines}
        fullWidth
        helperText={
          maxLines < Infinity
            ? `Add up to ${maxLines} lines of text for this animation`
            : ''
        }
        FormHelperTextProps={{
          className: classes.helperText,
        }}
        rows={3}
        inputProps={{ 'aria-label': 'text' }}
        margin="none"
        multiline
        onBlur={handleOnBlur}
        onChange={handleTextChange}
        onFocus={handleOnFocus}
        spellCheck={false}
        value={value}
        variant="outlined"
      />
    </div>
  );
}
