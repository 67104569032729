import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../../assets/images/sb-logo.svg';
import { SB_URL } from '../../constants/Urls';
import Navbar from './Navbar';
import SiteLinks from './SiteLinks';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: theme.spacing(4),
    verticalAlign: 'middle',
  },
}));

function HomeNavbar() {
  const classes = useStyles();
  return (
    <Navbar tall>
      <a href={SB_URL}>
        <img className={classes.logo} src={logo} alt="Storyblocks Home" />
      </a>
      <Box marginRight="auto" height="100%">
        <SiteLinks />
      </Box>
    </Navbar>
  );
}

export default HomeNavbar;
