import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

import CloseButton from '../CloseButton';
import ResultsGrid from '../assetDrawer/ResultsGrid';
import BrandCard from '../cards/BrandCard';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(0.5),
    overflow: 'hidden',
  },
  grid: {
    gap: theme.spacing(2.5),
    marginTop: theme.spacing(3),
  },
  selectedCard: {
    borderColor: theme.palette.blue[500],
    borderWidth: theme.spacing(0.5),
    borderStyle: 'solid',
    borderRadius: theme.spacing(2),
    margin: theme.spacing(-0.5),
  },
}));

export default function BrandSelectDialog(props) {
  const classes = useStyles();
  const { initialBrand, brands, onConfirm, onClose, open } = props;
  const [selectedBrandUid, setSelectedBrandUid] = useState(initialBrand);

  const handleConfirm = () => {
    onConfirm(selectedBrandUid);
    onClose();
  };

  const buildBrandCard = (brand) => {
    const cardClasses = clsx(classes.card, {
      [classes.selectedCard]: brand.uid === selectedBrandUid,
    });

    return (
      <div key={brand.uid} className={cardClasses}>
        <BrandCard
          brand={brand}
          onClick={() => setSelectedBrandUid(brand.uid)}
        />
      </div>
    );
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <Box padding={4}>
        <DialogTitle disableTypography>
          <Typography component="h2" variant="h4">
            Select Brand
          </Typography>
        </DialogTitle>
        <DialogContent>
          {brands && brands.length > 0 && (
            <ResultsGrid columns={3} className={classes.grid}>
              {brands.map(buildBrandCard)}
            </ResultsGrid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Done
          </Button>
        </DialogActions>
        <Box position="absolute" top={0} right={0} m={2} color="grey.700">
          <CloseButton onClick={onClose} />
        </Box>
      </Box>
    </Dialog>
  );
}

BrandSelectDialog.propTypes = {
  brands: PropTypes.array,
  initialBrand: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

BrandSelectDialog.defaultProps = {
  brands: [],
  initialBrand: '',
  onConfirm: () => {},
  onClose: () => {},
  open: false,
};
