import { Button, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';

import alphaToPercent from '../../utils/alphaToPercent';
import inRange from '../../utils/inRange';
import parsePercent from '../../utils/parsePercent';
import toUpperHexString from '../../utils/toUpperHexString';
import ColorPreview from './ColorPreview';

const useStyles = makeStyles((theme) => ({
  hexAlphaPicker: {
    display: 'flex',
  },
  inputGroup: {
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(0.75),
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),

    '& > :not(:first-child)': {
      borderLeftWidth: 'inherit',
      borderLeftStyle: 'inherit',
      borderLeftColor: theme.palette.grey[400],
      transition: theme.transitions.create('border-color', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    '&:hover': {
      borderColor: theme.palette.grey[600],
    },
    '&:hover > :not(:first-child)': {
      borderColor: theme.palette.grey[600],
    },
    '&:focus-within': {
      borderColor: theme.palette.secondary.main,
    },
    '&:focus-within > :not(:first-child)': {
      borderColor: theme.palette.secondary.main,
    },
  },
  hexInput: {
    padding: theme.spacing(0.625, 1.75),
    height: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  percentInput: {
    padding: theme.spacing(0.625, 1.75),
    width: theme.spacing(4.5),
    height: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  hiddenSubmit: {
    display: 'none',
  },
}));

export default function HexAlphaPicker({ color, onChange }) {
  color = tinycolor(color);
  const hex = toUpperHexString(color);
  const alpha = color.getAlpha();
  const percent = alphaToPercent(alpha);
  const classes = useStyles();

  // state for input change events
  const [hexValue, setHexValue] = useState(hex);
  const [percentValue, setPercentValue] = useState(percent);

  useEffect(() => {
    setHexValue(hex);
    setPercentValue(percent);
  }, [hex, percent]);

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleHexChange = (event) => {
    setHexValue(event.target.value);
  };

  const handlePercentChange = (event) => {
    setPercentValue(event.target.value);
  };

  const handleBlur = () => {
    const alpha = parsePercent(percentValue) / 100;
    const newColor = tinycolor(hexValue).setAlpha(alpha);

    if (newColor.isValid() && inRange(alpha, 0, 1)) {
      onChange(newColor.toRgb());
      setHexValue(toUpperHexString(newColor));
      setPercentValue(alphaToPercent(alpha));
    } else {
      setHexValue(hex);
      setPercentValue(percent);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    document.activeElement.blur();
  };

  return (
    <form
      className={classes.hexAlphaPicker}
      spellCheck="false"
      onSubmit={handleFormSubmit}
    >
      <ColorPreview color={color} />
      <div className={classes.inputGroup}>
        <InputBase
          inputProps={{
            className: classes.hexInput,
            'aria-label': 'Color',
          }}
          onFocus={handleFocus}
          onChange={handleHexChange}
          onBlur={handleBlur}
          value={hexValue}
        />
        <InputBase
          inputProps={{
            className: classes.percentInput,
            'aria-label': 'Alpha',
          }}
          onFocus={handleFocus}
          onChange={handlePercentChange}
          onBlur={handleBlur}
          value={percentValue}
        />
      </div>
      <Button className={classes.hiddenSubmit} type="submit" />
    </form>
  );
}

HexAlphaPicker.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

HexAlphaPicker.defaultProps = {
  color: { r: 0, g: 0, b: 0, a: 1 },
  onChange: () => {},
};
