import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import AudioIcon from '@videoblocks/react-icons/Audio';
import ImageIcon from '@videoblocks/react-icons/Image';
import VideoIcon from '@videoblocks/react-icons/Video';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: theme.spacing(4),
    flexDirection: 'row',
  },
  tab: {
    display: 'inline-flex',
    padding: theme.spacing(0.5, 1),
    minWidth: 'auto',
    minHeight: 'auto',
    lineHeight: 28 / 16,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.pink[500],
    backgroundColor: theme.palette.grey[100],

    '&:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },

  labelIcon: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
  },
  wrapper: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export default function MediaTypeDisplay(props) {
  const { value = MediaTypes.VIDEO } = props;
  const classes = useStyles();

  const mediaTypes = {
    [MediaTypes.VIDEO]: { label: 'Video', icon: VideoIcon },
    [MediaTypes.AUDIO]: { label: 'Audio', icon: AudioIcon },
    [MediaTypes.IMAGE]: { label: 'Images', icon: ImageIcon },
  };
  const mediaType = mediaTypes[value];

  return (
    <div className={classes.tabs}>
      <div className={classes.tab}>
        <div className={classes.wrapper}>
          <SvgIcon
            className={classes.labelIcon}
            component={mediaType.icon}
            fontSize="inherit"
          />
        </div>
        {mediaType.label}
      </div>
    </div>
  );
}

MediaTypeDisplay.propTypes = {
  value: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]),
};
