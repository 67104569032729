import {
  Box,
  Button,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import BrowserCom from '@videoblocks/react-icons/BrowserCom';
import Cursor from '@videoblocks/react-icons/Cursor';
import VideoFileUpload from '@videoblocks/react-icons/VideoFileUpload';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import emptyStateImage from '../assets/images/projects-empty-state.png';
import { EDIT_PATH } from '../constants/Urls';

const useStyles = makeStyles((theme) => ({
  body: {
    lineHeight: theme.typography.pxToRem(28),
    background: 'transparent',
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 'auto', 4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      maxWidth: '75%',
    },
  },
  createNewButton: {
    flexShrink: 0,
    marginTop: theme.spacing(1.75),
    lineHeight: theme.typography.pxToRem(26),
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(1.75),
  },
  featureIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(24),
  },
  featureText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    background: 'transparent',
  },
  headline: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8),
      marginLeft: theme.spacing(0),
    },
  },
  tagline: {
    fontWeight: 600,
    letterSpacing: '0.14em',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(3),
  },
  textContainer: {
    color: theme.palette.grey[900],
    maxWidth: '50%',
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}));

export default function ProjectsEmptyState() {
  const classes = useStyles();
  const { search } = useLocation();

  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Typography className={classes.tagline}>
          CREATE VIDEOS WITH MAKER
        </Typography>
        <Typography component="h2" className={classes.headline}>
          Simplify video creation with Maker's easy editor
        </Typography>
        <Typography component="span" className={classes.body}>
          Integrated with Storyblocks’ video library to save you time, Maker
          simplifies and streamlines the video creation process for you and your
          team. Choose from a library of unlimited stock, upload your own
          content, and add customizations with one click.
        </Typography>
        <Box className={classes.feature}>
          <SvgIcon
            component={BrowserCom}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          <Typography component="span" className={classes.featureText}>
            Formats for any platform
          </Typography>
        </Box>
        <Box className={classes.feature}>
          <SvgIcon
            component={VideoFileUpload}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          <Typography component="span" className={classes.featureText}>
            Upload your own content
          </Typography>
        </Box>
        <Box className={classes.feature}>
          <SvgIcon
            component={Cursor}
            className={classes.featureIcon}
            fontSize="inherit"
          />
          <Typography component="span" className={classes.featureText}>
            Add customizations with a single click
          </Typography>
        </Box>
        <Button
          className={classes.createNewButton}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={{ pathname: EDIT_PATH, search }}
        >
          Start Your First Project
        </Button>
      </Box>
      <Box className={classes.imageContainer}>
        <img alt="Example project elements" src={emptyStateImage} />
      </Box>
    </Box>
  );
}
