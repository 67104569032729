import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import StylesForm from '../components/StylesForm';
import { NEW_BRAND_UID } from '../constants/StylesConstants';
import { BRANDS_PATH } from '../constants/Urls';
import { setBrandUid } from '../features/assetDrawer/assetDrawerSlice';
import { openBrandDeleteDialog } from '../features/ui/uiSlice';
import useBrand from '../hooks/useBrand';
import useQuery from '../hooks/useQuery';
import { selectIsOrgAdmin } from '../selectors/user';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0),
  },
  pageContent: {
    padding: theme.spacing(6, 4),
    backgroundColor: theme.palette.common.white,
    minHeight: '100vh',
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.typography.pxToRem(12),
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(40),
  },
}));

export default function BrandDetailContainer() {
  const classes = useStyles();
  const { brandUid, isNew } = useParams();
  const { brand } = useBrand(brandUid);
  const { search } = useLocation();
  const { flags } = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setBrandUid(brandUid));
  }, [brandUid, dispatch]);

  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const isNewBrand = !!isNew;

  function handleDelete() {
    dispatch(
      openBrandDeleteDialog({
        brandUid,
        onSuccess: () => {
          // Pass the brand name back so we can show it in a toast
          history.push({
            pathname: BRANDS_PATH,
            search,
            state: {
              toastMessage: `'${brand.detail.name}' Deleted`,
            },
          });
        },
      })
    );
  }

  const navigateToBrand = (brandUid, isNewBrand = false, isEditing = false) => {
    history.push({
      pathname: `${BRANDS_PATH}/${brandUid}${
        isNewBrand ? `/${NEW_BRAND_UID}` : ''
      }`,
      search: stringify(isEditing ? { flags, isEditing: true } : { flags }),
    });
  };

  return (
    <div className={classes.pageContent}>
      <Container className={classes.container} maxWidth="sm">
        <StylesForm
          disabled={!isOrgAdmin}
          isBrandForm={true}
          isDrawer={false}
          isNewBrand={isNewBrand}
          navigateToBrand={navigateToBrand}
          onDelete={handleDelete}
        />
      </Container>
    </div>
  );
}
