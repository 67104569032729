import { Box, Button, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import * as Sentry from '@sentry/browser';
import { Component } from 'react';

import { dsn } from '../config/sentry';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Box padding={2.5} textAlign="center">
          <Box paddingY={2}>
            <Typography variant="h1" component="div">
              <WarningIcon fontSize="inherit" color="error" />
            </Typography>
          </Box>
          <Typography variant="h5" component="h1" gutterBottom>
            Uh-oh! Something went wrong.
          </Typography>
          <Button
            color="secondary"
            onClick={() => Sentry.showReportDialog({ dsn })}
          >
            Report Feedback
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
