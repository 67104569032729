import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatAlignCenterRounded from '@material-ui/icons/FormatAlignCenterRounded';
import FormatAlignLeftRounded from '@material-ui/icons/FormatAlignLeftRounded';
import FormatAlignRightRounded from '@material-ui/icons/FormatAlignRightRounded';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  alignmentButtons: {
    display: 'flex',
    height: '40px',
    width: '100%',
    '& button': {
      flex: '1',
    },
  },
}));

function AlignmentPicker({ value, onChange }) {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <InputLabel id="text-alignment-group-label">Alignment</InputLabel>
      <ToggleButtonGroup
        className={classes.alignmentButtons}
        id="text-alignment-group"
        aria-labelledby="text-alignment-group-label"
        exclusive
        value={value}
        onChange={onChange}
      >
        <ToggleButton value="left" aria-label="left aligned">
          <FormatAlignLeftRounded />
        </ToggleButton>
        <ToggleButton value="center" aria-label="centered">
          <FormatAlignCenterRounded />
        </ToggleButton>
        <ToggleButton value="right" aria-label="right aligned">
          <FormatAlignRightRounded />
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  );
}

AlignmentPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AlignmentPicker;
