import {
  Card,
  CardActions,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz, Videocam, VisibilityOutlined } from '@material-ui/icons';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router';

import formatTimestamp from '../../utils/formatTimestamp';
import PaddedAspectBox from '../PaddedAspectBox';
import VideoPreview from '../VideoPreview';
import CardBadge from './CardBadge';

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    width: '100%',
  },
  createdBy: {
    color: theme.palette.grey[600],
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  preview: {
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    color: theme.palette.grey[800],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexBasis: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  titleBar: {
    padding: 0,
    justifyContent: 'space-between',
  },
}));

function TemplateCard(props) {
  const {
    template = {},
    onPreviewTemplateClick,
    onStartProjectClick,
    onEditClick,
    onDuplicateClick,
    onDeleteClick,
  } = props;
  const {
    name,
    storyboard,
    creator_full_name: creatorFullName,
    preview_url: previewUrl,
  } = template;

  const { templateId } = useParams();
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const duration = formatTimestamp(
    getStoryboardDuration(storyboard?.items?.entities),
    {
      padMinutes: false,
    }
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePreviewTemplateClick = () => {
    onPreviewTemplateClick();
  };

  const handleStartProjectClick = () => {
    onStartProjectClick(template.uid);
  };

  const handleEditClick = () => {
    onEditClick();
  };

  const handleDuplicateClick = () => {
    onDuplicateClick();
  };

  const handleDeleteClick = () => {
    onDeleteClick();
  };

  return (
    <Card className={classes.card} elevation={0}>
      {/* Template preview */}
      <PaddedAspectBox className={classes.preview} aspectRatio={16 / 9}>
        <CardBadge
          show={true}
          badgeContent={template.uid === templateId ? 'Now Editing' : duration}
          horizontal={template.uid === templateId ? 'left' : 'right'}
        >
          <VideoPreview className={classes.preview} source={previewUrl} />
        </CardBadge>
      </PaddedAspectBox>
      {/* Template info */}
      <>
        <CardActions className={classes.titleBar} disableSpacing>
          <Typography className={classes.title} variant="body1" noWrap>
            {name}
          </Typography>
          <div>
            {/* French Fry menu */}
            <IconButton
              aria-label="Template action menu"
              aria-controls="template-actions-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              id="template-actions-menu"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {/* Preview Template */}
              {onPreviewTemplateClick && (
                <MenuItem onClick={handlePreviewTemplateClick}>
                  <ListItemIcon>
                    <VisibilityOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Preview Template" />
                </MenuItem>
              )}
              {/* Create Project from Template */}
              {onStartProjectClick && (
                <MenuItem onClick={handleStartProjectClick}>
                  <ListItemIcon>
                    <Videocam fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Create Project from Template" />
                </MenuItem>
              )}
              {/* Edit Template */}
              {onEditClick && (
                <MenuItem onClick={handleEditClick}>
                  <ListItemIcon>
                    <EditRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit Template" />
                </MenuItem>
              )}
              {/* Duplicate */}
              {onDuplicateClick && (
                <MenuItem
                  onClick={() => {
                    handleDuplicateClick();
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <FileCopyRoundedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                </MenuItem>
              )}
              {/* Delete */}
              {onDeleteClick && (
                <MenuItem onClick={handleDeleteClick}>
                  <ListItemIcon>
                    <DeleteRoundedIcon color="error" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary="Delete"
                    primaryTypographyProps={{ color: 'error' }}
                  />
                </MenuItem>
              )}
            </Menu>
          </div>
        </CardActions>
        <Typography className={classes.createdBy} variant="body2">
          {creatorFullName}
        </Typography>
      </>
    </Card>
  );
}

TemplateCard.propTypes = {
  template: PropTypes.object,
  onPreviewTemplateClick: PropTypes.func,
  onStartProjectClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default TemplateCard;
