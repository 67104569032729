import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const PROGRESS_BAR_HEIGHT = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    height: PROGRESS_BAR_HEIGHT,
    borderRadius: PROGRESS_BAR_HEIGHT / 2,
    overflow: 'hidden',
    willChange: 'transform', // fixes border-radius issue in Safari
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: PROGRESS_BAR_HEIGHT / 2,
    backgroundColor: theme.palette.success.main,
  },
}));

export default function BorderLinearProgress(props) {
  const classes = useStyles();
  return <LinearProgress classes={classes} {...props} />;
}
