import { secondsToPixels } from './timelineUtils';

export const SNAPPING_TOLERANCE = 8;

function isInBounds(value, bound, tolerance) {
  const difference = value - bound;
  return difference >= 0 && difference <= tolerance;
}

/**
 * Returns the first snappable offset in passed array that is within the defined
 * tolerance of the current value.
 *
 * @param {LayerOffset[]} offsets
 * @param {number} current - current number to evaluate against
 * @param {SnappingOptions} options - set tolerance, direction, and an itemId to exclude
 * @returns {LayerOffset | null} Returns a layer offset if found, null if not
 */
export function findSnappableOffset(offsets = [], current, options = {}) {
  const {
    tolerance = SNAPPING_TOLERANCE,
    isLowerBound = false,
    isUpperBound = false,
    excludeId = null,
  } = options;

  const evaluator = ({ itemId, offset }) => {
    if (itemId === excludeId) {
      return false;
    }

    if (isLowerBound) {
      return isInBounds(offset, current, tolerance);
    }

    if (isUpperBound) {
      return isInBounds(current, offset, tolerance);
    }

    return Math.abs(offset - current) <= tolerance;
  };

  return offsets.find(evaluator);
}

/**
 * Returns an array of snappable offsets (in pixels) ordered by distance from current layer
 *
 * @param {Layer[]} layersWithItems - layers with startY specified and an items array included
 * @param {number} currentLayerIndex - anchor layer index used to measure relative layer distance
 * @param {number} zoom - zoom level, for timestamp to pixels conversion
 * @returns {LayerOffset[] | null}
 */
export function getOrderedOffsets(
  layersWithItems = [],
  currentLayerIndex = 0,
  zoom = 0
) {
  if (layersWithItems?.length < 1) {
    return null;
  }

  return layersWithItems
    .flatMap(
      (layer, index) =>
        layer.items?.map(({ id, startTime = 0, duration = 0 }) => {
          const layerDistance = Math.abs(index - currentLayerIndex);
          return [
            {
              itemId: id,
              timestamp: startTime,
              layerDistance,
            },
            {
              itemId: id,
              timestamp: startTime + duration,
              layerDistance,
            },
          ];
        }) || []
    )
    .flat(2)
    .sort((a, b) => a.layerDistance - b.layerDistance)
    .map((layerOffset) => ({
      ...layerOffset,
      offset: secondsToPixels(layerOffset.timestamp, zoom),
    }));
}

/**
 *
 * @param {LayerOffset[]} offsets
 * @param {Object} item - item with start and duration specified in pixels
 * @param {number} tolerance - pixel value for calculating if an item is within snapping distance
 * @returns {LayerOffset | null} Returns a snappable offset if found, else null
 */
export function getSnappableOffset(
  offsets,
  item,
  tolerance = SNAPPING_TOLERANCE
) {
  const { id: itemId, start, duration } = item;

  const snappableStart = findSnappableOffset(offsets, start, {
    excludeId: itemId,
    tolerance,
  });
  if (snappableStart) {
    return snappableStart;
  }

  const snappableEnd = findSnappableOffset(offsets, start + duration, {
    excludeId: itemId,
    tolerance,
  });
  if (snappableEnd) {
    return { ...snappableEnd, snapToEnd: true };
  }

  return null;
}
