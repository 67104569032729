import { Box, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VolumeFullIcon from '@videoblocks/react-icons/VolumeFull';
import VolumeOffIcon from '@videoblocks/react-icons/VolumeOff';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import AspectRatioSelect from '../../components/AspectRatioSelect';
import HotkeyLabel from '../../components/HotkeyLabel';
import PlayPauseButton from '../../components/PlayPauseButton';
import {
  MIN_PLAYBACK_CONTROLS_WIDTH,
  PREVIEW_MIN_WIDTH,
} from '../../constants/Constants';
import { PLAYBACK_CONTROLS } from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { EDITOR } from '../../events/tags';
import useRenderer from '../../hooks/useRenderer';
import { selectIsLoading, selectMute } from '../../slices/editorSlice';
import { toggleMute } from '../../slices/editorSlice';
import { selectIsOverMaxDuration } from '../../slices/storyboardSlice';
import formatTimestamp from '../../utils/formatTimestamp';
import { HOTKEYS } from '../../utils/hotkeys';

const useStyles = makeStyles((theme) => ({
  playbackControls: {
    margin: 'auto',
    userSelect: 'none',
    '& * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  timestamp: {
    fontFamily: 'sans-serif',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.25,
    color: theme.palette.grey[900],
  },
  muteButton: {
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.common.black,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  muted: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

export default function PlaybackControls(props) {
  const { width = PREVIEW_MIN_WIDTH } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMuted = useSelector(selectMute);
  const isLoading = useSelector(selectIsLoading);
  const isOverMaxDuration = useSelector(selectIsOverMaxDuration);
  const { timestamp, renderer } = useRenderer();
  const { projectDuration, playing } = renderer;

  const togglePlayPause = () => {
    trackEvent(playing ? EDITOR.PAUSE : EDITOR.PLAY);
    renderer.togglePlayPause();
  };

  const handleProjectToggleMute = () => {
    dispatch(toggleMute());
    trackEvent(isMuted ? EDITOR.UNMUTE : EDITOR.MUTE);
  };

  return (
    <div
      className={classes.playbackControls}
      style={{
        width: Math.max(width, MIN_PLAYBACK_CONTROLS_WIDTH),
      }}
      data-testid={PLAYBACK_CONTROLS}
    >
      <Box display="flex" alignItems="center" width="100%">
        <PlayPauseButton
          isLoading={isLoading}
          isPlaying={renderer?.playing}
          onClick={togglePlayPause}
        />
        <div className={classes.timestamp}>
          {formatTimestamp(timestamp)}
          {' / '}
          <Box
            component="span"
            color={isOverMaxDuration ? 'error.main' : 'inherit'}
          >
            {formatTimestamp(projectDuration)}
          </Box>
        </div>
        <Box flex={1}>{/* spacer element */}</Box>
        <AspectRatioSelect />
        <Tooltip
          title={
            <Box display="flex" alignItems="center" p={0.5}>
              {isMuted ? 'Unmute' : 'Mute'}
              <HotkeyLabel hotkey={HOTKEYS.MUTE.keys} ml={1} />
            </Box>
          }
        >
          <IconButton
            className={clsx(classes.muteButton, { [classes.muted]: isMuted })}
            onClick={handleProjectToggleMute}
            aria-label={isMuted ? 'unmute' : 'mute'}
          >
            <SvgIcon
              component={isMuted ? VolumeOffIcon : VolumeFullIcon}
              fontSize="inherit"
            />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
}
