import { DropShadow, MediaTypes } from '@videoblocks/jelly-renderer';

import { DEFAULT_HEADER_FONT, TEXT_VARIANT } from '../constants/FontStyles';
import { DEFAULT_ITEM_DURATION } from '../constants/Storyboard';
import createBaseObject from './createBaseObject';

/**
 * @param {Object} options
 * @returns {TextItem}
 */
export default function createTextObject(options = {}) {
  return createBaseObject({
    // base attributes
    duration: DEFAULT_ITEM_DURATION,
    mediaType: MediaTypes.TEXT,

    // text attributes
    anchor: { x: 0, y: 0 },
    animationPreset: options.animationPreset || 'cut',
    dropShadow: options.font?.dropShadow ?? DropShadow.NONE,
    fontSize: options.font?.fontSize ?? options.fontSize ?? 48,
    position: { x: 'center', y: 'center' },
    primaryColor: options.primaryTextColor ?? DEFAULT_HEADER_FONT.primaryColor,
    secondaryColor:
      options.secondaryTextColor ?? DEFAULT_HEADER_FONT.secondaryColor,
    highlightColor: options.highlightTextColor ?? null,
    style: {
      fontFamily: options.font?.family ?? DEFAULT_HEADER_FONT.family,
      fontWeight: options.font?.weight ?? DEFAULT_HEADER_FONT.weight,
      align: 'center',
    },
    text: options.text ?? 'Add Text Here',
    textVariant: options.textVariant ?? TEXT_VARIANT.BODY,
  });
}
