import { getStoryboardDuration, MediaTypes } from '@videoblocks/jelly-renderer';
import { upperFirst } from 'lodash';

import ProjectsAPI from '../api/ProjectsAPI';
import { EDIT_PATH } from '../constants/Urls';
import TelemetryClient from '../events/TelemetryClient';
import { selectEmail, selectUserId } from '../selectors/user';
import { selectWorkspaceProjectId } from '../selectors/workspace';
import {
  selectItemsByMediaType,
  selectName,
  selectRatio,
  selectStoryboard,
} from '../slices/storyboardSlice';
import store from '../store';

function copyToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy');
  } catch (error) {
    console.warn('Copy to clipboard failed.', error);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
}

window.makerSession = function makerSession() {
  const state = store.getState();
  const userId = selectUserId(state);
  const userEmail = selectEmail(state);
  const storyboard = selectStoryboard(state);
  const projectName = selectName(state);
  const projectId = selectWorkspaceProjectId(state);

  const info = {
    userId,
    userEmail,
    projectId,
    projectName,
    storyboard,
  };

  copyToClipboard(JSON.stringify(info, null, 2));

  return info;
};

window.storyboardInfo = function storyboardInfo() {
  const state = store.getState();
  const storyboard = selectStoryboard(state);
  const name = selectName(state);
  const ratio = selectRatio(state);
  const duration = `${getStoryboardDuration(storyboard.items.entities).toFixed(
    2
  )}s`;

  console.log('%c=== Storyboard ===', 'font-weight:bold');

  console.group('General Info');
  console.info('%cName:', 'color: #FF0089', name);
  console.info('%cRatio:', 'color: #FF0089', ratio);
  console.info('%cDuration:', 'color: #FF0089', duration);
  console.groupEnd();

  [
    MediaTypes.ANIMATION,
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.TEXT,
    MediaTypes.VIDEO,
  ].forEach((mediaType) => {
    const items = selectItemsByMediaType(state, mediaType);
    if (items.length > 0) {
      console.group(`${upperFirst(mediaType)} Items`);
      console.table(items);
      console.groupEnd();
    }
  });
};

function downloadJSON(filename, data) {
  const element = document.createElement('a');
  const json = JSON.stringify(data, null, 2);

  element.setAttribute(
    'href',
    `data:text/json;charset=utf-8,${encodeURIComponent(json)}`
  );
  element.setAttribute('download', `${filename}.json`);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

async function uploadJSON() {
  return new Promise((resolve) => {
    const element = document.createElement('input');
    element.setAttribute('type', 'file');
    element.setAttribute('accept', '.json');
    element.style.display = 'none';

    element.onchange = async () => {
      const file = element.files[0];
      const jsonString = await file.text();
      const json = JSON.parse(jsonString);
      resolve(json);
      document.body.removeChild(element);
    };

    document.body.appendChild(element);
    element.click();
  });
}

window.exportStoryboard = function exportStoryboard() {
  const state = store.getState();
  const storyboard = selectStoryboard(state);
  const name = selectName(state);
  downloadJSON(name, storyboard);
};

window.importStoryboard = async function importStoryboard() {
  const storyboard = await uploadJSON();
  const { project_id } = await new ProjectsAPI().postProject({
    storyboard,
  });
  window.location.href = `${EDIT_PATH}/${project_id}`;
};

window.enableEventLogging = function enableEventLogging() {
  console.info('Event Logging Enabled');
  TelemetryClient.setIsLoggingEnabled(true);
};

window.disableEventLogging = function enableEventLogging() {
  console.info('Event Logging Disabled');
  TelemetryClient.setIsLoggingEnabled(false);
};
