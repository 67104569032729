import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import log from 'loglevel';
import { string } from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { socialPlatformDisplayOrder } from '../../constants/SocialMedia';
import { PROJECTS_PATH } from '../../constants/Urls';
import useProject from '../../hooks/useProject';
import useToast from '../../hooks/useToast';
import theme from '../../styles/theme';
import Alert from '../Alert';
import AspectRatioToggle from '../AspectRatioToggle';
import SocialMediaFormats from '../SocialMediaFormats';
import Toast from '../Toast';

const useStyles = makeStyles((theme) => ({
  aspectRatioToggle: {
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(-1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  socialMediaLabel: {
    marginBottom: theme.spacing(-1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  divider: {
    border: `1px solid ${theme.palette.grey[500]}`,
  },
  duplicateProjectButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

export default function CrossPostCard({ eventLocation }) {
  const { projectId } = useParams();
  const { project, duplicateAndScaleProject } = useProject(projectId);
  const classes = useStyles();
  const [ratio, setRatio] = useState('');
  const [hoverRatio, setHoverRatio] = useState('');
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const { open, message, openToast, closeToast } = useToast();

  const handleRatioSelect = (event, value) => {
    const [newRatio] = value;
    if (ratio !== newRatio) {
      setHoverRatio('');
      setRatio(newRatio);
      setShowDuplicateError(false);
    }
  };

  const handleToggleButtonEnter = (event) => {
    const ratio = event.currentTarget.value;
    setHoverRatio(ratio);
  };

  const handleToggleButtonLeave = () => setHoverRatio('');

  const duplicateProjectWithNewAspectRatio = async () => {
    try {
      const duplicatedProject = await duplicateAndScaleProject({
        ratio,
        location: eventLocation,
      });

      openToast(
        `'${project.name}' has been duplicated as '${duplicatedProject.name}'`
      );

      setTimeout(() => {
        window.open(PROJECTS_PATH, '_blank', 'noopener');
      }, theme.transitions.duration.leavingScreen);
    } catch (error) {
      log.error(`Error duplicating project in ${ratio}: ${error?.message}`, {
        error,
      });
      setShowDuplicateError(true);
    }
  };

  return (
    <div className={classes.card}>
      <Toast open={open} severity="success" onClose={closeToast}>
        {message}
      </Toast>
      <Typography variant="h4">Cross-posting your video?</Typography>
      <div>Try duplicating your project in a new aspect ratio.</div>
      <label className={classes.label} id="aspect-ratio-label">
        Canvas Aspect Ratio
      </label>
      <AspectRatioToggle
        aria-labelledby="aspect-ratio-label"
        className={classes.aspectRatioToggle}
        onChange={handleRatioSelect}
        onToggleButtonEnter={handleToggleButtonEnter}
        onToggleButtonLeave={handleToggleButtonLeave}
        selectedRatio={ratio}
      />
      <Divider className={classes.divider} />
      <label
        className={classes.socialMediaLabel}
        id="social-media-formats-label"
      >
        This format is ideal for:
      </label>
      <SocialMediaFormats
        aria-labelledby="social-media-formats-label"
        platforms={socialPlatformDisplayOrder}
        ratio={hoverRatio || ratio}
      />
      {showDuplicateError ? (
        <Alert severity="error">
          {`There was an error while duplicating your project in ${ratio}.`}
        </Alert>
      ) : (
        <Button
          disabled={!ratio}
          variant="contained"
          className={classes.duplicateProjectButton}
          onClick={duplicateProjectWithNewAspectRatio}
        >
          {ratio ? `Duplicate Project in ${ratio}` : `Duplicate Project`}
        </Button>
      )}
    </div>
  );
}

CrossPostCard.propTypes = {
  eventLocation: string.isRequired,
};
