import {
  Button,
  Collapse,
  Container,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import GroupsIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import StoryblocksIcon from '@videoblocks/react-icons/Storyblocks';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';

import emptyStateImage from '../assets/images/templates-empty-state.png';
import Alert from '../components/Alert';
import LoaderContainer from '../components/LoaderContainer';
import TemplateMasonryGrid from '../components/TemplateMasonryGrid';
import TemplateTable from '../components/TemplateTable';
import TemplatesEmptyState from '../components/TemplatesEmptyState';
import Toast from '../components/Toast';
import { EDIT_TEMPLATE_PATH } from '../constants/Urls';
import { openToast } from '../features/ui/uiSlice';
import useTemplates from '../hooks/useTemplates';
import { selectIsOrgAdmin, selectOrganizationName } from '../selectors/user';

const MY_TEMPLATES = 'my-templates';
const TEAM_TEMPLATES = 'team-templates';
const STORYBLOCKS_TEMPLATES = 'storyblocks-templates';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  emptyGrid: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      minWidth: theme.spacing(52),
    },
  },
  emptyImage: {
    maxWidth: theme.spacing(83),
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(67),
      marginBottom: theme.spacing(8),
      marginLeft: 0,
    },
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0, 2),
  },
  subtitle: {
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    maxWidth: 800,
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0),
  },
  tabButton: {
    color: theme.palette.grey[600],
    borderRadius: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(28),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
  },
  tabButtonActive: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
  },
}));

export default function TemplatesContainer() {
  useTitle('Maker | Templates');
  const [currentTab, setCurrentTab] = useState(MY_TEMPLATES);
  const classes = useStyles();
  const history = useHistory();
  const { search, state: locationState } = useLocation();
  const dispatch = useDispatch();
  const {
    isLoading,
    moreTemplateResultsExist,
    myTemplates,
    orgTemplates,
    orgAgnosticTemplates,
    setTemplateTeamAccess,
    duplicateTemplate,
  } = useTemplates();

  const organizationName = useSelector(selectOrganizationName);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleTeamAccessToggle = (templateId, teamAccess, templateName) => {
    setTemplateTeamAccess(templateId, teamAccess);
    if (teamAccess) {
      setToastMessage(
        `'${templateName}' has been shared to the Team workspace.`
      );
    } else {
      setToastMessage(`'${templateName}' has been removed from team access.`);
    }
    setShowToast(true);
  };

  if (locationState?.toastMessage) {
    dispatch(openToast(locationState.toastMessage));
    history.replace({
      search,
      state: { toastMessage: null },
    });
  }

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const templatesToDisplay =
    currentTab === MY_TEMPLATES
      ? myTemplates
      : currentTab === TEAM_TEMPLATES
      ? orgTemplates
      : orgAgnosticTemplates;

  const handleCreateNewTemplate = () => {
    history.push({
      pathname: EDIT_TEMPLATE_PATH,
      search,
    });
  };

  const handleDuplicateTemplate = (template) => {
    duplicateTemplate(template);
    setCurrentTab(MY_TEMPLATES);
  };

  const hasTemplates = templatesToDisplay?.length > 0;

  return (
    <Container>
      <Toast open={showToast} severity="success" onClose={closeToast}>
        {toastMessage}
      </Toast>
      <div className={classes.pageHeader}>
        <Typography variant="h2" element="h1">
          Templates
        </Typography>
      </div>
      <Typography className={classes.subtitle}>
        <span>
          Create custom video templates to speed up and simplify your team's
          video production process. Anyone at{' '}
          <strong>{organizationName}</strong> can view and make a copy of a Team
          template.
        </span>
      </Typography>
      <div className={classes.tableActions}>
        <div>
          <Button
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: currentTab === MY_TEMPLATES,
            })}
            startIcon={<PersonIcon />}
            onClick={() => setCurrentTab(MY_TEMPLATES)}
          >
            My Templates
          </Button>
          <Button
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: currentTab === TEAM_TEMPLATES,
            })}
            startIcon={<GroupsIcon />}
            onClick={() => setCurrentTab(TEAM_TEMPLATES)}
          >
            Team Templates
          </Button>
          <Button
            className={clsx(classes.tabButton, {
              [classes.tabButtonActive]: currentTab === STORYBLOCKS_TEMPLATES,
            })}
            startIcon={
              <SvgIcon component={StoryblocksIcon} fontSize="inherit" />
            }
            onClick={() => setCurrentTab(STORYBLOCKS_TEMPLATES)}
          >
            Storyblocks Templates
          </Button>
        </div>
        {!isLoading && hasTemplates && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewTemplate}
          >
            Create New Template
          </Button>
        )}
      </div>
      <div>
        {isLoading && <LoaderContainer />}
        {!isLoading && currentTab === STORYBLOCKS_TEMPLATES && (
          <TemplateMasonryGrid />
        )}
        {!isLoading && currentTab !== STORYBLOCKS_TEMPLATES && hasTemplates && (
          <TemplateTable
            // No pagination currently according to the ticket MKRTMS-175
            currentPage={1}
            isTeamTemplatesTable={currentTab === TEAM_TEMPLATES}
            moreResultsExist={moreTemplateResultsExist}
            onTeamAccessToggle={handleTeamAccessToggle}
            templates={templatesToDisplay}
            setCurrentPage={() => {}}
            onDuplicate={handleDuplicateTemplate}
          />
        )}
        {!isLoading && !hasTemplates && (
          <>
            <Collapse in={currentTab === TEAM_TEMPLATES && !isOrgAdmin}>
              <Alert severity="info" shadow className={classes.alert}>
                Only Admins can create Team Templates.
              </Alert>
            </Collapse>
            <div className={classes.emptyGrid}>
              <TemplatesEmptyState
                showButton={isOrgAdmin || currentTab === MY_TEMPLATES}
              />
              <div className={classes.emptyImage}>
                <img alt="Example template elements" src={emptyStateImage} />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}
