import { makeStyles } from '@material-ui/core/styles';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { DND_ZONES } from '../../constants/Constants';
import { selectIsAtLayerLimit } from '../../slices/storyboardSlice';
import Droppable from '../Droppable';

const useStyles = makeStyles((theme) => ({
  newLayer: {
    height: theme.spacing(3),
    margin: theme.spacing(-1, 0),
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'padding'], {
      duration: 100,
    }),
    width: '100%',
    zIndex: 0,
    display: 'flex',
    padding: theme.spacing(0.25, 0),
    '&:first-child': {
      flexGrow: 1,
      alignItems: 'flex-end',
      height: 'auto',
    },
    '&:last-child': {
      flexGrow: 1,
      height: 'auto',
    },
  },
  isOver: {
    opacity: 1,
    zIndex: 1,
  },
  displayLine: {
    backgroundColor: ({ isAtLayerLimit }) =>
      isAtLayerLimit ? theme.palette.red[700] : theme.palette.green[500],
    borderRadius: theme.typography.pxToRem(16),
    height: theme.spacing(0.5),
    margin: theme.spacing(1, 0),
    width: '100%',
  },
}));

const NewLayerPlaceholder = forwardRef((props, ref) => {
  const { index = 0 } = props;
  const layerId = nanoid();

  const isAtLayerLimit = useSelector(selectIsAtLayerLimit);
  const classes = useStyles({ isAtLayerLimit });

  const DROPPABLE_ID = `add-layer-${index}`;

  return (
    <Droppable
      className={classes.newLayer}
      id={DROPPABLE_ID}
      isOverClassName={classes.isOver}
      data={{ layerIndex: index, layerId, zone: DND_ZONES.TIMELINE }}
      ref={ref}
    >
      <div className={classes.displayLine} />
    </Droppable>
  );
});

NewLayerPlaceholder.propTypes = {
  index: PropTypes.number,
};

export default NewLayerPlaceholder;
