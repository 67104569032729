import { useDraggable } from '@dnd-kit/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  draggableContainer: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'grab'),
    opacity: ({ isDragging }) => (isDragging ? 0.4 : 1),
    visibility: ({ hideOnDrag, isDragging }) =>
      hideOnDrag && isDragging ? 'hidden' : 'visible',
  },
}));

export default function Draggable(props) {
  const {
    children,
    className,
    data = {},
    disabled = false,
    element: Element = 'div',
    hideOnDrag = false,
    id = 'draggable',
    ...rest
  } = props;

  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    id,
    data,
    disabled,
  });

  const classes = useStyles({ disabled, hideOnDrag, isDragging });

  return (
    <Element
      className={clsx(classes.draggableContainer, className)}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...rest}
    >
      {children}
    </Element>
  );
}

Draggable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  element: PropTypes.string,
  hideOnDrag: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
