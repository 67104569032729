import { combineReducers } from 'redux';
import undoable, { excludeAction } from 'redux-undo';

import assetDrawer from '../features/assetDrawer/assetDrawerSlice';
import flags from '../features/flags/slice';
import ui from '../features/ui/uiSlice';
import editor from '../slices/editorSlice';
import storyboard, {
  fontRemoved,
  textItemUpdated,
} from '../slices/storyboardSlice';
import user from './user';
import workspace from './workspace';

const textItemUpdatedType = textItemUpdated().type;
const fontRemovedType = fontRemoved().type;

const storyboardUndoOptions = {
  limit: 10,
  filter: excludeAction([fontRemovedType]),
  groupBy: (action) => {
    if (action.type !== textItemUpdatedType) {
      return null;
    }

    return action.payload?.changes?.text != null ? true : null;
  },
};

const rootReducer = combineReducers({
  user,
  editor,
  flags,
  storyboard: undoable(storyboard, storyboardUndoOptions),
  assetDrawer,
  ui,
  workspace,
});

export default rootReducer;
