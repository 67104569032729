import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';

import { EDIT_PATH, HOME_PATH } from '../../constants/Urls';
import MakerHomeButton from './MakerHomeButton';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  backButton: {
    padding: theme.spacing(1, 2),
    color: 'inherit',
    fontSize: 'inherit',
  },
}));

function DefaultNavbar(props) {
  const { isExportPage, isDetailPage } = props;
  const { projectId, listType } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const capitalizeFirstLetter = (string = '') => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  let pathBack;
  let backButtonText;
  if (isExportPage) {
    pathBack = `${EDIT_PATH}/${projectId}`;
    backButtonText = 'Back to Editor';
  } else if (isDetailPage) {
    pathBack = `/${listType}`;
    backButtonText = `Back to ${capitalizeFirstLetter(listType)}`;
  } else {
    pathBack = HOME_PATH;
    backButtonText = 'Home';
  }

  return (
    <Navbar>
      <Box display="flex" alignItems="center" marginRight="auto">
        <MakerHomeButton />
        <Button
          className={classes.backButton}
          startIcon={<ChevronLeftRoundedIcon />}
          component={RouterLink}
          to={{
            pathname: pathBack,
            search: history.location.search,
          }}
        >
          {backButtonText}
        </Button>
      </Box>
    </Navbar>
  );
}

DefaultNavbar.propTypes = {
  isExportPage: PropTypes.bool,
  isDetailPage: PropTypes.bool,
};

DefaultNavbar.defaultProps = {
  isExportPage: false,
  isDetailPage: false,
};

export default DefaultNavbar;
