import queryString from 'query-string';

import { MEDIA_TYPE_TO_SEARCH_TYPE } from '../constants/SearchTypes';
import { SB_API_URL } from '../constants/Urls';
import AbstractAPI from './AbstractAPI';

export default class StockAPI extends AbstractAPI {
  constructor(contentToken) {
    super(false, { token: contentToken }, false);
  }

  getBaseURL() {
    return SB_API_URL;
  }

  paramsSerializer(params) {
    return queryString.stringify(params, { arrayFormat: 'comma' });
  }

  getStockItemById(mediaType, stockItemId) {
    return this.get(
      `${MEDIA_TYPE_TO_SEARCH_TYPE[mediaType]}/stock-item/details/${stockItemId}`
    );
  }
}
