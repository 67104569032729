import { useEffect, useState } from 'react';

/**
 * @typedef {Object} VideoMetadata
 * @property {number} duration
 * @property {number} videoWidth
 * @property {number} videoHeight
 */

/**
 * Provide metadata about the given video source.
 *
 * @param {string} source
 * @returns {VideoMetadata|null}
 */
export default function useVideoMetadata(source) {
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    if (!source) {
      return;
    }

    const video = document.createElement('video');
    video.volume = 0;
    video.crossOrigin = 'anonymous';
    function onLoadedMetadata() {
      video.removeEventListener('loadedmetadata', onLoadedMetadata);
      setMetadata({
        duration: video.duration,
        videoWidth: video.videoWidth,
        videoHeight: video.videoHeight,
      });
    }
    video.addEventListener('loadedmetadata', onLoadedMetadata);
    video.src = source;
  }, [source]);

  return metadata;
}
