import DeleteIcon from '@videoblocks/react-icons/Bin';
import DuplicateIcon from '@videoblocks/react-icons/FileDouble';
import TrimIcon from '@videoblocks/react-icons/Scissors';
import SplitIcon from '@videoblocks/react-icons/VideoEditSplit';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SOURCE_TYPES } from '../../constants/Constants';
import * as MediaActions from '../../constants/MediaActions';
import { trackEvent } from '../../events/sendEvents';
import { TOOLBAR } from '../../events/tags';
import { openTrimDialog } from '../../features/ui/uiSlice';
import useActiveItem from '../../hooks/useActiveItem';
import useRenderer from '../../hooks/useRenderer';
import useUploads from '../../hooks/useUploads';
import {
  itemDuplicated,
  itemRemoved,
  itemSplit,
  selectActiveItemId,
  selectAllLayers,
} from '../../slices/storyboardSlice';
import { HOTKEYS } from '../../utils/hotkeys';
import { checkMediaItem } from '../../utils/mediaUtils';
import ToolbarButton from './ToolbarButton';

export default function ItemControls() {
  const dispatch = useDispatch();
  const activeItem = useActiveItem();
  const { timestamp } = useRenderer();
  const { disassociateUpload } = useUploads();
  const { projectId: projectUid, templateId: templateUid } = useParams();

  const { isAudio, isVideo } = checkMediaItem(activeItem);
  const activeItemId = useSelector(selectActiveItemId);
  const layers = useSelector(selectAllLayers);

  const canTrim = isAudio || isVideo;

  const disableItemControls = isEmpty(activeItem);

  const disableDelete = disableItemControls;
  const disableDuplicate = disableItemControls;
  const disableSplit = disableItemControls;
  const disableTrim = disableItemControls || !canTrim;

  const handleDelete = useCallback(async () => {
    dispatch(itemRemoved(activeItemId));
    const itemLayer = layers.find((layer) =>
      layer.itemIds.includes(activeItemId)
    );
    trackEvent(TOOLBAR.ITEM_DELETE, {
      ...activeItem,
      layerDeleted: (itemLayer?.itemIds.length || 0) <= 1,
    });
    if (activeItem.itemSourceType === SOURCE_TYPES.UPLOAD) {
      await disassociateUpload({
        uploadId: activeItem.itemSourceId,
        templateUid,
        projectUid,
      });
    }
  }, [
    dispatch,
    activeItemId,
    layers,
    activeItem,
    projectUid,
    disassociateUpload,
    templateUid,
  ]);

  const handleDuplicate = useCallback(() => {
    dispatch(itemDuplicated({ id: activeItem.id, newItemId: nanoid() }));
    trackEvent(TOOLBAR.ITEM_DUPLICATE, activeItem);
  }, [activeItem, dispatch]);

  const handleSplit = useCallback(() => {
    dispatch(
      itemSplit({ id: activeItem.id, time: timestamp, newItemId: nanoid() })
    );
    trackEvent(TOOLBAR.ITEM_SPLIT, {
      id: activeItem.id,
      mediaType: activeItem.mediaType,
      splitTime: timestamp,
      originalDuration: activeItem.duration,
      originalItemId: activeItem.id,
    });
  }, [activeItem, timestamp, dispatch]);

  const handleTrim = useCallback(() => {
    dispatch(
      openTrimDialog({ media: activeItem, action: MediaActions.UPDATE })
    );
    trackEvent(TOOLBAR.ITEM_TRIM_MODAL_OPEN, activeItem);
  }, [activeItem, dispatch]);

  return (
    <div>
      <ToolbarButton
        title="Delete"
        hotkey={HOTKEYS.DELETE.keys}
        icon={DeleteIcon}
        disabled={disableDelete}
        onClick={handleDelete}
      />
      <ToolbarButton
        title="Trim"
        hotkey={HOTKEYS.TRIM.keys}
        icon={TrimIcon}
        disabled={disableTrim}
        onClick={handleTrim}
      />
      <ToolbarButton
        title="Split"
        hotkey={HOTKEYS.SPLIT.keys}
        icon={SplitIcon}
        disabled={disableSplit}
        onClick={handleSplit}
      />
      <ToolbarButton
        title="Duplicate"
        hotkey={HOTKEYS.DUPLICATE.keys}
        icon={DuplicateIcon}
        disabled={disableDuplicate}
        onClick={handleDuplicate}
      />
    </div>
  );
}
