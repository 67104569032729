import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'flags',
  initialState: {},
  reducers: {
    setFlags: (state, action) => Object.assign(state, action.payload),
  },
});

export const { setFlags } = slice.actions;

export default slice.reducer;
