import { useDndContext } from '@dnd-kit/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { nanoid } from '@reduxjs/toolkit';
import { LayerTypes } from '@videoblocks/jelly-renderer';
import AudioIcon from '@videoblocks/react-icons/Audio';
import TextStyleIcon from '@videoblocks/react-icons/TextStyle';
import VideoIcon from '@videoblocks/react-icons/Video';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  DND_ZONES,
  LAYER_HEIGHT,
  LAYER_TALL_HEIGHT,
  OFF_SCREEN,
} from '../../constants/Constants';
import {
  DRAWERS,
  openAssetDrawer,
} from '../../features/assetDrawer/assetDrawerSlice';
import { checkLayerType } from '../../utils/mediaUtils';
import Droppable from '../Droppable';
import Pulser from '../Pulser';
import ItemPlaceholder from './ItemPlaceholder';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    position: 'relative',
    height: LAYER_HEIGHT,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[800], 0.5),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    height: '100%',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'currentColor',
    borderRadius: 'inherit',
    cursor: 'pointer',
    opacity: 0.5,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      opacity: 0.8,
      backgroundColor: alpha(theme.palette.common.white, 0.1),
    },
  },
  over: {
    opacity: 0,
  },
  icon: {
    marginRight: theme.spacing(2),
    width: theme.spacing(2),
    height: theme.spacing(2),
    fill: 'currentColor',
  },
  placeholderItem: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    height: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'currentColor',
    borderRadius: 'inherit',
    backgroundColor: alpha(theme.palette.green[400], 0.4),
  },
}));

function EmptyLayerPlaceholder(props) {
  const { index, layerType, placeholderOffset = OFF_SCREEN } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { active, over } = useDndContext();
  const item = active?.data?.current?.item || {};

  const { isAudio, isText, isVideo } = checkLayerType(layerType);

  const id = `${layerType}-placeholder`;

  const isOver = over?.id === id;

  const handleClick = () => {
    if (isText) {
      dispatch(openAssetDrawer({ drawer: DRAWERS.TEXT }));
    } else {
      dispatch(
        openAssetDrawer({ drawer: DRAWERS.STOCK, mediaType: layerType })
      );
    }
  };

  const shouldRenderPlaceholder = useMemo(
    () => isOver && placeholderOffset !== OFF_SCREEN,
    [isOver, placeholderOffset]
  );

  const style = { height: isVideo ? LAYER_TALL_HEIGHT : LAYER_HEIGHT };

  return (
    <Droppable
      className={classes.placeholder}
      id={id}
      data={{ layerIndex: index, layerId: nanoid(), zone: DND_ZONES.TIMELINE }}
      element="button"
      style={style}
      onClick={handleClick}
    >
      <div className={clsx(classes.content, isOver && classes.over)}>
        {isAudio && (
          <>
            <AudioIcon className={classes.icon} /> Add Audio
          </>
        )}
        {isText && (
          <>
            <TextStyleIcon className={classes.icon} /> Add Text
          </>
        )}
        {isVideo && (
          <>
            <VideoIcon className={classes.icon} /> Add Video
          </>
        )}
      </div>
      {isOver && <Pulser />}
      {shouldRenderPlaceholder && (
        <ItemPlaceholder duration={item.duration} offset={placeholderOffset} />
      )}
    </Droppable>
  );
}

EmptyLayerPlaceholder.propTypes = {
  index: PropTypes.number,
  layerType: PropTypes.oneOf(Object.values(LayerTypes)),
  placeholderOffset: PropTypes.number,
};

export default memo(EmptyLayerPlaceholder);
