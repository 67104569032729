import { useState } from 'react';

/**
 * @typedef {Object} useToastOptions
 * @property {boolean} open
 * @property {string} message
 */

/**
 * @typedef {Object} ToastControls
 * @property {boolean} open
 * @property {string} message
 * @property {(message: string) => void} openToast
 * @property {(event, reason: string) => void} closeToast
 */

/**
 * @param {useToastOptions} options
 * @returns {ToastControls}
 */
const useToast = (options) => {
  const [open, setOpen] = useState(options?.open || false);
  const [message, setMessage] = useState(options?.message || '');
  const [severity, setSeverity] = useState('info');

  const openToast = (message, severity = 'info') => {
    setSeverity(severity);
    setOpen(true);
    setMessage(message);
  };

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage('');
  };

  return { open, message, openToast, closeToast, severity };
};

export default useToast;
