import { MediaTypes, NativeMediaControls } from '@videoblocks/jelly-renderer';
import { compact, reduce } from 'lodash';
import log from 'loglevel';

import StockAPI from '../api/StockAPI';

/**
 * @typedef {Object} Query
 * @property {number|number[]} [videoIds]
 * @property {number|number[]} [audioIds]
 * @property {number|number[]} [imageIds]
 */

/**
 * @typedef {Object} MediaItem
 * @property {number} id
 * @property {string} mediaType
 * @property {string} [preview_url]
 * @property {number} [duration]
 */

/**
 * Extracts an array of stock media items from a query object
 *
 * @param {Query} query - A string param
 * @return {MediaItem[]} An array of stock media items
 */
export function extractMediaItemsFromQuery(query = {}) {
  return reduce(
    {
      [MediaTypes.VIDEO]: query.videoIds,
      [MediaTypes.AUDIO]: query.audioIds,
      [MediaTypes.IMAGE]: query.imageIds,
    },
    (result, ids, mediaType) => {
      if (ids) {
        const mediaItems = [].concat(ids).map((id) => ({ id, mediaType }));
        result = result.concat(mediaItems);
      }
      return result;
    },
    []
  );
}

/**
 * Fetches details from the /details API for an array of stock media items
 *
 * @param {MediaItem[]} items - A string param
 * @param {string} token - API token
 * @return {MediaItem[]} An array of detailed stock media items
 */
export async function fetchStockItemDetails(items, token) {
  return compact(
    await Promise.all(items.map((item) => _fetchStockItemDetails(item, token)))
  );
}

/**
 * @param {MediaItem} item
 * @param {string} token
 * @return {MediaItem}
 */
async function _fetchStockItemDetails(item, token) {
  const { id, mediaType } = item;

  try {
    const data = await new StockAPI(token).getStockItemById(mediaType, id);

    // video returns an object of preview_urls rather than a single preview_url
    if (!data.preview_url) {
      data.preview_url = data.preview_urls._720p || data.preview_urls._480p;
    }

    // the API returns duration in seconds (not ms), so we load the media
    // in the background to get a more accurate duration before proceeding
    if (data.duration) {
      data.duration = await _loadActualMediaDuration(data.preview_url);
    }

    return { ...data, mediaType };
  } catch (error) {
    log.error('Could not get stock item info', { error, item });
    return;
  }
}

/**
 * @param {string} source
 * @return {number}
 */
async function _loadActualMediaDuration(source) {
  // preloadOffscreenVideo works for calculating duration on audio too
  const media = await NativeMediaControls.preloadOffscreenVideo(source);
  return media.duration;
}
