import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpwardRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import SaveIcon from '@material-ui/icons/Save';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import CustomTemplatesAPI from '../api/CustomTemplatesAPI';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  editButton: {
    visibility: 'hidden',
  },
  header: {
    padding: theme.spacing(1, 0, 2),
  },
  item: {
    '&:hover $editButton': {
      visibility: 'visible',
    },
  },
  itemNumber: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.grey[300],
    display: 'inline-block',
    borderRadius: 10,
    fontWeight: 700,
  },
}));

export default function TemplatesList({ templates: templatesToReorder }) {
  const classes = useStyles();

  const [state, setState] = useState({
    canSave: false,
    templates: [],
  });

  useEffect(() => {
    setState({ templates: templatesToReorder, canSave: false });
  }, [templatesToReorder]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const sendToTop = (index) => {
    setState({
      canSave: true,
      templates: reorder(state.templates, index, 0),
    });
  };

  const sendToBottom = (index) => {
    setState({
      canSave: true,
      templates: reorder(state.templates, index, state.templates.length - 1),
    });
  };

  const handleSave = async () => {
    const ids = state.templates.map((template) => template.uid);
    await new CustomTemplatesAPI().reorderTemplates(ids);
    setState({ ...state, canSave: false });
  };

  return (
    <Box m={2}>
      <Typography variant="h4" component="h2" className={classes.header}>
        Manage or Reorder
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<SaveIcon />}
        onClick={handleSave}
        disabled={!state.canSave}
      >
        {state.canSave ? 'Save Changes' : 'Saved'}
      </Button>

      <List>
        {state.templates.map((template, index) => {
          const { uid, name, published_at: publishedAt } = template;
          const isFirst = index === 0;
          const isLast = index === state.templates.length - 1;

          return (
            <ListItem
              className={classes.item}
              disabled={!publishedAt}
              key={uid}
            >
              <span className={classes.itemNumber}>{index + 1}</span>
              <ListItemText primary={name} />
              {!isFirst && (
                <Tooltip title="Send To Top">
                  <IconButton
                    className={classes.editButton}
                    aria-label="send to top"
                    onClick={() => sendToTop(index)}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!isLast && (
                <Tooltip title="Send To Bottom">
                  <IconButton
                    className={classes.editButton}
                    aria-label="send to bottom"
                    onClick={() => sendToBottom(index)}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Edit">
                <IconButton
                  className={classes.editButton}
                  aria-label="edit"
                  component={RouterLink}
                  to={`/admin/template/${uid}`}
                >
                  <EditRoundedIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
