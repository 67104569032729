import makeStyles from '@material-ui/core/styles/makeStyles';
import { isEmpty } from 'lodash';

import { PROPERTIES_PANEL_WIDTH } from '../../constants/Constants';
import { PROPERTIES_PANEL } from '../../constants/IntercomSelectors';
import useActiveItem from '../../hooks/useActiveItem';
import { checkMediaItem } from '../../utils/mediaUtils';
import AnimationOverlayPropertiesPanel from './AnimationOverlayPropertiesPanel';
import AudioPropertiesPanel from './AudioPropertiesPanel';
import ImagePropertiesPanel from './ImagePropertiesPanel';
import TextPropertiesPanel from './TextPropertiesPanel';
import VideoPropertiesPanel from './VideoPropertiesPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: PROPERTIES_PANEL_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closed: {
    overflowX: 'hidden',
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export default function PropertiesPanel() {
  const activeItem = useActiveItem();
  const classes = useStyles();

  const { isAnimation, isAudio, isImage, isText, isVideo } =
    checkMediaItem(activeItem);

  // properties panel should be open if there is a selected element
  const isOpen = !isEmpty(activeItem);

  return (
    <div
      className={isOpen ? classes.root : classes.closed}
      data-intercom-target={PROPERTIES_PANEL}
    >
      {isImage && <ImagePropertiesPanel />}
      {isAudio && <AudioPropertiesPanel />}
      {isText && <TextPropertiesPanel />}
      {isAnimation && <AnimationOverlayPropertiesPanel />}
      {isVideo && <VideoPropertiesPanel />}
    </div>
  );
}
