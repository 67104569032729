import {
  Box,
  Button,
  InputLabel,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import tinycolor from 'tinycolor2';

import toUpperHexString from '../utils/toUpperHexString';
import ColorPickerPopover from './colorPicker/ColorPickerPopover';

export default function StylesFormColorEditor({
  disabled = false,
  editMode = false,
  color,
  onColorChange = () => {},
  label = '',
  tooltip = '',
  classes, // use classes from parent
}) {
  return (
    <>
      <div className={classes.inputLabelGroup}>
        <InputLabel
          className={classes.inputLabel}
          htmlFor={`${label}-color-picker`}
        >
          {label}
        </InputLabel>
        <Tooltip title={tooltip}>
          <SvgIcon
            className={classes.infoTooltip}
            component={QuestionCircleIcon}
          />
        </Tooltip>
      </div>
      <Box mt={1} mb={1}>
        {editMode ? (
          <ColorPickerPopover
            disabled={disabled}
            color={color}
            onChange={onColorChange}
            id={`${label}-color-picker`}
          />
        ) : (
          <>
            <Button
              disabled={true}
              className={classes.viewOnlySwatch}
              style={{
                backgroundColor: tinycolor(color).toRgbString(),
              }}
            />
            <Typography variant="button" className={classes.swatchLabel}>
              {toUpperHexString(color)}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}
