import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import ExpandIcon from './ExpandIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

function PropertiesSelect(props) {
  const { id, label, options, hiddenOptions = [], value, ...rest } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        displayEmpty
        color="secondary"
        classes={{ root: classes.root }}
        IconComponent={(props) => (
          <SvgIcon
            fontSize="small"
            className={clsx(props.className, classes.icon)}
            component={ExpandIcon}
          />
        )}
        input={<OutlinedInput className={classes.inputRoot} />}
        value={value}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
        {hiddenOptions.map((option) => (
          <MenuItem
            className={classes.hidden}
            value={option.value}
            key={`${option.value}--hidden`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default PropertiesSelect;
