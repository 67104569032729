import RedoIcon from '@videoblocks/react-icons/Redo';
import UndoIcon from '@videoblocks/react-icons/Undo';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import { trackEvent } from '../../events/sendEvents';
import { TOOLBAR } from '../../events/tags';
import { selectCanUndo, selectCanRedo } from '../../slices/storyboardSlice';
import { HOTKEYS } from '../../utils/hotkeys';
import ToolbarButton from './ToolbarButton';

export default function UndoRedoControls() {
  const dispatch = useDispatch();
  const canRedo = useSelector(selectCanRedo);
  const canUndo = useSelector(selectCanUndo);

  const handleRedo = useCallback(() => {
    if (canRedo) {
      dispatch(UndoActionCreators.redo());
      trackEvent(TOOLBAR.REDO);
    }
  }, [canRedo, dispatch]);

  const handleUndo = useCallback(() => {
    if (canUndo) {
      dispatch(UndoActionCreators.undo());
      trackEvent(TOOLBAR.UNDO);
    }
  }, [canUndo, dispatch]);

  return (
    <div>
      <ToolbarButton
        disabled={!canUndo}
        title="Undo"
        hotkey={HOTKEYS.UNDO.keys}
        icon={UndoIcon}
        onClick={handleUndo}
      />
      <ToolbarButton
        disabled={!canRedo}
        title="Redo"
        hotkey={HOTKEYS.REDO.keys}
        icon={RedoIcon}
        onClick={handleRedo}
      />
    </div>
  );
}
