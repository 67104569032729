import PropTypes from 'prop-types';
import { memo, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';

import useHasEverBeenInView from '../hooks/useHasEverBeenInView';
import useVideoMetadata from '../hooks/useVideoMetadata';
import FilmStripCachedThumbnail from './FilmStripCachedThumbnail';

/**
 * FilmStrip creates a static 2d representation of a video
 * by drawing video thumbnail images side by side
 */
function FilmStrip(props) {
  const { width = 0, height = 0, source, onError } = props;

  const elementRef = useRef(null);
  // Wait for the filmstrip to come into view before rendering thumbnails.
  // Note that once we render the thumbnails we don't remove them.
  // This offers better UX in most cases, though can lead to browser crashes for very long projects, e.g. 20 mins.
  // An improvement to this approach could be to preload a few moments in the future so the filmstrip isn't
  // loading when automatically advancing during playback.
  const hasEverBeenInView = useHasEverBeenInView(elementRef, {
    // Ensure the filmstrip has been in view for 200ms so we don't kick off a
    // ton of network requests if they are scrolling quickly through the timeline.
    forAtLeastMs: 200,
  });

  const metadata = useVideoMetadata(hasEverBeenInView ? source : null);
  const [thumbnails, setThumbnails] = useState([]);

  useAsyncEffect(
    async (isMounted) => {
      if (!isMounted || !metadata || !width || !height) return;

      setThumbnails(calculateThumbnails(metadata, width, height));
    },
    [metadata, width, height]
  );

  const containerStyle = {
    width,
    height,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
  };

  return (
    <div ref={elementRef} style={containerStyle}>
      {thumbnails.map((thumbnail) => (
        <FilmStripCachedThumbnail
          src={source}
          position={thumbnail.position}
          width={thumbnail.width}
          height={thumbnail.height}
          key={source + thumbnail.position}
          onError={onError}
        />
      ))}
    </div>
  );
}

function getThumbnailDimensions(metadata, stripHeight) {
  const { videoWidth, videoHeight } = metadata;
  const aspectRatio = videoWidth / videoHeight;

  return {
    thumbnailWidth: stripHeight * aspectRatio,
    thumbnailHeight: stripHeight,
  };
}

export function calculateThumbnails(metadata, stripWidth, stripHeight) {
  const secondsPerPixel = metadata.duration / stripWidth;

  const { thumbnailWidth, thumbnailHeight } = getThumbnailDimensions(
    metadata,
    stripHeight
  );
  const thumbnails = [];

  for (let position = 0; position < stripWidth; position += thumbnailWidth) {
    thumbnails.push({
      position: position * secondsPerPixel,
      width: thumbnailWidth,
      height: thumbnailHeight,
    });
  }
  return thumbnails;
}

FilmStrip.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  source: PropTypes.string,
  onError: PropTypes.func,
};

export default memo(FilmStrip);
