import { Button, OutlinedInput as Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/CreateRounded';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  DEFAULT_TEMPLATE_NAME,
  DEFAULT_PROJECT_NAME,
} from '../../constants/Storyboard';
import { trackEvent } from '../../events/sendEvents';
import { PROJECT } from '../../events/tags';
import { selectIsProjectLoaded } from '../../selectors/workspace';
import { nameUpdated, selectName } from '../../slices/storyboardSlice';

const useStyles = makeStyles((theme) => ({
  projectNameInput: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: theme.spacing(24),
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(32),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(40),
    },
  },
  input: {
    fontSize: theme.typography.pxToRem(14),
    width: theme.spacing(24),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(32),
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(40),
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default function ProjectNameInput() {
  const name = useSelector(selectName);
  const isProjectLoaded = useSelector(selectIsProjectLoaded);
  const { projectId, templateId } = useParams();
  const dispatch = useDispatch();

  const [value, setValue] = useState(name);
  const [editing, setEditing] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setValue(name);
  }, [name]);

  const save = () => {
    setEditing(false);

    const fallbackName =
      (!!templateId && DEFAULT_TEMPLATE_NAME) ||
      (!!projectId && DEFAULT_PROJECT_NAME) ||
      '';
    const newName = value || fallbackName;

    if (newName !== name) {
      dispatch(nameUpdated(newName));
      trackEvent(PROJECT.EDIT, { name: newName });
    }
    setValue(newName);
  };

  const handleEdit = () => setEditing(true);

  const handleFocus = (event) => event.target.select();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setValue(name);
      setEditing(false);
    }
  };

  const handleBlur = () => save();

  const handleSubmit = (event) => {
    event.preventDefault();
    save();
  };

  if (editing) {
    return (
      <form className={classes.projectNameInput} onSubmit={handleSubmit}>
        <Input
          className={classes.input}
          color="secondary"
          margin="dense"
          variant="outlined"
          autoFocus
          spellCheck={false}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          value={value}
        />
      </form>
    );
  }

  return (
    <div className={classes.projectNameInput}>
      <Button
        color="inherit"
        size="small"
        onClick={handleEdit}
        endIcon={<CreateIcon />}
      >
        <span className={classes.textTruncate}>
          {isProjectLoaded ? name : ''}
        </span>
      </Button>
    </div>
  );
}
