import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import { useSelector } from 'react-redux';

import { DND_ZONES, SOURCE_TYPES } from '../../constants/Constants';
import {
  AudioFilters,
  CommonFilters,
  ImageFilters,
} from '../../constants/SearchFilters';
import * as SearchTypes from '../../constants/SearchTypes';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  selectContentType,
  selectFilters,
  selectKeywords,
  selectMediaType,
} from '../../features/assetDrawer/assetDrawerSlice';
import useStock from '../../hooks/useStock';
import AddIconButton from '../AddIconButton';
import Draggable from '../Draggable';
import LoadMoreButton from '../LoadMoreButton';
import MediaPreview from '../MediaPreview';
import PositionWrapper, { Positions } from '../PositionWrapper';
import AssetDrawerHeader from './AssetDrawerHeader';
import DragPreview from './DragPreview';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    overflowY: 'scroll',
  },
  itemWrapper: {
    '&:not(:hover) .add-button': {
      display: 'none',
    },
  },
}));

const getFilterParams = (searchType, filters) => {
  switch (searchType) {
    case SearchTypes.AUDIO:
      return {
        categories: [
          filters[AudioFilters.MOOD],
          filters[AudioFilters.GENRE],
          filters[AudioFilters.INSTRUMENT],
        ].filter((id) => !!id),
      };
    case SearchTypes.IMAGE:
      return {
        categories: filters[CommonFilters.CATEGORY],
        orientation: filters[ImageFilters.ORIENTATION],
      };
    case SearchTypes.VIDEO:
      return {
        categories: filters[CommonFilters.CATEGORY],
      };
    default:
      return {};
  }
};

export default function StockDrawer(props) {
  const { onSelect = () => {} } = props;
  const mediaType = useSelector(selectMediaType);
  const searchType = SearchTypes.MEDIA_TYPE_TO_SEARCH_TYPE[mediaType];
  const contentType = useSelector(selectContentType);
  const keywords = useSelector(selectKeywords);
  const filters = useSelector(selectFilters);
  const classes = useStyles();

  const filterParams = getFilterParams(searchType, filters);

  const isVideo = mediaType === MediaTypes.VIDEO;

  const { results, isLoadingMore, isReachingEnd, loadMore } = useStock(
    mediaType,
    { contentType, keywords, ...filterParams }
  );

  const handleLoadMore = () => {
    loadMore();
    trackEvent(SIDE_DRAWER.SEARCH_LOAD_MORE, { mediaType, drawer: 'stock' });
  };

  return (
    <div className={classes.drawer}>
      <AssetDrawerHeader />
      <div className={classes.content}>
        <ResultsGrid>
          {results.map((item) => {
            const previewUrl = isVideo
              ? item.preview_urls?._180p
              : item.preview_url;

            return (
              <Box
                key={item.id}
                position="relative"
                className={classes.itemWrapper}
              >
                <Draggable
                  data={{
                    item: {
                      ...item,
                      mediaType,
                      sourceType: SOURCE_TYPES.STOCK,
                    },
                    overlayElement: () => (
                      <DragPreview
                        mediaType={mediaType}
                        previewUrl={previewUrl}
                        thumbnailUrl={item.thumbnail_url}
                      />
                    ),
                    zone: DND_ZONES.DRAWER,
                  }}
                  id={item.id}
                >
                  <MediaPreview
                    contentType={item.type}
                    duration={item.duration}
                    mediaType={mediaType}
                    previewUrl={previewUrl}
                    thumbnailUrl={item.thumbnail_url}
                    title={item.title}
                  />
                </Draggable>
                <PositionWrapper position={Positions.BOTTOM_RIGHT}>
                  <AddIconButton
                    aria-label={`Add ${mediaType}`}
                    onClick={(event) => onSelect(event, { ...item, mediaType })}
                  />
                </PositionWrapper>
              </Box>
            );
          })}
        </ResultsGrid>
        <LoadMoreButton
          isLoadingMore={isLoadingMore}
          isReachingEnd={isReachingEnd}
          onClick={handleLoadMore}
        />
      </div>
    </div>
  );
}
