import { MediaTypes } from '@videoblocks/jelly-renderer';

import createBaseObject from './createBaseObject';

export default function createAnimationObject(item = {}) {
  return createBaseObject({
    // base attributes
    duration: 3,
    mediaType: MediaTypes.ANIMATION,

    // animation attributes
    animationPreset: 'radar',
    primaryColor: { r: 0, g: 0, b: 0, a: 1 },

    ...item,
  });
}
