import { MediaTypes } from '@videoblocks/jelly-renderer';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSWRInfinite } from 'swr';

import StockAPI from '../api/StockAPI';
import { SEARCH_RESULTS_PAGE_SIZE } from '../constants/Constants';
import * as ContentTypes from '../constants/ContentTypes';
import { ImageContentType } from '../constants/SearchQueryParams';
import * as SearchTypes from '../constants/SearchTypes';
import { selectUserId } from '../selectors/user';
import { selectWorkspaceProjectId } from '../selectors/workspace';
import useContentToken from './useContentToken';

function serializeParams(params) {
  return queryString.stringify(params, { arrayFormat: 'comma' });
}

function getContentType(searchType, contentType, hasCategories) {
  switch (searchType) {
    case SearchTypes.AUDIO:
      /* If music categories are included, the API requires us to send in
       * only the music contentType, or else no results will return.
       * We default to sending in both music & sfx otherwise. */
      return (
        contentType ||
        (hasCategories
          ? ContentTypes.MUSIC
          : `${ContentTypes.MUSIC},${ContentTypes.SFX}`)
      );
    case SearchTypes.IMAGE:
      return (
        contentType || [ImageContentType.PHOTOS, ImageContentType.ILLUSTRATIONS]
      );
    case SearchTypes.VIDEO:
      return (
        contentType ||
        `${ContentTypes.FOOTAGE},${ContentTypes.MOTION_BACKGROUND}`
      );
    default:
      return 'all';
  }
}

function constructUrl(searchType, options) {
  const query = serializeParams(options);
  return `/${searchType}/search?${query}`;
}

const fetcher = async (url, token) => {
  try {
    const response = await new StockAPI(token).get(url);
    return response.results.map((result) => ({
      ...result,
      type: ContentTypes.getContentType(result.type),
    }));
  } catch (error) {
    return [];
  }
};

export default function useStock(mediaType, options) {
  const userId = useSelector(selectUserId);
  const projectId = useSelector(selectWorkspaceProjectId);
  const searchType = SearchTypes.MEDIA_TYPE_TO_SEARCH_TYPE[mediaType];
  const { contentType, ...parameters } = options;
  const hasMusicCategories = parameters.categories?.length > 0;

  const { contentToken } = useContentToken();
  const { data, error, size, setSize } = useSWRInfinite(
    (index) => {
      if (!contentToken) {
        return null;
      }

      return [
        constructUrl(searchType, {
          content_type: getContentType(
            searchType,
            contentType,
            hasMusicCategories
          ),
          results_per_page: SEARCH_RESULTS_PAGE_SIZE,
          user_id: userId,
          project_id: projectId,
          page: index + 1,
          is_vr_360: mediaType === MediaTypes.VIDEO ? false : undefined,
          ...parameters,
        }),
        contentToken,
      ];
    },
    fetcher,
    { revalidateOnFocus: false }
  );

  const results = data ? data.flat(1) : [];
  const isEmpty = data?.[0]?.length === 0;
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.length < SEARCH_RESULTS_PAGE_SIZE);

  const loadMore = useCallback(() => setSize(size + 1), [size, setSize]);

  return { results, isLoadingMore, isReachingEnd, loadMore };
}
