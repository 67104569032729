/**
 * Parses a string representation of a percent (with or without a trailing '%' sign)
 *
 * @param {string} percentString - A string representation of a percent or number
 * @return {number} - The number parsed from the string. Returns NaN if the value cannot be parsed.
 */
export default function parsePercent(percentString = '') {
  return Math.round(
    parseFloat(percentString.replace(/^(\d+(\.\d+))(%?.*)/, '$1'))
  );
}
