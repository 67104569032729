import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function TabPanel(props) {
  const { children, index, value, ...rest } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${index}-tabpanel`}
      aria-labelledby={`${index}-tab`}
      width="100%"
      height="100%"
      {...rest}
    >
      {value === index && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string,
  value: PropTypes.string,
};
