import { useLocation } from 'react-router-dom';

import { SB_MAKER_UNSUPPORTED_URL } from '../constants/Urls';
import { SHARE_PATH } from '../constants/Urls';
import { IS_SUPPORTED_PLATFORM } from '../utils/BrowserUtils';

export default function MobileDeviceRedirect() {
  const { pathname } = useLocation();
  const isSharePage = pathname.includes(SHARE_PATH);

  if (!IS_SUPPORTED_PLATFORM && !isSharePage) {
    window.location.replace(SB_MAKER_UNSUPPORTED_URL);
  }
  return null;
}
