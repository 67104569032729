import { NativeMediaControls } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';

function FilmStripLite(props) {
  const { height = 32, onError, source, thumbnail, time = 1, ...rest } = props;
  const [url, setUrl] = useState(thumbnail);

  useEffect(() => {
    const loadThumbnailFromVideo = async () => {
      try {
        const video = await NativeMediaControls.preloadOffscreenVideo(source);
        const controls = new NativeMediaControls(video);
        await controls.seek(time);

        // renders the current video frame to a data URL
        const [thumbWidth, thumbHeight] = getThumbDimensions(video, height);
        const canvas = createCanvas(thumbWidth, thumbHeight);
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/jpeg');

        setUrl(dataUrl);
      } catch (error) {
        if (onError) onError(error);
      }
    };

    if (source && !url) {
      loadThumbnailFromVideo();
    }
  }, [height, onError, source, time, url]);

  useEffect(() => {
    if (thumbnail) {
      const image = new Image();
      image.onerror = onError;
      image.src = thumbnail;
      setUrl(thumbnail);
    }
  }, [onError, thumbnail]);

  const style = {
    height,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'auto 100%',
    backgroundImage: `url(${url})`,
  };

  return <div {...rest} style={style} />;
}

/**
 * Creates a canvas element with an initialized width & height
 * @param {number} width
 * @param {number} height
 * @returns {HTMLCanvasElement} - Returns a canvas element
 */
function createCanvas(width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

/**
 * Calculates a thumbnail size relative to a source video's intrinsic dimensions
 *
 * @param {HTMLVideoElement} video - the video element
 * @param {number} thumbHeight - the thumbnail height
 * @returns {[number, number]} - Returns a tuple of thumbnail width x height
 */
function getThumbDimensions(video, thumbHeight) {
  const { videoWidth, videoHeight } = video;
  const aspectRatio = videoWidth / videoHeight;
  const thumbWidth = thumbHeight * aspectRatio;
  return [thumbWidth, thumbHeight];
}

FilmStripLite.propTypes = {
  /**
   * The height of the rendered filmstrip
   * @default 32
   */
  height: PropTypes.number,
  /**
   * Callback fired when an error occurs when loading media
   */
  onError: PropTypes.func,
  /**
   * The video source url
   */
  source: PropTypes.string,
  /**
   * The image thumbnail url
   *
   * Note: If `thumbnail` is provided, `source` will be ignored
   */
  thumbnail: PropTypes.string,
  /**
   * The time to seek to before capturing a thumbnail
   *
   * Note: Only applies if `source` is defined and `thumbnail` is undefined
   * @default 1
   */
  time: PropTypes.number,
};

export default memo(FilmStripLite);
