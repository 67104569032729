import { useSelector } from 'react-redux';

import { shouldWatermark } from '../../selectors/user';
import Alert from '../Alert';

export default function DefaultPlanAlerts() {
  const isWatermarked = useSelector(shouldWatermark);

  return (
    isWatermarked && (
      <Alert severity="info">
        You cannot export videos without a watermark on your current plan.
      </Alert>
    )
  );
}
