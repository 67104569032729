import PropTypes from 'prop-types';
import { memo } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

function BrandUpdateDialog(props) {
  const { projectsCount, templatesCount, open, onClose, onConfirm } = props;
  const p = parseInt(projectsCount || 0);
  const t = parseInt(templatesCount || 0);
  const projectCountString = `${p} project${p === 1 ? '' : 's'}`;
  const templateCountString = `${t} template${t === 1 ? '' : 's'}`;

  return (
    <ConfirmationDialog
      title="Update Brand?"
      text={
        <>
          {projectCountString} and {templateCountString} are currently using it,
          and their owners will be prompted to update.
        </>
      }
      confirmButtonText="Save Changes"
      showCancel
      showClose
      onConfirm={onConfirm}
      onClose={onClose}
      open={open}
    />
  );
}

BrandUpdateDialog.propTypes = {
  brandUid: PropTypes.string,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

export default memo(BrandUpdateDialog);
