import { useDispatch, useSelector } from 'react-redux';

import { selectWorkspaceProjectId } from '../../selectors/workspace';
import {
  addRecentColor,
  removeRecentColors,
  selectRecentColorsByProjectId,
} from '../../slices/editorSlice';

export const useRecentColors = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(selectWorkspaceProjectId);
  const recentColors = useSelector((state) =>
    selectRecentColorsByProjectId(state, projectId)
  );

  const dispatchAddRecentColor = (color) => {
    dispatch(addRecentColor({ projectId, color }));
  };

  const dispatchRemoveRecentColors = (projectId) => {
    dispatch(removeRecentColors({ projectId }));
  };

  return {
    addRecentColor: dispatchAddRecentColor,
    recentColors,
    removeRecentColors: dispatchRemoveRecentColors,
  };
};
