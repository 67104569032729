import { Button, Grid, makeStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { parse, stringify } from 'query-string';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import { EDIT_PATH } from '../constants/Urls';
import { selectBrandUid } from '../features/assetDrawer/assetDrawerSlice';
import { selectIsOrgAdmin } from '../selectors/user';
import BrandSettingsMenu from './BrandSettingsMenu';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 2),
  },
  buttonDrawer: {
    backgroundColor: theme.palette.common.black,
    minHeight: theme.spacing(5),
  },
  buttonApplied: {
    backgroundColor: theme.palette.green[500],
    padding: theme.spacing(0, 2),
  },
  buttonDropdown: {
    border: `2px solid ${theme.palette.common.black}`,
    color: theme.palette.common.black,
  },
  buttonSection: {
    marginTop: theme.spacing(1),
  },
  buttonSectionv2: {
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function StylesFormButtons({
  appliedBrandUid = null,
  editMode = false,
  onApplyBrand = () => {},
  onDeleteBrand,
  onEditBrand,
  onViewBrandDetails,
  onSetStyleAsDefault = () => {},
  onApplyStyle = () => {},
  isBrandForm = false,
  isDefault = false,
  isDrawer = false,
}) {
  const { brandUid } = useParams();
  const { search } = useLocation();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const selectedBrandUid = useSelector(selectBrandUid);
  const classes = useStyles();

  const isCurrentBrandApplied = selectedBrandUid === appliedBrandUid;

  // Styles Drawer
  if (!isBrandForm) {
    return (
      <Grid
        container
        item
        spacing={1}
        xs={12}
        className={classes.buttonSection}
      >
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className={classes.button}
            fullWidth
            onClick={onSetStyleAsDefault}
          >
            Save as Default
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button}
            variant="outlined"
            fullWidth
            onClick={onApplyStyle}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    );
  }

  // Brands Drawer
  if (isDrawer && !editMode) {
    return (
      <div className={classes.buttonSectionv2}>
        <Button
          size="small"
          variant="contained"
          onClick={onApplyBrand}
          className={clsx(classes.buttonDrawer, {
            [classes.buttonApplied]: isCurrentBrandApplied,
          })}
          startIcon={isCurrentBrandApplied ? <Check /> : null}
        >
          {isCurrentBrandApplied ? 'Applied' : 'Apply'}
        </Button>
        {isOrgAdmin && (
          <BrandSettingsMenu
            isDefault={isDefault}
            isDrawer={isDrawer}
            onViewDetails={onViewBrandDetails}
            onEdit={onEditBrand}
            onDelete={onDeleteBrand}
            outlined
            brandUid={selectedBrandUid}
          />
        )}
      </div>
    );
  }

  // Brands Detail page
  if (!isDrawer && isBrandForm) {
    return (
      <div className={classes.buttonSectionv2}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={{
            pathname: EDIT_PATH,
            search: stringify({ ...parse(search), brandUid }),
          }}
        >
          Start Project with Brand
        </Button>
        {isOrgAdmin && (
          <BrandSettingsMenu
            isDefault={isDefault}
            isDrawer={true}
            onViewDetails={onViewBrandDetails}
            onEdit={onEditBrand}
            onDelete={onDeleteBrand}
            outlined
            brandUid={brandUid}
          />
        )}
      </div>
    );
  }
  return null;
}
