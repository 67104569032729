import {
  Button,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { TEAM_ACCESS_SWITCH } from '../constants/TestSelectors';
import { selectIsEnterprise } from '../selectors/user';
import formatTimestamp from '../utils/formatTimestamp';
import PaddedAspectBox from './PaddedAspectBox';
import ProjectSettingsMenu from './ProjectSettingsMenu';
import UnderlinedTextButton from './UnderlinedTextButton';
import VideoPreview from './VideoPreview';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
  dateCell: {
    width: theme.spacing(21), // keep it on one line
    minWidth: theme.spacing(21), // keep it on one line
  },
  previewCell: {
    width: theme.spacing(22),
    minWidth: theme.spacing(15),
  },
  titleCell: {
    maxWidth: theme.spacing(20),
    minWidth: theme.spacing(15),
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(30),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(40),
    },
  },
}));

export default function ProjectTableRow({
  onDuplicate: handleDuplicate = () => {},
  onPromptDelete: handlePromptDelete = () => {},
  onSaveAsTemplate: handleSaveAsTemplate = () => {},
  onSelect: handleSelect = () => {},
  onTeamAccessToggle: handleTeamAccessToggle = () => {},
  onViewExports: handleViewExports = () => {},
  openToast = () => {},
  project, // project should be passed in from list, rather than individually fetched (performance and policy difference)
  projectOwnedByUser = false,
  showCreatedBy = true,
  showExports = true,
  showTeamAccessToggle = false,
}) {
  const classes = useStyles();
  const isEnterprise = useSelector(selectIsEnterprise);
  const {
    creator_full_name: creatorFullName = '',
    exports_count: exportsCount, // projects passed in from list have export count but no deeper export details
    id,
    name: projectName = '',
    preview_url: previewUrl = '',
    share_id: shareId = '',
    team_access: projectTeamAccess = false,
    updated_at: updatedAt = '',
  } = project ?? {};
  const [teamAccess, setTeamAccess] = useState(!!projectTeamAccess);
  const hasExports = exportsCount > 0;
  const date = updatedAt && format(parseISO(updatedAt), 'MM/dd/yy hh:mm aa');
  const duration = getStoryboardDuration(project.storyboard?.items?.entities);
  const timestamp = formatTimestamp(duration);

  const handleToggleTeamAccess = () => {
    setTeamAccess(!teamAccess);
    handleTeamAccessToggle();
  };

  const handleClickTitle = () => {
    if (projectOwnedByUser) {
      handleSelect();
    }
  };

  return (
    <TableRow data-testid={`project-${id}`}>
      <TableCell className={clsx(classes.cell, classes.previewCell)}>
        <PaddedAspectBox
          role={projectOwnedByUser ? 'button' : null}
          aspectRatio={16 / 9}
          onClick={handleClickTitle}
        >
          <VideoPreview source={previewUrl} />
        </PaddedAspectBox>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.titleCell)}>
        {projectOwnedByUser ? (
          <UnderlinedTextButton
            onClick={handleClickTitle}
            aria-label={`Edit ${projectName}`}
            inCell={true}
          >
            {projectName}
          </UnderlinedTextButton>
        ) : (
          <span>{projectName}</span>
        )}
      </TableCell>
      <TableCell className={classes.cell}>{timestamp}</TableCell>
      <TableCell className={clsx(classes.cell, classes.dateCell)}>
        {date}
      </TableCell>
      {showTeamAccessToggle && (
        <TableCell>
          <Switch
            aria-label="Team access"
            checked={teamAccess}
            onChange={handleToggleTeamAccess}
            inputProps={{ 'data-testid': TEAM_ACCESS_SWITCH }}
          />
        </TableCell>
      )}
      {showCreatedBy && isEnterprise && !showTeamAccessToggle && (
        <TableCell>{creatorFullName}</TableCell>
      )}
      {showExports && (
        <TableCell className={classes.cell}>
          <Tooltip title={!hasExports ? 'No Available Exports' : ''}>
            <span>
              <Button
                disabled={!hasExports || !projectOwnedByUser}
                onClick={handleViewExports}
              >
                <GetAppRoundedIcon fontSize="small" /> {exportsCount}
              </Button>
            </span>
          </Tooltip>
        </TableCell>
      )}
      <TableCell className={classes.cell} align="right">
        <ProjectSettingsMenu
          numExports={exportsCount}
          onPromptDelete={handlePromptDelete}
          onDuplicate={handleDuplicate}
          onSaveAsTemplate={handleSaveAsTemplate}
          onSelect={handleSelect}
          onViewExports={handleViewExports}
          openToast={openToast}
          shareId={shareId}
          projectOwnedByUser={projectOwnedByUser}
        />
      </TableCell>
    </TableRow>
  );
}

ProjectTableRow.propTypes = {
  onDuplicate: PropTypes.func,
  onPromptDelete: PropTypes.func,
  onSaveAsTemplate: PropTypes.func,
  onSelect: PropTypes.func,
  onTeamAccessToggle: PropTypes.func,
  onViewExports: PropTypes.func,
  openToast: PropTypes.func,
  project: PropTypes.object,
  projectOwnedByUser: PropTypes.bool,
  showCreatedBy: PropTypes.bool,
  showExports: PropTypes.bool,
  showTeamAccessToggle: PropTypes.bool,
};
