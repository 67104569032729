import { screenAnimations } from '@videoblocks/jelly-renderer';

import Bullseye from '../assets/overlays/Bullseye.mp4';
import BullseyeThumb from '../assets/overlays/Bullseye.svg';
import { ReactComponent as BullseyeThumbSquare } from '../assets/overlays/BullseyeSquare.svg';
import Clap from '../assets/overlays/Clap.mp4';
import ClapThumb from '../assets/overlays/Clap.svg';
import { ReactComponent as ClapThumbSquare } from '../assets/overlays/ClapSquare.svg';
import Fade from '../assets/overlays/Fade.mp4';
import FadeThumb from '../assets/overlays/Fade.svg';
import { ReactComponent as FadeThumbSquare } from '../assets/overlays/FadeSquare.svg';
import Falling from '../assets/overlays/Falling.mp4';
import FallingThumb from '../assets/overlays/Falling.svg';
import { ReactComponent as FallingThumbSquare } from '../assets/overlays/FallingSquare.svg';
import Flex from '../assets/overlays/Flex.mp4';
import FlexThumb from '../assets/overlays/Flex.svg';
import { ReactComponent as FlexThumbSquare } from '../assets/overlays/FlexSquare.svg';
import Radar from '../assets/overlays/Radar.mp4';
import RadarThumb from '../assets/overlays/Radar.svg';
import { ReactComponent as RadarThumbSquare } from '../assets/overlays/RadarSquare.svg';
import Shutter from '../assets/overlays/Shutter.mp4';
import ShutterThumb from '../assets/overlays/Shutter.svg';
import { ReactComponent as ShutterThumbSquare } from '../assets/overlays/ShutterSquare.svg';
import Split from '../assets/overlays/Split.mp4';
import SplitThumb from '../assets/overlays/Split.svg';
import { ReactComponent as SplitThumbSquare } from '../assets/overlays/SplitSquare.svg';
import Stacked from '../assets/overlays/Stacked.mp4';
import StackedThumb from '../assets/overlays/Stacked.svg';
import { ReactComponent as StackedThumbSquare } from '../assets/overlays/StackedSquare.svg';
import StepUp from '../assets/overlays/StepUp.mp4';
import StepUpThumb from '../assets/overlays/StepUp.svg';
import { ReactComponent as StepUpThumbSquare } from '../assets/overlays/StepUpSquare.svg';

const overlays = [
  {
    label: 'Bullseye',
    value: screenAnimations.bullseye,
    preview: Bullseye,
    thumbnail: BullseyeThumb,
    thumbnailSquare: BullseyeThumbSquare,
  },
  {
    label: 'Clap',
    value: screenAnimations.clap,
    preview: Clap,
    thumbnail: ClapThumb,
    thumbnailSquare: ClapThumbSquare,
  },
  {
    label: 'Fade',
    value: screenAnimations.fade,
    preview: Fade,
    thumbnail: FadeThumb,
    thumbnailSquare: FadeThumbSquare,
  },
  {
    label: 'Flex',
    value: screenAnimations.flex,
    preview: Flex,
    thumbnail: FlexThumb,
    thumbnailSquare: FlexThumbSquare,
  },
  {
    label: 'Shutter',
    value: screenAnimations.shutter,
    preview: Shutter,
    thumbnail: ShutterThumb,
    thumbnailSquare: ShutterThumbSquare,
  },
  {
    label: 'Split',
    value: screenAnimations.split,
    preview: Split,
    thumbnail: SplitThumb,
    thumbnailSquare: SplitThumbSquare,
  },
  {
    label: 'Stacked',
    value: screenAnimations.stacked,
    preview: Stacked,
    thumbnail: StackedThumb,
    thumbnailSquare: StackedThumbSquare,
  },
  {
    label: 'Step Up',
    value: screenAnimations.stepup,
    preview: StepUp,
    thumbnail: StepUpThumb,
    thumbnailSquare: StepUpThumbSquare,
  },
  {
    label: 'Radar',
    value: screenAnimations.radar,
    preview: Radar,
    thumbnail: RadarThumb,
    thumbnailSquare: RadarThumbSquare,
  },
  {
    label: 'Falling',
    value: screenAnimations.falling,
    preview: Falling,
    thumbnail: FallingThumb,
    thumbnailSquare: FallingThumbSquare,
  },
];

export default overlays;
