import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { makeStyles } from '@material-ui/styles';
import { DraggableCore } from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  dragIndicator: {
    transform: 'rotate(90deg)',
  },
  resizeBar: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    cursor: 'ns-resize',
    display: 'flex',
    height: theme.spacing(1.5),
    justifyContent: 'center',
    position: 'relative',
    '&:active': {
      cursor: 'ns-resize',
    },
  },
}));

export default function TimelineResizeBar(props) {
  const { onStart = () => {}, onDrag = () => {}, onStop = () => {} } = props;

  const classes = useStyles();

  return (
    <DraggableCore axis="y" onStart={onStart} onDrag={onDrag} onStop={onStop}>
      <div className={classes.resizeBar}>
        <DragIndicatorIcon className={classes.dragIndicator} fontSize="small" />
      </div>
    </DraggableCore>
  );
}
