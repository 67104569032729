import { Dialog } from '@material-ui/core';
import log from 'loglevel';
import { useSelector } from 'react-redux';

import UploadAPI from '../../api/UploadAPI';
import useFonts from '../../hooks/useFonts';
import { selectOrganizationId } from '../../selectors/user';
import UppyInstance from '../UppyInstance';

export default function TypefaceUploadDialog({
  onClose = () => {},
  open = false,
}) {
  const organizationId = useSelector(selectOrganizationId);
  const { refetchUserFonts, refetchOrgFonts } = useFonts({ organizationId });

  const uploadResult = (result, assemblyId) => {
    if (!result || !assemblyId) {
      log.error('Cannot upload malformed typeface assembly result to DB', {
        result,
        assemblyId,
      });
    }
    return new UploadAPI().postUpload({
      name: result.basename, // File name without the extension.
      mimetype: `font/${result.ext}`,
      size: result.size,
      filename: `${result.id}.${result.ext}`,
      encoder_id: assemblyId,
      is_logo: false,
      meta: result.meta,
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <UppyInstance
        id="typeface-uploads"
        onComplete={() => {
          refetchUserFonts();
          if (organizationId) {
            refetchOrgFonts();
          }
          onClose();
        }}
        remoteSources={['Box', 'Dropbox', 'GoogleDrive', 'OneDrive']}
        allowedFileTypes={['.ttf', '.otf']}
        saveToDatabase={uploadResult}
        transloaditTemplateType="font"
        useRecordingSources={false}
        useImageEditor={false}
      />
    </Dialog>
  );
}
