import { createAction } from 'redux-actions';

import AuthAPI from '../api/AuthAPI';
import * as ActionTypes from '../constants/ActionTypes';

export const userLoadUserRequested = createAction(
  ActionTypes.USER_LOAD_USER_REQUESTED
);
export const userLoadUserCompleted = createAction(
  ActionTypes.USER_LOAD_USER_COMPLETED
);
export const userLoadUserFailed = createAction(
  ActionTypes.USER_LOAD_USER_FAILED
);

export function authLoadUser() {
  return async (dispatch) => {
    dispatch(userLoadUserRequested());

    const api = new AuthAPI();

    try {
      const json = await api.getUserAttributes();
      dispatch(userLoadUserCompleted(json));
      return json;
    } catch (error) {
      dispatch(userLoadUserFailed(error));
    }
  };
}

export const authLogout = createAction(ActionTypes.USER_LOGOUT);
