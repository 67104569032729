import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DEFAULT_BRAND_NAME } from '../constants/StylesConstants';
import { trackEvent } from '../events/sendEvents';
import { BRANDS } from '../events/tags';
import { setIsBrandUnsaved } from '../features/assetDrawer/assetDrawerSlice';
import useBrands from '../hooks/useBrands';
import Toast from './Toast';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: theme.typography.pxToRem(16),
  },
  narrow: {
    width: theme.spacing(28),
  },
  normal: {
    width: theme.spacing(32),
  },
  wide: {
    width: '100%',
  },
}));

export default function CreateNewBrandButton(props) {
  const {
    navigateToBrand,
    buttonText = 'Create New Brand',
    variant = 'contained',
    color = 'primary',
    width = 'normal',
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const { brands, createBrand } = useBrands();
  const hasBrands = brands && brands.length > 0;

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const openToast = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const startCreateBrand = async () => {
    dispatch(setIsBrandUnsaved(true));
    trackEvent(BRANDS.START_CREATE, { first: !hasBrands });

    try {
      const { brand_uid: brandUid } = await createBrand(
        {
          name: DEFAULT_BRAND_NAME,
          styles: {
            colors: [],
          },
        },
        true
      );
      navigateToBrand(brandUid, true);
    } catch (err) {
      // Handle org brand limit
      const toastMessage = err?.response?.data?.message;
      if (toastMessage) {
        openToast(toastMessage);
      }
    }
  };

  return (
    <>
      <Toast open={showToast} severity="info" onClose={closeToast}>
        {toastMessage}
      </Toast>
      <Button
        className={clsx(classes.button, classes[width])}
        variant={variant}
        color={color}
        onClick={startCreateBrand}
      >
        {buttonText}
      </Button>
    </>
  );
}
