import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[700],
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    opacity: 0.8,
    transition: theme.transitions.create(['opacity', 'background-color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
      opacity: 1,
    },
  },
}));

export default function AddIconButton(props) {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <IconButton
      className={clsx('add-button', classes.root, className)}
      {...rest}
    >
      <AddCircleRoundedIcon />
    </IconButton>
  );
}

AddIconButton.propTypes = {
  className: PropTypes.string,
};
