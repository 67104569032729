import PropTypes from 'prop-types';

import { trackEvent } from '../../events/sendEvents';
import { BRANDS } from '../../events/tags';
import useBrand from '../../hooks/useBrand';
import useBrands from '../../hooks/useBrands';
import ConfirmationDialog from './ConfirmationDialog';

function BrandDeleteDialog(props) {
  const { onClose, open, brandUid, onSuccess = () => {} } = props;
  const { deleteBrand } = useBrands();
  const { brand } = useBrand(brandUid);

  const handleConfirm = async () => {
    trackEvent(BRANDS.DELETE, { numProjectsAffected: brand.projects_count });

    await deleteBrand(brandUid);

    onSuccess();
  };

  return (
    <ConfirmationDialog
      title="Delete Brand?"
      text={
        <>
          It will no longer be available to members of your organization. Any
          projects and templates that use styles from this brand will remain
          unchanged.
        </>
      }
      confirmButtonText="Delete"
      showCancel
      showClose
      onClose={onClose}
      onConfirm={handleConfirm}
      open={open}
    />
  );
}

BrandDeleteDialog.propTypes = {
  onConfirm: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};

export default BrandDeleteDialog;
