import { IS_BROWSER_SUPPORTED } from '../utils/BrowserUtils';
import toBool from '../utils/toBool';

const env = process.env;

export const dsn = env.SENTRY_DSN || env.REACT_APP_SENTRY_DSN;
const environment = env.CONTEXT || env.REACT_APP_CONTEXT || 'dev';
const release = env.REACT_APP_COMMIT_REF;

const enabled = IS_BROWSER_SUPPORTED && toBool(env.REACT_APP_SENTRY_ENABLED);

export const sentryOptions = {
  dsn,
  environment,
  enabled,
  release,
};
