import axios from 'axios';
import useSWR, { cache } from 'swr';

import {
  CONTENT_TOKEN_URL,
  GET_API_TOKEN_BY_SEED_URL,
} from '../constants/Urls';

export default function useContentToken() {
  const fetcher = async (url) => {
    const { data } = await axios.get(url, {
      withCredentials: true,
    });

    return data;
  };

  const { data, error, mutate } = useSWR(CONTENT_TOKEN_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: !cache.has(CONTENT_TOKEN_URL),
    revalidateOnReconnect: false,
  });

  // Makes a request to retrieve the token by seed. 404's if token is expired or doesn't exist
  const refreshIfExpired = async () => {
    if (!data?.seed) {
      return;
    }

    let isExpired = false;
    try {
      const url = `${GET_API_TOKEN_BY_SEED_URL}/${data.seed}`;
      await axios.get(url);
    } catch (e) {
      isExpired = true;
    }

    if (isExpired) {
      await mutate();
    }
  };

  return {
    contentToken: data?.token ?? undefined,
    seed: data?.seed ?? undefined,
    error,
    isLoading: !data && !error,
    refreshIfExpired,
    refreshToken: mutate,
  };
}
