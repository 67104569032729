import { migrate } from '@videoblocks/jelly-scripts';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import ProjectsAPI from '../api/ProjectsAPI';
import { trackEvent } from '../events/sendEvents';
import { PROJECT } from '../events/tags';
import { selectUserId } from '../selectors/user';
import { scaleStoryboard } from '../utils/storyboardUtils';
import useContentToken from './useContentToken';

/**
 * @param {number} projectId
 * @returns {{project:Project}}
 */
export default function useProject(
  projectId,
  shouldPollForStatusChange = false
) {
  const [shouldPoll, setShouldPoll] = useState(shouldPollForStatusChange);
  const userId = useSelector(selectUserId);
  const { contentToken } = useContentToken();

  const fetcher = async (url) => {
    try {
      const response = await new ProjectsAPI(contentToken).get(url);
      response.storyboard = migrate(response.storyboard);
      return response;
    } catch (error) {
      log.error(`Error loading project: ${error?.message}`, { error });
      return {};
    }
  };

  const { data: project = {}, mutate } = useSWR(
    projectId ? `/project/${projectId}` : null,
    fetcher,
    shouldPoll ? { refreshInterval: 5000 /* milliseconds */ } : {}
  );

  const hasPendingExports = project?.exports?.some(
    (projectExport) => !projectExport.completed_at
  );
  const hasNoExportsYet = !project?.exports?.length;

  useEffect(() => {
    setShouldPoll(
      shouldPollForStatusChange && (hasPendingExports || hasNoExportsYet)
    );
  }, [hasPendingExports, hasNoExportsYet, shouldPollForStatusChange]);

  /**
   * @param {Project} project
   * @returns {ProjectPutResponse}
   */
  const editProject = async (project) => {
    await new ProjectsAPI().editProject(project.uid, project.storyboard);

    mutate();
  };

  const setProjectBrand = async (id, brandUid) => {
    await new ProjectsAPI().setProjectBrand(id, brandUid);

    mutate();
  };

  const renameProject = async (id, newName) => {
    const newStoryboard = { ...project.storyboard, name: newName };

    mutate({ ...project, storyboard: newStoryboard });

    await new ProjectsAPI().putProject(id, newStoryboard);

    mutate();
  };

  const duplicateAndScaleProject = async ({ ratio, location }) => {
    const migratedStoryboard = migrate(project.storyboard);
    const duplicated = await new ProjectsAPI().duplicateProject(project.uid, {
      storyboard: scaleStoryboard(migratedStoryboard, {
        ratio,
      }),
    });

    trackEvent(PROJECT.DUPLICATE, {
      projectUid: project.uid,
      ratio,
      location,
      isOwner: project.user_id === userId,
      newUid: duplicated.uid,
    });

    return duplicated;
  };

  const getExportById = (exportId) => {
    return project?.exports?.find((e) => e?.id === parseInt(exportId));
  };

  return {
    project,
    duplicateAndScaleProject,
    editProject,
    getExportById,
    renameProject,
    setProjectBrand,
  };
}
