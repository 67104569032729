import { camelCase, isPlainObject } from 'lodash';

/**
 * Recursively ensures all keys in an object are in camelCase
 *
 * @param {object} object
 * @return {object} - an object where all properties are in camelcase
 */
export default function formatPropsToCamelCase(object) {
  if (Array.isArray(object)) {
    return object.map((value) => formatPropsToCamelCase(value));
  } else if (isPlainObject(object)) {
    return Object.keys(object).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: formatPropsToCamelCase(object[key]),
      }),
      {}
    );
  }

  return object;
}
