import { mapValues } from 'lodash';
import { UAParser } from 'ua-parser-js';

const parser = new UAParser(navigator.userAgent);
const os = parser.getOS().name;
export const isMacOS = os === 'Mac OS';

export function parseHotkey(hotkey = '') {
  return hotkey.split('+').map((key) => {
    switch (key) {
      case 'delete, backspace':
        return '⌫';
      case 'cmd':
        return '⌘';
      case 'shift':
        return '⇧';
      case 'left':
        return '←';
      case 'right':
        return '→';
      case '=':
        return '+';
      default:
        return key;
    }
  });
}

export const SKIP_PX = 10;
export const SKIP_MORE_PX = SKIP_PX * 10;

export const HOTKEYS = mapValues(
  {
    DELETE: {
      description: 'Delete',
      keys: 'delete, backspace',
    },
    DESELECT: {
      description: 'Deselect',
      keys: 'esc',
    },
    DUPLICATE: {
      description: 'Duplicate',
      keys: 'ctrl+d',
    },
    MUTE: {
      description: 'Mute',
      keys: 'm',
    },
    OPEN_SHORTCUTS: {
      description: 'Show Keyboard Shortcuts',
      keys: '/',
    },
    PLAY_PAUSE: {
      description: 'Toggle Play/Pause',
      keys: 'space',
    },
    REDO: {
      description: 'Redo',
      keys: 'ctrl+shift+z',
    },
    SEEK_BACKWARD: {
      description: 'Seek Backward',
      keys: 'left',
    },
    SEEK_BACKWARD_MORE: {
      description: 'Seek Backward (more)',
      keys: 'shift+left',
    },
    SEEK_FORWARD: {
      description: 'Seek Forward',
      keys: 'right',
    },
    SEEK_FORWARD_MORE: {
      description: 'Seek Forward (more)',
      keys: 'shift+right',
    },
    SEEK_TO_END: {
      description: 'Seek To End',
      keys: 'ctrl+right',
    },
    SEEK_TO_START: {
      description: 'Seek To Start',
      keys: 'ctrl+left',
    },
    SPLIT: {
      description: 'Split',
      keys: 's',
    },
    TRIM: {
      description: 'Trim',
      keys: 't',
    },
    UNDO: {
      description: 'Undo',
      keys: 'ctrl+z',
    },
    ZOOM_IN: {
      description: 'Zoom In',
      keys: 'ctrl+=',
    },
    ZOOM_OUT: {
      description: 'Zoom Out',
      keys: 'ctrl+-',
    },
  },
  (hotkey, key) => ({
    id: key,
    description: hotkey.description,
    keys: isMacOS ? hotkey.keys.replace('ctrl', 'cmd') : hotkey.keys,
  })
);
