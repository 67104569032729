/**
 * Returns the duration of media at a source url.
 * Works for both video and audio sources.
 *
 * @param {string} source - The source url of the media
 * @returns {Promise<number>} Returns the duration of the media
 */
export default function getMediaDuration(source) {
  const video = document.createElement('video');
  video.setAttribute('preload', 'metadata');
  video.setAttribute('muted', 'true');
  video.setAttribute('src', source);

  return new Promise((resolve) => {
    video.addEventListener('loadedmetadata', (event) => {
      resolve(event.target.duration);
    });
    video.addEventListener('error', (event) => {
      console.error(event.target.error);
      resolve(0);
    });
  });
}
