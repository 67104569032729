import { cloneDeep } from 'lodash';

import { checkMediaItem } from '../utils/mediaUtils';

export default function replaceMediaObject(oldMedia, newMedia) {
  const { isAudio, isImage, isVideo } = checkMediaItem(oldMedia);
  const isTrimmable = isAudio || isVideo;
  const hasAspectRatio = isImage || isVideo;

  const media = {
    ...cloneDeep(oldMedia),
    id: newMedia.id,
    itemSourceId: newMedia.itemSourceId,
    itemSourceType: newMedia.itemSourceType,
    resolutions: newMedia.resolutions,
    source: newMedia.source,
    title: newMedia.title,
  };

  if (isVideo) {
    media.includesAudio = newMedia.includesAudio;
  }

  if (isTrimmable) {
    // take the duration of the shorter clip
    // and set the trim start back to zero
    media.duration = Math.min(oldMedia.duration, newMedia.duration);
    media.maxDuration = newMedia.maxDuration;
    media.trimStart = 0;
  }

  if (hasAspectRatio) {
    media.aspectRatio = newMedia.aspectRatio;
  }

  return media;
}
