import { Box, Button, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  copyInput: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.5, 1.25),
    lineHeight: 1.75,
  },
  copyInputDisabled: {
    backgroundColor: 'transparent',
  },
  copyInputButton: {
    flexShrink: 0,
    marginLeft: theme.spacing(3),
  },
}));

export default function CopyTextInput(props) {
  const { actionText, disabled, onCopy, value } = props;

  const classes = useStyles();
  const copyInput = useRef(null);

  // highlights all Input text
  const selectInputText = () => copyInput.current.select();

  // copies Input text to clipboard and runs provided copy callback
  const copyInputText = () => {
    if (disabled) {
      return;
    }

    selectInputText();
    copy(copyInput.current.value);
    onCopy();
  };

  return (
    <Box display="flex">
      <OutlinedInput
        classes={{
          input: classes.copyInput,
          disabled: classes.copyInputDisabled,
        }}
        color="secondary"
        fullWidth
        value={value}
        disabled={disabled}
        onClick={selectInputText}
        inputRef={copyInput}
      />
      <Button
        className={classes.copyInputButton}
        variant="outlined"
        onClick={copyInputText}
        disabled={disabled}
      >
        {actionText}
      </Button>
    </Box>
  );
}

CopyTextInput.propTypes = {
  actionText: PropTypes.string,
  disabled: PropTypes.bool,
  onCopy: PropTypes.func,
  value: PropTypes.string,
};

CopyTextInput.defaultProps = {
  onCopy: () => {},
};
