import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTitle } from 'react-use';

import ArticleList from '../components/ArticleList';
import QuickLinks from '../components/QuickLinks';
import YoutubeCard from '../components/cards/YoutubeCard';
import { TUTORIAL_VIDEOS } from '../constants/Urls';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

export default function TutorialsContainer() {
  useTitle('Maker | Tutorials');
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12}>
            <QuickLinks />
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography component="h2" variant="h4">
                Videos
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {TUTORIAL_VIDEOS.map((videoProps) => {
                return (
                  <Grid item xs={12} sm={6} lg={4} key={videoProps.youtubeId}>
                    <YoutubeCard {...videoProps} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <ArticleList />
      </Grid>
    </Grid>
  );
}
