import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import CloseButton from '../../components/CloseButton';
import { trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import { setActiveItemId } from '../../slices/editorSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.875,
    color: theme.palette.pink[500],
  },
}));

export default function PropertiesPanelHeading({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  // close properties panel
  const deselectActiveElement = () => {
    dispatch(setActiveItemId());
    trackEvent(PROPERTIES_PANEL.CLOSE);
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>{children}</div>
      <Box color="grey.700">
        <CloseButton onClick={deselectActiveElement} size="small" />
      </Box>
    </div>
  );
}
