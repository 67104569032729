import { makeStyles } from '@material-ui/core';
import Audio from '@uppy/audio';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import RemoteSources from '@uppy/remote-sources';
import ScreenCapture from '@uppy/screen-capture';
import Transloadit, {
  COMPANION_ALLOWED_HOSTS,
  COMPANION_URL,
} from '@uppy/transloadit';
import Webcam from '@uppy/webcam';
import { differenceInMilliseconds } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { flatMap } from 'lodash';
import log from 'loglevel';
import { useEffect, useRef, useState } from 'react';

import '@uppy/core/dist/style.min.css';

import TransloaditAPI from '../api/TransloaditAPI';
// extracted stylesheets that must be imported after '@uppy/core/dist/style.min.css' and shouldn't be reordered alphabetically
import '../assets/uppy-stylesheets';
import { UPLOAD_SIZE_LIMIT } from '../constants/Constants';
import { UPPY_TARGET } from '../constants/TestSelectors';
import { trackUploads, trackEvent } from '../events/sendEvents';
import { RECORD } from '../events/tags';
import extractThumbnails from '../utils/extractThumbnails';

const useStyles = makeStyles((theme) => ({
  uppy: {
    '& .uppy-Informer span': {
      width: '100%', // This is a hack for bad styling in the current Uppy version. Re-assess after upgrades.
      backgroundColor: 'transparent',
    },
  },
}));

export default function UppyInstance({
  height = 550,
  id,
  disableLocalFiles = false,
  onComplete = () => {},
  remoteSources = [],
  allowedFileTypes = ['audio/*', 'image/*', 'video/*'],
  maxNumberOfFiles = 10,
  saveToDatabase = () => {},
  showUploadRestrictions = true,
  transloaditTemplateType,
  useRecordingSources = true,
  useImageEditor = true,
}) {
  const classes = useStyles();
  const dashboardRef = useRef(null);
  const uppyRef = useRef(null);
  const [transloaditAuthResponse, setTransloaditAuthResponse] = useState();

  const isRecording = () => {
    const state = uppyRef.current?.getState();
    return (
      state?.plugins?.Webcam?.isRecording ||
      state?.plugins?.ScreenCapture?.recording || // no idea why property name is different; keep an eye on it in version upgrades
      state?.plugins?.Audio?.isRecording
    );
  };

  // define unmount
  useEffect(() => {
    return () => {
      if (isRecording()) {
        console.log('Recording in progress');
        // TODO: FLOW-818 handle it
      }
      uppyRef.current?.close();
    };
  }, []); // watched properties array must be empty

  /**
   * FIXME (FLOW-835): This is a temporary bandaid for the Cancel/Back button not
   * working on Screen Record. This useEffect can be removed when
   * https://github.com/transloadit/uppy/issues/4445 is resolved by Uppy.
   */
  useEffect(() => {
    if (!!dashboardRef.current) {
      dashboardRef.current.addEventListener('click', (e) => {
        if (
          e.target.className &&
          e.target.className.indexOf('uppy-DashboardContent-back') > -1
        ) {
          uppyRef.current?.cancelAll();
        }
      });
    }
  }, [dashboardRef]);

  useEffect(() => {
    (async () => {
      // Authorize transloadit once when component is instantiated
      const response = await new TransloaditAPI().getSignatureAuth(
        transloaditTemplateType
      );
      setTransloaditAuthResponse(response);

      // Convert transloadit date format 'yyyy/MM/dd HH:mm:ss+00:00' to date-fns-tx format '2014-10-25T13:46:20+04:00'
      const expireString = response.expires
        .replace(/\//g, '-')
        .replace(/ /g, 'T');
      const expireDate = toDate(expireString);

      // Set up an interval to refresh transloadit auth, 1 minute before it expires (at time of writing, 1 hour).
      // Ensure the interval is non-negative and nonzero.
      const nowDate = new Date();
      const refreshIntervalMs =
        differenceInMilliseconds(expireDate, nowDate) - 60000;
      if (!!refreshIntervalMs && refreshIntervalMs > 1000) {
        const interval = setInterval(async () => {
          setTransloaditAuthResponse(
            await new TransloaditAPI().getSignatureAuth(transloaditTemplateType)
          );
        }, refreshIntervalMs);
        return () => clearInterval(interval); // runs when component unmounts
      }
    })();
  }, [transloaditTemplateType]);

  useEffect(() => {
    (async () => {
      if (!transloaditAuthResponse) return;

      const { expires, key, signature, templateId, userId } =
        transloaditAuthResponse;
      const dashboardContents = dashboardRef?.current?.children;

      if (!!dashboardContents && dashboardContents.length === 0) {
        const uppyConfigs = {
          restrictions: {
            allowedFileTypes,
            maxFileSize: UPLOAD_SIZE_LIMIT * 1024 * 1024, // convert to bits
            maxNumberOfFiles,
          },
          onBeforeUpload: (files) => {
            // Note that this method is intended for quick synchronous checks/modifications only.
            // We’ll be careful to return a new object, not mutating the original `files`.
            const updatedFiles = {};

            Object.keys(files).forEach((fileID) => {
              const originalFile = files[fileID];
              const { meta, name } = originalFile;
              let newName = meta?.name || name; // use name the user entered as meta, or the default name
              newName = newName.replace(/[^a-z0-9.]/gi, '_'); // simple sanitize by removing non alphanumeric characters

              updatedFiles[fileID] = {
                ...originalFile,
                name: newName,
              };
            });
            return updatedFiles;
          },
        };
        uppyRef.current = new Uppy(uppyConfigs);
        uppyRef.current.use(Dashboard, {
          inline: true,
          height,
          target: `#${id}`,
          showProgressDetails: true,
          note: showUploadRestrictions
            ? `You may upload up to ${maxNumberOfFiles} files at a time. The maximum file size is ${UPLOAD_SIZE_LIMIT} MB.`
            : '',
          proudlyDisplayPoweredByUppy: false,
          disableLocalFiles,
          metaFields: [{ id: 'name', name: 'Name', placeholder: 'File name' }], // make filename editable
          locale: {
            strings: {
              importFiles: '',
              browseFiles: 'files',
              browseFolders: 'folders',
              importFrom: '%{name}',
              dropPasteFiles: 'Drop files here',
              dropPasteImportFiles: 'Drop files here, or import from:',
            },
          },
        });

        if (remoteSources?.length > 0) {
          uppyRef.current.use(RemoteSources, {
            companionUrl: COMPANION_URL,
            companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
            sources: remoteSources,
            target: Dashboard,
          });
        }

        if (useRecordingSources) {
          uppyRef.current.use(ScreenCapture, {
            target: Dashboard,
            title: 'Record Screen',
            displayMediaConstraints: {
              video: {
                frameRate: {
                  ideal: 24,
                  max: 30,
                },
                width: { ideal: 3072 }, // optimize for 3072 × 1920 retina display
                height: { ideal: 1920 }, // optimize for 3072 × 1920 retina display
                aspectRatio: { ideal: 1.7777777778 }, // HD, aka 16:9
                cursor: 'motion',
              },
            },
          });
          uppyRef.current.use(Webcam, {
            target: Dashboard,
            title: 'Use Webcam',
            locale: {
              strings: {
                pluginNameCamera: 'Use Webcam', // title isn't working on @uppy/webcam v3.3.1
              },
            },
            videoConstraints: {
              width: { ideal: 3072 }, // optimize for 3072 × 1920 retina display
              height: { ideal: 1920 }, // optimize for 3072 × 1920 retina display
              aspectRatio: { ideal: 1.7777777778 }, // HD, aka 16:9
            },
            showVideoSourceDropdown: true,
            showRecordingLength: true,
          });
          uppyRef.current.use(Audio, {
            target: Dashboard,
            title: 'Record Audio',
            showAudioSourceDropdown: true,
            locale: {
              strings: {
                pluginNameAudio: 'Record Audio', // title isn't working on @uppy/audio v1.1.1
              },
            },
            showRecordingLength: true,
          });

          uppyRef.current.on('file-added', (file) => {
            const recordingEvent = {
              Webcam: 'ACCEPT_WEBCAM',
              ScreenCapture: 'ACCEPT_SCREEN',
              Audio: 'ACCEPT_AUDIO',
            };
            trackEvent(RECORD[recordingEvent[file.source]], {
              fileName: file.name,
            });
          });

          uppyRef.current.on('upload-success', (file) => {
            const recordingEvent = {
              Webcam: 'UPLOAD_WEBCAM',
              ScreenCapture: 'UPLOAD_SCREEN',
              Audio: 'UPLOAD_AUDIO',
            };

            if (file.source === 'Webcam' && file.type?.includes('image')) {
              trackEvent(RECORD.UPLOAD_PICTURE, { fileName: file.name });
            } else {
              trackEvent(RECORD[recordingEvent[file.source]], {
                fileName: file.name,
              });
            }
          });
        }

        if (useImageEditor) {
          uppyRef.current.use(ImageEditor, { target: Dashboard });
        }

        uppyRef.current.use(Transloadit, {
          assemblyOptions: {
            params: {
              auth: { expires, key },
              template_id: templateId,
              fields: { userId },
            },
            signature,
          },
          waitForEncoding: true,
          waitForMetadata: true,
        });

        uppyRef.current.on('transloadit:complete', async (assembly) => {
          const { assembly_id: assemblyId, uploads } = assembly;
          trackUploads(assembly.uploads);

          const results = assembly.results.thumbnailed
            ? flatMap(extractThumbnails(assembly.results))
            : flatMap(assembly.results);

          await Promise.all(
            results.map((result) => saveToDatabase(result, assemblyId))
          );

          const firstUploadType = uploads?.[0]?.type;
          onComplete({ firstUploadType });
        });

        uppyRef.current.on('transloadit:error', (error) => {
          log.error('Transloadit error', error);
        });

        uppyRef.current.on('error', (error) => {
          log.error('Uppy error', error);
        });
      }
    })();
  }, [
    onComplete,
    id,
    useRecordingSources,
    useImageEditor,
    saveToDatabase,
    allowedFileTypes,
    maxNumberOfFiles,
    remoteSources,
    transloaditAuthResponse,
    showUploadRestrictions,
    disableLocalFiles,
    height,
  ]);

  return (
    <div
      id={id}
      className={classes.uppy}
      data-testid={UPPY_TARGET}
      ref={dashboardRef}
    />
  );
}
