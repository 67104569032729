import { Dialog } from '@material-ui/core';
import log from 'loglevel';

import UploadAPI from '../../api/UploadAPI';
import UppyInstance from '../UppyInstance';

export default function UploadDialog({
  brandUid,
  templateUid,
  isLogo,
  onClose = () => {},
  open = false,
  refetch = () => {},
  setErrorMessage = () => {},
}) {
  const uploadResult = async (result, assemblyId) => {
    if (!result || !assemblyId) {
      log.error('Cannot upload malformed upload assembly result to DB', {
        result,
        assemblyId,
      });
    }
    return new UploadAPI()
      .postUpload({
        name: result.name,
        mimetype: result.mime,
        size: result.size,
        filename: `${result.id}.${result.ext}`,
        encoder_id: assemblyId,
        is_logo: isLogo,
        meta: result.meta,
        brand_uid: brandUid,
        template_uid: templateUid,
        thumbnail: result.thumbnail,
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          setErrorMessage('Each brand has a limit of 10 logos.');
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <UppyInstance
        id="general-uploads"
        onComplete={() => {
          onClose();
          refetch();
        }}
        remoteSources={[
          'Facebook',
          'Instagram',
          'Box',
          'Dropbox',
          'GoogleDrive',
          'OneDrive',
        ]}
        allowedFileTypes={
          isLogo ? ['image/*'] : ['audio/*', 'image/*', 'video/*']
        }
        saveToDatabase={uploadResult}
        useRecordingSources={false}
        useImageEditor={true}
      />
    </Dialog>
  );
}
