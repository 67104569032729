import { Box, Button, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import PaddedAspectBox from '../PaddedAspectBox';
import VideoPreview from '../VideoPreview';

const useStyles = makeStyles({
  content: {
    padding: 0,
    textAlign: 'center',
    '&:first-child': {
      padding: 0,
    },
  },
  video: {
    width: '100%',
    height: '100%',
    outline: 'none',
  },
});

/** FIXME: rename this component to VideoPlayerDialog, since it's doing more than previews at this point */
export default function VideoPreviewDialog(props) {
  const { onClose, onClickPrimary, open, src, primaryButtonText, ...rest } =
    props;
  const classes = useStyles();

  const handleClickPrimary = () => {
    onClickPrimary();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <PaddedAspectBox aspectRatio={16 / 9}>
          <VideoPreview
            source={src}
            autoPlay={true}
            borderRadius={false}
            playOnHover={false}
            controls={true}
            {...rest}
          ></VideoPreview>
        </PaddedAspectBox>
        {onClickPrimary && primaryButtonText && (
          <Box paddingY={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickPrimary}
            >
              {primaryButtonText}
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

VideoPreviewDialog.propTypes = {
  onClose: PropTypes.func,
  onClickPrimary: PropTypes.func,
  open: PropTypes.bool,
  src: PropTypes.string,
  primaryButtonText: PropTypes.string,
};

VideoPreviewDialog.defaultProps = {
  onClose: () => {},
  open: false,
};
