import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Admin from '../admin';
import ExportsToast from '../components/ExportsToast';
import MobileDeviceRedirect from '../components/MobileDeviceRedirect';
import {
  ADMIN_PATH,
  BRANDS_PATH,
  EDIT_PATH,
  EDIT_TEMPLATE_PATH,
  HOME_PATH,
  PROJECTS_PATH,
  SHARE_PATH,
  TEMPLATES_PATH,
  TUTORIALS_PATH,
} from '../constants/Urls';
import BrandDetailContainer from '../containers/BrandDetailContainer';
import BrandsContainer from '../containers/BrandsContainer';
import CreateContainer from '../containers/CreateContainer';
import EditorContainer from '../containers/EditorContainer';
import ExportContainer from '../containers/ExportContainer';
import HomeContainer from '../containers/HomeContainer';
import HomeTabContainer from '../containers/HomeTabContainer';
import NavbarContainer from '../containers/NavbarContainer';
import ProjectsContainer from '../containers/ProjectsContainer';
import ShareContainer from '../containers/ShareContainer';
import TemplatesContainer from '../containers/TemplatesContainer';
import TutorialsContainer from '../containers/TutorialsContainer';
import UIContainer from '../containers/UIContainer';
import ErrorBoundary from '../utils/ErrorBoundary';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';

export default function Routes() {
  return (
    <Router>
      <ErrorBoundary>
        {/*
         * The Navbar is intentionally contained in a separate boundary
         * such that it still shows up when there is an editor error.
         */}
        <NavbarContainer />
      </ErrorBoundary>
      <ErrorBoundary>
        <MobileDeviceRedirect />
        <Switch>
          <PrivateRoute exact path={HOME_PATH}>
            <HomeContainer>
              <HomeTabContainer />
            </HomeContainer>
          </PrivateRoute>
          <PrivateRoute exact path={EDIT_TEMPLATE_PATH}>
            <CreateContainer templateMode={true} />
          </PrivateRoute>
          <PrivateRoute exact path={EDIT_PATH}>
            <CreateContainer templateMode={false} />
          </PrivateRoute>
          <PrivateRoute path={`${EDIT_TEMPLATE_PATH}/:templateId`}>
            <EditorContainer />
          </PrivateRoute>
          <PrivateRoute path={`${EDIT_PATH}/:projectId`}>
            <EditorContainer />
          </PrivateRoute>
          <PrivateRoute path={`${PROJECTS_PATH}/:projectId/exports/:exportId`}>
            <ExportContainer />
          </PrivateRoute>
          <PrivateRoute path={PROJECTS_PATH}>
            <HomeContainer>
              <ProjectsContainer />
            </HomeContainer>
          </PrivateRoute>
          <PrivateRoute path={`${BRANDS_PATH}/:brandUid/:isNew?`}>
            <BrandDetailContainer />
          </PrivateRoute>
          <PrivateRoute path={BRANDS_PATH}>
            <HomeContainer enterpriseOnly>
              <BrandsContainer />
            </HomeContainer>
          </PrivateRoute>
          <Route path={`${SHARE_PATH}/:shareId`}>
            <ShareContainer />
          </Route>
          <PrivateRoute path={TEMPLATES_PATH}>
            <HomeContainer enterpriseOnly>
              <TemplatesContainer />
            </HomeContainer>
          </PrivateRoute>
          <PrivateRoute path={TUTORIALS_PATH}>
            <HomeContainer maxWidth={false}>
              <TutorialsContainer />
            </HomeContainer>
          </PrivateRoute>
          <AdminRoute path={ADMIN_PATH}>
            <Admin />
          </AdminRoute>
          <Route>
            <Redirect to={HOME_PATH} />
          </Route>
        </Switch>
        <UIContainer />
        <ExportsToast />
      </ErrorBoundary>
    </Router>
  );
}
