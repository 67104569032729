import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TemplateMasonryGrid from '../components/TemplateMasonryGrid';
import useTemplates from '../hooks/useTemplates';
import AdminTemplatesList from './AdminTemplatesList';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  drawer: {
    flexShrink: 0,
    width: 400,
  },
  drawerPaper: {
    width: 400,
  },
  root: {
    display: 'flex',
  },
}));

function AdminIndexPage() {
  const classes = useStyles();
  const { orgAgnosticTemplates, isLoading } = useTemplates();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        variant="permanent"
        anchor="left"
      >
        <AdminTemplatesList templates={isLoading ? [] : orgAgnosticTemplates} />
      </Drawer>
      <main className={classes.content}>
        <TemplateMasonryGrid filterByPublished />
      </main>
    </div>
  );
}

export default AdminIndexPage;
