import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parse, stringify } from 'query-string';
import { Link, useLocation } from 'react-router-dom';

import logoIcon from '../../assets/images/sb-icon.svg';
import { HOME_PATH } from '../../constants/Urls';

const useStyles = makeStyles((theme) => ({
  homeButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  logo: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function MakerHomeButton() {
  const classes = useStyles();
  const { search } = useLocation();
  // remove any editor-specific queries
  const { isEditing, firstTimeUser, ...rest } = parse(search);

  return (
    <IconButton
      aria-label="Home"
      className={classes.homeButton}
      component={Link}
      to={{ pathname: HOME_PATH, search: stringify(rest) }}
    >
      <img className={classes.logo} src={logoIcon} alt="Maker Home" />
    </IconButton>
  );
}
