import axios from 'axios';
import { isEmpty } from 'lodash';
import log from 'loglevel';
import useSWR from 'swr';

import { getContentType } from '../constants/ContentTypes';
import { SB_API_URL } from '../constants/Urls';

export default function useFolders(contentToken, folderId) {
  const url = folderId ? `/folders/${folderId}` : `/folders`;

  const fetchWithToken = async (url) => {
    try {
      const config = {
        baseURL: SB_API_URL,
        headers: {
          token: contentToken,
        },
        withCredentials: false,
      };

      const response = await axios.get(url, config);
      return response.data;
    } catch (error) {
      log.error(`Error loading folders: ${error?.message}`, { error });
      return [];
    }
  };

  const result = useSWR(
    contentToken ? [url, contentToken] : null,
    fetchWithToken
  );
  const isLoading = !result.data;

  let data;

  if (folderId) {
    const folder = result.data || {};
    data = isEmpty(folder) ? {} : normalizeFolderData(folderId, folder);
  } else {
    data = result.data || [];
  }

  return { data, isLoading };
}

function normalizeFolderData(folderId, folder = {}) {
  const { name = '', results = [] } = folder;
  const items = results.map((stockItem) => {
    return {
      contentType: getContentType(stockItem.type),
      duration: stockItem.duration,
      id: stockItem.id,
      mediaType: stockItem.contentClass,
      preview_url: stockItem.preview_url,
      preview_urls: stockItem.preview_urls,
      thumbnail_url: stockItem.thumbnail_url,
      title: stockItem.title,
    };
  });
  return { id: folderId, name, items };
}
