import { ReactComponent as Crop_1_1 } from '../assets/icons/crop-1-1.svg';
import { ReactComponent as Crop_2_3 } from '../assets/icons/crop-2-3.svg';
import { ReactComponent as Crop_4_5 } from '../assets/icons/crop-4-5.svg';
import { ReactComponent as Crop_9_16 } from '../assets/icons/crop-9-16.svg';
import { ReactComponent as Crop_16_9 } from '../assets/icons/crop-16-9.svg';
import { ReactComponent as Box1By1Cropped } from '../assets/shapes/box-1-1-cropped.svg';
import { ReactComponent as Box4By5Cropped } from '../assets/shapes/box-4-5-cropped.svg';
import { ReactComponent as Box9By16Cropped } from '../assets/shapes/box-9-16-cropped.svg';
import { ReactComponent as Box16By9Cropped } from '../assets/shapes/box-16-9-cropped.svg';

export const RATIO_1_1 = '1:1';
export const RATIO_2_3 = '2:3';
export const RATIO_4_5 = '4:5';
export const RATIO_9_16 = '9:16';
export const RATIO_16_9 = '16:9';

/**
 * Allowed aspect ratio sizes for projects and associated properties
 */
export const ASPECT_RATIOS = [
  RATIO_16_9,
  RATIO_9_16,
  RATIO_1_1,
  RATIO_4_5,
  RATIO_2_3,
];

export const ASPECT_RATIO_ICONS = {
  [RATIO_1_1]: Crop_1_1,
  [RATIO_2_3]: Crop_2_3,
  [RATIO_4_5]: Crop_4_5,
  [RATIO_9_16]: Crop_9_16,
  [RATIO_16_9]: Crop_16_9,
};

export const ASPECT_RATIO_BOXES = {
  [RATIO_1_1]: Box1By1Cropped,
  [RATIO_4_5]: Box4By5Cropped,
  [RATIO_9_16]: Box9By16Cropped,
  [RATIO_16_9]: Box16By9Cropped,
};
