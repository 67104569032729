import { purgeStoredState } from 'redux-persist';

import { REDUX_PERSIST_CONFIG } from '../config/redux';
import * as ActionTypes from '../constants/ActionTypes';
import { SB_URL } from '../constants/Urls';

const defaultUser = {
  email: '',
  firstName: '',
  lastName: '',
  userId: null,
  subscriptionId: null,
  planId: null,
  organizationId: null,
  organizationName: '',
  isEnterprise: false,
};

export const initialState = {
  /**
   * The user's information loaded directly from the backend.
   */
  ...defaultUser,

  /**
   * Non-empty when the user plan is changed or added
   */
  billingChangeMessage: {},

  /**
   * What the user is able to do
   */
  permissions: {
    isStoryblocksAdmin: false,
    isOrgAdmin: false,
    monthlyExports: 0,
    maxDownloadsPerMonth: 0,
    maxExportsPerMonth: 0,
  },

  /**
   * Used for ajax requests
   */
  isLoading: true,
  errorMessage: null,

  /**
   * Path to allow the user to upgrade depending on his plan type
   */
  upgradePath: '',

  visitorId: '',
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USER_LOAD_USER_REQUESTED: {
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    }

    case ActionTypes.USER_LOAD_USER_COMPLETED: {
      const user = action?.payload?.user || defaultUser;
      const permissions =
        action?.payload?.permissions || initialState.permissions;

      const visitorId = action?.payload?.visitorId || initialState.visitorId;

      const billingChangeMessage =
        action?.payload?.billingChangeMessage ||
        initialState.billingChangeMessage;

      let upgradePath =
        action?.payload?.upgradePath || initialState.upgradePath;

      if (!user.isEnterprise && upgradePath && !upgradePath.includes(SB_URL)) {
        upgradePath = `${SB_URL}${upgradePath}`;
      }

      return {
        ...state,
        ...user,
        permissions: { ...permissions },
        billingChangeMessage,
        upgradePath,
        visitorId,
        isLoading: false,
      };
    }

    case ActionTypes.USER_LOAD_USER_FAILED: {
      const error = action?.payload?.error || {};

      return {
        ...state,
        ...initialState,
        errorMessage: error.message || error,
        isLoading: false,
      };
    }

    /**
     * We aggressively clean up state when a user is logged out.
     * Since the app live saves, there should rarely be much
     * that gets lost when this happens.
     */
    case ActionTypes.USER_LOGOUT: {
      purgeStoredState(REDUX_PERSIST_CONFIG);
      return { ...state, ...initialState };
    }

    default:
      return state;
  }
}
