import axios from 'axios';
import queryString from 'query-string';
import useSWR from 'swr';

import { MAKER_API_URL } from '../../constants/Urls';
import makerFlags from './flags';

const ffsFlagMap = Object.fromEntries(
  Object.values(makerFlags)
    .filter((flag) => !!flag.ffsKey)
    .map(({ id, ffsKey }) => [ffsKey, id ?? ffsKey])
);
const ffsFlagKeys = Object.keys(ffsFlagMap);

export const api = axios.create({
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: 'index' }),
  withCredentials: true,
});

const fetcher = async (url) => {
  const params = { flagKeys: ffsFlagKeys };
  try {
    const response = await api.get(url, { params });
    return response.data;
  } catch (error) {
    return {};
  }
};

export const url = `${MAKER_API_URL}/user-attributes/feature-flags`;

export default function useFFSFlags() {
  const { data: ffsFlagsEnabled = {} } = useSWR(url, fetcher);

  // convert feature flag service flag names to maker flag names
  const makerFlags = Object.fromEntries(
    Object.entries(ffsFlagsEnabled).map(([flag, enabled]) => [
      ffsFlagMap[flag],
      enabled,
    ])
  );

  return makerFlags;
}
