import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import PropTypes from 'prop-types';

import PaddedAspectBox from './PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius,
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
  },
  errorIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    fontSize: 78,
  },
  video: {
    margin: 'auto',
    height: '100%',
    outline: 0,
    borderRadius: theme.shape.borderRadius,
  },
}));
export default function VideoLoader(props) {
  const { src, status } = props;
  const classes = useStyles();
  return (
    <PaddedAspectBox className={classes.root} aspectRatio={16 / 9}>
      {status === 'pending' && (
        <CircularProgress
          className={classes.progress}
          color="secondary"
          size={64}
        />
      )}
      {status === 'error' && (
        <ErrorRoundedIcon
          className={classes.errorIcon}
          color="error"
          fontSize="inherit"
        />
      )}
      {status === 'success' && (
        <video className={classes.video} controls>
          <source src={src} type="video/mp4" />
        </video>
      )}
    </PaddedAspectBox>
  );
}

VideoLoader.propTypes = {
  status: PropTypes.oneOf(['error', 'pending', 'success']),
  src: PropTypes.string,
};
