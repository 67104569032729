import { Card, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ControlsPlay } from '@videoblocks/react-icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { YOUTUBE_CARD } from '../../constants/TestSelectors';
import { openVideoPreviewDialog } from '../../features/ui/uiSlice';
import PaddedAspectBox from '../PaddedAspectBox';
import VideoPreview from '../VideoPreview';
import CardBadge from './CardBadge';

const PLAY_ICON_DIMENSION = 32;

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  excerpt: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
  },
  playIcon: {
    position: 'absolute',
    width: PLAY_ICON_DIMENSION,
    height: PLAY_ICON_DIMENSION,
    top: `calc(50% - ${PLAY_ICON_DIMENSION / 2}px)`,
    left: `calc(50% - ${PLAY_ICON_DIMENSION / 2}px)`,
    color: theme.palette.grey[100],
    display: 'none',
    pointerEvents: 'none',
    transition: theme.transitions.create(['display'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  preview: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[900],
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(21),
    },
  },
  videoThumbnail: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      opacity: 0.4,
      '& + .sb-icon': {
        display: 'block',
      },
    },
  },
}));

function YoutubeCard({
  title = '',
  excerpt = '',
  youtubeId,
  duration = '00.00',
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const source = youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : '';
  const thumbnail = youtubeId
    ? `https://img.youtube.com/vi/${youtubeId}/0.jpg`
    : '';

  const onClick = () => {
    dispatch(
      openVideoPreviewDialog({
        src: source,
        iframe: true,
      })
    );
  };

  return (
    <Card className={classes.card} elevation={0} onClick={onClick}>
      <PaddedAspectBox className={classes.preview} aspectRatio={16 / 9}>
        <CardBadge show={true} badgeContent={duration} horizontal="right">
          <VideoPreview
            className={classes.videoThumbnail}
            thumbnail={thumbnail}
            title={title}
            data-testid={YOUTUBE_CARD}
          />
          <SvgIcon
            component={ControlsPlay}
            className={classes.playIcon}
          ></SvgIcon>
        </CardBadge>
      </PaddedAspectBox>
      <>
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
        <Typography className={classes.excerpt} variant="body2">
          {excerpt}
        </Typography>
      </>
    </Card>
  );
}

YoutubeCard.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  thumbnail: PropTypes.string,
  duration: PropTypes.string,
};

export default YoutubeCard;
