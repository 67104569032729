import {
  Button,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { EDIT_PATH, TEMPLATES_PATH } from '../constants/Urls';
import { openProjectDeleteDialog } from '../features/ui/uiSlice';
import useTemplates from '../hooks/useTemplates';
import { selectIsEnterprise, selectUserId } from '../selectors/user';
import ProjectTableRow from './ProjectTableRow';

const useStyles = makeStyles((theme) => ({
  infoTooltip: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
  headerText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const TEAM_ACCESS_TOOLTIP_TEXT =
  'Allow everyone in your organization to view and make a copy of your project';

export default function ProjectTable({
  currentPage = 1,
  isLoading = false,
  moreResultsExist = false,
  onDuplicate: handleDuplicate = () => {},
  onTeamAccessToggle: handleTeamAccessToggle = () => {},
  onViewExports: handleViewExports = () => {},
  openToast,
  projects,
  setCurrentPage = () => {},
  showCreatedBy = true,
  showExports = true,
  showTeamAccessToggle = false,
}) {
  const classes = useStyles();

  const isEnterprise = useSelector(selectIsEnterprise);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const userId = useSelector(selectUserId);
  const { createTemplateFromProject } = useTemplates();

  const handleSelect = useCallback(
    (projectId) => {
      history.push({
        pathname: `${EDIT_PATH}/${projectId}`,
        search,
      });
    },
    [history, search]
  );

  const handlePromptDelete = useCallback(
    (projectId) => {
      dispatch(openProjectDeleteDialog({ projectId }));
    },
    [dispatch]
  );

  const handleSaveAsTemplate = (project) => {
    createTemplateFromProject(project);
    history.push({
      pathname: TEMPLATES_PATH,
      search,
      state: {
        toastMessage: `'${project.name}' has been saved as a Template.`,
      },
    });
  };

  const handleClickNext = () => setCurrentPage(currentPage + 1);
  const handleClickPrev = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    // If the current page gets emptied by deletion, switch to previous page
    if (!isLoading && projects && projects.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [isLoading, projects, currentPage, setCurrentPage]);

  const filterProjects = (project) => {
    return !!project.storyboard;
  };

  const renderProject = (project) => {
    return (
      <ProjectTableRow
        key={project.uid}
        project={project}
        projectOwnedByUser={project.user_id === userId}
        onPromptDelete={() => handlePromptDelete(project.uid)}
        onDuplicate={() => handleDuplicate(project)}
        onSaveAsTemplate={() => handleSaveAsTemplate(project)}
        onSelect={() => handleSelect(project.uid)}
        onViewExports={() => handleViewExports(project)}
        onTeamAccessToggle={() =>
          handleTeamAccessToggle(project.id, !project.team_access, project.name)
        }
        openToast={openToast}
        showCreatedBy={showCreatedBy}
        showExports={showExports}
        showTeamAccessToggle={showTeamAccessToggle}
      />
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="project table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.headerText}>Title</TableCell>
              <TableCell className={classes.headerText}>Length</TableCell>
              <TableCell className={classes.headerText}>Last Edited</TableCell>
              {showTeamAccessToggle && (
                <TableCell className={classes.headerText}>
                  Team Access{' '}
                  <Tooltip title={TEAM_ACCESS_TOOLTIP_TEXT}>
                    <SvgIcon
                      className={classes.infoTooltip}
                      component={QuestionCircleIcon}
                    />
                  </Tooltip>
                </TableCell>
              )}
              {showCreatedBy && isEnterprise && !showTeamAccessToggle && (
                <TableCell className={classes.headerText}>Created by</TableCell>
              )}
              {showExports && (
                <TableCell className={classes.headerText}>Exports</TableCell>
              )}
              <TableCell
                className={classes.headerText}
                aria-label="Actions"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.filter(filterProjects).map(renderProject) ?? null}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <div className={classes.buttonContainer}>
          {currentPage !== 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickPrev}
            >
              Prev
            </Button>
          )}
          {moreResultsExist && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickNext}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </>
  );
}

ProjectTable.propTypes = {
  currentPage: PropTypes.number,
  isLoading: PropTypes.bool,
  moreResultsExist: PropTypes.bool,
  onDuplicate: PropTypes.func,
  onTeamAccessToggle: PropTypes.func,
  openToast: PropTypes.func,
  projects: PropTypes.array,
  setCurrentPage: PropTypes.func,
  showCreatedBy: PropTypes.bool,
  showExports: PropTypes.bool,
  showTeamAccessToggle: PropTypes.bool,
};
