import { Button, SvgIcon } from '@material-ui/core';
import TrimIcon from '@videoblocks/react-icons/Scissors';
import { useDispatch } from 'react-redux';

import FadeControls from '../../components/FadeControls';
import VolumeControls from '../../components/VolumeControls';
import { UPDATE } from '../../constants/MediaActions';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import { openTrimDialog } from '../../features/ui/uiSlice';
import useActiveItem from '../../hooks/useActiveItem';
import { itemUpdated } from '../../slices/storyboardSlice';
import PropertiesPanelHeading from './PropertiesPanelHeading';
import PropertiesCard from './cards/PropertiesCard';

export default function AudioPropertiesPanel() {
  const activeItem = useActiveItem();
  const dispatch = useDispatch();
  const { fadeIn, fadeOut, duration, volume = 1 } = activeItem;

  const handleVolumeChange = (volume) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { volume } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'volume', volume)
    );
  };

  const handleFadeInChange = (fadeIn) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { fadeIn } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'fadeIn', fadeIn)
    );
  };

  const handleFadeOutChange = (fadeOut) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { fadeOut } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'fadeOut', fadeOut)
    );
  };

  const handleTrimButtonClick = () => {
    dispatch(openTrimDialog({ media: activeItem, action: UPDATE }));
    trackEvent(PROPERTIES_PANEL.ITEM_TRIM_MODAL_OPEN, activeItem);
  };

  return (
    <>
      <PropertiesPanelHeading>Audio Properties</PropertiesPanelHeading>
      <PropertiesCard title="Length">
        <Button
          onClick={handleTrimButtonClick}
          variant="outlined"
          startIcon={<SvgIcon component={TrimIcon} />}
          fullWidth
        >
          Trim clip
        </Button>
      </PropertiesCard>
      <PropertiesCard title="Sound">
        <VolumeControls value={volume} onVolumeChange={handleVolumeChange} />
      </PropertiesCard>
      <PropertiesCard title="Fade">
        <FadeControls
          duration={duration}
          fadeIn={fadeIn}
          fadeOut={fadeOut}
          onFadeInChange={handleFadeInChange}
          onFadeOutChange={handleFadeOutChange}
        />
      </PropertiesCard>
    </>
  );
}
