import ZoomInIcon from '@videoblocks/react-icons/Add';
import ZoomOutIcon from '@videoblocks/react-icons/Subtract';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCanZoomIn,
  selectCanZoomOut,
  zoomIn,
  zoomOut,
} from '../../slices/editorSlice';
import { HOTKEYS } from '../../utils/hotkeys';
import ToolbarButton from './ToolbarButton';

export default function ZoomControls() {
  const dispatch = useDispatch();
  const canZoomIn = useSelector(selectCanZoomIn);
  const canZoomOut = useSelector(selectCanZoomOut);

  const handleZoomIn = useCallback(() => {
    dispatch(zoomIn());
  }, [dispatch]);

  const handleZoomOut = useCallback(() => {
    dispatch(zoomOut());
  }, [dispatch]);

  return (
    <div>
      <ToolbarButton
        title="Zoom Out"
        hotkey={HOTKEYS.ZOOM_OUT.keys}
        icon={ZoomOutIcon}
        disabled={!canZoomOut}
        onClick={handleZoomOut}
      />
      <ToolbarButton
        title="Zoom In"
        hotkey={HOTKEYS.ZOOM_IN.keys}
        icon={ZoomInIcon}
        disabled={!canZoomIn}
        onClick={handleZoomIn}
      />
    </div>
  );
}
