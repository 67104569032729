import { Link } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Toast from '../components/Toast';
import { PROJECTS_PATH } from '../constants/Urls';
import { useUserEvent } from '../pusher/hooks';

function getNextValidExport(displayQueue, currentPathname) {
  let exportToDisplay = {};
  let updatedQueue = displayQueue;

  while (!isEmpty(updatedQueue) && isEmpty(exportToDisplay)) {
    const exportEvent = { ...updatedQueue[0] };
    updatedQueue = updatedQueue.slice(1);

    const { projectUid, exportId } = exportEvent;
    const eventExportPathname = `${PROJECTS_PATH}/${projectUid}/exports/${exportId}`;

    // Don't notify if we're already on the export page
    if (currentPathname !== eventExportPathname) {
      exportToDisplay = {
        ...exportEvent,
        exportPathname: eventExportPathname,
      };
    }
  }
  return { exportToDisplay, updatedQueue };
}

export default function ExportsToast() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [displayQueue, setDisplayQueue] = useState([]);
  const [displayedExport, setDisplayedExport] = useState({});

  const { exportPathname, projectName } = displayedExport;
  const isError = !!displayedExport.errorAt;

  useUserEvent('encoding.complete', (event) =>
    setDisplayQueue((prev) => [...prev, event])
  );

  useUserEvent('encoding.error', (event) =>
    setDisplayQueue((prev) => [...prev, event])
  );

  useEffect(() => {
    if (!isEmpty(displayQueue) && !isEmpty(displayedExport) && open) {
      setOpen(false);
    }

    if (!isEmpty(displayQueue) && isEmpty(displayedExport)) {
      const { exportToDisplay, updatedQueue } = getNextValidExport(
        displayQueue,
        location.pathname
      );
      setDisplayedExport(exportToDisplay);
      setDisplayQueue(updatedQueue);
      setOpen(!isEmpty(exportToDisplay));
    }
  }, [displayQueue, displayedExport, open, location.pathname]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setDisplayedExport({});
  };

  return (
    <Toast
      open={open}
      severity={isError ? 'error' : 'success'}
      onExited={handleExited}
      onClose={handleClose}
    >
      <div>
        {isError ? (
          <>
            There was an error while rendering your video.{' '}
            <Link
              component={RouterLink}
              color="secondary"
              to={{ pathname: exportPathname, search: location.search }}
            >
              View more details
            </Link>
          </>
        ) : (
          `${projectName} has finished rendering.`
        )}
      </div>
      <Link
        component={RouterLink}
        color="secondary"
        to={{ pathname: exportPathname, search: location.search }}
        onClick={handleClose}
      >
        View Download
      </Link>
    </Toast>
  );
}
