import { Button, Popover, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaintingPaletteIcon from '@videoblocks/react-icons/PaintingPalette';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';

import { PROPERTIES_PANEL_WIDTH } from '../../constants/Constants';
import useBrands from '../../hooks/useBrands';
import ApplyBrandMenuItem from './ApplyBrandMenuItem';

const brandButtonWidth = PROPERTIES_PANEL_WIDTH - 8;

const useStyles = makeStyles((theme) => ({
  applyBrandButton: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[500],
    borderWidth: 1,
  },
  popoverPaper: {
    width: brandButtonWidth,
    padding: theme.spacing(1, 0),
  },
}));

export default function ApplyBrandMenu() {
  const classes = useStyles();

  const { brands, isLoading } = useBrands();
  const savedBrands = isLoading ? [] : brands.filter((b) => !b.is_draft);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'brand-picker-popover',
  });

  if (savedBrands.length === 0) return null;

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<SvgIcon component={PaintingPaletteIcon} />}
        className={classes.applyBrandButton}
        {...bindTrigger(popupState)}
      >
        Apply Brand Styles
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popoverPaper}>
          {savedBrands.map((brand) => (
            <ApplyBrandMenuItem
              brand={brand}
              key={brand.uid}
              closeMenu={popupState.close}
            />
          ))}
        </div>
      </Popover>
    </>
  );
}
