import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { Fade, Grow, SvgIcon } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as AddCircle } from '../../assets/icons/add-circle.svg';
import image from '../../assets/images/timeline-drop.png';
import { SOURCE_TYPES } from '../../constants/Constants';
import { trackEvent } from '../../events/sendEvents';
import { TIMELINE } from '../../events/tags';
import {
  openAssetDrawer,
  selectOpen,
} from '../../features/assetDrawer/assetDrawerSlice';
import useUploads from '../../hooks/useUploads';
import createObject from '../../models/createObject';
import { itemAdded } from '../../slices/storyboardSlice';
import { mapMediaToLayer } from '../../utils/storyboardUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: '100%',
    color: theme.palette.common.white,
    userSelect: 'none',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  image: {
    width: 128,
    marginBottom: theme.spacing(1),
  },
  placeholder: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'currentColor',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.green[500], 0.4),
  },
  iconWrapper: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
      width: '60%',
      height: '60%',
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  },
  addIcon: {
    position: 'relative',
    fontSize: theme.typography.pxToRem(40),
    color: theme.palette.green[500],
  },
}));

const DROPPABLE_ID = 'empty-layers';

function TimelineEmptyState() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const drawerOpen = useSelector(selectOpen);
  const { projectId, templateId } = useParams();
  const { associateUpload } = useUploads();

  const handleClick = (event) => {
    event.stopPropagation();

    if (!drawerOpen) {
      dispatch(openAssetDrawer());
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (over?.id === DROPPABLE_ID) {
      const { item } = active.data.current;
      const object = await createObject(item);
      dispatch(itemAdded({ layerId: nanoid(), ...object }));

      if (item.sourceType === SOURCE_TYPES.UPLOAD) {
        await associateUpload({
          uploadId: item.id,
          templateUid: templateId,
          projectUid: projectId,
        });
      }

      trackEvent(TIMELINE.ITEM_ADD, {
        ...object,
        insertedIntoGap: false,
        newLayerCreated: true,
        toLayerIndex: 0,
        toLayerType: mapMediaToLayer(item.mediaType),
      });
    }
  };

  const { isOver, setNodeRef } = useDroppable({ id: DROPPABLE_ID });

  useDndMonitor({ onDragEnd: handleDragEnd });

  return (
    <div className={classes.container} onClick={handleClick} ref={setNodeRef}>
      <Fade in={!isOver}>
        <div className={classes.content}>
          <img className={classes.image} src={image} alt="" />
          Drag and drop content here
        </div>
      </Fade>
      <Grow in={isOver}>
        <div className={classes.placeholder}>
          <div className={classes.iconWrapper}>
            <SvgIcon className={classes.addIcon} component={AddCircle} />
          </div>
        </div>
      </Grow>
    </div>
  );
}

export default memo(TimelineEmptyState);
