import { useSelector } from 'react-redux';

import { selectRatio } from '../../slices/storyboardSlice';
import getResolution from '../../utils/getResolution';

export default function usePreviewSize() {
  const ratio = useSelector(selectRatio);

  return getResolution(ratio);
}
