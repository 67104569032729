import { Box, makeStyles, Typography } from '@material-ui/core';
import {
  LaptopSmiley,
  MeetingCamera,
  MicrophoneAlternate,
} from '@videoblocks/react-icons';
import log from 'loglevel';
import { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useDispatch } from 'react-redux';

import UploadAPI from '../../api/UploadAPI';
import {
  DRAWERS,
  openAssetDrawer,
} from '../../features/assetDrawer/assetDrawerSlice';
import UppyInstance from '../UppyInstance';

const reactSvgComponentToMarkupString = (component, props) =>
  `url("data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(component, props))
  ).replace(/svg/, "svg xmlns='http://www.w3.org/2000/svg'")}")`;

const useStyles = makeStyles((theme) => ({
  note: {
    color: theme.palette.grey[500],
  },
  uppy: {
    '& .uppy-Dashboard-inner': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& .uppy-Dashboard-AddFiles-title': {
      display: 'none',
    },
    '& .uppy-DashboardTab': {
      border: 'none',
      height: theme.spacing(12),
      backgroundColor: theme.palette.grey[200],
      marginBottom: theme.spacing(2),
      borderRadius: '16px',
    },
    '& .uppy-DashboardTab-btn': {
      display: 'inline-flex',
      borderRadius: '16px',
    },
    '& .uppy-DashboardTab-inner': {
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      '& svg': {
        visibility: 'hidden',
      },
    },
    '& .uppy-DashboardTab-name': {
      fontWeight: 700,
      color: theme.palette.grey[800],
      position: 'relative',
      marginLeft: theme.spacing(2),
      top: theme.spacing(-3),
    },
    '& .uppy-DashboardTab-name::after': {
      color: theme.palette.grey[600],
      fontSize: theme.typography.pxToRem(11),
      fontWeight: 400,
      lineHeight: theme.typography.button.lineHeight,
      whiteSpace: 'normal',
      textAlign: 'left',
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(0),
      minWidth: theme.spacing(30),
    },
    // Record Screen
    "& button[aria-controls='uppy-DashboardContent-panel--ScreenCapture'] .uppy-DashboardTab-inner":
      {
        content: reactSvgComponentToMarkupString(LaptopSmiley, {
          fill: theme.palette.grey[600],
        }),
      },
    "& button[aria-controls='uppy-DashboardContent-panel--ScreenCapture'] .uppy-DashboardTab-name::after":
      {
        content:
          '"Record a window or program on your screen, and record audio at the same time."',
      },
    // Use Webcam
    "& button[aria-controls='uppy-DashboardContent-panel--Webcam'] .uppy-DashboardTab-inner":
      {
        content: reactSvgComponentToMarkupString(MeetingCamera, {
          fill: theme.palette.grey[600],
        }),
      },
    "& button[aria-controls='uppy-DashboardContent-panel--Webcam'] .uppy-DashboardTab-name::after":
      {
        content: '"Capture a video or photo using the webcam on your device."',
      },
    // Record Audio
    "& button[aria-controls='uppy-DashboardContent-panel--Audio'] .uppy-DashboardTab-inner":
      {
        content: reactSvgComponentToMarkupString(MicrophoneAlternate, {
          fill: theme.palette.grey[600],
        }),
      },
    "& button[aria-controls='uppy-DashboardContent-panel--Audio'] .uppy-DashboardTab-name::after":
      {
        content: '"Use your microphone to add a voiceover for your project."',
      },
  },
}));

export default function RecordDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadResult = async (result, assemblyId) => {
    if (!result || !assemblyId) {
      log.error('Cannot upload malformed recording assembly result to DB', {
        result,
        assemblyId,
      });
    }
    return new UploadAPI().postUpload({
      name: result.name,
      mimetype: result.mime,
      size: result.size,
      filename: `${result.id}.${result.ext}`,
      encoder_id: assemblyId,
      is_logo: false,
      meta: {
        ...result.meta,
        is_recorded: true,
      },
      thumbnail: result.thumbnail,
    });
  };

  return (
    <Box m={2} className={classes.uppy}>
      <Typography variant="h4" component="h2" gutterBottom>
        Recording Options:
      </Typography>
      <UppyInstance
        height="auto"
        id="recording-uploads"
        disableLocalFiles={true}
        saveToDatabase={uploadResult}
        showUploadRestrictions={false}
        useRecordingSources={true}
        useImageEditor={false}
        onComplete={({ firstUploadType }) => {
          dispatch(
            openAssetDrawer({
              drawer: DRAWERS.UPLOADS,
              mediaType: firstUploadType,
            })
          );
        }}
      />
      <Box mt={1}>
        <Typography variant="body2" className={classes.note}>
          For faster uploads, the recommended recording length is five minutes
          or less.
        </Typography>
      </Box>
    </Box>
  );
}
