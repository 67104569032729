import { Box, Link, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useState } from 'react';

import {
  SB_LOGOUT_URL,
  SB_MEMBER_PROFILE_URL,
  SB_SUPPORT_URL,
} from '../../constants/Urls';

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.black,
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
}));

export default function AccountMenu() {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState(null);

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box marginX={2}>
      <AccountCircleOutlinedIcon
        className={classes.icon}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        <MenuItem
          component={Link}
          color="inherit"
          href={SB_MEMBER_PROFILE_URL}
          target="_blank"
          rel="noopener"
        >
          My Account
        </MenuItem>
        <MenuItem
          component={Link}
          color="inherit"
          href={SB_SUPPORT_URL}
          target="_blank"
          rel="noopener"
        >
          Support
        </MenuItem>
        <MenuItem
          component={Link}
          color="inherit"
          href={SB_LOGOUT_URL}
          target="_blank"
          rel="noopener"
        >
          Log out
        </MenuItem>
      </Menu>
    </Box>
  );
}
