import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  topLeft: {
    top: 0,
    left: 0,
  },
  top: {
    right: 0,
    left: 0,
    top: 0,
    margin: '0 auto',
    textAlign: 'center',
  },
  topRight: {
    top: 0,
    right: 0,
  },
  left: {
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  right: {
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
  },
  bottom: {
    bottom: 0,
    right: 0,
    left: 0,
    margin: '0 auto',
    textAlign: 'center',
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
}));

export const Positions = {
  TOP_LEFT: 'topLeft',
  TOP: 'top',
  TOP_RIGHT: 'topRight',

  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',

  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM: 'bottom',
  BOTTOM_RIGHT: 'bottomRight',
};

export default function PositionWrapper(props) {
  const { children, padding = 1, position = Positions.CENTER } = props;

  const classes = useStyles();

  return (
    <Box position="absolute" p={padding} className={classes[position]}>
      {children}
    </Box>
  );
}

PositionWrapper.propTypes = {
  position: PropTypes.oneOf(Object.values(Positions)),
};
