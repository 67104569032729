import * as ContentTypes from '../constants/ContentTypes';

const contentTypesSupport = {
  // Audio content types
  [ContentTypes.MUSIC]: true,
  [ContentTypes.SFX]: true,

  // Image content types
  [ContentTypes.ILLUSTRATION]: true,
  [ContentTypes.PHOTO]: true,
  [ContentTypes.VECTOR]: false,

  // Video content types
  [ContentTypes.FOOTAGE]: true,
  [ContentTypes.MOTION_BACKGROUND]: true,
  [ContentTypes.TEMPLATE]: false,
  [ContentTypes.VR]: false,
};

export default function isSupportedContentType(contentType) {
  return !!contentTypesSupport[contentType];
}
