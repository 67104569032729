import parseStoryboardResponse from '../utils/parseStoryboardResponse';
import stringifyStoryboardRequest from '../utils/stringifyStoryboardRequest';
import AbstractAPI from './AbstractAPI';

export default class ProjectsAPI extends AbstractAPI {
  /**
   * @param {string} contentToken
   */
  constructor(contentToken) {
    if (contentToken) {
      super(false, { token: contentToken }, true);
    } else {
      super();
    }
  }

  /**
   * @param {number} page
   * @returns {Promise<ProjectIndexResponse>}
   */
  getProjects(page) {
    const params = { page };
    return this.get('project', { params });
  }

  /**
   * @param {string} projectUid
   * @returns {Promise<Project>}
   */
  getProject(projectUid) {
    return this.get(`project/${projectUid}`);
  }

  /**
   * @param {Project} project
   * @returns {Promise<ProjectPostResponse>}
   */
  postProject(project) {
    return this.post('project', project);
  }

  /**
   * @param {string} projectUid
   * @param {DuplicateProjectRequestBody} projectFields
   * @returns {Promise<ProjectPostResponse>}
   */
  duplicateProject(projectUid, projectFields) {
    return this.post(`project/${projectUid}/duplicate`, projectFields);
  }

  /**
   * @param {Project} project
   * @returns {Promise<ProjectPutResponse>}
   */
  putProject(project) {
    return this.put(`project/${project.uid}`, project);
  }

  /**
   * @param {string} projectUid
   * @returns {Promise<any>}
   */
  deleteProject(projectUid) {
    return this.delete(`project/${projectUid}`);
  }

  /**
   * @param {string} projectUid
   * @param {boolean} setTeamAccess
   * @returns {Promise<Project>}
   */
  setProjectTeamAccess(projectUid, setTeamAccess) {
    return this.patch(`project/${projectUid}/team-access`, {
      team_access: setTeamAccess,
    });
  }

  /**
   * @param {string} projectUid
   * @param {string} brandUid
   * @returns {Promise<Project>}
   */
  setProjectBrand(projectUid, brandUid) {
    return this.patch(`project/${projectUid}/brand`, {
      brand_uid: brandUid,
    });
  }

  transformResponse(data) {
    return parseStoryboardResponse(data);
  }

  transformRequest(data) {
    return stringifyStoryboardRequest(data);
  }
}
