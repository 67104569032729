import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { selectIsOrgAdmin } from '../../selectors/user';
import StylesForm from '../StylesForm';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

export default function BrandDetailsDrawer({
  appliedBrandUid = null,
  isNewBrand = false,
  navigateToBrand = () => {},
  onApply = () => {},
  onDelete = () => {},
  onLogoSelect = () => {},
  onSaveNewBrand = () => {},
}) {
  const classes = useStyles();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  return (
    <Container className={classes.container}>
      <StylesForm
        appliedBrandUid={appliedBrandUid}
        disabled={!isOrgAdmin}
        isBrandForm={true}
        isDrawer={true}
        isNewBrand={isNewBrand}
        navigateToBrand={navigateToBrand}
        onApply={onApply}
        onDelete={onDelete}
        onLogoSelect={onLogoSelect}
        onSaveNewBrand={onSaveNewBrand}
      />
    </Container>
  );
}
