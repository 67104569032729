import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fontRemoved, selectAllCustomFonts } from '../slices/storyboardSlice';
import useFonts from './useFonts';

export default function useRemoveUnavailableFonts(organizationId) {
  const dispatch = useDispatch();
  const { allFonts, isLoadingMoreAllFonts } = useFonts({ organizationId });
  const customFonts = useSelector(selectAllCustomFonts);

  // Replace any custom user fonts that have been deleted.
  // Make sure to wait until all fonts have been loaded to determine if any are missing.
  useEffect(() => {
    if (isLoadingMoreAllFonts) {
      return;
    }

    const availableFontFileIds = allFonts
      .map((font) => font.fileId)
      .filter((fileId) => !!fileId);
    const deletedFonts = (customFonts || []).filter(
      (customFont) => !availableFontFileIds.includes(customFont?.fileId)
    );

    deletedFonts.forEach((deletedFont) => {
      dispatch(fontRemoved(deletedFont?.name));
    });
  }, [allFonts, customFonts, isLoadingMoreAllFonts, dispatch]);
}
