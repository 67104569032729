import createTextObject from './createTextObject';

export default function createRatioBasedTextObject(ratio, options = {}) {
  if (ratio === '9:16') {
    return createTextObject({
      fontSize: 32,
      text: 'Add Text\nHere',
      ...options,
    });
  }

  if (ratio === '1:1') {
    return createTextObject({
      fontSize: 32,
      ...options,
    });
  }

  return createTextObject(options);
}
