import { levels } from 'loglevel';

import { sendErrorEvent } from '../events/sendAvroEvent';
import sentryTransport from './sentryTransport';

export default async function analyticsErrorTransport({ name, extras }) {
  const { error, ...otherExtras } = extras || {};

  try {
    await sendErrorEvent({
      errorContext: otherExtras,
      errorMessage: error?.message,
      errorStackTrace: error?.stack,
      errorType: name,
    });
  } catch (newError) {
    // Cannot log an error here in the usual way because it can produce an infinite loop
    sentryTransport({
      level: levels.ERROR,
      name: 'Unable to send analytics error event',
      extras: {
        ...otherExtras,
        originalError: error,
        newError,
      },
    });
  }
}
