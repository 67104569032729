import makeStyles from '@material-ui/core/styles/makeStyles';

import PropertiesSelect from '../PropertiesSelect';

const alignmentOptions = {
  topleft: {
    label: 'Top Left',
    horizontal: 'left',
    vertical: 'top',
  },
  topcenter: {
    label: 'Top Center',
    horizontal: 'center',
    vertical: 'top',
  },
  topright: {
    label: 'Top Right',
    horizontal: 'right',
    vertical: 'top',
  },
  centerleft: {
    label: 'Center Left',
    horizontal: 'left',
    vertical: 'center',
  },
  centercenter: {
    label: 'Center',
    horizontal: 'center',
    vertical: 'center',
  },
  centerright: {
    label: 'Center Right',
    horizontal: 'right',
    vertical: 'center',
  },
  bottomleft: {
    label: 'Bottom Left',
    horizontal: 'left',
    vertical: 'bottom',
  },
  bottomcenter: {
    label: 'Bottom Center',
    horizontal: 'center',
    vertical: 'bottom',
  },
  bottomright: {
    label: 'Bottom Right',
    horizontal: 'right',
    vertical: 'bottom',
  },
};

const useStyles = makeStyles((theme) => ({
  list: {
    padding: theme.spacing(2),
    margin: 'auto',
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(3, 40px)',

    '& li': {
      padding: 0,
      height: theme.spacing(5),
      background: theme.palette.grey[300],
    },
  },
}));

function PositionPicker({ onChange, value }) {
  const classes = useStyles();

  const { x, y } = value ?? {};

  return (
    <PropertiesSelect
      displayEmpty
      id="position"
      label="Position"
      MenuProps={{
        classes,
      }}
      onChange={(event) => onChange(alignmentOptions[event.target.value])}
      options={Object.keys(alignmentOptions).map((key) => ({
        label: '',
        value: key,
      }))}
      renderValue={(value) => alignmentOptions[value]?.label || 'Custom'}
      hiddenOptions={[
        {
          label: 'Custom',
          value: 'custom',
        },
      ]}
      value={alignmentOptions[`${y}${x}`] ? `${y}${x}` : 'custom'}
    />
  );
}

export default PositionPicker;
