import * as ActionTypes from '../constants/ActionTypes';

/**
 * This is a separate "workspace" store that only holds information about what
 * current project or template is being created or updated in the editor. It is
 * logically tied to the editor store, but was separated out for cleanliness.
 */
const initialState = {
  isProjectLoaded: false,
  areFontsAvailable: false,
  errorMessage: null,
  selectedProjectId: null,
  lastAutoSavedAt: null,
  autoSavePending: false,
  brandUid: null,
};

export default function workspace(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CLEAR_SELECTED_PROJECT: {
      return {
        ...state,
        isProjectLoaded: false,
        errorMessage: null,
        selectedProjectId: null,
        lastAutoSavedAt: null,
        autoSavePending: false,
      };
    }

    case ActionTypes.LOAD_PROJECT_REQUESTED: {
      return {
        ...state,
        isProjectLoaded: false,
        errorMessage: null,
        selectedProjectId: action.payload,
        lastAutoSavedAt: null,
        autoSavePending: false,
      };
    }

    case ActionTypes.LOAD_PROJECT_FAILED: {
      const error = action.payload;

      return {
        ...state,
        isProjectLoaded: false,
        errorMessage: error?.message || error,
        selectedProjectId: null,
      };
    }

    case ActionTypes.LOAD_PROJECT_COMPLETED: {
      return {
        ...state,
        isProjectLoaded: true,
        errorMessage: null,
        storyboard: action.payload.storyboard,
        brandUid: action.payload.brandUid,
      };
    }

    case ActionTypes.SET_WORKSPACE_PROJECT_ID: {
      return {
        ...state,
        selectedProjectId: action.payload,
      };
    }

    // STORE/SAVE workspace actions
    case ActionTypes.STORE_PROJECT_REQUESTED:
      return {
        ...state,
        errorMessage: null,
        autoSavePending: true,
      };

    case ActionTypes.STORE_PROJECT_COMPLETED:
      return {
        ...state,
        errorMessage: null,
        selectedProjectId: action.payload,
        lastAutoSavedAt: Date.now(),
        autoSavePending: false,
      };

    case ActionTypes.STORE_PROJECT_FAILED: {
      const error = action?.payload?.error;

      return {
        ...state,
        errorMessage: (error && error.message) || error,
        autoSavePending: false,
      };
    }

    case ActionTypes.FONTS_AVAILABLE:
      return {
        ...state,
        areFontsAvailable: true,
      };

    default:
      return state;
  }
}
