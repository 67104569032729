import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { bool, func, string } from 'prop-types';

import CopyTextInput from '../../components/CopyTextInput';

const useStyles = makeStyles((theme) => ({
  borderedCard: {
    boxShadow: '0px 1px 4px rgba(18, 18, 19, 0.2)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    flexDirection: 'column',
  },
  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  sectionBody: {
    marginBottom: theme.spacing(2),
  },
  shareLinkLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
  },
}));

const shareLinkCopy = {
  pending:
    'When your video is finished rendering, a shareable URL will be available.',
  success:
    'Share a private link and viewers will be able to see a preview of your video. Only people with the link will have access.',
};

export default function ShareExportCard({
  isComplete,
  onCopy,
  shareLink,
  withBorder = true,
}) {
  const classes = useStyles();

  const handleCopy = () => {
    onCopy('Link has been copied to clipboard!', 'success');
  };

  return (
    <div
      className={clsx(classes.card, {
        [classes.borderedCard]: withBorder,
      })}
    >
      <Typography className={classes.sectionTitle} variant="h4">
        Share your video
      </Typography>
      <Typography className={classes.sectionBody}>
        {isComplete ? shareLinkCopy.success : shareLinkCopy.pending}
      </Typography>
      <Typography className={classes.shareLinkLabel}>Share URL</Typography>
      <CopyTextInput
        actionText="Copy URL"
        disabled={!isComplete}
        onCopy={() => handleCopy()}
        value={isComplete ? shareLink : ''}
      />
    </div>
  );
}

ShareExportCard.propTypes = {
  isComplete: bool.isRequired,
  onCopy: func.isRequired,
  shareLink: string,
};
