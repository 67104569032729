import { createSelector, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

import { LOAD_PROJECT_REQUESTED } from '../constants/ActionTypes';
import { addRecentItem, removeRecentItem } from '../utils/manageRecentItems';
import { itemAdded, itemDuplicated, itemReplaced } from './storyboardSlice';

export const MIN_ZOOM = -10;
export const MAX_ZOOM = 10;
export const RECENT_COLOR_LIMIT = 7;

export const initialState = {
  /**
   * The id of the currently active/selected item in the timeline
   */
  activeItemId: undefined,

  /**
   * Indicates whether or not the editor is currently loading a preview
   */
  isLoading: false,

  /**
   * Controls the global mute/unmute in the editor
   */
  mute: false,

  /**
   * A circular buffer of recently used colors (per project indexed)
   */
  recentColors: {},

  /**
   * A circular buffer of recently used fonts (global)
   */
  recentFonts: [],

  /**
   * Exponent which affects the calculation of pixels per second
   * which allows for zooming in and out on the timeline
   */
  zoom: 0,

  /**
   * Indicates whether a template or project is being edited
   */
  isEditingTemplate: false,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    addRecentColor(state, action) {
      const { color, projectId } = action.payload;
      return set(
        state,
        ['recentColors', projectId],
        addRecentItem(
          state.recentColors?.[projectId],
          color,
          RECENT_COLOR_LIMIT
        )
      );
    },
    addRecentFont(state, action) {
      state.recentFonts = addRecentItem(state.recentFonts, action.payload);
    },
    preloadPreviewStart(state) {
      state.isLoading = true;
    },
    preloadPreviewSuccess(state) {
      state.isLoading = false;
    },
    removeRecentColors(state, action) {
      const { projectId } = action.payload;
      delete state.recentColors[projectId];
    },
    removeRecentFont(state, action) {
      state.recentFonts = removeRecentItem(
        state.recentFonts,
        action.payload,
        'name'
      );
    },
    setActiveItemId(state, action) {
      state.activeItemId = action.payload;
    },
    setIsEditingTemplate(state, action) {
      state.isEditingTemplate = action.payload;
    },
    toggleMute(state) {
      state.mute = !state.mute;
    },
    zoomIn(state) {
      state.zoom = Math.min(state.zoom + 1, MAX_ZOOM);
    },
    zoomOut(state) {
      state.zoom = Math.max(state.zoom - 1, MIN_ZOOM);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LOAD_PROJECT_REQUESTED, (state) => {
        // ensures persisted state (zoom and mute) is reset between projects
        return Object.assign({}, initialState, {
          recentColors: state.recentColors,
        });
      })
      .addCase(itemAdded, (state, action) => {
        state.activeItemId = action.payload.id;
      })
      .addCase(itemDuplicated, (state, action) => {
        state.activeItemId = action.payload.newItemId;
      })
      .addCase(itemReplaced, (state, action) => {
        state.activeItemId = action.payload.replacement.id;
      });
  },
});

/* ACTIONS */

export const {
  addRecentColor,
  addRecentFont,
  preloadPreviewStart,
  preloadPreviewSuccess,
  removeRecentColors,
  removeRecentFont,
  setActiveItemId,
  setIsEditingTemplate,
  toggleMute,
  zoomIn,
  zoomOut,
} = editorSlice.actions;

/* SELECTORS */
export const selectIsLoading = (state) => state.editor.isLoading;

export const selectMute = (state) => state.editor.mute;

export const selectRecentColors = (state) => state.editor.recentColors;

export const selectRecentFonts = (state) => state.editor.recentFonts;

export const selectZoom = (state) => state.editor.zoom;

export const selectCanZoomIn = createSelector(
  selectZoom,
  (zoom) => zoom < MAX_ZOOM
);

export const selectCanZoomOut = createSelector(
  selectZoom,
  (zoom) => zoom > MIN_ZOOM
);

export const selectRecentColorsByProjectId = createSelector(
  [selectRecentColors, (state, projectId) => projectId],
  (recentColors = {}, projectId) => recentColors[projectId] ?? []
);

export default editorSlice.reducer;
