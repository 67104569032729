const env = process.env;

const appId = env.PUSHER_APP_ID || '';
const key = env.REACT_APP_PUSHER_KEY || '';

// Have to support Node
module.exports = {
  appId,
  key,
  cluster: 'us2',
};
