import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

export default function PropertiesCard({ title, children }) {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
