import { Button, SvgIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UploadButton from '@videoblocks/react-icons/UploadButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { EXPORT_VIDEO } from '../../constants/AnalyticsEvents';
import { EXPORT_BUTTON } from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { EXPORT } from '../../events/tags';
import { openExportDrawer } from '../../features/ui/uiSlice';
import { selectIsStoryboardEmpty } from '../../slices/storyboardSlice';
import { sendNonReduxEvent } from '../../utils/EventsHelper';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  tooltip: {
    padding: theme.spacing(2),
    maxWidth: theme.spacing(32),
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

export default function ExportButton(props) {
  const { showTooltip = false, tooltipText = '' } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const isStoryboardEmpty = useSelector(selectIsStoryboardEmpty);
  const disabled = isStoryboardEmpty;

  const handleClick = () => {
    sendNonReduxEvent({ type: EXPORT_VIDEO });
    trackEvent(EXPORT.SETTINGS);
    dispatch(openExportDrawer());
  };

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      placement="bottom"
      title={!disabled && showTooltip ? tooltipText : ''}
    >
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<SvgIcon component={UploadButton} />}
        disabled={disabled}
        onClick={handleClick}
        data-testid={EXPORT_BUTTON}
      >
        Export Video
      </Button>
    </Tooltip>
  );
}

ExportButton.propTypes = {
  showTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};
