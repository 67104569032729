import tinycolor from 'tinycolor2';

/**
 * Extends tinycolor's toHexString() method to return uppercase hex strings
 * - https://github.com/bgrins/TinyColor#tohexstring
 * - https://github.com/bgrins/TinyColor#accepted-string-input
 *
 * @param {(string|any)} color - A color string or tinycolor instance.
 * @returns {string} - An uppercase hex string (e.g #AABBCC)
 */
export default function toUpperHexString(color) {
  return color ? tinycolor(color).toHexString().toUpperCase() : 'None';
}
