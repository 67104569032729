import { DropShadow } from '@videoblocks/jelly-renderer';
import { useDispatch } from 'react-redux';

import GridSelect from '../../components/GridSelect';
import {
  getAnimationByValue,
  textAnimations,
} from '../../constants/TextAnimations';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import useRenderer from '../../hooks/useRenderer';
import { textItemUpdated } from '../../slices/storyboardSlice';
import PropertiesPanelHeading from './PropertiesPanelHeading';
import TextEditorContainer from './TextEditorContainer';
import PropertiesCard from './cards/PropertiesCard';
import TextStyleCard from './cards/TextStyleCard';

export default function TextPropertiesPanel() {
  const activeItem = useActiveItem();
  const dispatch = useDispatch();
  const { renderer } = useRenderer();

  const handleSelectChange = (event, value) => {
    const animation = getAnimationByValue(value);

    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: {
          animationPreset: value,
          dropShadow: animation?.showDropShadow
            ? activeItem.dropShadow
            : DropShadow.NONE,
          secondaryColor: animation?.showSecondaryColor
            ? activeItem.secondaryColor
            : undefined,
          highlightColor: animation?.showHighlight
            ? activeItem.highlightColor
            : undefined,
        },
      })
    );

    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'animationPreset', value)
    );

    renderer.playItemOnce(activeItem);
  };

  return (
    <>
      <PropertiesPanelHeading>Text Properties</PropertiesPanelHeading>
      <PropertiesCard title="Text">
        <TextEditorContainer />
      </PropertiesCard>
      <TextStyleCard activeItem={activeItem} />
      <PropertiesCard title="Animation">
        <GridSelect
          options={textAnimations}
          onChange={handleSelectChange}
          value={activeItem.animationPreset}
        />
      </PropertiesCard>
    </>
  );
}
