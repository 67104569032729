import { LayerTypes, MediaTypes } from '@videoblocks/jelly-renderer';
import {
  cloneDeep,
  compact,
  isEmpty,
  isNumber,
  pickBy,
  sortBy,
  sortedIndexBy,
} from 'lodash';

import { LAYER_HEIGHT, LAYER_TALL_HEIGHT } from '../constants/Constants';
import { MIN_FONT_SIZE } from '../constants/FontStyles';
import { checkMediaItem } from './mediaUtils';
import parseAspectRatio from './parseAspectRatio';
import { secondsToPixels } from './timelineUtils';

/**
 * Adds an item to an item array.
 *
 *  - The item is added to the array sorted by start time
 *  - The item array is re-sorted so that no overlap occurs between items
 *
 * @param {StoryboardItem[]} items - The array of adds to add to
 * @param {StoryboardItem} item - The new item to add
 * @returns {StoryboardItem[]} Returns a new item array with items sorted by start time
 */
export function addItem(items, item) {
  const index = sortedIndexBy(items, item, 'startTime');
  const sortedItems = insert(items, index, item);

  return removeOverlapsFromLayer(sortedItems);
}

/**
 * Cleans the storyboard by removing null items and layers.
 *
 * @param {Storyboard} storyboard
 * @returns {Storyboard}
 */
export function cleanStoryboard(storyboard) {
  const { items, layers, customFonts, styles } = storyboard;
  const cleanedItems = {
    entities: pickBy(items.entities, (item) => !isEmpty(item)),
    ids: compact(items.ids),
  };

  const cleanedLayers = layers.ids
    .map((id) => layers.entities[id])
    .reduce(
      (result, layer) => {
        if (isEmpty(layer)) {
          return result;
        }

        const updatedLayer = { ...layer, itemIds: compact(layer.itemIds) };

        if (updatedLayer.itemIds?.length > 0) {
          result.entities[layer.id] = updatedLayer;
          result.ids.push(layer.id);
        }

        return result;
      },
      { entities: {}, ids: [] }
    );

  /**
   * Bandaid for FLOW-691. The BE has trouble returning nested empty objects, representing
   * them as arrays instead (https://github.com/FriendsOfSymfony/FOSRestBundle/issues/980).
   * Convert malformed storyboards on load.
   */
  let cleanedCustomFonts = cloneDeep(customFonts);
  if (Array.isArray(customFonts?.entities)) {
    cleanedCustomFonts.entities = {};
  }
  let cleanedStyles = cloneDeep(styles);
  if (Array.isArray(styles)) {
    cleanedStyles = {};
  }

  return {
    ...storyboard,
    items: cleanedItems,
    layers: cleanedLayers,
    customFonts: cleanedCustomFonts,
    styles: cleanedStyles,
  };
}

/**
 * Finds and returns the first gap, if any
 * @param {StoryboardItem[]} timelineItems - array of timeline items in layer
 * @param {StoryboardItem} item - item being added
 * @returns {null | TimelineGap}
 */
export function findGap(timelineItems, item) {
  const { startTime = 0, duration = 0 } = item;
  const layerGaps = getTimelineGaps(timelineItems);

  return layerGaps.find(
    (gap) =>
      gap.startTime <= startTime &&
      gap.startTime + gap.duration >= startTime + duration
  );
}

/**
 *
 * @param {StoryboardItem[]} layersWithItems - array of layers with items
 * @param {StoryboardItem} item - Storyboard Item
 * @returns {number} Returns the layer index
 */
export function getInsertLayerIndex(layersWithItems = [], item) {
  const layerIndex = layersWithItems.findIndex(
    ({ type }) => type === mapMediaToLayer(item.mediaType)
  );

  if (layerIndex < 0) {
    return layerIndex;
  }

  const layerItems = layersWithItems[layerIndex].items || [];
  const layerDuration = getLayerDuration(layerItems);
  const gap = findGap(layerItems, item);

  return item.startTime >= layerDuration || gap ? layerIndex : -1;
}

/**
 * Determines a seek time, either the start or midpoint of an item
 *
 * @param {StoryboardItem} item - The item
 * @returns {number} Returns a timestamp
 */
export function getItemSeekTime(item = {}) {
  const { isAnimation, isText } = checkMediaItem(item);
  const { startTime = 0, duration = 0 } = item;
  if (isAnimation || isText) {
    return startTime + duration / 2;
  }
  return startTime;
}

const REPLACE_MINIMUM_PX = 50;
export const REPLACE_TARGET_ZONE = 0.8;

/**
 * Returns the item that the passed start time overlaps with, if any.
 * @param {number} dragStartTime - dragged item start time (in seconds)
 * @param {StoryboardItem[]} timelineItems - array of timeline items to check against
 * @param {int} zoom - the current timeline zoom
 * @param {MediaType} mediaType - the dragged item's media type
 * @param {float} replaceTarget - decimal representation of percentage of searched items' durations to compare against
 * @returns {null | StoryboardItem}
 */
export function getItemToReplace(
  dragStartTime,
  timelineItems = [],
  zoom,
  mediaType = null,
  replaceTarget = REPLACE_TARGET_ZONE
) {
  return timelineItems.find(
    (item) =>
      (!mediaType || item.mediaType === mediaType) &&
      secondsToPixels(item.duration, zoom) > REPLACE_MINIMUM_PX &&
      dragStartTime >= item.startTime &&
      dragStartTime < item.startTime + item.duration * replaceTarget
  );
}

/**
 * Returns the layer duration (i.e. end timestamp of last item in layer)
 * @param {StoryboardItem[]} layerItems
 */
export function getLayerDuration(layerItems = []) {
  return layerItems.reduce((max, item) => {
    const { startTime = 0, duration = 0 } = item || {};
    const endTime = startTime + duration;
    return endTime > max ? endTime : max;
  }, 0);
}

/**
 * Returns the layer height in pixels.
 * @param {LayerType} layerType
 * @returns {number}
 */
export function getLayerHeight(layerType) {
  return layerType === LayerTypes.VIDEO ? LAYER_TALL_HEIGHT : LAYER_HEIGHT;
}

/**
 * Returns an array of gaps in the timeline layer
 * @param {StoryboardItem[]} timelineItems - array of timeline items to check against
 * @returns {[] | TimelineGap[]}
 */
export function getTimelineGaps(timelineItems = []) {
  const sortedItems = sortBy(timelineItems, 'startTime');

  return sortedItems.reduce((result, current, currentIndex, array) => {
    if (isEmpty(current)) {
      return result;
    }

    const previous = array[currentIndex - 1] || { startTime: 0, duration: 0 };

    const startTime = previous.startTime + previous.duration;
    const endTime = current.startTime;
    const duration = endTime - startTime;
    const gap = { startTime, duration };

    if (duration > 0) {
      result.push(gap);
    }

    return result;
  }, []);
}

/**
 * Inserts element(s) into an array at an index.
 * - Note: This method does _not_ mutate the original array
 *
 * @param {Array} array - The array
 * @param {number} index - The index to add element(s) at
 * @param  {...any} newElements - The new elements to insert
 * @returns {Array} Returns a new array with the inserted elements
 */
function insert(array, index, ...newElements) {
  return [...array.slice(0, index), ...newElements, ...array.slice(index)];
}

/**
 * Evaluates whether or not a layer type and media type are compatible.
 *
 * @param {LayerType} layerType - The layer type
 * @param {MediaType} mediaType - The media type
 * @returns {boolean} Returns true if the layer accepts the media, else false
 */
export function layerAcceptsMediaType(layerType, mediaType) {
  return layerType ? mapMediaToLayer(mediaType) === layerType : true;
}

/**
 * Maps a media type to the layer type that accepts it.
 *
 * @param {MediaType} mediaType - The media type
 * @returns {null | LayerType} Returns the layer type that accepts the media type
 */
export function mapMediaToLayer(mediaType) {
  switch (mediaType) {
    case MediaTypes.AUDIO:
      return LayerTypes.AUDIO;
    case MediaTypes.VIDEO:
    case MediaTypes.IMAGE:
      return LayerTypes.VIDEO;
    case MediaTypes.TEXT:
    case MediaTypes.ANIMATION:
      return LayerTypes.TEXT;
    default:
      return null;
  }
}

/**
 * Removes overlaps from layer items.
 *
 * @param {StoryboardItem[]} layerItems - Sorted array of layer items
 * @returns {StoryboardItem[]} Returns a new item array without overlaps
 */
export function removeOverlapsFromLayer(layerItems) {
  return layerItems.reduce((result, current, currentIndex) => {
    const previous = result[currentIndex - 1] || { startTime: 0, duration: 0 };

    const previousEnd = previous.startTime + previous.duration;
    if (current.startTime < previousEnd) {
      result.push({ ...current, startTime: previousEnd });
    } else {
      result.push(current);
    }

    return result;
  }, []);
}

/**
 * Takes an item and updates to be made to the item, and adjusts
 * fade properties to be less than the item duration.
 *
 * @param {StoryboardItem} item - The item to update
 * @param {object} changes - The item properties to update
 * @returns {StoryboardItem} Returns the item with the changes sanitized
 */
export function sanitizeFadeOptions(item, changes) {
  const fadeInRatio = (item.fadeIn || 0) / item.duration;
  const fadeOutRatio = (item.fadeOut || 0) / item.duration;

  const { duration, fadeIn, fadeOut } = { ...item, ...changes };

  const fadeOptions = {
    ...(fadeIn == null
      ? {}
      : {
          fadeIn:
            fadeIn + fadeOut > duration
              ? Math.min(
                  item.fadeIn,
                  parseFloat(fadeInRatio * duration).toFixed(1)
                )
              : fadeIn,
        }),
    ...(fadeOut == null
      ? {}
      : {
          fadeOut:
            fadeIn + fadeOut > duration
              ? Math.min(
                  item.fadeOut,
                  parseFloat(fadeOutRatio * duration).toFixed(1)
                )
              : fadeOut,
        }),
  };

  return {
    ...changes,
    ...fadeOptions,
  };
}

/**
 * Takes an item and splits it in two based on a timestamp.
 *
 * @param {StoryboardItem} item - The item to split
 * @param {number} splitTime - The timestamp to split at
 * @param {string} newItemId - The id to assign to the new (left) item
 * @returns {[StoryboardItem, StoryboardItem]} Returns a tuple of items
 */
export function splitItem(item, splitTime, newItemId) {
  const { duration, fadeIn, fadeOut, trimStart, startTime } = item;

  const left = {
    ...item,
    duration: splitTime - startTime,
    id: newItemId,
  };

  if (isNumber(fadeOut)) {
    left.fadeOut = 0;
  }

  const right = {
    ...item,
    startTime: splitTime,
    duration: duration + startTime - splitTime,
  };

  if (isNumber(fadeIn)) {
    right.fadeIn = 0;
  }

  if (isNumber(trimStart)) {
    right.trimStart = trimStart + splitTime - startTime;
  }

  return [left, right];
}

export function getLayersProperties(layers) {
  return layers?.reduce(
    (previous, layer) => ({
      layerOrder: [previous.layerOrder, `${layer.type} ${layer.itemIds.length}`]
        .filter((l) => !!l)
        .join(','),
      numItems: previous.numItems + layer.itemIds.length,
      numLayers: previous.numLayers + 1,
    }),
    {
      layerOrder: null,
      numItems: 0,
      numLayers: 0,
    }
  );
}

export function scaleText(fontSize, scale) {
  return Math.max(fontSize * scale, MIN_FONT_SIZE);
}

/**
 * Duplicates the storyboard and performs all necessary updates.
 * Updates:
 *  - Scale text items for new aspect ratios.
 *
 * @param {Storyboard} storyboard
 * @param {ScaleStoryboardChanges} changes
 * @returns {Storyboard}
 */
export function scaleStoryboard(storyboard, changes) {
  const { ratio: newRatio } = changes;
  const { items, ratio: oldRatio } = storyboard;
  const textItemScale = parseAspectRatio(newRatio) / parseAspectRatio(oldRatio);

  const updatedItems = items.ids
    .map((id) => items.entities[id])
    .reduce(
      (result, item) => {
        const fontSize =
          item.mediaType === MediaTypes.TEXT
            ? scaleText(item.fontSize, textItemScale)
            : item.fontSize;

        const updatedItem = {
          ...item,
          fontSize,
        };

        result.entities[item.id] = updatedItem;
        result.ids.push(item.id);

        return result;
      },
      { entities: {}, ids: [] }
    );

  return {
    ...storyboard,
    ratio: newRatio,
    items: updatedItems,
  };
}
