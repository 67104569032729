import { Slide, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Alert from './Alert';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: theme.spacing(12),
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function Toast(props) {
  const { autoHideDuration, onClose, onExited, open, severity, message } =
    props;
  const classes = useStyles();

  return (
    open && (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        TransitionProps={{ onExited }}
      >
        <Alert severity={severity} shadow onClose={onClose}>
          {message || props.children}
        </Alert>
      </Snackbar>
    )
  );
}

Toast.propTypes = {
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  open: PropTypes.bool,
  severity: PropTypes.string,
};

Toast.defaultProps = {
  autoHideDuration: 6000, // six seconds
};
