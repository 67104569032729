import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

import { COLOR_PREVIEW } from '../../constants/TestSelectors';
import { checkerboardBackground } from './checkerboardBackground';

const useStyles = makeStyles((theme) => ({
  colorPreview: {
    position: 'relative',
    width: '2em',
    height: '2em',
    flexShrink: 0,
    backgroundColor: theme.palette.common.white,
    backgroundImage: checkerboardBackground,
    backgroundPosition: 'center',
    borderRadius: (props) => (props.rounded ? '50%' : theme.shape.borderRadius),
  },
  colorPreviewColor: {
    height: '100%',
    borderRadius: 'inherit',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
}));

export default function ColorPreview({ color, rounded }) {
  const classes = useStyles({ rounded });
  const style = { backgroundColor: tinycolor(color).toRgbString() };

  return (
    <div className={classes.colorPreview}>
      <div
        className={classes.colorPreviewColor}
        style={style}
        data-testid={COLOR_PREVIEW}
      />
    </div>
  );
}

ColorPreview.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  rounded: PropTypes.bool,
};

ColorPreview.defaultProps = {
  color: { r: 0, g: 0, b: 0, a: 1 },
  rounded: false,
};
