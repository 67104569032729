import { Renderer } from '@videoblocks/jelly-renderer';
import { nanoid } from 'nanoid';

export default function createBaseObject(options) {
  return {
    // generated attributes
    id: nanoid(),
    startTime: Renderer.getInstance().currentTime,

    // passed attributes
    duration: options.duration,
    mediaType: options.mediaType,

    ...options,
  };
}
