import { makeStyles } from '@material-ui/core/styles';
import VideocamOffIcon from '@material-ui/icons/VideocamOffRounded';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import {
  VIDEO_PREVIEW,
  VIDEO_PREVIEW_CONTAINER,
  VIDEO_PREVIEW_SOURCE,
  VIDEO_THUMBNAIL,
} from '../constants/TestSelectors';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[200],
  },
  rootBorderRadius: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    // creates a new stacking context to apply border-radius in Safari
    // https://bugs.webkit.org/show_bug.cgi?id=98538
    zIndex: 0,
  },
  fallbackIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '20%',
    height: 'auto',
  },
  iframe: {
    objectFit: 'contain', // important for Safari
    objectPosition: 'center top', // important for Safari
    width: '100%',
    height: '100%',
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: ({ objectFit }) => objectFit,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: ({ objectFit }) => objectFit,
  },
}));

/** FIXME: rename this component to VideoPlayer, since it's doing more than previews at this point */
export default function VideoPreview({
  autoPlay = false,
  borderRadius = true,
  className,
  loop = true,
  muted = true,
  controls = false,
  iframe = false,
  objectFit = 'cover',
  playOnHover = true,
  source,
  thumbnail,
  title = '',
  ...rest
}) {
  const videoRef = useRef(null);
  const promiseRef = useRef(null);

  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const classes = useStyles({ objectFit });

  if (source?.includes('/image/')) {
    thumbnail = source;
    source = null;
  }

  const handleThumbnailLoad = () => {
    setIsThumbnailLoaded(true);
  };

  const handleMouseEnter = () => {
    if (thumbnail) {
      setIsHovered(true);
    } else if (playOnHover) {
      playVideo();
    }
  };

  const handleMouseLeave = () => {
    if (thumbnail) {
      setIsHovered(false);
    } else if (playOnHover) {
      stopVideo();
    }
  };

  const playVideo = () => {
    const videoElement = videoRef.current;
    if (videoElement && !!source) {
      videoElement.currentTime = 0;
      promiseRef.current = videoElement.play();
    }
  };

  const stopVideo = async () => {
    if (!promiseRef.current) {
      return;
    }

    // since video.play() is async, we need to wait
    // until the promise fulfills before pausing
    await promiseRef.current;

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.rootBorderRadius]: borderRadius,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={VIDEO_PREVIEW_CONTAINER}
      {...rest}
    >
      {!source && !thumbnail && (
        <VideocamOffIcon
          className={classes.fallbackIcon}
          titleAccess="video missing"
        />
      )}
      {thumbnail && (
        <img
          className={classes.thumbnail}
          style={{ opacity: isThumbnailLoaded ? 1 : 0 }}
          draggable={false}
          loading="lazy"
          src={thumbnail}
          alt={title}
          onLoad={handleThumbnailLoad}
          data-testid={VIDEO_THUMBNAIL}
        />
      )}
      {thumbnail && isHovered && (
        <video
          className={classes.video}
          autoPlay={playOnHover}
          loop={loop}
          muted={muted}
          data-testid={VIDEO_PREVIEW}
          onContextMenu={(event) => event.preventDefault()}
        >
          <source
            src={source}
            type="video/mp4"
            data-testid={VIDEO_PREVIEW_SOURCE}
          />
        </video>
      )}
      {!thumbnail && !iframe && source && (
        <video
          className={classes.video}
          loop={loop}
          autoPlay={autoPlay}
          controls={controls}
          muted={muted}
          data-testid={VIDEO_PREVIEW}
          ref={videoRef}
          onContextMenu={(event) => event.preventDefault()}
        >
          <source
            src={source}
            type="video/mp4"
            data-testid={VIDEO_PREVIEW_SOURCE}
          />
        </video>
      )}
      {!thumbnail && iframe && source && (
        <iframe
          className={classes.iframe}
          title={title}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={source}
          data-testid={VIDEO_PREVIEW}
        ></iframe>
      )}
    </div>
  );
}

VideoPreview.propTypes = {
  autoPlay: PropTypes.bool,
  classes: PropTypes.object,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  objectFit: PropTypes.oneOf(['cover', 'contain']),
  source: PropTypes.string,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
};
