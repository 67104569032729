import { migrate } from '@videoblocks/jelly-scripts';
import log from 'loglevel';
import { createAction } from 'redux-actions';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import CustomTemplatesAPI from '../api/CustomTemplatesAPI';
import ProjectsAPI from '../api/ProjectsAPI';
import * as ActionTypes from '../constants/ActionTypes';
import { trackEvent } from '../events/sendEvents';
import { PROJECT } from '../events/tags';
import { cleanStoryboard } from '../utils/storyboardUtils';

/**
 * FIXME: these actions should be converted to a slice.
 * Feels like a mixture of editor and storyboard things.
 */

// store project actions
export const storeProjectRequested = createAction(
  ActionTypes.STORE_PROJECT_REQUESTED
);
export const storeProjectFailed = createAction(
  ActionTypes.STORE_PROJECT_FAILED,
  (error) => error
);
export const storeProjectCompleted = createAction(
  ActionTypes.STORE_PROJECT_COMPLETED,
  (selectedProjectId) => selectedProjectId
);

// load project actions
export const loadProjectRequested = createAction(
  ActionTypes.LOAD_PROJECT_REQUESTED,
  (selectedProjectId) => selectedProjectId
);
export const loadProjectFailed = createAction(
  ActionTypes.LOAD_PROJECT_FAILED,
  (error) => error
);
export const loadProjectCompleted = createAction(
  ActionTypes.LOAD_PROJECT_COMPLETED
);

// Misc
export const setWorkspaceProjectId = createAction(
  ActionTypes.SET_WORKSPACE_PROJECT_ID,
  (selectedProjectId) => selectedProjectId
);

export const fontsAvailable = createAction(ActionTypes.FONTS_AVAILABLE);

export function loadProject(projectId, token) {
  return async (dispatch, getState) => {
    dispatch(loadProjectRequested(projectId));

    try {
      // FIXME should be able to use project as an arg, instead of re-fetching and re-migrating
      const { storyboard, brand_uid: brandUid } = await new ProjectsAPI(
        token
      ).getProject(projectId);
      const migratedStoryboard = migrate(storyboard);
      const cleanedStoryboard = cleanStoryboard(migratedStoryboard);

      dispatch(
        loadProjectCompleted({ storyboard: cleanedStoryboard, brandUid })
      );
      trackEvent(PROJECT.LOAD, { projectUid: projectId }); // FIXME we probably don't care about this event-- confirm with analytics
      dispatch(UndoActionCreators.clearHistory());
    } catch (error) {
      log.error(`Error loading and migrating project: ${error?.message}`, {
        error,
      });
      dispatch(loadProjectFailed(error));
    }
  };
}

export function loadTemplateAsProject(templateId) {
  return async (dispatch, getState) => {
    dispatch(loadProjectRequested(templateId));

    try {
      // FIXME should be able to use template as an arg, instead of re-fetching and re-migrating.
      // That should also allow make this method identical to the previous method (and removable).
      const { storyboard, brand_uid: brandUid } =
        await new CustomTemplatesAPI().getTemplate(templateId);
      const migratedStoryboard = migrate(storyboard);
      const cleanedStoryboard = cleanStoryboard(migratedStoryboard);

      dispatch(
        loadProjectCompleted({ storyboard: cleanedStoryboard, brandUid })
      );
      dispatch(UndoActionCreators.clearHistory());
    } catch (error) {
      log.error(`Error loading and migrating template: ${error?.message}`, {
        error,
      });
      dispatch(loadProjectFailed(error));
    }
  };
}

// unload project actions
export const clearSelectedProject = createAction(
  ActionTypes.CLEAR_SELECTED_PROJECT
);

export function unloadProject() {
  return async (dispatch) => {
    dispatch(clearSelectedProject());
    dispatch(UndoActionCreators.clearHistory());
  };
}
