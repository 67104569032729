import { useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

/**
 * Hook that determines if an element is in view relative to a container.
 * The hook returns true if the element is currently in view or has ever been in view.
 *
 * @param elementRef Ref of an element.
 * @param [root] Optional container to intersect against. Defaults to whole viewport.
 * @param [forAtLeastMs] Optionally require that the element is in view for this duration.
 * @returns {boolean}
 */
export default function useHasEverBeenInView(
  elementRef,
  { root = null, forAtLeastMs = 0 } = {}
) {
  if (!elementRef || !('current' in elementRef)) {
    elementRef = { current: elementRef };
  }

  const intersection = useIntersection(elementRef, {
    root,
  });
  const isCurrentlyInView = intersection?.isIntersecting || false;
  const [hasEverBeenInView, setHasEverBeenInView] = useState(false);

  useEffect(() => {
    if (hasEverBeenInView) return;

    let timeout;
    if (isCurrentlyInView) {
      // Wait to make sure the element stays in view for the specified amount of time.
      // If it leaves view, the timeout will be canceled and the hook still returns false.
      timeout = setTimeout(() => {
        setHasEverBeenInView(true);
      }, forAtLeastMs);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isCurrentlyInView, forAtLeastMs, hasEverBeenInView]);

  return hasEverBeenInView;
}
