import {
  Box,
  Card,
  CardActions,
  CardMedia,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import FilterIcon from '@videoblocks/react-icons/FilterImage';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import tinycolor from 'tinycolor2';

import {
  DEFAULT_BRAND_LOGO_BACKGROUND_COLOR,
  DEFAULT_BRAND_NAME,
  DEFAULT_BRAND_PRIMARY_COLOR,
  DEFAULT_BRAND_SECONDARY_COLOR,
} from '../../constants/StylesConstants';
import useUploads from '../../hooks/useUploads';
import theme from '../../styles/theme';
import BrandSettingsMenu from '../BrandSettingsMenu';
import PaddedAspectBox from '../PaddedAspectBox';
import CardBadge from './CardBadge';

const useStyles = makeStyles((theme) => ({
  brandInfo: {
    paddingTop: theme.spacing(1),
  },
  brandPrimaryColor: {
    width: '50%',
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  brandSecondaryColor: {
    width: '50%',
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  buttonContainer: {
    flexShrink: 0,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    cursor: 'pointer',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    width: '100%',
  },
  colorContainer: {
    height: '25%',
    display: 'flex',
    marginTop: theme.spacing(0.25),
    columnGap: theme.spacing(0.25),
  },
  editedAt: {
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  greyBorder: {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  logo: {
    display: 'flex',
    height: '100%',
    width: '100%',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundSize: 'contain',
    backgroundColor: theme.palette.grey[200],
  },
  missingLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    fontSize: theme.spacing(12),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundColor: tinycolor(
      DEFAULT_BRAND_LOGO_BACKGROUND_COLOR
    ).toHexString(),
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexBasis: 'auto',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  titleBar: {
    padding: 0,
    justifyContent: 'space-between',
  },
}));

function BrandCard({
  brand = {},
  isBrandApplied = false,
  isDefault = false,
  isDrawer = false,
  className: additionalClasses = {},
  isOrgAdmin = false,
  onClick = () => {},
  onApply = () => {},
  onViewDetails = () => {},
  onEdit = () => {},
  onDelete = () => {},
}) {
  const {
    uid,
    organization_id: organizationId,
    updated_at: updatedAt,
  } = brand || {};
  const {
    primaryTextColor: primaryColor = DEFAULT_BRAND_PRIMARY_COLOR,
    secondaryTextColor: secondaryColor = DEFAULT_BRAND_SECONDARY_COLOR,
  } = brand?.detail?.styles || {};
  const brandName = brand?.detail?.name || DEFAULT_BRAND_NAME;
  const classes = useStyles(additionalClasses);

  const { data: logosData } = useUploads(MediaTypes.IMAGE, {
    isLogo: true,
    brandUid: uid,
    organization: organizationId,
  });
  const logo = logosData[0];

  const lastEdited =
    updatedAt && format(parseISO(updatedAt), 'MM/dd/yy hh:mm aa');

  const showCardBadge = isBrandApplied || (!isDrawer && isDefault);
  const cardBadgeContent = isBrandApplied ? 'Applied' : 'Default';

  const showColorBorder = (color) => {
    const brandColor = tinycolor(color);
    const white = tinycolor(`rgba(255, 255, 255, ${brandColor.getAlpha()})`);
    const isTransparent = brandColor.getAlpha() === 0;
    const isWhite = tinycolor.equals(brandColor, white);

    return isTransparent || isWhite;
  };

  return (
    <Card
      className={clsx(classes.card, additionalClasses)}
      elevation={0}
      onClick={onClick}
    >
      <PaddedAspectBox aspectRatio={10 / 6}>
        <Box height="70%">
          <CardBadge show={showCardBadge} badgeContent={cardBadgeContent}>
            {logo?.files?.[0]?.url ? (
              <CardMedia
                image={logo?.files?.[0]?.url}
                className={classes.logo}
                title="brand logo"
              />
            ) : (
              <Box className={classes.missingLogoContainer}>
                <SvgIcon
                  component={FilterIcon}
                  title="missing brand logo"
                  fontSize="inherit"
                  style={{
                    color: theme.palette.common.white,
                    width: '30%',
                    height: 'auto',
                  }}
                />
              </Box>
            )}
          </CardBadge>
        </Box>
        <div className={classes.colorContainer}>
          <div
            className={clsx(classes.brandPrimaryColor, {
              [classes.greyBorder]: showColorBorder(primaryColor),
            })}
            title={`${
              primaryColor === DEFAULT_BRAND_PRIMARY_COLOR ? 'missing ' : ''
            } primary brand color`}
            style={{ backgroundColor: tinycolor(primaryColor) }}
          />
          <div
            className={clsx(classes.brandSecondaryColor, {
              [classes.greyBorder]: showColorBorder(secondaryColor),
            })}
            title={`${
              secondaryColor === DEFAULT_BRAND_SECONDARY_COLOR ? 'missing ' : ''
            } secondary brand color`}
            style={{ backgroundColor: tinycolor(secondaryColor) }}
          />
        </div>
      </PaddedAspectBox>
      <div className={classes.brandInfo}>
        <CardActions className={classes.titleBar} disableSpacing>
          <Typography className={classes.title} variant="body1" noWrap>
            {brandName}
          </Typography>
          {isOrgAdmin && (
            <div className={classes.buttonsWrapper}>
              <div
                className={classes.buttonContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <BrandSettingsMenu
                  isDefault={isDefault}
                  isDrawer={isDrawer}
                  onApply={onApply}
                  onEdit={onEdit}
                  onViewDetails={onViewDetails}
                  onDelete={onDelete}
                  brandUid={brand.uid}
                />
              </div>
            </div>
          )}
        </CardActions>
        <Typography className={classes.editedAt} variant="body2">
          {lastEdited && `Last edited ${lastEdited}`}
        </Typography>
      </div>
    </Card>
  );
}

export default BrandCard;
