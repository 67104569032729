import { Box, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import { parse, stringify } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  EDIT_PATH,
  HOME_PATH,
  PROJECTS_PATH,
  TEMPLATES_PATH,
} from '../../constants/Urls';
import { openSaveAsTemplateDialog } from '../../features/ui/uiSlice';
import useQuery from '../../hooks/useQuery';
import {
  selectHasUnlimitedExports,
  selectIsEnterprise,
  selectIsLimitedStarterBundle,
} from '../../selectors/user';
import { selectWorkspaceProjectId } from '../../selectors/workspace';
import theme from '../../styles/theme';
import AutosaveNotification from './AutosaveNotification';
import ExportButton from './ExportButton';
import MakerHomeButton from './MakerHomeButton';
import NavActionButton from './NavActionButton';
import NavLink from './NavLink';
import Navbar from './Navbar';
import ProjectNameInput from './ProjectNameInput';
import UpsellButton from './UpsellButton';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    minWidth: 300,
    textAlign: 'right',
    '& > button': {
      margin: theme.spacing('auto', 1),
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
  },
}));

export default function EditorNavbar() {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { firstTimeUser } = useQuery();
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const isScreenSizeSmall = useMediaQuery(theme.breakpoints.down('md'));

  const isEnterprise = useSelector(selectIsEnterprise);
  const hasUnlimitedExports = useSelector(selectHasUnlimitedExports);
  const isLimitedStarterBundle = useSelector(selectIsLimitedStarterBundle);
  const showWatermarkTooltip = !hasUnlimitedExports || isLimitedStarterBundle;
  const isEditingTemplate = !!templateId;
  const id = useSelector(selectWorkspaceProjectId);

  return (
    <Navbar>
      <Box display="flex" alignItems="center" marginRight="auto" flexShrink={0}>
        <MakerHomeButton />
        <NavLink
          path={
            firstTimeUser
              ? HOME_PATH
              : isEditingTemplate
              ? TEMPLATES_PATH
              : PROJECTS_PATH
          }
          title={
            firstTimeUser
              ? 'Home'
              : isEditingTemplate
              ? 'My Templates'
              : 'My Projects'
          }
          startIcon={<ChevronLeftRoundedIcon />}
        />
        <Box mx={0.5} height={32}>
          <Divider className={classes.divider} orientation="vertical" />
        </Box>
        <ProjectNameInput />
        <AutosaveNotification />
      </Box>
      <div className={classes.buttonContainer}>
        {!hasUnlimitedExports && (
          <UpsellButton
            variant="contained"
            iconButton={
              isEditingTemplate
                ? false // one button present, never tiny it
                : isScreenSizeSmall // three buttons present
            }
          />
        )}
        {isEnterprise && (
          <NavActionButton
            onClick={() =>
              isEditingTemplate
                ? history.push({
                    pathname: EDIT_PATH,
                    search: `${stringify({
                      ...parse(search),
                      fromTemplateId: id,
                    })}`,
                  })
                : dispatch(
                    openSaveAsTemplateDialog({
                      onConfirm: async (newName) =>
                        history.push({
                          pathname: TEMPLATES_PATH,
                          search,
                          state: {
                            toastMessage: `'${newName}' has been saved as a Template.`,
                          },
                        }),
                    })
                  )
            }
            color={
              isEditingTemplate
                ? {
                    color: theme.palette.common.black,
                    bgColor: theme.palette.primary.main,
                    bgColorHover: theme.palette.primary.dark,
                  }
                : {
                    color: theme.palette.common.black,
                    bgColor: theme.palette.grey[200],
                    bgColorHover: theme.palette.grey[300],
                  }
            }
            iconButton={isScreenSizeSmall}
            buttonText={
              isEditingTemplate
                ? 'Create Project from Template'
                : 'Save as Template'
            }
          />
        )}
        {!isEditingTemplate && (
          <ExportButton
            showTooltip={showWatermarkTooltip}
            tooltipText="Video will export with watermarks. Upgrade plan to remove."
          />
        )}
      </div>
    </Navbar>
  );
}
