import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { TIMELINE_TOOLBAR_HEIGHT } from '../../constants/Constants';
import { TIMELINE_TOOLBAR } from '../../constants/IntercomSelectors';
import { selectIsNearLayerLimit } from '../../slices/storyboardSlice';
import ItemControls from './ItemControls';
import LayerLimit from './LayerLimit';
import UndoRedoControls from './UndoRedoControls';
import ZoomControls from './ZoomControls';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    height: TIMELINE_TOOLBAR_HEIGHT,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
  },
  divider: {
    backgroundColor: theme.palette.grey[700],
  },
}));

function TimelineToolbar() {
  const classes = useStyles();
  const isNearLayerLimit = useSelector(selectIsNearLayerLimit);

  return (
    <div className={classes.toolbar} data-intercom-target={TIMELINE_TOOLBAR}>
      <Box display="flex" alignItems="center" width="100%">
        <UndoRedoControls />
        <Box mx={1} height={16}>
          <Divider className={classes.divider} orientation="vertical" />
        </Box>
        <ItemControls />
        <Box flex={1}>{/* spacer element */}</Box>
        {isNearLayerLimit && (
          <>
            <LayerLimit />
            <Box mx={1} height={16}>
              <Divider className={classes.divider} orientation="vertical" />
            </Box>
          </>
        )}
        <ZoomControls />
      </Box>
    </div>
  );
}

export default memo(TimelineToolbar);
