import { Box, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import AspectRatioPreview from '../../features/preview/AspectRatioPreview';
import PlaybackControls from '../../features/preview/PlaybackControls';
import PreviewContainer from '../../features/preview/PreviewContainer';
import usePreviewDimensions from '../../features/preview/usePreviewDimensions';
import { selectRatio } from '../../slices/storyboardSlice';
import { AspectRatioPreviewContext } from './AspectRatioPreviewProvider';

const useStyles = makeStyles({
  previewContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default function StageArea({ timelineHeight }) {
  const { hoverRatio } = useContext(AspectRatioPreviewContext);
  const classes = useStyles();

  const aspectRatio = useSelector(selectRatio);
  const previewDimensions = usePreviewDimensions(aspectRatio, timelineHeight);

  const overlayPreviewDimensions = usePreviewDimensions(
    hoverRatio,
    timelineHeight
  );
  const showOverlay = !!hoverRatio;

  return (
    <Box pt={2}>
      <div className={classes.previewContainer}>
        {showOverlay && (
          <AspectRatioPreview
            previewHeight={previewDimensions.height}
            previewWidth={previewDimensions.width}
            overlayPreviewHeight={overlayPreviewDimensions.height}
            overlayPreviewWidth={overlayPreviewDimensions.width}
          />
        )}
        <PreviewContainer
          height={previewDimensions.height}
          width={previewDimensions.width}
        />
      </div>
      <Box py={2}>
        <PlaybackControls width={previewDimensions.width} />
      </Box>
    </Box>
  );
}
