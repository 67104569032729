import { MediaTypes } from '@videoblocks/jelly-renderer';

import { SOURCE_TYPES } from '../constants/Constants';
import getMediaDuration from '../utils/getMediaDuration';
import createSourcedObject from './createSourcedObject';

export default async function createAudioObject(item = {}) {
  const source = item.preview_url || item?.files?.[0]?.url;
  let duration = item.durationMs ? item.durationMs / 1000 : item.duration;

  if (!duration) {
    duration = await getMediaDuration(source);
  }

  return createSourcedObject({
    // base attributes
    duration,
    mediaType: MediaTypes.AUDIO,

    // sourced attributes
    isRecorded: item?.meta?.is_recorded ?? false,
    itemSourceId: item.id,
    itemSourceType: item.sourceType || SOURCE_TYPES.STOCK, // stock || upload
    maxDuration: duration,
    source: item.preview_url || item?.files?.[0]?.url,
    title: item.title || item.name,
    contentType: item.type,

    fadeIn: item.fadeIn ?? 0,
    fadeOut: item.fadeOut ?? 0,
    volume: item.volume ?? 1,
  });
}
