import { filter, map, sortBy, uniq } from 'lodash';
import useSWR from 'swr';

import StockAPI from '../api/StockAPI';
import { getContentType } from '../constants/ContentTypes';
import isSupportedContentType from '../utils/isSupportedContentType';
import useContentToken from './useContentToken';

const VR_360_CATEGORY_ID = 23;
const GREEN_SCREEN_CATEGORY_ID = 24;
const unsupportedCategoryIds = [VR_360_CATEGORY_ID, GREEN_SCREEN_CATEGORY_ID];

const fetcher = async (url, token) => {
  try {
    const response = await new StockAPI(token).get(url);
    return response.map((category) => ({
      ...category,
      content_type: getContentType(category.content_type),
    }));
  } catch (error) {
    return [];
  }
};

export default function useVideoCategories() {
  const { contentToken } = useContentToken();
  const { data = [] } = useSWR(
    contentToken ? ['/videos/stock-item/categories', contentToken] : null,
    fetcher
  );

  const categories = filter(
    data,
    (category) =>
      isSupportedContentType(category.content_type) &&
      !unsupportedCategoryIds.includes(category.id)
  );
  return {
    contentTypes: uniq(map(categories, 'content_type')),
    categories: sortBy(categories, 'name'),
  };
}
