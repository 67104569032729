import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { selectIsLoading } from '../selectors/user';
import { sendPageViewEvent } from './sendAvroEvent';

/**
 * Track page views on route changes
 */
export default function PageViewTracker() {
  const { pathname, search } = useLocation();
  const [referrer, setReferrer] = useState(document.referrer);
  const isUserLoading = useSelector(selectIsLoading);

  useEffect(() => {
    // Wait until user is loaded to prevent sending event before an ID is present
    if (!isUserLoading && pathname !== referrer) {
      sendPageViewEvent({ pathname, search, referrer });
      setReferrer(pathname);
    }
  }, [pathname, search, referrer, isUserLoading]);

  return null;
}
