import { useDispatch } from 'react-redux';

import FadeControls from '../../components/FadeControls';
import SliderField from '../../components/SliderField';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import { itemUpdated } from '../../slices/storyboardSlice';
import PropertiesPanelHeading from './PropertiesPanelHeading';
import FilterPropertiesCard from './cards/FilterPropertiesCard';
import PropertiesCard from './cards/PropertiesCard';
import PositionPicker from './controls/PositionPicker';

export default function ImagePropertiesPanel() {
  const activeItem = useActiveItem();
  const dispatch = useDispatch();
  const { fadeIn, fadeOut, duration, opacity } = activeItem;

  const handlePositionChange = ({ horizontal, vertical }) => {
    const position = {
      x: horizontal || activeItem.position?.x,
      y: vertical || activeItem.position?.y,
    };

    dispatch(itemUpdated({ id: activeItem.id, changes: { position } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'position', position)
    );
  };

  const handleFadeInChange = (fadeIn) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { fadeIn } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'fadeIn', fadeIn)
    );
  };

  const handleFadeOutChange = (fadeOut) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { fadeOut } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'fadeOut', fadeOut)
    );
  };

  const handleOpacityChange = (opacity) => {
    dispatch(itemUpdated({ id: activeItem.id, changes: { opacity } }));
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'opacity', opacity)
    );
  };

  return (
    <>
      <PropertiesPanelHeading>Image Properties</PropertiesPanelHeading>
      <FilterPropertiesCard activeItem={activeItem} />
      <PropertiesCard title="Style">
        <PositionPicker
          value={activeItem.position}
          onChange={handlePositionChange}
        />
      </PropertiesCard>
      <PropertiesCard title="Opacity">
        <SliderField
          defaultValue={1}
          hideLabel
          id="opacity-slider"
          label="Opacity"
          onChange={handleOpacityChange}
          value={opacity ?? 1}
        />
      </PropertiesCard>
      <PropertiesCard title="Fade">
        <FadeControls
          fadeIn={fadeIn}
          fadeOut={fadeOut}
          duration={duration}
          onFadeInChange={handleFadeInChange}
          onFadeOutChange={handleFadeOutChange}
        />
      </PropertiesCard>
    </>
  );
}
