import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Toast from '../components/Toast';
import BrandDeleteDialog from '../components/dialogs/BrandDeleteDialog';
import BrandSelectDialog from '../components/dialogs/BrandSelectDialog';
import BrandUnsavedDialog from '../components/dialogs/BrandUnsavedDialog';
import BrandUpdateDialog from '../components/dialogs/BrandUpdateDialog';
import ConfirmationDialog from '../components/dialogs/ConfirmationDialog';
import ProjectDeleteDialog from '../components/dialogs/ProjectDeleteDialog';
import SaveAsTemplateDialog from '../components/dialogs/SaveAsTemplateDialog';
import ShortcutsDialog from '../components/dialogs/ShortcutsDialog';
import TemplateDeleteDialog from '../components/dialogs/TemplateDeleteDialog';
import TrimDialog from '../components/dialogs/TrimDialog';
import TypefaceUploadDialog from '../components/dialogs/TypefaceUploadDialog';
import UploadDialog from '../components/dialogs/UploadDialog';
import UpsellConfirmationDialog from '../components/dialogs/UpsellConfirmationDialog';
import UpsellDialog from '../components/dialogs/UpsellDialog';
import VideoPreviewDialog from '../components/dialogs/VideoPreviewDialog';
import {
  BRAND_DELETE_DIALOG,
  BRAND_SELECT_DIALOG,
  BRAND_UNSAVED_DIALOG,
  BRAND_UPDATE_DIALOG,
  closeDialog,
  closeToast,
  CONFIRMATION_DIALOG,
  PROJECT_DELETE_DIALOG,
  SAVE_AS_TEMPLATE_DIALOG,
  selectCurrentDialogProps,
  selectCurrentDialogType,
  selectCurrentToast,
  SHORTCUTS_DIALOG,
  TEMPLATE_DELETE_DIALOG,
  TRIM_DIALOG,
  TYPEFACE_UPLOAD_DIALOG,
  UPLOAD_DIALOG,
  UPSELL_CONFIRMATION_DIALOG,
  UPSELL_DIALOG,
  VIDEO_PREVIEW_DIALOG,
} from '../features/ui/uiSlice';

export default function UIContainer() {
  const dispatch = useDispatch();
  const dialogType = useSelector(selectCurrentDialogType);
  const dialogProps = useSelector(selectCurrentDialogProps);
  const toastProps = useSelector(selectCurrentToast);

  const isBrandDeleteDialogOpen = dialogType === BRAND_DELETE_DIALOG;
  const isBrandSelectDialogOpen = dialogType === BRAND_SELECT_DIALOG;
  const isBrandUnsavedDialogOpen = dialogType === BRAND_UNSAVED_DIALOG;
  const isBrandUpdateDialogOpen = dialogType === BRAND_UPDATE_DIALOG;
  const isConfirmationDialogOpen = dialogType === CONFIRMATION_DIALOG;
  const isProjectDeleteDialogOpen = dialogType === PROJECT_DELETE_DIALOG;
  const isSaveAsTemplateDialogOpen = dialogType === SAVE_AS_TEMPLATE_DIALOG;
  const isShortcutsDialogOpen = dialogType === SHORTCUTS_DIALOG;
  const isTemplateDeleteDialogOpen = dialogType === TEMPLATE_DELETE_DIALOG;
  const isTrimDialogOpen = dialogType === TRIM_DIALOG;
  const isTypefaceUploadDialogOpen = dialogType === TYPEFACE_UPLOAD_DIALOG;
  const isUploadDialogOpen = dialogType === UPLOAD_DIALOG;
  const isUpsellConfirmationDialogOpen =
    dialogType === UPSELL_CONFIRMATION_DIALOG;
  const isUpsellDialogOpen = dialogType === UPSELL_DIALOG;
  const isVideoPreviewDialogOpen = dialogType === VIDEO_PREVIEW_DIALOG;

  const isToastOpen = !isEmpty(toastProps);

  const handleDialogClose = () => {
    dispatch(closeDialog());
  };

  const handleToastClose = () => {
    dispatch(closeToast());
  };

  return (
    <>
      {isProjectDeleteDialogOpen && (
        <ProjectDeleteDialog
          {...dialogProps}
          open
          onClose={handleDialogClose}
        />
      )}
      {isTemplateDeleteDialogOpen && (
        <TemplateDeleteDialog
          {...dialogProps}
          open
          onClose={handleDialogClose}
        />
      )}
      {isBrandDeleteDialogOpen && (
        <BrandDeleteDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isBrandUpdateDialogOpen && (
        <BrandUpdateDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isBrandUnsavedDialogOpen && (
        <BrandUnsavedDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isShortcutsDialogOpen && (
        <ShortcutsDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isSaveAsTemplateDialogOpen && (
        <SaveAsTemplateDialog
          {...dialogProps}
          open
          onClose={handleDialogClose}
        />
      )}
      {isTrimDialogOpen && (
        <TrimDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isBrandSelectDialogOpen && (
        <BrandSelectDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isUploadDialogOpen && (
        <UploadDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isUpsellConfirmationDialogOpen && (
        <UpsellConfirmationDialog
          {...dialogProps}
          open
          onClose={handleDialogClose}
        />
      )}
      {isUpsellDialogOpen && (
        <UpsellDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isVideoPreviewDialogOpen && (
        <VideoPreviewDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isTypefaceUploadDialogOpen && (
        <TypefaceUploadDialog
          {...dialogProps}
          open
          onClose={handleDialogClose}
        />
      )}
      {isConfirmationDialogOpen && (
        <ConfirmationDialog {...dialogProps} open onClose={handleDialogClose} />
      )}
      {isToastOpen && <Toast {...toastProps} open onClose={handleToastClose} />}
    </>
  );
}
