import axios from 'axios';
import useSWR from 'swr';

import { WP_MEDIA_URL } from '../constants/Urls';

const fetcher = async (url) => {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return [];
  }
};

export default function useBlogMedia(media_id) {
  const { data: media } = useSWR(`${WP_MEDIA_URL}/${media_id}`, fetcher);

  return { media };
}
