import log from 'loglevel';
import { nanoid } from 'nanoid';
import { stringify } from 'query-string';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createStore } from 'redux';

import BrandsAPI from '../api/BrandsAPI';
import CustomTemplatesAPI from '../api/CustomTemplatesAPI';
import { ASPECT_RATIOS } from '../constants/AspectRatios';
import { DEFAULT_TEMPLATE_NAME } from '../constants/Storyboard';
import { EDIT_PATH, EDIT_TEMPLATE_PATH } from '../constants/Urls';
import useContentToken from '../hooks/useContentToken';
import useProjects from '../hooks/useProjects';
import useQuery from '../hooks/useQuery';
import useTemplates from '../hooks/useTemplates';
import createObject from '../models/createObject';
import { selectIsEnterprise } from '../selectors/user';
import storyboardReducer, {
  initialState,
  itemAdded,
  nameUpdated,
  ratioUpdated,
  stylesApplied,
} from '../slices/storyboardSlice';
import {
  extractMediaItemsFromQuery,
  fetchStockItemDetails,
} from '../utils/stockAPIUtils';

export default function CreateContainer(props) {
  const { templateMode } = props;
  const history = useHistory();
  const query = useQuery();
  const { fromTemplateId, brandUid, flags, firstTimeUser } = query;
  const { contentToken } = useContentToken();
  const { createTemplate } = useTemplates();
  const { createProject } = useProjects();
  const isEnterprise = useSelector(selectIsEnterprise);

  const mediaItems = extractMediaItemsFromQuery(query);
  const ratio = ASPECT_RATIOS.includes(query.ratio) ? query.ratio : undefined;

  async function createStoryboardFromTemplate() {
    try {
      const template = await new CustomTemplatesAPI().getTemplate(
        fromTemplateId
      );
      return { ...template.storyboard, fromTemplateId };
    } catch (error) {
      log.error(`Error fetching template: ${error?.message}`, {
        error,
        fromTemplateId,
      });
      return initialState;
    }
  }

  async function createStoryboardFromBrand(activeBrandUid) {
    try {
      const brand = await new BrandsAPI().getBrand(activeBrandUid);
      const args = { styles: brand?.detail?.styles };
      return createStoryboard(args);
    } catch (error) {
      log.error(`Error fetching brand: ${error?.message}`, {
        error,
        activeBrandUid,
      });
      return initialState;
    }
  }

  async function createStoryboard({ styles = initialState.styles }) {
    const storyboard = createStore(storyboardReducer, initialState);
    storyboard.dispatch(ratioUpdated(ratio || initialState.ratio));
    storyboard.dispatch(stylesApplied(styles));
    if (templateMode) {
      storyboard.dispatch(nameUpdated(DEFAULT_TEMPLATE_NAME));
    }

    if (mediaItems.length > 0) {
      const fetchedMediaItems = await fetchStockItemDetails(
        mediaItems,
        contentToken
      );
      const object = await createObject(fetchedMediaItems[0]);
      storyboard.dispatch(
        itemAdded({ ...object, layerIndex: 0, layerId: nanoid(9) })
      );
    }

    return storyboard.getState();
  }

  useEffect(() => {
    async function setupEditor() {
      let storyboard;

      const defaultBrand = isEnterprise
        ? await new BrandsAPI()
            .getDefaultBrand()
            .catch((e) =>
              log.error(
                'Encountered an error while fetching the default brand.',
                e
              )
            )
        : null;
      const activeBrandUid = brandUid ?? defaultBrand?.uid ?? null;

      if (fromTemplateId) {
        storyboard = await createStoryboardFromTemplate();
      } else if (activeBrandUid) {
        storyboard = await createStoryboardFromBrand(activeBrandUid);
      } else {
        storyboard = await createStoryboard({
          mediaItems,
          ratio,
          templateMode,
        });
      }

      const createdFromId = mediaItems?.[0]?.id;
      const createdFromMediaType = mediaItems?.[0]?.mediaType;
      const additionalAnalyticsArgs = {
        createdFromId,
        createdFromMediaType,
        fromTemplateId,
      };

      const id = templateMode
        ? await createTemplate(
            {
              storyboard,
              brand_uid: activeBrandUid,
              published_at: new Date(),
              ratio: storyboard.ratio,
              name: storyboard.name,
            },
            additionalAnalyticsArgs
          )
        : await createProject(
            {
              storyboard,
              brand_uid: activeBrandUid,
              ratio: storyboard.ratio,
              name: storyboard.name,
            },
            additionalAnalyticsArgs
          );

      history.replace({
        pathname: templateMode
          ? `${EDIT_TEMPLATE_PATH}/${id}`
          : `${EDIT_PATH}/${id}`,
        // pass along flags if they exist, drop unnecessary query params
        search: stringify({ flags, firstTimeUser }),
        state: {
          toastMessage: fromTemplateId
            ? `"${storyboard.name}" has been created from a Template.`
            : null,
        },
      });
    }

    if (contentToken) {
      setupEditor();
    }
  }, [contentToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
