import { useState } from 'react';

import useProject from './useProject';

export default function useBrandSelect(props) {
  const [selectedBrandUid, setSelectedBrandUid] = useState(null);
  const { brands, brandsIsLoading, projectId, brandUid } = props;
  const { project, isLoading: projectIsLoading } = useProject(projectId);
  const defaultBrand = brands.find((brand) => brand.is_default);

  if (!selectedBrandUid && !!brands?.length) {
    const projectBrand = brands?.find(
      (brand) => brand.uid === project.brand_uid
    );
    if (!projectIsLoading && project.brand_uid && projectBrand) {
      // We're viewing a project. Show the project's associated brand first in the list, if available.
      setSelectedBrandUid(project.brand_uid);
    } else if (brandUid) {
      // We're viewing a brand. Show this brand first in the list.
      setSelectedBrandUid(brandUid);
    } else if (defaultBrand) {
      // There is a default brand for this org. Show this first.
      setSelectedBrandUid(defaultBrand.uid);
    } else if (!brandsIsLoading && brands) {
      // Default to the first brand in the list. It may be pre-sorted however the caller determined.
      setSelectedBrandUid(brands?.[0]?.uid);
    }
  }

  return [selectedBrandUid, setSelectedBrandUid];
}
