import { Link, makeStyles, Typography } from '@material-ui/core';

import useBlogMedia from '../hooks/useBlogMedia';

const EXCERPT_LINES_TO_SHOW = 2;

const useStyles = makeStyles((theme) => ({
  article: {
    display: 'block',
    padding: theme.spacing(3, 0, 1.5),
    margin: theme.spacing(2, 0),
    minHeight: theme.spacing(18),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      paddingBottom: theme.spacing(3),
    },
  },
  excerpt: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[600],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': EXCERPT_LINES_TO_SHOW,
    '-webkit-box-orient': 'vertical',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(1),
  },
  thumbnail: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    backgroundImage: ({ mediaLink }) => `url(${mediaLink ?? ''})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    float: 'left',
    margin: theme.spacing(0.5, 2, 1, 0),
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function Article({ title, excerpt, featuredMediaId, link }) {
  const { media = {} } = useBlogMedia(featuredMediaId);
  const classes = useStyles({ mediaLink: media.link });

  const sanitizeExcerpt = () => {
    const sandbox = document.implementation.createHTMLDocument('');
    sandbox.body.innerHTML = excerpt;
    const sanitized = sandbox.body.textContent;
    return sanitized;
  };

  return (
    <Link
      className={classes.article}
      color="inherit"
      href={link}
      target="_blank"
      rel="noopener"
    >
      <div
        alt={media.alt_text || media.title?.rendered}
        className={classes.thumbnail}
      ></div>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.excerpt}>
        {sanitizeExcerpt()}
      </Typography>
    </Link>
  );
}
