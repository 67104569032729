import {
  Button,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { EDIT_PATH, EDIT_TEMPLATE_PATH } from '../constants/Urls';
import { trackEvent } from '../events/sendEvents';
import { TEMPLATE } from '../events/tags';
import { openTemplateDeleteDialog } from '../features/ui/uiSlice';
import { selectIsOrgAdmin, selectUserId } from '../selectors/user';
import TemplateTableRow from './TemplateTableRow';

const useStyles = makeStyles((theme) => ({
  infoTooltip: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
  headerText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const TEAM_ACCESS_TOOLTIP_TEXT =
  'Allow everyone in your organization to view and make a copy of your template';

export default function TemplateTable(props) {
  const classes = useStyles();
  const {
    isLoading,
    moreResultsExist,
    templates,
    currentPage,
    setCurrentPage,
    isTeamTemplatesTable, // shows created by
    onTeamAccessToggle: handleTeamAccessToggle,
    onDuplicate: handleDuplicate,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const showTeamAccessToggle = isOrgAdmin && !isTeamTemplatesTable;

  const userId = useSelector(selectUserId);

  const handleStartProjectFromTemplate = (id) => {
    history.push({
      pathname: EDIT_PATH,
      search: `${stringify({
        ...parse(search),
        fromTemplateId: id,
      })}`,
    });
  };

  const handlePromptDelete = useCallback(
    (templateId) => {
      dispatch(openTemplateDeleteDialog({ templateId }));
    },
    [dispatch]
  );

  const handleSelect = (uid) => {
    trackEvent(TEMPLATE.START_EDIT, { templateId: uid, isOrgAdmin });
    history.push({
      pathname: `${EDIT_TEMPLATE_PATH}/${uid}`,
      search,
    });
  };

  const handleClickNext = () => setCurrentPage(currentPage + 1);
  const handleClickPrev = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    // If the current page gets emptied by deletion, switch to previous page
    if (!isLoading && templates?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [isLoading, templates, currentPage, setCurrentPage]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="template table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.headerText}>Title</TableCell>
              <TableCell className={classes.headerText}>Length</TableCell>
              <TableCell className={classes.headerText}>Last Edited</TableCell>
              {showTeamAccessToggle && (
                <TableCell className={classes.headerText}>
                  Team Access{' '}
                  <Tooltip title={TEAM_ACCESS_TOOLTIP_TEXT}>
                    <SvgIcon
                      className={classes.infoTooltip}
                      component={QuestionCircleIcon}
                    />
                  </Tooltip>
                </TableCell>
              )}
              {isTeamTemplatesTable && (
                <TableCell className={classes.headerText}>Created by</TableCell>
              )}
              <TableCell
                className={classes.headerText}
                aria-label="Actions"
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(templates || [])
              .filter((template) => !!template.storyboard)
              .map((template) => (
                <TemplateTableRow
                  template={template}
                  key={template.uid}
                  templateOwnedByUser={template.user_id === userId}
                  onPromptDelete={() => handlePromptDelete(template.uid)}
                  onDuplicate={() => handleDuplicate(template)}
                  onSelect={() => handleSelect(template.uid)}
                  onStartProject={() =>
                    handleStartProjectFromTemplate(template.uid)
                  }
                  onTeamAccessToggle={() =>
                    handleTeamAccessToggle(
                      template.uid,
                      !template.team_access,
                      template.name
                    )
                  }
                  showCreatedBy={isTeamTemplatesTable}
                  showTeamAccessToggle={showTeamAccessToggle}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <div className={classes.buttonContainer}>
          {currentPage !== 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickPrev}
            >
              Prev
            </Button>
          )}
          {moreResultsExist && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickNext}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </>
  );
}

TemplateTable.propTypes = {
  isLoading: PropTypes.bool,
  isTeamTemplatesTable: PropTypes.bool,
  showTeamAccessToggle: PropTypes.bool,
  moreResultsExist: PropTypes.bool,
  templates: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  onDuplicate: PropTypes.func,
  onTeamAccessToggle: PropTypes.func,
};

TemplateTable.defaultProps = {
  isLoading: false,
  isTeamTemplatesTable: false,
  showTeamAccessToggle: false,
  moreResultsExist: false,
  templates: undefined,
  currentPage: 1,
  setCurrentPage: () => {},
  onDuplicate: () => {},
  onTeamAccessToggle: () => {},
};
