import createBaseObject from './createBaseObject';

export default function createSourcedObject(options) {
  return createBaseObject({
    // generated attributes
    trimStart: 0,

    // passed attributes
    itemSourceId: options.itemSourceId,
    itemSourceType: options.itemSourceType, // stock || upload
    maxDuration: options.maxDuration,
    source: options.source,
    title: options.title,

    ...options,
  });
}
