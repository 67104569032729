import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';

import PaddedAspectBox from '../PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  border: {
    position: 'relative',
    textAlign: 'center',
    borderRadius: '4px',
    color: theme.palette.pink[500],
    boxSizing: 'border-box',
    border: `1px dashed ${theme.palette.grey[300]}`,
  },
  label: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: '.6',
      transition: 'opacity 200ms ease-in-out',
    },
  },
  icon: {
    marginRight: '.2rem',
  },
}));

export default function UploadButton({ onClick, ...rest }) {
  const classes = useStyles();
  return (
    <PaddedAspectBox
      onClick={onClick}
      className={classes.border}
      role="button"
      aspectRatio={16 / 9}
    >
      <div className={classes.label}>
        <AddCircleIcon className={classes.icon} />
        Upload Media
      </div>
    </PaddedAspectBox>
  );
}

UploadButton.propTypes = {
  onClick: PropTypes.func,
};
