import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { parseHotkey } from '../utils/hotkeys';

const useStyles = makeStyles((theme) => ({
  hotkeys: {
    '& > * + *': {
      marginLeft: theme.spacing(0.5),
    },
  },
  key: {
    position: 'relative',
    padding: theme.spacing(0.25, 0.5),
    fontFamily: 'system-ui',
    textTransform: 'capitalize',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: 2,
      backgroundColor: 'currentColor',
      opacity: 0.2,
    },
  },
}));
export default function HotkeyLabel({ hotkey, ...props }) {
  const classes = useStyles();
  const keys = parseHotkey(hotkey);

  return (
    <Box className={classes.hotkeys} {...props}>
      {keys.map((key) => {
        return (
          <code className={classes.key} key={key}>
            {key}
          </code>
        );
      })}
    </Box>
  );
}

HotkeyLabel.propTypes = {
  hotkey: PropTypes.string,
};
