import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

import PaddedAspectBox from './PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(2),
    width: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.common.white,
    overflow: 'hidden',
    cursor: 'pointer',
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:not($selected):hover': {
      borderColor: theme.palette.grey[200],
    },
  },
  selected: {
    borderColor: theme.palette.blue[400],
  },
  thumbnail: {
    width: '100%',
    height: '100%',
  },
}));

export default function GridSelect(props) {
  const {
    color,
    getOptionThumbnail = (option) => option.thumbnail,
    options,
    onChange,
    value,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const style = {
    backgroundColor: color
      ? tinycolor
          .mostReadable(color, [
            theme.palette.grey[400],
            theme.palette.common.white,
          ])
          .toHexString()
      : theme.palette.common.white,
  };

  return (
    <div className={classes.grid} role="listbox">
      {options.map((option) => {
        const { label, value: optionValue } = option;
        const isSelected = optionValue === value;

        const Thumbnail = getOptionThumbnail(option);
        const hasThumbnail = !!Thumbnail;

        return (
          <PaddedAspectBox aspectRatio={1} key={optionValue}>
            <div
              className={clsx(classes.item, {
                [classes.selected]: isSelected,
              })}
              style={style}
              role="option"
              aria-selected={isSelected}
              onClick={(event) => onChange(event, optionValue)}
            >
              {hasThumbnail ? (
                <Thumbnail
                  className={classes.thumbnail}
                  title={label}
                  fill={color && tinycolor(color).toRgbString()}
                />
              ) : (
                label
              )}
            </div>
          </PaddedAspectBox>
        );
      })}
    </div>
  );
}

GridSelect.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      thumbnail: PropTypes.elementType,
      value: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.string,
};

GridSelect.defaultProps = {
  options: [],
  onChange: () => {},
};
