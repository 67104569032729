import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';

import { BASIC_COLOR_PICKER_SECTION } from '../../constants/TestSelectors';
import useBrands from '../../hooks/useBrands';
import { selectIsEnterprise } from '../../selectors/user';
import BrandPicker from './BrandPicker';
import DebouncedPicker from './DebouncedPicker';
import HexAlphaPicker from './HexAlphaPicker';
import RecentPicker from './RecentPicker';
import SwatchPicker from './SwatchPicker';

const useStyles = makeStyles((theme) => ({
  colorPicker: {
    width: 252,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
}));

export default function ColorPicker({
  color = { r: 1, g: 1, b: 1, a: 1 },
  onChange = () => {},
  showNoneOption = true,
}) {
  color = tinycolor(color);
  const rgbString = color.toRgbString();
  const classes = useStyles();
  const isEnterprise = useSelector(selectIsEnterprise);
  const { brands = [], isLoading: brandsIsLoading } = useBrands();
  const hasBrands = brands?.length > 0;

  const [value, setValue] = useState(color.toRgb());

  useEffect(() => {
    setValue(color.toRgb());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rgbString]);

  const handleChange = (value, isBranded, isRecent) => {
    if (!tinycolor.equals(value, color)) {
      setValue(value);
      onChange(value, isBranded, isRecent);
    }
  };

  return (
    <div className={classes.colorPicker}>
      <Box mb={1}>
        <DebouncedPicker color={value} onChange={handleChange} />
      </Box>
      <Box mb={1}>
        <HexAlphaPicker color={value} onChange={handleChange} />
      </Box>
      {!brandsIsLoading && !hasBrands && (
        <Box mb={1} data-testid={BASIC_COLOR_PICKER_SECTION}>
          <SwatchPicker onChange={handleChange} />
        </Box>
      )}
      <Box mb={1}>
        <RecentPicker onChange={(value) => handleChange(value, false, true)} />
      </Box>
      {isEnterprise && hasBrands && (
        <Box mb={1}>
          <BrandPicker onChange={(value) => handleChange(value, true, false)} />
        </Box>
      )}
      {showNoneOption && (
        <Box mt={2}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            startIcon={<FormatColorResetIcon />}
            onClick={(_) => handleChange()}
          >
            None
          </Button>
        </Box>
      )}
    </div>
  );
}

ColorPicker.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  onChange: PropTypes.func,
  showNoneOption: PropTypes.bool,
};
