// Query parameter values used by Search API

export const ImageContentType = {
  ILLUSTRATIONS: 'illustrations',
  PHOTOS: 'photos',
  VECTORS: 'vectors',
};

export const ImageOrientation = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  SQUARE: 'square',
};
