import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { DraggableCore } from 'react-draggable';
import { useSelector } from 'react-redux';

import { ReactComponent as CursorHandle } from '../../assets/shapes/cursor.svg';
import { TIMELINE_LEFT_PADDING } from '../../constants/Constants';
import useRenderer from '../../hooks/useRenderer';
import { selectZoom } from '../../slices/editorSlice';
import { pixelsToSeconds, secondsToPixels } from '../../utils/timelineUtils';

const CURSOR_HANDLE_WIDTH = 12;
const CURSOR_LINE_WIDTH = 2;

const useStyles = makeStyles((theme) => ({
  cursor: {
    position: 'absolute',
    zIndex: 1,
    top: -1,
    left: 0,
    bottom: 0,
    width: CURSOR_LINE_WIDTH,
    backgroundColor: theme.palette.yellow[500],
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 16px 2px',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
    // hover guard, maintains hover event on element while dragging/scrubbing
    '&:active::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: CURSOR_HANDLE_WIDTH * 2,
      transform: `translateX(calc(-50% + ${CURSOR_LINE_WIDTH / 2}px))`,
    },
  },
  handle: {
    position: 'absolute',
    top: 0,
    width: CURSOR_HANDLE_WIDTH,
    fill: theme.palette.yellow[500],
    transform: `translateX(calc(-50% + ${CURSOR_LINE_WIDTH / 2}px))`,
  },
}));

export default function TimelineCursor(props) {
  const { scrollX = 0 } = props;
  const zoom = useSelector(selectZoom);
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [shouldResume, setShouldResume] = useState(false);
  const classes = useStyles();

  const { timestamp, renderer } = useRenderer();
  const { projectDuration, reloading } = renderer;

  useEffect(() => {
    if (!isDragging) {
      setOffset(secondsToPixels(timestamp, zoom));
    }
  }, [isDragging, timestamp, zoom]);

  useEffect(() => {
    if (timestamp > projectDuration && !reloading) {
      renderer.seek(projectDuration);
    }
  }, [timestamp, projectDuration, reloading, renderer]);

  const handleStart = () => {
    setIsDragging(true);

    if (renderer.playing) {
      renderer.pause();
      setShouldResume(true);
    }
  };

  const handleDrag = (event, data) => {
    const { deltaX } = data;
    const newOffset = offset + deltaX;

    setOffset(Math.max(0, newOffset));

    if (!renderer.seeking) {
      renderer.seek(pixelsToSeconds(newOffset, zoom));
    }
  };

  const handleStop = () => {
    setIsDragging(false);

    if (shouldResume) {
      renderer.play();
      setShouldResume(false);
    }
  };

  const x = offset - scrollX + TIMELINE_LEFT_PADDING;
  const transform = `translate3d(${x}px, 0, 0)`;

  return (
    <DraggableCore
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
    >
      <div className={classes.cursor} style={{ transform }}>
        <CursorHandle className={classes.handle} />
      </div>
    </DraggableCore>
  );
}
