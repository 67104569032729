import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import { useDebounce } from 'react-use';
import tinycolor from 'tinycolor2';

const DEBOUNCE_DURATION = 200; // ms

const useStyles = makeStyles({
  rgbaColorPicker: {
    '&.react-colorful': {
      width: 'auto',
    },
    '& > .react-colorful__saturation': {
      borderRadius: '4px 4px 0 0',
    },
    '& > .react-colorful__alpha': {
      borderRadius: '0 0 4px 4px',
    },
  },
});

export default function DebouncedPicker({ color, onChange }) {
  const [value, setValue] = useState(color);
  const colorRgbString = tinycolor(color).toRgbString();
  const valueRgbString = tinycolor(value).toRgbString();
  const classes = useStyles();

  useEffect(() => {
    setValue(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorRgbString]);

  useDebounce(() => onChange(value), DEBOUNCE_DURATION, [valueRgbString]);

  return (
    <RgbaColorPicker
      className={classes.rgbaColorPicker}
      color={value}
      onChange={setValue}
    />
  );
}

DebouncedPicker.propTypes = {
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

DebouncedPicker.defaultProps = {
  color: { r: 0, g: 0, b: 0, a: 1 },
  onChange: () => {},
};
