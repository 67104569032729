import {
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckRounded from '@material-ui/icons/CheckRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import dialogBackground from '../../assets/shapes/gradient-wave-a.svg';
import { trackUpsellSignUp } from '../../events/sendEvents';
import { selectIsEnterprise, selectUpgradePath } from '../../selectors/user';
import CloseButton from '../CloseButton';
import UnlimitedLibraryPopover from '../UnlimitedLibraryPopover';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: 480,
  },
  dialogHeader: {
    marginTop: -1,
    backgroundImage: `url(${dialogBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    textAlign: 'center',
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    color: theme.palette.common.white,
  },
  text: {
    marginBottom: theme.spacing(1.5),
    fontWeight: 600,
    textAlign: 'center',
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.pink[500],
  },
  check: {
    color: theme.palette.pink[500],
  },
  info: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey[400],
  },
  listItem: {
    padding: 0,
  },
  buttonContainer: {
    textAlign: 'center',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  denyButton: {
    color: theme.palette.grey[700],
    '&:hover, &:focus': {
      color: theme.palette.grey[900],
    },
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
    color: theme.palette.common.white,
  },
}));

export default function UpsellDialog(props) {
  const { onClose, open } = props;
  const upgradePath = useSelector(selectUpgradePath);
  const isEnterprise = useSelector(selectIsEnterprise);
  const classes = useStyles();

  const listItems = [
    'Use content directly from Storyblocks in Maker',
    'Download content to use in other editors',
  ];

  const onUpgradeClick = () => {
    trackUpsellSignUp(isEnterprise);
    window.location = upgradePath;
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.dialogHeader}>
        <Box paddingTop={8} paddingBottom={10} paddingX={4}>
          <h3 className={classes.title}>
            {isEnterprise
              ? 'Ask us about getting full access to Maker'
              : 'Upgrade to export Maker projects without a watermark'}
          </h3>
        </Box>
      </div>
      <Box paddingTop={5} paddingBottom={6} paddingX={4}>
        <div className={classes.text}>
          {isEnterprise ? (
            <div>
              Contact your customer success manager to unlock unlimited
              watermark-free project downloads.
            </div>
          ) : (
            <>
              <span className={classes.highlight}>Plus,</span> get access to
              Storyblocks&apos;{' '}
              <UnlimitedLibraryPopover>
                unlimited library
                <InfoRoundedIcon className={classes.info} fontSize="inherit" />
              </UnlimitedLibraryPopover>
            </>
          )}
        </div>
        {!isEnterprise && (
          <Box marginBottom={2} paddingLeft={3}>
            <List>
              {listItems.map((text) => (
                <ListItem
                  className={classes.listItem}
                  disableGutters
                  key={text}
                >
                  <ListItemIcon>
                    <CheckRounded className={classes.check} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={onUpgradeClick}>
            {isEnterprise
              ? 'Contact Your Customer Success Manager'
              : 'Upgrade My Account'}
          </Button>
          {!isEnterprise && (
            <Button className={classes.denyButton} onClick={onClose}>
              Continue using the free version
            </Button>
          )}
        </div>
      </Box>
      <Box position="absolute" top={0} right={0} m={1} color="white">
        <CloseButton onClick={onClose} />
      </Box>
    </Dialog>
  );
}

UpsellDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
