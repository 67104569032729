import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@videoblocks/react-icons/Search';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.75),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.common.black,
    },
  },
}));

export default function SearchInput(props) {
  const {
    className,
    onSubmit = () => {},
    placeholder = 'Search…',
    value = '',
    ...rest
  } = props;
  const [valueState, setValueState] = useState(value);
  const classes = useStyles();

  const handleChange = (event) => {
    setValueState(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(event, valueState);
  };

  useEffect(() => {
    setValueState(value);
  }, [value]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <OutlinedInput
        type="search"
        inputProps={{ 'aria-label': 'search' }}
        color="secondary"
        spellCheck="false"
        autoComplete="false"
        fullWidth
        endAdornment={
          <InputAdornment position="end">
            <IconButton className={classes.iconButton} type="submit">
              <SvgIcon component={SearchIcon} fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
        onChange={handleChange}
        value={valueState}
        {...rest}
      />
    </form>
  );
}

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
