import PropTypes from 'prop-types';

import ColorGrid from './ColorGrid';
import colors from './swatchColors';

export default function SwatchPicker({ onChange }) {
  return <ColorGrid colors={colors} onChange={onChange} />;
}

SwatchPicker.propTypes = {
  onChange: PropTypes.func,
};

SwatchPicker.defaultProps = {
  onChange: () => {},
};
