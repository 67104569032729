import { IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@videoblocks/react-icons/Close';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(16),
  },
  sizeSmall: {
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
  },
}));

function CloseButton(props) {
  const classes = useStyles();
  return (
    <IconButton
      classes={{ root: classes.root, sizeSmall: classes.sizeSmall }}
      color="inherit"
      aria-label="Close"
      {...props}
    >
      <SvgIcon component={CloseIcon} fontSize="inherit" />
    </IconButton>
  );
}

export default memo(CloseButton);
