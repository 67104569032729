import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  LaptopSmiley,
  MeetingCamera,
  MicrophoneAlternate,
  RecordCircle,
} from '@videoblocks/react-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EDIT_PATH } from '../../constants/Urls';
import { trackEvent } from '../../events/sendEvents';
import { RECORD } from '../../events/tags';
import {
  DRAWERS,
  openAssetDrawer,
} from '../../features/assetDrawer/assetDrawerSlice';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 'max-content',
  },
  boxStart: {
    display: 'flex',
    flexGrow: 8,
  },
  button: {
    flexGrow: 1,
    color: theme.palette.grey[900],
    borderColor: theme.palette.grey[900],
  },
  buttonIcon: {
    fill: theme.palette.grey[900],
    maxWidth: theme.spacing(2),
    minWidth: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
  },
  icon: {
    maxWidth: theme.spacing(3),
    minWidth: theme.spacing(3),
    margin: theme.spacing(0, 2),
    fill: theme.palette.blue[500],
  },
  option: {
    color: theme.palette.grey[800],
    marginRight: theme.spacing(3),
    padding: theme.spacing(0, 1),
    display: 'flex',
    '&:not(:first-child)': {
      borderLeftWidth: 1,
      borderLeftColor: theme.palette.grey[400],
      borderLeftStyle: 'solid',
    },
  },
  content: {
    position: 'relative',
    zIndex: 1,
  },
  heading: {
    margin: theme.spacing(2, 'auto'),
  },
  text: {
    marginTop: theme.spacing(1),
    minWidth: 'max-content',
  },
}));

function StartWithRecording() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleButtonClick = () => {
    history.replace({
      pathname: `${EDIT_PATH}`,
      search: history.location.search,
    });
    dispatch(openAssetDrawer({ drawer: DRAWERS.RECORD }));
    trackEvent(RECORD.CREATE);
  };

  return (
    <>
      <div className={classes.content}>
        <Typography variant="h4" component="h2" className={classes.heading}>
          Start with a Recording
        </Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.boxStart}>
          <div className={classes.option}>
            <LaptopSmiley className={classes.icon} />
            <Typography className={classes.text}>Record your screen</Typography>
          </div>
          <div className={classes.option}>
            <MicrophoneAlternate className={classes.icon} />
            <Typography className={classes.text}>Record audio</Typography>
          </div>
          <div className={classes.option}>
            <MeetingCamera className={classes.icon} />
            <Typography className={classes.text}>Record from webcam</Typography>
          </div>
        </div>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleButtonClick}
        >
          <RecordCircle className={classes.buttonIcon} />
          Try it out
        </Button>
      </div>
    </>
  );
}

export default StartWithRecording;
