import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTitle } from 'react-use';

import LoaderContainer from '../components/LoaderContainer';
import PaddedAspectBox from '../components/PaddedAspectBox';
import PageNotFound from '../components/PageNotFound';
import Toast from '../components/Toast';
import { SB_MAKER_LANDING_URL } from '../constants/Urls';
import {
  trackCopyShareLinkOnSharePage,
  trackCreateVideo,
} from '../events/sendEvents';
import useSharedExport from '../hooks/useSharedExport';
import { selectIsAuthenticated } from '../selectors/user';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(20),
      paddingLeft: theme.spacing(20),
    },
  },
  content: {
    margin: 'auto',
    maxWidth: 1024,
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    fontWeight: theme.typography.fontWeightBold,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: theme.spacing(4),
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  },
  video: {
    width: '100%',
    height: '100%',
  },
}));

export default function ShareContainer() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { shareId } = useParams();
  const { sharedExport, error, loading } = useSharedExport(shareId);
  const [showCopyToast, setShowCopyToast] = useState(false);
  const classes = useStyles();

  const exportName = sharedExport?.storyboard.name || '';
  useTitle(exportName ? `Maker | ${exportName}` : 'Maker');

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowCopyToast(false);
  };

  const handleCopy = () => {
    copy(window.location.href);
    setShowCopyToast(true);
    trackCopyShareLinkOnSharePage(isAuthenticated);
  };

  /**
   * Prevents context menu from appearing on right click,
   * stopping general users from downloading the video
   */
  const disableRightClick = (event) => {
    event.preventDefault();
  };

  if (error) {
    return <PageNotFound />;
  }

  if (loading) {
    return <LoaderContainer />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Box px={[4, 0]} py={4}>
          <div className={classes.title}>{exportName}</div>
        </Box>
        <Box bgcolor="black">
          <PaddedAspectBox aspectRatio={16 / 9}>
            <video
              onContextMenu={disableRightClick}
              controlsList="nodownload"
              controls
              className={classes.video}
            >
              <source src={sharedExport.cloudfront_url} type="video/mp4" />
            </video>
          </PaddedAspectBox>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button variant="outlined" onClick={handleCopy}>
            Copy Share Link
          </Button>
          {isAuthenticated ? (
            <Button
              variant="outlined"
              component={RouterLink}
              onClick={() => trackCreateVideo(isAuthenticated)}
              to="/"
            >
              Create a Video
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => trackCreateVideo(isAuthenticated)}
              href={SB_MAKER_LANDING_URL}
            >
              Create a Video
            </Button>
          )}
        </Box>
        <Toast open={showCopyToast} severity="success" onClose={closeToast}>
          URL copied to clipboard
        </Toast>
      </div>
    </div>
  );
}
