import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import * as ContentTypes from '../constants/ContentTypes';
import { MEDIA_PREVIEW } from '../constants/TestSelectors';
import formatTimestamp from '../utils/formatTimestamp';
import HoverMediaWrapper from './HoverMediaWrapper';

const useStyles = makeStyles((theme) => ({
  styledMediaPreview: {
    position: 'relative',
    minWidth: 0,
  },
  sfxBadge: {
    color: theme.palette.common.white,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    backgroundColor: theme.palette.green[400],
    display: 'flex',
    alignItems: 'center',
    borderRadius: '9999px',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '0.125rem 0.5rem',
  },
  label: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(12),
    borderBottomRightRadius: theme.typography.pxToRem(4),
    borderBottomLeftRadius: theme.typography.pxToRem(4),
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: theme.palette.common.white,
    pointerEvents: 'none',
    opacity: 1,
    transition: 'opacity 200ms ease-in-out',
    '$styledMediaPreview:hover &': {
      opacity: 0,
    },
  },
  disabledMessageOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  disabledMessageContent: {
    padding: '4px 12px',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.red[100],
    color: theme.palette.red[700],
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 150ms ease-in-out',
    '$disabledMessageOverlay:hover &': {
      opacity: 1,
    },
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default function MediaPreview(props) {
  const {
    children,
    contentType,
    disabled = false,
    duration,
    mediaType,
    objectFit,
    onClick = () => {},
    previewUrl,
    thumbnailUrl,
    title,
    ...rest
  } = props;
  const classes = useStyles();

  const isSfx = contentType === ContentTypes.SFX;
  const isImage = mediaType === MediaTypes.IMAGE;

  return (
    <div
      className={classes.styledMediaPreview}
      data-testid={MEDIA_PREVIEW}
      onClick={onClick}
      {...rest}
    >
      <HoverMediaWrapper
        mediaType={mediaType}
        objectFit={objectFit}
        previewUrl={previewUrl}
        thumbnailUrl={thumbnailUrl}
        title={title}
      />
      {isSfx && <span className={classes.sfxBadge}>SFX</span>}
      {!isImage && (
        <div className={classes.label}>
          {title && <div className={classes.title}>{title}</div>}
          {duration && (
            <Box component="time" marginLeft={2}>
              {formatTimestamp(duration)}
            </Box>
          )}
        </div>
      )}
      {disabled && (
        <div className={classes.disabledMessageOverlay}>
          <div className={classes.disabledMessageContent}>
            This content type is not supported.
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

MediaPreview.propTypes = {
  contentType: PropTypes.oneOf(Object.values(ContentTypes)),
  disabled: PropTypes.bool,
  duration: PropTypes.number,
  mediaType: PropTypes.oneOf(Object.values(MediaTypes)).isRequired,
  objectFit: PropTypes.oneOf(['cover', 'contain']),
  onClick: PropTypes.func,
  previewUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  title: PropTypes.string,
};
