import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { arrayOf, string } from 'prop-types';

import socialMediaMap from '../constants/SocialMedia';

const useStyles = makeStyles((theme) => ({
  socialIcon: {
    fill: theme.palette.grey[400],
    marginRight: theme.spacing(0.75),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  socialName: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 20 / 14,
  },
  socialNameActive: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function SocialMediaFormats({ platforms, ratio, ...rest }) {
  const classes = useStyles();

  return (
    <Grid container spacing={1} {...rest}>
      {platforms.map((id) => {
        const { name, icon, iconMono, ratios } = socialMediaMap[id];
        const active = ratios.includes(ratio);
        const Component = active ? icon : iconMono;
        return (
          <Grid item xs={4} key={id}>
            <Box display="flex" alignItems="center">
              <Component className={classes.socialIcon} />
              <span
                className={clsx(classes.socialName, {
                  [classes.socialNameActive]: active,
                })}
              >
                {name}
              </span>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}

SocialMediaFormats.propTypes = {
  platforms: arrayOf(string).isRequired,
  ratio: string.isRequired,
};
