import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import DefaultNavbar from '../components/navbar/DefaultNavbar';
import EditorNavbar from '../components/navbar/EditorNavbar';
import HomeNavbar from '../components/navbar/HomeNavbar';
import ShareNavbar from '../components/navbar/ShareNavbar';
import {
  PROJECTS_PATH,
  TEMPLATES_PATH,
  BRANDS_PATH,
  EDIT_PATH,
  EDIT_TEMPLATE_PATH,
  TUTORIALS_PATH,
} from '../constants/Urls';
import { selectIsAuthenticated } from '../selectors/user';

export default function NavbarContainer() {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Switch>
      <Route path="/share/">
        <ShareNavbar authenticated={isAuthenticated} />
      </Route>
      {isAuthenticated && (
        <Switch>
          <Route
            exact
            path={[
              '/',
              PROJECTS_PATH,
              TEMPLATES_PATH,
              BRANDS_PATH,
              TUTORIALS_PATH,
            ]}
          >
            <HomeNavbar />
          </Route>
          <Route path={`${EDIT_TEMPLATE_PATH}/:templateId`}>
            <EditorNavbar />
          </Route>
          <Route path={`${EDIT_PATH}/:projectId`}>
            <EditorNavbar />
          </Route>
          <Route path={`${PROJECTS_PATH}/:projectId/exports/:exportId`}>
            <DefaultNavbar isExportPage={true} />
          </Route>
          <Route path="/:listType/:detailId">
            <DefaultNavbar isDetailPage={true} />
          </Route>
          <Route>
            <DefaultNavbar />
          </Route>
        </Switch>
      )}
    </Switch>
  );
}
