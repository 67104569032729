import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import colors from '../styles/colors';

/**
 * Waveform draws a 2d audio waveform of a specified size
 * @see https://wavesurfer-js.org/docs/options
 */
function Waveform({ width, height, sourceUrl, color, onError }) {
  const waveformDiv = useRef();
  const surfer = useRef();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (surfer.current) {
      surfer.current.destroy();
    }

    // load and draw waveform (should only occur once on initial component render)
    setIsReady(false);
    surfer.current = WaveSurfer.create({
      barWidth: 1,
      container: waveformDiv.current,
      cursorWidth: 0,
      height,
      hideScrollbar: true,
      interact: false,
      waveColor: color,
    });

    if (sourceUrl) {
      surfer.current.load(sourceUrl);
    }

    surfer.current.once('ready', () => {
      setIsReady(true);
    });

    surfer.current.once('error', onError);

    return function cleanup() {
      if (surfer.current) {
        surfer.current.destroy();
      }
    };
  }, [height, sourceUrl, color, onError]);

  useEffect(() => {
    // redraw waveform on width change
    if (isReady && surfer.current) {
      surfer.current.zoom(width / surfer.current.getDuration());
    }
  }, [isReady, width]);

  return <div style={{ width, height }} ref={waveformDiv} />;
}

Waveform.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  onError: PropTypes.func,
};

Waveform.defaultProps = {
  color: colors.gray[500],
  height: 128,
};

export default memo(Waveform);
