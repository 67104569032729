import { Fonts } from '@videoblocks/jelly-renderer';
import FontName from 'fontname';
import { groupBy, isEmpty, last, map, uniqBy, memoize } from 'lodash';
import WebFont from 'webfontloader';

import UploadAPI from '../api/UploadAPI';
import { TEXT_VARIANT } from '../constants/FontStyles';

export function loadGoogleFonts() {
  const fontsByFamily = groupBy(Fonts.GOOGLE_FONTS, 'family');
  const families = map(fontsByFamily, joinFontWeights);
  WebFont.load({
    classes: false,
    google: { families },
  });
}

// Memoized font loader. Ensures we do not load the same font multiple times.
const loadUploadedFont = memoize(
  async (font) => {
    try {
      document.fonts.add(
        await new FontFace(font.name, `url(${font.url})`).load()
      );
      if (isEmpty(font.meta)) {
        font.meta = {};
        await parseFontName(font.uploadId, font.url, font.meta);
      }
    } catch (e) {
      // Swallow the error, log, and resolve anyway.
      // WARNING: if this is changed to reject instead of resolve we need to
      // adjust FontLoader.js logic to account for that.
      console.warn('Failed to parse font metadata', e);
    }
  },
  (font) => font.fileName
);

export function loadUploadedFonts(fonts = []) {
  return Promise.all(fonts.filter(({ url }) => !!url).map(loadUploadedFont));
}

async function parseFontName(uploadId, url, meta) {
  let blob = await fetch(url).then((r) => r.blob());
  const reader = new FileReader();
  reader.onload = async (e) => {
    try {
      const fontMeta = FontName.parse(e.target.result)[0];
      meta.family = fontMeta.fontFamily;
      meta.style = fontMeta.fontSubfamily;
      await new UploadAPI().updateUpload({ uploadId, data: { meta } });
    } catch (e) {
      // FontName may throw an Error
    }
  };
  reader.readAsArrayBuffer(blob);
}

export function joinFontWeights(fonts = [], family = '') {
  const weights = fonts.map(getFontWeight).join(',');
  return `${family}:${weights}`;
}

export function groupFontsByFamily(fonts = []) {
  return fonts.reduce((grouped, font) => {
    if (font.meta?.family || font.family) {
      const family = font.meta?.family || font.family;
      grouped[family] = (grouped[family] || []).concat(font);
    } else {
      grouped[font.name] = [font];
    }
    return grouped;
  }, {});
}

/**
 * @param {FontStyle} font
 */
export function getFontWeight(font = {}) {
  return font.weight;
}

/**
 * @param {FontStyle} font
 * @return {TextStyle}
 */
export function getStylePropertiesFromFont(font = {}) {
  return {
    fontFamily: font.family,
    fontWeight: font.weight,
    fontSize: font.size,
  };
}

export function getUniqueFonts(fonts = []) {
  const sortedFonts = fonts
    .filter((font) => !!font) // remove empties
    .sort((a, b) => a.name.localeCompare(b.name)); // sort alphabetically by name

  // compare uniqueness by multiple properties
  return uniqBy(
    sortedFonts,
    (font) => `${font.family}_${font.name}_${font.weight}`
  );
}

/**
 * @param {CustomFont} customFont
 */
export function standardizeFont(customFont = {}) {
  const { id: uploadId, files = [], name, meta } = customFont;
  if (files.length === 0) {
    return null;
  }
  const { id: fileId, path = '', url } = files[0];
  const fileName = last(path.split('/'));
  return {
    uploadId,
    family: name,
    fileName,
    fileId,
    name,
    weight: 400,
    url,
    meta,
  };
}

/**
 * @param {Styles} styles
 * @param {TextVariant} textVariant
 * @returns {FontStyle}
 */
export function getFontForTextVariantFromStyles(
  styles,
  textVariant = TEXT_VARIANT.HEADER
) {
  return styles.fonts?.[textVariant] ?? styles.font;
}

export function getLookupIdByStyle(fonts = [], style) {
  const { fontFamily, fontWeight } = style;
  // TODO remove the handling of missing weights once old projects are updated
  const font = fonts.find(
    (font) =>
      font.family === fontFamily && (!fontWeight || font.weight === fontWeight)
  );
  return font ? getLookupIdByFont(font) : fontFamily;
}

export const getLookupIdByFont = (font) => {
  return font.fileId || font.name;
};

export function getFontByLookupId(fonts = [], lookupId = '') {
  return (
    fonts.find((font) => font.fileId === lookupId) ||
    fonts.find((font) => font.name === lookupId)
  );
}
