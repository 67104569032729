import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';

import { DND_ZONES } from '../../constants/Constants';
import overlays from '../../constants/Overlays';
import AddIconButton from '../AddIconButton';
import Draggable from '../Draggable';
import PaddedAspectBox from '../PaddedAspectBox';
import PositionWrapper, { Positions } from '../PositionWrapper';
import VideoPreview from '../VideoPreview';
import DragPreview from './DragPreview';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    overflowY: 'scroll',
  },
  drawerHeader: {
    position: 'relative',
    padding: theme.spacing(5, 2, 1),
  },
  itemWrapper: {
    '&:not(:hover) .add-button': {
      display: 'none',
    },
  },
  draggableWrapper: {
    height: '100%',
    width: '100%',
  },
  label: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    textAlign: 'center',
  },
}));

export default function OverlaysDrawer(props) {
  const { onSelect = () => {} } = props;
  const classes = useStyles();

  return (
    <div className={classes.drawer}>
      <div className={classes.content}>
        <ResultsGrid>
          {overlays.map((overlay) => {
            const item = {
              ...overlay,
              animationPreset: overlay.value,
              mediaType: MediaTypes.ANIMATION,
            };
            return (
              <div key={overlay.label}>
                <PaddedAspectBox
                  aspectRatio={16 / 9}
                  className={classes.itemWrapper}
                >
                  <Draggable
                    className={classes.draggableWrapper}
                    data={{
                      item,
                      overlayElement: () => (
                        <DragPreview thumbnailUrl={overlay.thumbnail} />
                      ),
                      zone: DND_ZONES.DRAWER,
                    }}
                    id={overlay.label}
                    aria-label={overlay.label}
                  >
                    <VideoPreview
                      source={overlay.preview}
                      thumbnail={overlay.thumbnail}
                    />
                  </Draggable>
                  <PositionWrapper position={Positions.BOTTOM_RIGHT}>
                    <AddIconButton
                      aria-label={`Add ${overlay.label} ${MediaTypes.ANIMATION}`}
                      onClick={(event) => onSelect(event, item)}
                    />
                  </PositionWrapper>
                </PaddedAspectBox>
                <div className={classes.label}>{overlay.label}</div>
              </div>
            );
          })}
        </ResultsGrid>
      </div>
    </div>
  );
}
