/* Duplicated from src/app/external/account/dto/Plan.ts */
export const PLAN_CLASSIFICATIONS = {
  LEGACY: 'Legacy', // deprecated but present in DB
  BUSINESS: 'Business', // deprecated but present in DB
  BASIC: 'Basic',
  BUNDLE: 'Bundle',
  STARTER: 'Starter',
  UNLIMITED: 'Unlimited',
  ENTERPRISE: 'Enterprise',
  PRO: 'Pro',
};
