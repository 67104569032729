import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { useSelector } from 'react-redux';

import { SB_URL } from '../constants/Urls';
import {
  selectHasUnlimitedDownloads,
  selectHasUnlimitedExports,
  selectMaxDownloadsPerMonth,
  selectMaxExportsPerMonth,
} from '../selectors/user';

const videoLink = `${SB_URL}/video/search?max_duration=240&media-type=footage&sort=most_relevant&video_quality=HD`;
const musicLink = `${SB_URL}/audio/search`;
const photosLink = `${SB_URL}/images/search?media-type=photos&sort=most_relevant`;
const templatesLink = `${SB_URL}/video/search?max_duration=240&media-type=templates&sort=most_relevant&video_quality=HD`;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.grey[800],
  },
  list: {
    maxWidth: theme.spacing(48),
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(0, 2.5),
    lineHeight: theme.typography.pxToRem(28),
    color: theme.palette.grey[800],
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.pink[500],
  },
  link: {
    textDecoration: 'underline',
  },
  info: {
    color: theme.palette.grey[800],
  },
  tooltip: {
    padding: theme.spacing(2),
    maxWidth: theme.spacing(32),
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

export default function StarterPlanFeatures() {
  const hasUnlimitedDownloads = useSelector(selectHasUnlimitedDownloads);
  const hasUnlimitedExports = useSelector(selectHasUnlimitedExports);
  const maxDownloadsPerMonth = useSelector(selectMaxDownloadsPerMonth);
  const maxExportsPerMonth = useSelector(selectMaxExportsPerMonth);
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>With a Starter plan, you have:</div>
      <List className={classes.list} disablePadding>
        <ListItem
          className={classes.listItem}
          disableGutters
          alignItems="flex-start"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CheckRoundedIcon color="inherit" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Box display="inline-block" marginRight={0.5}>
              {hasUnlimitedDownloads
                ? 'Unlimited Downloads of 4K/HD'
                : `${maxDownloadsPerMonth} Monthly Downloads of 4K/HD`}
            </Box>
            <a className={classes.link} href={videoLink}>
              footage
            </a>
            ,{' '}
            <a className={classes.link} href={musicLink}>
              music
            </a>
            ,{' '}
            <a className={classes.link} href={photosLink}>
              photos
            </a>
            ,{' '}
            <a className={classes.link} href={templatesLink}>
              templates
            </a>
            , and more
          </ListItemText>
        </ListItem>
        <ListItem
          className={classes.listItem}
          alignItems="flex-start"
          disableGutters
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CheckRoundedIcon color="inherit" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Box display="inline-block" marginRight={0.5}>
              {hasUnlimitedExports
                ? 'Unlimited Maker exports'
                : `${maxExportsPerMonth} Monthly Maker exports`}
            </Box>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement="bottom"
              title="Exports encode your video. Once exported, you can download your video as many times as you need to."
            >
              <InfoRoundedIcon className={classes.info} />
            </Tooltip>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
}
