import { useContext, useEffect, useMemo, useState } from 'react';

import { UserChannelContext } from './UserChannelProvider';
import { findCurrentStage, getOverallPercent } from './progress';

export function useBindEvent(channel, event, callback) {
  useEffect(() => {
    channel?.bind(event, callback);
    return () => channel?.unbind(event, callback);
  }, [channel, event, callback]);
}

export function useUserEvent(event, callback) {
  const channel = useContext(UserChannelContext);
  useBindEvent(channel, event, callback);
}

export function useExportProgress(exportId) {
  const [exportProgress, setExportProgress] = useState({
    percentProgress: 0,
    currentStage: null,
  });

  const handleProgress = useMemo(
    () => (progress) => {
      if (progress.exportId !== exportId) {
        return;
      }

      const percentProgress = getOverallPercent(progress);
      const currentStage = findCurrentStage(progress);
      setExportProgress((prev) => ({
        ...prev,
        // prevent events that arrive out of order from moving the progress bar backwards
        percentProgress: Math.max(percentProgress, prev.percentProgress),
        currentStage,
      }));
    },
    [exportId, setExportProgress]
  );

  useUserEvent('encoding.progress', handleProgress);

  const handleError = useMemo(
    () => (error) => {
      const { errorAt, reason } = error;
      if (error.exportId !== exportId) {
        return;
      }
      setExportProgress((prev) => ({
        ...prev,
        errorAt,
        reason,
      }));
    },
    [exportId, setExportProgress]
  );

  useUserEvent('encoding.error', handleError);

  return exportProgress;
}
