import { Box, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import TextIcon from '@videoblocks/react-icons/TextFormat';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { DND_ZONES } from '../../constants/Constants';
import {
  DEFAULT_BODY_FONT,
  DEFAULT_HEADER_FONT,
  TEXT_VARIANT,
} from '../../constants/FontStyles';
import { textAnimations } from '../../constants/TextAnimations';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import useRenderer from '../../hooks/useRenderer';
import createRatioBasedTextObject from '../../models/createRatioBasedTextObject';
import {
  itemAdded,
  selectAllLayersWithItems,
  selectRatio,
  selectStyles,
} from '../../slices/storyboardSlice';
import theme from '../../styles/theme';
import {
  getInsertLayerIndex,
  mapMediaToLayer,
} from '../../utils/storyboardUtils';
import AddIconButton from '../AddIconButton';
import Draggable from '../Draggable';
import PaddedAspectBox from '../PaddedAspectBox';
import PositionWrapper, { Positions } from '../PositionWrapper';
import DragPreview from './DragPreview';
import FontPreview from './FontPreview';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  animation: {
    borderColor: theme.palette.grey[200],
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    transition: theme.transitions.create('border-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      borderColor: theme.palette.blue[500],
    },
  },
  container: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  animatedTextContainer: {
    flexGrow: 1,
    overflowY: 'scroll',
  },
  itemWrapper: {
    '&:not(:hover) .add-button': {
      display: 'none',
    },
  },
  sectionHeader: {
    color: theme.palette.grey[600],
    display: 'flex',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
  },
  textIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function TextDrawer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ratio = useSelector(selectRatio);
  const textStyles = useSelector(selectStyles) ?? {};
  const layersWithItems = useSelector(selectAllLayersWithItems);
  const { timestamp } = useRenderer();

  const addTextItem = (options = {}) => {
    const item = createRatioBasedTextObject(ratio, options);

    const layerIndex = getInsertLayerIndex(layersWithItems, {
      ...item,
      startTime: timestamp,
    });
    const toLayerIndex = Math.max(layerIndex, 0);

    dispatch(
      itemAdded({
        ...item,
        layerId: layersWithItems[layerIndex]?.id || nanoid(),
        layerIndex: toLayerIndex,
      })
    );
    trackEvent(SIDE_DRAWER.ITEM_ADD, {
      ...item,
      toLayerIndex,
      toLayerType: mapMediaToLayer(item.mediaType),
      newLayerCreated: layerIndex < 0,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.sectionHeader}>
        <SvgIcon
          component={TextIcon}
          className={classes.textIcon}
          fontSize="inherit"
        />
        <p>Static Text</p>
      </div>
      <div>
        <Box padding={theme.spacing(1.5, 0)}>
          <FontPreview
            textVariant={TEXT_VARIANT.HEADER}
            isDrawer={true}
            previewText="Header"
            styles={{
              primaryTextColor: textStyles?.primaryTextColor,
              font: Object.assign(
                {},
                DEFAULT_HEADER_FONT,
                textStyles?.fonts?.header
              ),
            }}
          />
          <FontPreview
            textVariant={TEXT_VARIANT.BODY}
            isDrawer={true}
            previewText="Body"
            styles={{
              primaryTextColor: textStyles?.primaryTextColor,
              font: Object.assign(
                {},
                DEFAULT_BODY_FONT,
                textStyles?.fonts?.body
              ),
            }}
          />
        </Box>
      </div>
      <div className={classes.sectionHeader}>
        <SvgIcon
          component={TextIcon}
          className={classes.textIcon}
          fontSize="inherit"
        />
        <p>Animated Text</p>
      </div>
      <ResultsGrid columns={3} className={classes.animatedTextContainer}>
        {textAnimations
          .filter(({ label }) => label !== 'None')
          .map(({ thumbnail: Thumbnail, value, label }) => (
            <PaddedAspectBox
              aspectRatio={1}
              className={classes.itemWrapper}
              data-testid={label}
              key={label}
            >
              <Draggable
                data={{
                  item: {
                    ...textStyles,
                    animationPreset: value,
                    mediaType: MediaTypes.TEXT,
                    ratio,
                  },
                  overlayElement: () => (
                    <DragPreview>
                      <Thumbnail />
                    </DragPreview>
                  ),
                  zone: DND_ZONES.DRAWER,
                }}
                id={label}
              >
                <div className={classes.animation}>
                  <Thumbnail />
                </div>
              </Draggable>
              <PositionWrapper position={Positions.BOTTOM_RIGHT}>
                <AddIconButton
                  aria-label={`Add text with ${label}`}
                  onClick={() =>
                    addTextItem({
                      textVariant: TEXT_VARIANT.HEADER,
                      animationPreset: value,
                      primaryTextColor: textStyles.primaryTextColor,
                      font: textStyles?.fonts?.header ?? textStyles?.font,
                    })
                  }
                />
              </PositionWrapper>
            </PaddedAspectBox>
          ))}
      </ResultsGrid>
    </div>
  );
}
