export default function transformUploadResponse(data) {
  if (!data) {
    return undefined;
  }

  if (typeof data === 'string') {
    data = JSON.parse(data);
  }

  // parse single item
  if (data?.meta) {
    data.meta = JSON.parse(data.meta) ?? {};
  }
  // parse multiple items
  if (data?.data) {
    data.data = data.data.map((item) => ({
      ...item,
      meta: item && item.meta ? JSON.parse(item.meta) : {},
    }));
  }

  return data;
}
