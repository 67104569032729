import Pusher from 'pusher-js';

import { SB_URL } from '../constants/Urls';
import { cluster, key } from './constants';

// This is needed to support cross origin auth
// Pusher doesn't have better support for xhr.withCredentials at this time
// Solution taken from here:
// https://github.com/pusher/pusher-js/issues/471#issuecomment-659107992
Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

export default new Pusher(key, {
  cluster,
  authEndpoint: `${SB_URL}/broadcasting/auth`,
});
