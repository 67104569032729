import { RATIO_16_9 } from '../constants/AspectRatios';
import parseAspectRatio from './parseAspectRatio';

/**
 * Returns resolution width and height for a given aspect ratio and height.
 * @param aspectRatioString Defaults to '16:9'
 * @param format Width of the desired resolution. Defaults to 720.
 * @returns {(number)[]} [width, height]
 */
export default function getResolution(
  aspectRatioString = RATIO_16_9,
  format = 720
) {
  const aspectRatio = parseAspectRatio(aspectRatioString);

  return aspectRatio > 1
    ? [Math.ceil(aspectRatio * format), format]
    : [format, Math.ceil(format / aspectRatio)];
}
