import { Box, Button, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import dialogBackground from '../../assets/shapes/gradient-wave-b.svg';
import { PLAN_CLASSIFICATIONS } from '../../constants/Plan';
import { SB_MEMBER_BILLING_URL } from '../../constants/Urls';
import { trackUpsellGoToAccount } from '../../events/sendEvents';
import { selectPlanClassification } from '../../selectors/user';
import CloseButton from '../CloseButton';
import StarterPlanFeatures from '../StarterPlanFeatures';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: theme.spacing(64),
  },
  dialogHeader: {
    backgroundImage: `url(${dialogBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  text: {
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.grey[800],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  denyButton: {
    color: theme.palette.grey[700],
    '&:hover, &:focus': {
      color: theme.palette.grey[900],
    },
  },
  endIcon: {
    marginLeft: theme.spacing(0.5),
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
    color: theme.palette.common.white,
  },
}));

export default function UpsellConfirmationDialog(props) {
  const { onClose, open } = props;
  const planClassification = useSelector(selectPlanClassification);
  const classes = useStyles();

  const handleClick = () => {
    trackUpsellGoToAccount();
    window.location = SB_MEMBER_BILLING_URL;
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.dialogHeader}>
        <Box paddingTop={10} paddingBottom={18} paddingX={8}>
          <h3 className={classes.title}>
            Your account has been successfully upgraded!
          </h3>
        </Box>
      </div>
      <Box paddingX={4} paddingBottom={4}>
        {planClassification === PLAN_CLASSIFICATIONS.STARTER && (
          <StarterPlanFeatures />
        )}
        {planClassification === PLAN_CLASSIFICATIONS.PRO && (
          <div className={classes.text}>Enjoy your Pro subscription.</div>
        )}
        {planClassification === PLAN_CLASSIFICATIONS.BUNDLE && (
          <div className={classes.text}>Enjoy your Unlimited subscription.</div>
        )}
        <div className={classes.buttonContainer}>
          <Button color="primary" variant="contained" onClick={onClose}>
            Continue Editing
          </Button>
          <Button
            className={classes.denyButton}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowRightAltRoundedIcon />}
            onClick={handleClick}
          >
            Go To My Account
          </Button>
        </div>
      </Box>
      <Box position="absolute" top={0} right={0} m={1} color="white">
        <CloseButton onClick={onClose} />
      </Box>
    </Dialog>
  );
}

UpsellConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
