import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterVideoIcon from '@videoblocks/react-icons/FilterVideo';
import Share from '@videoblocks/react-icons/Share';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShareExportCard from '../containers/exportContainer/ShareExportCard';
import { closeDialog, openConfirmationDialog } from '../features/ui/uiSlice';
import { selectIsEnterprise } from '../selectors/user';

export default function ProjectSettingsMenu(props) {
  const {
    numExports,
    onPromptDelete: handlePromptDelete,
    onDuplicate: handleDuplicate,
    onSaveAsTemplate: handleSaveAsTemplate,
    onSelect: handleSelect,
    onViewExports: handleViewExports,
    openToast,
    shareId,
    projectOwnedByUser,
  } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isEnterprise = useSelector(selectIsEnterprise);

  const hasExports = numExports > 0;
  const copyText = projectOwnedByUser ? 'Duplicate' : 'Copy to My Projects';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleSelect();
    handleClose();
  };

  const handleDuplicateClick = () => {
    handleDuplicate();
    handleClose();
  };

  const handleSaveAsTemplateClick = () => {
    handleSaveAsTemplate();
    handleClose();
  };

  const handleCopyClick = (message, severity) => {
    dispatch(closeDialog());
    openToast(message, severity);
  };

  const handleShareClick = () => {
    const shareLink = `${window.location.origin}/share/${shareId}`;
    dispatch(
      openConfirmationDialog({
        text: (
          <ShareExportCard
            isComplete={true}
            onCopy={handleCopyClick}
            shareLink={shareLink}
            withBorder={false}
          />
        ),
        showConfirm: false,
      })
    );
    handleClose();
  };

  const handleExportClick = () => {
    handleViewExports();
    handleClose();
  };

  const handleDeleteClick = () => {
    handlePromptDelete();
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="Project settings menu"
        aria-controls="project-settings-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="project-settings-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {projectOwnedByUser && (
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <EditRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Edit Project" />
          </MenuItem>
        )}
        <MenuItem onClick={handleDuplicateClick}>
          <ListItemIcon>
            <FileCopyRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={copyText} />
        </MenuItem>
        {isEnterprise && (
          <MenuItem onClick={handleSaveAsTemplateClick}>
            <ListItemIcon>
              <SvgIcon component={FilterVideoIcon} fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Save as Template" />
          </MenuItem>
        )}
        {shareId && (
          <MenuItem onClick={handleShareClick}>
            <ListItemIcon>
              <SvgIcon component={Share} fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}
        <MenuItem onClick={handleExportClick} disabled={!hasExports}>
          <ListItemIcon>
            <GetAppRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={`View Exports (${numExports})`} />
        </MenuItem>
        {projectOwnedByUser && (
          <MenuItem onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteRoundedIcon color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ color: 'error' }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

ProjectSettingsMenu.propTypes = {
  numExports: PropTypes.number,
  onPromptDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSaveAsTemplate: PropTypes.func,
  onSelect: PropTypes.func,
  onViewExports: PropTypes.func,
};

ProjectSettingsMenu.defaultProps = {
  numExports: 0,
  onPromptDelete: () => {},
  onDuplicate: () => {},
  onSaveAsTemplate: () => {},
  onSelect: () => {},
  onViewExports: () => {},
};
