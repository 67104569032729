import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) => ({
  tint: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
  },
}));

function TimelineItemTint(props) {
  const { tint } = props;
  const classes = useStyles();
  const style = { backgroundColor: tinycolor(tint) };
  return <div className={classes.tint} style={style} />;
}

TimelineItemTint.propTypes = {
  tint: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
};

export default TimelineItemTint;
