import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupsIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';

import LoaderContainer from '../components/LoaderContainer';
import ProjectExportsDrawer from '../components/ProjectExportsDrawer';
import ProjectTable from '../components/ProjectTable';
import ProjectsEmptyState from '../components/ProjectsEmptyState';
import Toast from '../components/Toast';
import { EDIT_PATH } from '../constants/Urls';
import { trackActiveTabOnProjectsPage } from '../events/sendEvents';
import { openToast } from '../features/ui/uiSlice';
import useProjects from '../hooks/useProjects';
import useToast from '../hooks/useToast';
import { selectIsEnterprise, selectOrganizationName } from '../selectors/user';
import theme from '../styles/theme';

const useStyles = makeStyles((theme) => ({
  createNewButton: {
    flexShrink: 0,
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0, 2),
  },
  subtitle: {
    lineHeight: theme.typography.pxToRem(28),
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    maxWidth: 800,
  },
  tableActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: theme.spacing(5),
    margin: theme.spacing(1, 0),
  },
  tabButton: {
    color: theme.palette.grey[600],
    borderRadius: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(28),
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
  },
  tabButtonActive: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      color: theme.palette.common.white,
    },
  },
  tabs: {
    display: 'inline-block',
  },
}));

export default function ProjectsContainer() {
  useTitle('Maker | Projects');
  const { search, state: locationState } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showMyProjects, setShowMyProjects] = useState(true);
  const [currentMyProjectsPage, setCurrentMyProjectsPage] = useState(1);
  const [currentOrgPage, setCurrentOrgPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const {
    open,
    message,
    openToast: openToastMessage,
    closeToast,
    severity,
  } = useToast();

  const isEnterprise = useSelector(selectIsEnterprise);
  const organizationName =
    useSelector(selectOrganizationName) || 'your organization';

  const {
    isLoading,
    getMoreResultsExist,
    getCurrentPage,
    myProjects,
    orgProjects,
    duplicateProject,
    setProjectTeamAccess,
  } = useProjects(currentMyProjectsPage, currentOrgPage);

  const classes = useStyles();

  const handleTeamAccessToggle = (id, teamAccess, projectName) => {
    setProjectTeamAccess(id, teamAccess);
    if (teamAccess) {
      openToastMessage(
        `'${projectName}' has been shared to the Team workspace.`,
        'success'
      );
    } else {
      openToastMessage(
        `'${projectName}' has been removed from team access.`,
        'success'
      );
    }
  };

  if (locationState?.toastMessage) {
    dispatch(openToast(locationState.toastMessage));
    history.replace({
      search,
      state: { toastMessage: null },
    });
  }

  const handleDrawerOpen = (project = {}) => {
    setSelectedProjectId(project.uid);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setTimeout(() => {
      setSelectedProjectId();
    }, theme.transitions.duration.leavingScreen);
  };

  const toggleActiveTab = (showMyProjects) => {
    trackActiveTabOnProjectsPage(showMyProjects);
    setShowMyProjects(showMyProjects);
  };

  const handleProjectDuplicate = (project) => {
    duplicateProject(project);
    setShowMyProjects(true);
  };

  const hasProjects = showMyProjects
    ? myProjects?.length > 0
    : orgProjects?.length > 0;
  const projects = showMyProjects ? myProjects : orgProjects;

  const renderCreateNewButton = () => {
    return (
      <Button
        className={classes.createNewButton}
        variant="contained"
        color="primary"
        component={RouterLink}
        to={{ pathname: EDIT_PATH, search }}
      >
        {hasProjects ? 'Create New Project' : 'Start Your First Project'}
      </Button>
    );
  };

  return (
    <Container>
      <Toast open={open} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <div className={classes.pageHeader}>
        <Typography variant="h2" element="h1">
          Projects
        </Typography>
        {!isEnterprise && !isLoading && hasProjects && renderCreateNewButton()}
      </div>
      <Typography className={classes.subtitle}>
        {isEnterprise ? (
          <span>
            Projects are private by default. Turn on "Team Access" to allow
            anyone at <strong>{organizationName}</strong> to view and make a
            copy of your project.
          </span>
        ) : (
          'Manage your video projects and view all your exports.'
        )}
      </Typography>
      {isEnterprise && (
        <div className={classes.tableActions}>
          <div className={classes.tabs}>
            <Button
              className={clsx(classes.tabButton, {
                [classes.tabButtonActive]: showMyProjects,
              })}
              startIcon={<PersonIcon />}
              onClick={() => toggleActiveTab(true)}
            >
              My Projects
            </Button>
            <Button
              className={clsx(classes.tabButton, {
                [classes.tabButtonActive]: !showMyProjects,
              })}
              startIcon={<GroupsIcon />}
              onClick={() => toggleActiveTab(false)}
            >
              Team Projects
            </Button>
          </div>
          {!isLoading && hasProjects && renderCreateNewButton()}
        </div>
      )}

      {isLoading && <LoaderContainer />}

      {!isLoading && !hasProjects && getCurrentPage(showMyProjects) === 1 && (
        <Box mt={6}>
          <ProjectsEmptyState />
        </Box>
      )}

      {hasProjects && (
        <Box mt={2}>
          <ProjectTable
            isLoading={isLoading}
            showTeamAccessToggle={isEnterprise && showMyProjects}
            moreResultsExist={getMoreResultsExist(showMyProjects)}
            projects={projects}
            currentPage={
              showMyProjects ? currentMyProjectsPage : currentOrgPage
            }
            setCurrentPage={
              showMyProjects ? setCurrentMyProjectsPage : setCurrentOrgPage
            }
            onViewExports={handleDrawerOpen}
            onDuplicate={handleProjectDuplicate}
            onTeamAccessToggle={handleTeamAccessToggle}
            openToast={openToastMessage}
          />
        </Box>
      )}

      <ProjectExportsDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        projectId={selectedProjectId}
      />
    </Container>
  );
}
