import { Button, Grid, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRightRounded } from '@material-ui/icons';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import LayersBackIcon from '@videoblocks/react-icons/LayersBack';
import PaintingPalette from '@videoblocks/react-icons/PaintingPalette';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SEARCH_RESULTS_PAGE_SIZE } from '../../constants/Constants';
import { openBrandSelectDialog } from '../../features/ui/uiSlice';
import useBrandSelect from '../../hooks/useBrandSelect';
import useBrands from '../../hooks/useBrands';
import useUploads from '../../hooks/useUploads';
import { selectOrganizationId } from '../../selectors/user';
import UploadPreview from '../UploadPreview';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  brandSelect: {
    color: theme.palette.common.black,
    maxWidth: '100%',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    paddingLeft: 0,
  },
  buttonText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  emptyLogosContainer: {
    width: '100%',
    display: 'flex',
    justify: 'center',
    textAlign: 'center',
  },
  emptyLogosText: {
    color: theme.palette.grey[600],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    width: '100%',
    alignSelf: 'center',
  },
  endIcon: {
    color: theme.palette.common.black,
    fill: theme.palette.common.black,
    transform: 'rotate(90deg)',
  },
  icon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  sectionHeader: {
    color: theme.palette.grey[600],
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

export default function BrandLogos(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onUploadSelect } = props;
  const organizationId = useSelector(selectOrganizationId);

  // Determine context within the app based on route
  const { projectId } = useParams();
  const { brands = [], isLoading: brandsIsLoading } = useBrands();

  const brandsWithLogos = brands.filter((brand) => brand.uploads_count > 0);

  const [selectedBrandUid, setSelectedBrandUid] = useBrandSelect({
    brands: brandsWithLogos,
    brandsIsLoading,
    projectId,
  });

  const { data } = useUploads(MediaTypes.IMAGE, {
    isLogo: true,
    pageSize: SEARCH_RESULTS_PAGE_SIZE,
    organization: organizationId,
    brandUid: selectedBrandUid,
  });

  const getBrandName = () => {
    const selectedBrand = brands.find(
      (brand) => brand.uid === selectedBrandUid
    );
    return selectedBrand?.detail?.name;
  };

  const handleBrandsClick = () => {
    dispatch(
      openBrandSelectDialog({
        brands: brandsWithLogos,
        initialBrand: selectedBrandUid,
        onConfirm: (uid) => {
          const newBrand = brands.find((brand) => brand.uid === uid);
          setSelectedBrandUid(newBrand.uid);
        },
      })
    );
  };

  return (
    <Grid container item spacing={2} className={classes.content}>
      <Grid item xs={12} className={classes.sectionHeader}>
        <SvgIcon
          component={PaintingPalette}
          className={classes.icon}
          fontSize="inherit"
        />
        From Brands
      </Grid>
      {selectedBrandUid && !!brandsWithLogos?.length && (
        <Grid item xs={8}>
          <Button
            variant="text"
            className={classes.brandSelect}
            endIcon={<ChevronRightRounded />}
            classes={{ endIcon: classes.endIcon }}
            onClick={handleBrandsClick}
            style={{ backgroundColor: 'transparent' }}
          >
            <div className={classes.buttonText}>{getBrandName()}</div>
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <ResultsGrid>
          {data.map((item) => (
            <UploadPreview
              mediaType={MediaTypes.IMAGE}
              onSelect={onUploadSelect}
              key={item.id}
              showDelete={false}
              upload={item}
            />
          ))}
        </ResultsGrid>
      </Grid>
      {!brandsWithLogos?.length && (
        <Grid item xs={12} className={classes.emptyLogosContainer}>
          <span className={classes.emptyLogosText}>
            None of your team’s brands contain logos.
          </span>
        </Grid>
      )}
      <Grid item xs={12} className={classes.sectionHeader}>
        <SvgIcon
          component={LayersBackIcon}
          className={classes.icon}
          fontSize="inherit"
        />
        Logos
      </Grid>
    </Grid>
  );
}
