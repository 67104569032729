import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';

import { HOTKEYS } from '../../utils/hotkeys';
import HotkeyLabel from '../HotkeyLabel';

const hotkeys = [
  HOTKEYS.OPEN_SHORTCUTS.id,
  HOTKEYS.UNDO.id,
  HOTKEYS.REDO.id,
  HOTKEYS.DELETE.id,
  HOTKEYS.TRIM.id,
  HOTKEYS.SPLIT.id,
  HOTKEYS.DUPLICATE.id,
  HOTKEYS.DESELECT.id,
  HOTKEYS.ZOOM_IN.id,
  HOTKEYS.ZOOM_OUT.id,
  HOTKEYS.PLAY_PAUSE.id,
  HOTKEYS.MUTE.id,
  HOTKEYS.SEEK_BACKWARD.id,
  HOTKEYS.SEEK_FORWARD.id,
  HOTKEYS.SEEK_BACKWARD_MORE.id,
  HOTKEYS.SEEK_FORWARD_MORE.id,
  HOTKEYS.SEEK_TO_START.id,
  HOTKEYS.SEEK_TO_END.id,
];

export default function ShortcutsDialog({ onClose, open }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>Keyboard Shortcuts</DialogTitle>
      <DialogContent dividers>
        <Table size="small">
          {hotkeys.map((id) => (
            <TableRow key={id}>
              <TableCell>{HOTKEYS[id].description}</TableCell>
              <TableCell align="right">
                <HotkeyLabel hotkey={HOTKEYS[id].keys} />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </DialogContent>
    </Dialog>
  );
}
