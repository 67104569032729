import { getStoryboardDuration } from '@videoblocks/jelly-renderer';

const BITRATE_MBPS_480 = 0.96;
const BYTES_TO_MEGABYTES = 1000 * 1000;

// bitrates for each resolution taken from http://www.lighterra.com/papers/videoencodingh264/
const getBitrateFromResolution = (resolution) => {
  if (resolution === 720) {
    return BITRATE_MBPS_480 * 2;
  }
  if (resolution === 1080) {
    return BITRATE_MBPS_480 * 4;
  }
  return BITRATE_MBPS_480;
};

export const calculateEstimatedFileSize = (storyboardString, resolution) => {
  if (!storyboardString) {
    return 0;
  }

  const storyboard = JSON.parse(storyboardString);
  const duration = getStoryboardDuration(storyboard.items.entities);
  const bitrate = getBitrateFromResolution(resolution);
  const sizeBytes = ((bitrate * BYTES_TO_MEGABYTES) / 8) * duration;
  return Math.round(sizeBytes / BYTES_TO_MEGABYTES) * BYTES_TO_MEGABYTES;
};
