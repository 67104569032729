import { Box, ButtonBase, Divider, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASPECT_RATIO_ICONS } from '../constants/AspectRatios';
import { socialPlatformDisplayOrder } from '../constants/SocialMedia';
import { trackEvent } from '../events/sendEvents';
import { PROJECT } from '../events/tags';
import { ratioUpdated, selectRatio } from '../slices/storyboardSlice';
import AspectRatioToggle from './AspectRatioToggle';
import SocialMediaFormats from './SocialMediaFormats';
import { AspectRatioPreviewContext } from './editor/AspectRatioPreviewProvider';

const useStyles = makeStyles((theme) => ({
  aspectRatioButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  aspectRatioButtonIcon: {
    width: '100%',
    color: theme.palette.common.white,
    pointerEvents: 'none',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    userSelect: 'none',
  },
  popoverTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 16 / 14,
  },
  ratioLabelSelected: {
    color: theme.palette.common.white,
  },
  popoverSubtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    lineHeight: 20 / 14,
  },
  socialIcon: {
    marginRight: theme.spacing(0.75),
    width: theme.spacing(3),
    height: theme.spacing(3),
    filter: 'saturate(0%)',
    opacity: 0.6,
    pointerEvents: 'none',
  },
  socialIconActive: {
    filter: 'none',
    opacity: 1,
  },
  socialName: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 20 / 14,
  },
}));

export default function AspectRatioSelect() {
  const context = useContext(AspectRatioPreviewContext);
  const dispatch = useDispatch();
  const selectedRatio = useSelector(selectRatio);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'formatMenu',
  });
  const [hoverRatio, setHoverRatio] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (popupState.isOpen) {
      context.setHoverRatio(hoverRatio || selectedRatio);
    } else {
      context.setHoverRatio('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState.isOpen, hoverRatio, selectedRatio]);

  const handleRatioSelect = (event, value) => {
    const [ratio] = value;
    setHoverRatio('');
    if (ratio !== selectedRatio) {
      dispatch(ratioUpdated(ratio));
      trackEvent(PROJECT.EDIT, { ratio });
    }
    popupState.close();
  };

  const handleToggleButtonEnter = (event) => {
    const ratio = event.currentTarget.value;
    setHoverRatio(ratio);
  };

  const handleToggleButtonLeave = () => setHoverRatio('');

  const ButtonIcon = ASPECT_RATIO_ICONS[selectedRatio];

  const id = popupState.isOpen ? 'aspect-ratio-popover' : undefined;

  return (
    <>
      <ButtonBase
        className={classes.aspectRatioButton}
        aria-describedby={id}
        aria-label="aspect-ratio"
        value={selectedRatio}
        {...bindTrigger(popupState)}
      >
        <ButtonIcon className={classes.aspectRatioButtonIcon} />
      </ButtonBase>
      <Popover
        classes={{ paper: classes.paper }}
        id={id}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        {...bindPopover(popupState)}
      >
        <div className={classes.popoverTitle}>Canvas Aspect Ratio</div>
        <AspectRatioToggle
          onChange={handleRatioSelect}
          onToggleButtonEnter={handleToggleButtonEnter}
          onToggleButtonLeave={handleToggleButtonLeave}
          selectedRatio={selectedRatio}
        />
        <Divider />
        <div className={classes.popoverSubtitle} id="aspect-ratio-select-label">
          This format is ideal for:
        </div>
        <Box maxWidth={320}>
          <SocialMediaFormats
            aria-labelledby="aspect-ratio-select-label"
            platforms={socialPlatformDisplayOrder}
            ratio={hoverRatio || selectedRatio}
          />
        </Box>
      </Popover>
    </>
  );
}
