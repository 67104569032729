import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import { trackEvent } from '../events/sendEvents';
import { SIDE_DRAWER } from '../events/tags';
import AudioPreview from './AudioPreview';
import ImagePreview from './ImagePreview';
import PaddedAspectBox from './PaddedAspectBox';
import VideoPreview from './VideoPreview';

const useStyles = makeStyles((theme) => ({
  mediaWrapper: {
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
  },
}));

export default function HoverMediaWrapper(props) {
  const {
    mediaType,
    objectFit = 'cover',
    previewUrl,
    thumbnailUrl,
    title,
  } = props;
  const classes = useStyles();

  const isAudio = mediaType === MediaTypes.AUDIO;
  const isImage = mediaType === MediaTypes.IMAGE;
  const isVideo = mediaType === MediaTypes.VIDEO;

  const handleMouseEnter = () => {
    trackEvent(SIDE_DRAWER.ITEM_PREVIEW, { mediaType });
  };

  return (
    <div className={classes.mediaWrapper} onMouseEnter={handleMouseEnter}>
      <PaddedAspectBox aspectRatio={16 / 9}>
        {isImage && (
          <ImagePreview
            objectFit={objectFit}
            source={thumbnailUrl || previewUrl}
            title={title}
          />
        )}
        {isVideo && (
          <VideoPreview
            objectFit={objectFit}
            source={previewUrl}
            thumbnail={thumbnailUrl}
            title={title}
          />
        )}
        {isAudio && (
          <AudioPreview
            source={previewUrl}
            thumbnail={thumbnailUrl}
            title={title}
          />
        )}
      </PaddedAspectBox>
    </div>
  );
}

HoverMediaWrapper.propTypes = {
  mediaType: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]).isRequired,
  objectFit: PropTypes.oneOf(['cover', 'contain']),
  previewUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  title: PropTypes.string,
};
