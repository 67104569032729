import { DEFAULT_BODY_FONT, DEFAULT_HEADER_FONT } from './FontStyles';

export const DEFAULT_COLOR_OVERLAY_TINT = { r: 0, g: 0, b: 0, a: 0.3 };

/**
 * Brand Defaults
 */
export const NEW_BRAND_UID = 'new';
export const DEFAULT_BRAND_LOGO_BACKGROUND_COLOR = {
  r: 212,
  g: 214,
  b: 217,
  a: 1,
};
export const DEFAULT_BRAND_PRIMARY_COLOR = { r: 54, g: 65, b: 77, a: 1 };
export const DEFAULT_BRAND_SECONDARY_COLOR = { r: 185, g: 188, b: 193, a: 1 };
export const DEFAULT_BRAND_NAME = 'Untitled Brand';
export const MAX_BRAND_COLORS = 20;

export const DEFAULT_STYLES = {
  fonts: {
    header: DEFAULT_HEADER_FONT,
    body: DEFAULT_BODY_FONT,
  },
  colors: [DEFAULT_BRAND_PRIMARY_COLOR, DEFAULT_BRAND_SECONDARY_COLOR],
  imageTint: DEFAULT_COLOR_OVERLAY_TINT,
  videoTint: DEFAULT_COLOR_OVERLAY_TINT,
  primaryTextColor: DEFAULT_BRAND_PRIMARY_COLOR,
  secondaryTextColor: DEFAULT_BRAND_SECONDARY_COLOR,
};
