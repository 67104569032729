import { Box, IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';

import HotkeyLabel from '../HotkeyLabel';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(16),
    '&:disabled': {
      color: theme.palette.grey[700],
    },
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function ToolbarButton(props) {
  const { disabled, hotkey, icon = 'svg', title, ...rest } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (disabled && open) {
      setOpen(false);
    }
  }, [disabled, open]);

  return (
    <Tooltip
      title={
        <Box display="flex" alignItems="center" p={0.5}>
          {title}
          <HotkeyLabel hotkey={hotkey} ml={1} />
        </Box>
      }
      onOpen={() => {
        if (!disabled) setOpen(true);
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <IconButton
        className={classes.button}
        disabled={disabled}
        aria-label={title}
        {...rest}
      >
        <SvgIcon component={icon} fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

ToolbarButton.propTypes = {
  disabled: PropTypes.bool,
  hotkey: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default memo(ToolbarButton);
