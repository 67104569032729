import { Box, IconButton, Slider, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VolumeFullIcon from '@videoblocks/react-icons/VolumeFull';
import VolumeLowIcon from '@videoblocks/react-icons/VolumeLow';
import VolumeMediumIcon from '@videoblocks/react-icons/VolumeMedium';
import VolumeOffIcon from '@videoblocks/react-icons/VolumeOff';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Range set to 0-1 to match renderer volume range
const MAX_VOLUME = 1;
const MIN_VOLUME = 0;
const VOLUME_STEP = 0.01;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16 / 14,
  },
  volumeButton: {
    zIndex: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginRight: theme.spacing(1),
  },
  volumeLevel: {
    marginLeft: 'auto',
    fontFamily: 'Lato',
    color: theme.palette.grey,
  },
}));

function VolumeIcon({ volume }) {
  if (volume > 0.66) {
    return <SvgIcon component={VolumeFullIcon} fontSize="small" />;
  }

  if (volume > 0.33) {
    return <SvgIcon component={VolumeMediumIcon} fontSize="small" />;
  }

  if (volume > 0) {
    return <SvgIcon component={VolumeLowIcon} fontSize="small" />;
  }

  return <SvgIcon component={VolumeOffIcon} fontSize="small" />;
}

export default function VolumeControls(props) {
  const { label = 'Volume', onVolumeChange = () => {}, value = 1 } = props;

  const [currentVolume, setCurrentVolume] = useState(value);
  const [previousVolume, setPreviousVolume] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    setCurrentVolume(value);
  }, [value]);

  const handleMute = () => {
    if (currentVolume === 0) {
      setCurrentVolume(previousVolume);
      onVolumeChange(previousVolume);
    } else {
      setPreviousVolume(currentVolume);
      setCurrentVolume(0);
      onVolumeChange(0);
    }
  };

  const handleChange = (event, newValue) => {
    setCurrentVolume(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    onVolumeChange(newValue);
  };

  return (
    <div className={classes.root}>
      <Box display="flex">
        <label htmlFor="volume-slider">{label}</label>
        <div className={classes.volumeLevel}>
          {Math.round(currentVolume * 100)}
        </div>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label={currentVolume === 0 ? 'unmute' : 'mute'}
          className={classes.volumeButton}
          color="inherit"
          disableRipple
          onClick={handleMute}
        >
          <VolumeIcon volume={currentVolume} />
        </IconButton>
        <Slider
          id="volume-slider"
          color="secondary"
          min={MIN_VOLUME}
          max={MAX_VOLUME}
          step={VOLUME_STEP}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          value={currentVolume}
        />
      </Box>
    </div>
  );
}

VolumeControls.propTypes = {
  value: PropTypes.number,
  onVolumeChange: PropTypes.func,
};
