import AbstractAPI from './AbstractAPI';

export default class BrandsAPI extends AbstractAPI {
  /**
   * @returns {Promise<BrandsIndexResponse>}
   */
  getBrands() {
    return this.get('brands');
  }

  /**
   * @returns {Promise<Brand>}
   */
  getDefaultBrand() {
    const params = { is_default: 1 };
    return this.get('brands', { params });
  }

  /**
   * @param {string} brandUid
   * @returns {Promise<Brand>}
   */
  getBrand(brandUid) {
    return this.get(`brands/${brandUid}`);
  }

  /**
   * @param {BrandDetail} detail
   * @param {boolean} isDraft
   * @returns {Promise<BrandPostResponse>}
   */
  postBrand(detail, isDraft = false) {
    return this.post('brands', { detail, is_draft: isDraft });
  }

  /**
   * @param {string} brandUid
   * @param {BrandDetail} detail
   * @param {boolean} isDraft
   * @param {boolean} isDefault
   * @returns {Promise<BrandPutResponse>}
   */
  putBrand(brandUid, detail, isDraft = false, isDefault = false) {
    const data = { detail, is_draft: isDraft, is_default: isDefault };
    return this.put(`brands/${brandUid}`, data);
  }

  /**
   * @param {string} brandUid
   * @returns {Promise<any>}
   */
  deleteBrand(brandUid) {
    return this.delete(`brands/${brandUid}`);
  }

  transformResponse(data) {
    if (!data) {
      return undefined;
    }

    data = JSON.parse(data);

    // parse single item
    if (data?.detail) {
      data.detail = JSON.parse(data.detail);
    }

    // parse multiple items
    if (Array.isArray(data)) {
      data = data.map((item) => ({
        ...item,
        detail: JSON.parse(item.detail),
      }));
    }

    return data;
  }

  transformRequest(data) {
    if (data?.detail) {
      data = { ...data, detail: JSON.stringify(data.detail) };
    }
    return data;
  }
}
