import { Button, IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import NavigationUpCircle from '@videoblocks/react-icons/NavigationUpCircle';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  trackUpsellDialogOpen,
  trackUpsellSignUp,
} from '../../events/sendEvents';
import { openUpsellDialog } from '../../features/ui/uiSlice';
import { selectIsEnterprise, selectUpgradePath } from '../../selectors/user';

const useStyles = makeStyles((theme) => ({
  tinyButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

export function UpsellButton(props) {
  const { onClick, variant, size = 'small', iconButton = false } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const isEnterprise = useSelector(selectIsEnterprise);
  const upgradePath = useSelector(selectUpgradePath);

  const handleClick = async () => {
    onClick();
    if (isEnterprise) {
      await trackUpsellDialogOpen();
      dispatch(openUpsellDialog());
    } else {
      await trackUpsellSignUp();
      window.location.assign(upgradePath); // can't use history.replace because domain is different
    }
  };

  if (iconButton) {
    return (
      <IconButton
        className={classes.tinyButton}
        aria-label="Upgrade"
        onClick={handleClick}
        size={size}
        variant={variant}
      >
        <SvgIcon
          component={NavigationUpCircle}
          fontSize="small"
          alt="Upgrade"
        />
      </IconButton>
    );
  }

  return (
    <Button
      variant={variant}
      size={size}
      startIcon={<SvgIcon component={NavigationUpCircle} fontSize="small" />}
      onClick={handleClick}
    >
      Upgrade
    </Button>
  );
}

UpsellButton.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

UpsellButton.defaultProps = {
  onClick: () => {},
  variant: 'outlined',
};

export default UpsellButton;
