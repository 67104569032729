import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import * as ContentTypes from '../../constants/ContentTypes';
import { AudioFilters } from '../../constants/SearchFilters';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  changeContentType,
  changeFilters,
  removeFilter,
  resetContentType,
  selectContentType,
  selectDrawer,
  selectFilters,
  selectKeywords,
} from '../../features/assetDrawer/assetDrawerSlice';
import useAudioCategories from '../../hooks/useAudioCategories';
import { findLabel } from '../../utils/searchUtils';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingBottom: theme.spacing(1),
  },
  chips: {
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function getAudioTypeLabel(value) {
  switch (value) {
    case ContentTypes.SFX:
      return 'Sound Effects';
    case ContentTypes.MUSIC:
      return 'Music';
    default:
      return 'All';
  }
}

export default function AudioSearchFilters(props) {
  const dispatch = useDispatch();
  const contentType = useSelector(selectContentType);
  const drawer = useSelector(selectDrawer);
  const filters = useSelector(selectFilters);
  const keywords = useSelector(selectKeywords);

  const { editable } = props;
  const { mood = '', genre = '', instrument = '' } = filters;
  const {
    mood: moodCategories,
    genre: genreCategories,
    instrument: instrumentCategories,
  } = useAudioCategories();
  const classes = useStyles();
  const mediaType = MediaTypes.AUDIO;

  const handleChange = (filterName) => (event) => {
    const oldValue = filters[filterName];
    const filterValue = event.target.value.toString();
    dispatch(changeFilters({ ...filters, [filterName]: filterValue }));
    if (filterValue !== oldValue) {
      trackEvent(SIDE_DRAWER.FILTER_SELECT, {
        drawer,
        filterName,
        filterValue,
        mediaType,
      });
      trackEvent(SIDE_DRAWER.SEARCH_FILTERS, {
        text: keywords,
        ...filters,
        [filterName]: filterValue,
        contentType,
        mediaType,
        drawer,
      });
    }
  };

  const handleContentTypeChange = (event) => {
    const oldValue = contentType;
    const filterValue = event.target.value.toString();
    dispatch(changeContentType(filterValue));
    if (filterValue !== oldValue) {
      trackEvent(SIDE_DRAWER.FILTER_SELECT, {
        drawer,
        filterName: 'contentType',
        filterValue,
        mediaType,
      });
      const updatedFilters = filterValue !== ContentTypes.SFX ? filters : {};
      trackEvent(SIDE_DRAWER.SEARCH_FILTERS, {
        text: keywords,
        ...updatedFilters,
        //contentType has not been updated to the filterValue at this point
        contentType: filterValue,
        mediaType,
        drawer,
      });
    }
  };

  const handleChipDelete = (filterName) => {
    const oldValue = filters[filterName];
    dispatch(removeFilter(filterName));
    trackEvent(SIDE_DRAWER.FILTER_REMOVE, {
      drawer,
      filterName,
      filterValue: oldValue,
      mediaType,
    });
  };

  const handleContentTypeDelete = () => {
    const oldValue = contentType;
    dispatch(resetContentType());
    trackEvent(SIDE_DRAWER.FILTER_REMOVE, {
      drawer,
      filterName: 'contentType',
      filterValue: oldValue,
      mediaType,
    });
  };

  const handleOpen = (filterName) => () => {
    trackEvent(SIDE_DRAWER.FILTER_OPEN, {
      drawer,
      filterName,
      filterValue: filters[filterName] || '',
      mediaType,
    });
  };

  const handleContentTypeOpen = () => {
    trackEvent(SIDE_DRAWER.FILTER_OPEN, {
      drawer,
      filterName: 'contentType',
      filterValue: contentType || '',
      mediaType,
    });
  };

  const shouldDisableCategories = contentType === ContentTypes.SFX;

  if (!editable) {
    if (!contentType && !mood && !genre && !instrument) {
      return null;
    }
    return (
      <div className={classes.chips}>
        {contentType && (
          <Chip
            label={getAudioTypeLabel(contentType)}
            onDelete={() => handleContentTypeDelete()}
          />
        )}
        {mood && (
          <Chip
            label={findLabel(moodCategories, mood)}
            onDelete={() => handleChipDelete(AudioFilters.MOOD)}
          />
        )}
        {genre && (
          <Chip
            label={findLabel(genreCategories, genre)}
            onDelete={() => handleChipDelete(AudioFilters.GENRE)}
          />
        )}
        {instrument && (
          <Chip
            label={findLabel(instrumentCategories, instrument)}
            onDelete={() => handleChipDelete(AudioFilters.INSTRUMENT)}
          />
        )}
      </div>
    );
  }

  return (
    <Grid className={classes.grid} container spacing={1}>
      <Grid item xs={6}>
        <InputLabel id="media-type-label">Media Type</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="media-type-label"
            color="secondary"
            displayEmpty
            onChange={handleContentTypeChange}
            value={contentType}
            onOpen={handleContentTypeOpen}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value={ContentTypes.MUSIC} key={ContentTypes.MUSIC}>
              {getAudioTypeLabel(ContentTypes.MUSIC)}
            </MenuItem>
            <MenuItem value={ContentTypes.SFX} key={ContentTypes.SFX}>
              {getAudioTypeLabel(ContentTypes.SFX)}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="mood-label">Mood</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="mood-label"
            color="secondary"
            displayEmpty
            onChange={handleChange(AudioFilters.MOOD)}
            onOpen={handleOpen(AudioFilters.MOOD)}
            value={mood}
            disabled={shouldDisableCategories}
          >
            <MenuItem value="">None</MenuItem>
            {moodCategories.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="genre-label">Genre</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="genre-label"
            color="secondary"
            displayEmpty
            onChange={handleChange(AudioFilters.GENRE)}
            value={genre}
            disabled={shouldDisableCategories}
            onOpen={handleOpen(AudioFilters.GENRE)}
          >
            <MenuItem value="">None</MenuItem>
            {genreCategories.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <InputLabel id="instrument-label">Instrument</InputLabel>
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="instrument-label"
            color="secondary"
            displayEmpty
            onChange={handleChange(AudioFilters.INSTRUMENT)}
            value={instrument}
            disabled={shouldDisableCategories}
            onOpen={handleOpen(AudioFilters.INSTRUMENT)}
          >
            <MenuItem value="">None</MenuItem>
            {instrumentCategories.map(({ id, name }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

AudioSearchFilters.propTypes = {
  editable: PropTypes.bool,
};
