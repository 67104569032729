import { Button, makeStyles, Typography } from '@material-ui/core';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import ExportsAPI from '../../api/ExportsAPI';
import { PROJECTS_PATH } from '../../constants/Urls';
import { trackEvent } from '../../events/sendEvents';
import { PROJECT } from '../../events/tags';
import {
  selectIsLimitedStarterBundle,
  shouldWatermark,
} from '../../selectors/user';
import {
  selectAllLayers,
  selectStoryboard,
} from '../../slices/storyboardSlice';
import { getLayersProperties } from '../../utils/storyboardUtils';
import ResolutionToggle from '../ResolutionToggle';
import UpsellButton from '../navbar/UpsellButton';

const exportsAPI = new ExportsAPI();

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(-1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export default function ExportCard({ closeExportDrawer }) {
  const classes = useStyles();
  const history = useHistory();
  const { projectId } = useParams();
  const [resolution, setResolution] = useState(480);
  const storyboard = useSelector(selectStoryboard);
  const layers = useSelector(selectAllLayers);

  const isLimitedStarterBundle = useSelector(selectIsLimitedStarterBundle);
  const isWatermarked = useSelector(shouldWatermark);

  const showLimitedStarterPlanExportButton =
    isLimitedStarterBundle && !isWatermarked;
  const showDefaultPlanUpgradeButton = isWatermarked;

  const startExport = async (forceWatermark = false) => {
    const { id: exportId } = await exportsAPI.createExport({
      is_watermarked: isWatermarked || forceWatermark,
      project_id: projectId,
      resolution,
      storyboard,
    });

    trackEvent(PROJECT.EXPORT, {
      exportId,
      isWatermarked: isWatermarked || forceWatermark,
      resolution,
      projectDuration: getStoryboardDuration(storyboard.items?.entities),
      projectUid: projectId,
      ...getLayersProperties(layers),
    });
    closeExportDrawer();
    history.push({
      pathname: `${PROJECTS_PATH}/${projectId}/exports/${exportId}`,
      search: history.location.search,
    });
  };

  const exportWithWatermarks = () => startExport(true);

  const exportText = `Export at ${resolution}p`;
  const exportWithWatermarksText = `Export at ${resolution}p with watermarks`;

  return (
    <div className={classes.card}>
      <Typography variant="h4" component="h4">
        Export
      </Typography>
      <div>Your video will export in .MP4 format.</div>
      <label className={classes.label} id="resolution-label">
        Export Resolution
      </label>
      <ResolutionToggle
        aria-labelledby="resolution-label"
        onChange={setResolution}
        value={resolution}
      />
      {showLimitedStarterPlanExportButton ? (
        <Button variant="outlined" onClick={exportWithWatermarks}>
          {exportWithWatermarksText}
        </Button>
      ) : showDefaultPlanUpgradeButton ? (
        <UpsellButton onClick={closeExportDrawer} size="medium" />
      ) : null}
      <Button variant="contained" color="primary" onClick={() => startExport()}>
        {isWatermarked ? exportWithWatermarksText : exportText}
      </Button>
    </div>
  );
}
