import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, last } from 'lodash';

// constants
export const MAX_UI_STACK_DEPTH = 5;

export const BRAND_DELETE_DIALOG = 'BRAND_DELETE_DIALOG';
export const BRAND_SELECT_DIALOG = 'BRAND_SELECT_DIALOG';
export const BRAND_UNSAVED_DIALOG = 'BRAND_UNSAVED_DIALOG';
export const BRAND_UPDATE_DIALOG = 'BRAND_UPDATE_DIALOG';
export const CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG';
export const PROJECT_DELETE_DIALOG = 'PROJECT_DELETE_DIALOG';
export const SAVE_AS_TEMPLATE_DIALOG = 'SAVE_AS_TEMPLATE_DIALOG';
export const SHORTCUTS_DIALOG = 'SHORTCUTS_DIALOG';
export const TEMPLATE_DELETE_DIALOG = 'TEMPLATE_DELETE_DIALOG';
export const TRIM_DIALOG = 'TRIM_DIALOG';
export const TYPEFACE_UPLOAD_DIALOG = 'TYPEFACE_UPLOAD_DIALOG';
export const UPLOAD_DIALOG = 'UPLOAD_DIALOG';
export const UPSELL_CONFIRMATION_DIALOG = 'UPSELL_CONFIRMATION_DIALOG';
export const UPSELL_DIALOG = 'UPSELL_DIALOG';
export const VIDEO_PREVIEW_DIALOG = 'VIDEO_PREVIEW_DIALOG';

export const EXPORT_DRAWER = 'EXPORT_DRAWER';

export const TOAST = 'TOAST';

const initialState = {
  // TODO: make dialog a single object representing one dialog instead of array of objects
  dialog: [],
  toast: {},
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closeDialog: (state) => {
      state.dialog.pop();
    },
    closeAllDialogs: (state) => {
      state.dialog = [];
    },
    openDialog: {
      reducer: (state, action) => {
        const { uiType, uiProps } = action.payload;

        if (state.dialog.length < MAX_UI_STACK_DEPTH) {
          state.dialog.push({ uiType, uiProps });
        }
      },
      prepare: (uiType, uiProps = {}) => ({
        payload: { uiType, uiProps },
      }),
    },
    setDialogUIProps: {
      reducer: (state, action) => {
        const { uiType, uiProps } = action.payload;
        const dialog = state.dialog.find((dialog) => dialog.uiType === uiType);
        if (dialog) {
          dialog.uiProps = { ...dialog.uiProps, ...uiProps };
        }
      },
      prepare: (uiType, uiProps = {}) => ({
        payload: { uiType, uiProps },
      }),
    },
    openToast: {
      reducer: (state, action) => {
        state.toast = action.payload;
      },
      prepare: (message, options = {}) => ({
        payload: { message, ...options },
      }),
    },
    closeToast: (state) => {
      state.toast = {};
    },
  },
});

// actions
export const {
  closeAllDialogs,
  closeDialog,
  closeToast,
  openDialog,
  openToast,
  setDialogUIProps,
} = uiSlice.actions;

// Brand dialogs
export const openBrandDeleteDialog = (...args) =>
  openDialog(BRAND_DELETE_DIALOG, ...args);
export const openBrandSelectDialog = (...args) =>
  openDialog(BRAND_SELECT_DIALOG, ...args);
export const openBrandUnsavedDialog = (...args) =>
  openDialog(BRAND_UNSAVED_DIALOG, ...args);
export const openBrandUpdateDialog = (...args) =>
  openDialog(BRAND_UPDATE_DIALOG, ...args);

// Editing dialogs
export const openTrimDialog = (...args) => openDialog(TRIM_DIALOG, ...args);
export const openVideoPreviewDialog = (...args) =>
  openDialog(VIDEO_PREVIEW_DIALOG, ...args);

// Upload dialogs
export const openTypefaceUploadDialog = (...args) =>
  openDialog(TYPEFACE_UPLOAD_DIALOG, ...args);
export const openUploadDialog = (...args) => openDialog(UPLOAD_DIALOG, ...args);

// Navbar actions
export const openExportDrawer = (...args) => openDialog(EXPORT_DRAWER, ...args);
export const openSaveAsTemplateDialog = (...args) =>
  openDialog(SAVE_AS_TEMPLATE_DIALOG, ...args);
export const openUpsellDialog = (...args) => openDialog(UPSELL_DIALOG, ...args);
export const openUpsellConfirmationDialog = (...args) =>
  openDialog(UPSELL_CONFIRMATION_DIALOG, ...args);

// Miscellaneous dialogs
export const openProjectDeleteDialog = (...args) =>
  openDialog(PROJECT_DELETE_DIALOG, ...args);

export const openTemplateDeleteDialog = (...args) =>
  openDialog(TEMPLATE_DELETE_DIALOG, ...args);

export const openConfirmationDialog = (...args) =>
  openDialog(CONFIRMATION_DIALOG, ...args);

export const openShortcutsDialog = (...args) =>
  openDialog(SHORTCUTS_DIALOG, ...args);

// selectors
export const selectDialog = (state) => state?.ui?.dialog ?? [];

export const selectCurrentDialog = createSelector(
  selectDialog,
  (items) => last(items) || {}
);

export const selectIsDialogOpen = createSelector(
  selectCurrentDialog,
  (dialog) => !isEmpty(dialog)
);

export const selectCurrentDialogType = createSelector(
  selectCurrentDialog,
  (item) => item.uiType || ''
);

export const selectCurrentDialogProps = createSelector(
  selectCurrentDialog,
  (item) => item.uiProps || {}
);

export const selectCurrentToast = (state) => state?.ui?.toast ?? {};

export const selectIsExportDrawerOpen = createSelector(
  selectCurrentDialogType,
  (uiType) => uiType === EXPORT_DRAWER
);

export default uiSlice.reducer;
