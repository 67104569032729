import { MediaTypes } from '@videoblocks/jelly-renderer';

import replaceMediaObject from './replaceMediaObject';
import replacePresetObject from './replacePresetObject';

export default function replaceObject(oldItem, newItem) {
  switch (oldItem.mediaType) {
    case MediaTypes.ANIMATION:
    case MediaTypes.TEXT:
      return replacePresetObject(oldItem, newItem);
    case MediaTypes.AUDIO:
    case MediaTypes.IMAGE:
    case MediaTypes.VIDEO:
    default:
      return replaceMediaObject(oldItem, newItem);
  }
}
