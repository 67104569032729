import { Switch, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import clsx from 'clsx';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { TEAM_ACCESS_SWITCH } from '../constants/TestSelectors';
import formatTimestamp from '../utils/formatTimestamp';
import PaddedAspectBox from './PaddedAspectBox';
import TemplateSettingsMenu from './TemplateSettingsMenu';
import UnderlinedTextButton from './UnderlinedTextButton';
import VideoPreview from './VideoPreview';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
  },
  dateCell: {
    width: theme.spacing(21), // keep it on one line
    minWidth: theme.spacing(21), // keep it on one line
  },
  previewCell: {
    width: theme.spacing(22),
    minWidth: theme.spacing(15),
  },
  titleCell: {
    maxWidth: theme.spacing(20),
    minWidth: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(40),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(50),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(60),
    },
  },
}));

export default function TemplateTableRow(props) {
  const {
    template = {},
    onStartProject: handleStartProject,
    onPromptDelete: handlePromptDelete,
    onDuplicate: handleDuplicate,
    onSelect: handleSelect,
    onTeamAccessToggle: handleTeamAccessToggle,
    templateOwnedByUser,
    showCreatedBy,
    showTeamAccessToggle,
  } = props;
  const {
    creator_full_name: creatorName,
    storyboard,
    uid,
    team_access: initialTeamAccess,
    name,
    updated_at: updatedAt,
    preview_url: previewUrl,
  } = template;
  const duration = getStoryboardDuration(storyboard?.items?.entities);

  const classes = useStyles();
  const [teamAccess, setTeamAccess] = useState(!!initialTeamAccess);

  const handleToggleTeamAccess = () => {
    setTeamAccess(!teamAccess);
    handleTeamAccessToggle();
  };

  const date = updatedAt && format(parseISO(updatedAt), 'MM/dd/yy hh:mm aa');
  const timestamp = formatTimestamp(duration);

  const handleClickTitle = () => {
    if (templateOwnedByUser) {
      handleSelect();
    }
  };

  return (
    <TableRow data-testid={`template-${uid}`}>
      <TableCell className={clsx(classes.cell, classes.previewCell)}>
        <PaddedAspectBox
          role={templateOwnedByUser ? 'button' : null}
          aspectRatio={16 / 9}
          onClick={handleClickTitle}
        >
          <VideoPreview source={previewUrl} />
        </PaddedAspectBox>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.titleCell)}>
        {templateOwnedByUser ? (
          <UnderlinedTextButton
            onClick={handleClickTitle}
            aria-label={`Edit ${name}`}
            inCell={true}
          >
            {name}
          </UnderlinedTextButton>
        ) : (
          <span>{name}</span>
        )}
      </TableCell>
      <TableCell className={classes.cell}>{timestamp}</TableCell>
      <TableCell className={clsx(classes.cell, classes.dateCell)}>
        {date}
      </TableCell>
      {showTeamAccessToggle && (
        <TableCell>
          <Switch
            aria-label="Team access"
            checked={teamAccess}
            onChange={handleToggleTeamAccess}
            inputProps={{ 'data-testid': TEAM_ACCESS_SWITCH }}
          />
        </TableCell>
      )}
      {showCreatedBy && (
        <TableCell className={classes.cell}>{creatorName}</TableCell>
      )}
      <TableCell className={classes.cell} align="right">
        <TemplateSettingsMenu
          onStartProject={handleStartProject}
          onPromptDelete={handlePromptDelete}
          onDuplicate={handleDuplicate}
          onSelect={handleSelect}
          templateOwnedByUser={templateOwnedByUser}
        />
      </TableCell>
    </TableRow>
  );
}

TemplateTableRow.propTypes = {
  creatorName: PropTypes.string,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  initialTeamAccess: PropTypes.bool,
  name: PropTypes.string,
  onStartProject: PropTypes.func,
  onPromptDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSelect: PropTypes.func,
  onTeamAccessToggle: PropTypes.func,
  previewUrl: PropTypes.string,
  showCreatedBy: PropTypes.bool,
  showTeamAccessToggle: PropTypes.bool,
  templateOwnedByUser: PropTypes.bool,
  updatedAt: PropTypes.string,
};

TemplateTableRow.defaultProps = {
  creatorName: '',
  duration: '',
  name: '',
  onStartProject: () => {},
  onPromptDelete: () => {},
  onDuplicate: () => {},
  onSelect: () => {},
  onTeamAccessToggle: () => {},
  previewUrl: '',
  showCreatedBy: true,
  showTeamAccessToggle: false,
  templateOwnedByUser: false,
  updatedAt: '',
};
