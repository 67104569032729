import { useDispatch, useSelector } from 'react-redux';

import { getItemEditProps, trackEvent } from '../events/sendEvents';
import { PROPERTIES_PANEL } from '../events/tags';
import useActiveItem from '../hooks/useActiveItem';
import useFonts from '../hooks/useFonts';
import { selectOrganizationId } from '../selectors/user';
import { textItemUpdated } from '../slices/storyboardSlice';
import {
  getFontByLookupId,
  getLookupIdByStyle,
  getStylePropertiesFromFont,
} from '../utils/fonts';
import FontSelectGrouped from './FontSelectGrouped';

export default function FontSelect(props) {
  const { labelId } = props;
  const organizationId = useSelector(selectOrganizationId);
  const { allFonts } = useFonts({ organizationId });

  const activeItem = useActiveItem();
  const activeItemLookupId = getLookupIdByStyle(allFonts, activeItem.style);
  const activeItemFont = getFontByLookupId(allFonts, activeItemLookupId);
  const dispatch = useDispatch();

  const handleChange = (font, isBranded, isRecent, isCustom) => {
    dispatch(
      textItemUpdated({
        id: activeItem.id,
        changes: {
          style: { ...activeItem.style, ...getStylePropertiesFromFont(font) },
        },
        font,
      })
    );
    trackEvent(PROPERTIES_PANEL.ITEM_EDIT, {
      ...getItemEditProps(activeItem, 'fontFamily', font),
      isBranded,
      isRecent,
      isCustom,
    });
  };

  return (
    <FontSelectGrouped
      onChange={handleChange}
      value={activeItemFont}
      labelId={labelId}
    />
  );
}
