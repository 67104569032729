import { Box, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import TemplateMasonryGrid from '../components/TemplateMasonryGrid';
import RecentProjects from '../components/templates/RecentProjects';
import StartWithRecording from '../components/templates/StartWithRecording';
import TemplateAspectRatioSelect from '../components/templates/TemplateAspectRatioSelect';
import { selectIsSubscribed } from '../selectors/user';

export default function HomeTabContainer() {
  useTitle('Maker | Home');
  const isSubscribed = useSelector(selectIsSubscribed);

  return (
    <Container>
      {isSubscribed && (
        <Box mt={4}>
          <StartWithRecording />
        </Box>
      )}
      <Box mt={4}>
        <RecentProjects />
      </Box>
      <Box mt={4}>
        <TemplateAspectRatioSelect />
      </Box>
      <Box mt={4} mb={2}>
        <TemplateMasonryGrid />
      </Box>
    </Container>
  );
}
