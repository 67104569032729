import { isFinite } from 'lodash';

export function translateX(x) {
  if (!x) {
    return;
  }
  return `translateX(${x}${isFinite(x) ? 'px' : ''})`;
}

export function translateY(y) {
  if (!y) {
    return;
  }
  return `translateY(${y}${isFinite(y) ? 'px' : ''})`;
}

export function translate(x, y) {
  if (!x) {
    return translateY(y);
  }

  if (!y) {
    return translateX(x);
  }

  return `translate(${x}${isFinite(x) ? 'px' : ''}, ${y}${
    isFinite(y) ? 'px' : ''
  })`;
}
