import { alpha, makeStyles } from '@material-ui/core';

import { MIN_PLAYBACK_CONTROLS_WIDTH } from '../../constants/Constants';

const useStyles = makeStyles((theme) => ({
  aspectRatioPreviewBorder: {
    border: `2px solid ${theme.palette.pink[500]}`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shorter,
    }),
    zIndex: 1,
  },
  backgroundOverlay: {
    backgroundColor: alpha(theme.palette.grey[800], 0.33),
    backdropFilter: 'blur(8px)',
    position: 'absolute',
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shorter,
    }),
    zIndex: 1,
  },
}));

function VerticalBlur({ className, height, width }) {
  return (
    <>
      <div
        className={className}
        style={{
          height,
          width,
          top: 0,
        }}
      />
      <div
        className={className}
        style={{
          height,
          width,
          bottom: 0,
        }}
      />
    </>
  );
}

function HorizontalBlur({ className, height, width, xOffset }) {
  return (
    <>
      <div
        className={className}
        style={{
          height,
          width,
          left: xOffset,
        }}
      />
      <div
        className={className}
        style={{
          height,
          width,
          right: xOffset,
        }}
      />
    </>
  );
}

function AspectRatioPreview({
  previewHeight,
  previewWidth,
  overlayPreviewHeight,
  overlayPreviewWidth,
}) {
  const classes = useStyles();

  const showHorizontalBlur = overlayPreviewWidth < previewWidth;
  const blurWidth = showHorizontalBlur
    ? (previewWidth - overlayPreviewWidth) / 2
    : 0;
  const xOffset =
    previewWidth < MIN_PLAYBACK_CONTROLS_WIDTH
      ? (MIN_PLAYBACK_CONTROLS_WIDTH - previewWidth) / 2
      : 0;

  const showVerticalBlur = overlayPreviewHeight < previewHeight;
  const blurHeight = showVerticalBlur
    ? (previewHeight - overlayPreviewHeight) / 2
    : 0;

  return (
    <>
      <VerticalBlur
        className={classes.backgroundOverlay}
        height={blurHeight}
        width={previewWidth}
      />
      <HorizontalBlur
        className={classes.backgroundOverlay}
        height={previewHeight}
        width={blurWidth}
        xOffset={xOffset}
      />
      <div
        className={classes.aspectRatioPreviewBorder}
        style={{
          height: overlayPreviewHeight,
          width: overlayPreviewWidth,
        }}
      />
    </>
  );
}

export default AspectRatioPreview;
