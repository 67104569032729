import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

import useHasEverBeenInView from '../hooks/useHasEverBeenInView';
import {
  useGetFromThumbnailCache,
  useAddToThumbnailCache,
} from '../hooks/useThumbnailCache';

const useStyles = makeStyles({
  thumbnail: {
    flexShrink: 0,
  },
});

/**
 * Thumbnail component used in the filmstrip.
 * @param src Video source.
 * @param position Time in seconds representing the desired thumbnail frame.
 * @param width Width in pixels.
 * @param height Height in pixels.
 * @param rest Other html attributes to apply to <div>, <video> or <img>.
 * @returns {JSX.Element}
 * @constructor
 */
function FilmStripCachedThumbnail({ src, position, width, height, ...rest }) {
  // Reactive state containing the DOM element.
  const [element, setElement] = useState(null);

  // Keep track of whether this thumbnail has even been in view.
  // We defer loading the thumbnail until it is viewable.
  const hasEverBeenInView = useHasEverBeenInView(element);

  const classes = useStyles();

  // Render a cached image of the thumbnail if we have seen it before.
  useAddToThumbnailCache(element, src, position, width, height);
  const cachedDataUrl = useGetFromThumbnailCache(src, position, width);
  if (cachedDataUrl) {
    return (
      <img
        {...rest}
        ref={setElement}
        className={classes.thumbnail}
        style={{ width, height }}
        src={cachedDataUrl}
        alt=""
      />
    );
  }

  // Avoid rendering a ton of videos all at once if many of them are off-screen.
  // Once we have captured a filmstrip thumbnail we continue to render it for
  // good UX.
  if (!hasEverBeenInView) {
    return (
      <div
        {...rest}
        ref={setElement}
        className={classes.thumbnail}
        style={{ width, height }}
        data-testid="placeholder"
      />
    );
  }

  // When this thumbnail is in view and is being rendered for the first time,
  // we render a video tag and seek to the specified frame.
  return (
    <video
      {...rest}
      ref={setElement}
      className={classes.thumbnail}
      style={{ width, height }}
      src={src}
      data-testid="video"
      crossOrigin="anonymous"
      onLoadedMetadata={(e) => {
        // This event is fired when metadata has been loaded but before any frames have necessarily been loaded.
        // We use this opportunity to seek to the time of the frame in question.
        // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/currentTime.
        e.target.currentTime = position;
      }}
    />
  );
}

FilmStripCachedThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default FilmStripCachedThumbnail;
