import { Box, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { trackStarterExportUpgrade } from '../../events/sendEvents';
import {
  selectRemainingExports,
  selectUpgradePath,
  shouldWatermark,
} from '../../selectors/user';
import Alert from '../Alert';

export default function LimitedStarterPlanAlerts() {
  const isWatermarked = useSelector(shouldWatermark);
  const remainingExports = useSelector(selectRemainingExports);
  const upgradePath = useSelector(selectUpgradePath);

  return isWatermarked ? (
    <Alert severity="warning">
      <div>You're out of exports! Don't stop now - keep on creating.</div>
      <Link
        color="inherit"
        href={upgradePath}
        onClick={() => trackStarterExportUpgrade(true)}
        target="_blank"
        rel="noopener"
      >
        <strong>Upgrade to unlimited</strong>
      </Link>
    </Alert>
  ) : (
    <Alert severity="info">
      <Box>
        <strong>{remainingExports} </strong>
        watermark-free exports left this month.
      </Box>
      <Link
        color="secondary"
        href={upgradePath}
        onClick={() => trackStarterExportUpgrade(false)}
        target="_blank"
        rel="noopener"
      >
        <strong>Upgrade to unlimited</strong>
      </Link>
    </Alert>
  );
}
