import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    width: theme.spacing(32),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(26),
    boxShadow:
      '0 24px 48px 0 rgba(0, 0, 0, 0.14), 0 6px 12px 0 rgba(0, 0, 0, 0.2)',
  },
  content: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

function UnlimitedLibraryPopover({ children }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const popoverText =
    'Over 1,000,000+ royalty-free assets, including 4K Footage, After Effects templates, music, images, and more.';

  return (
    <>
      <span
        className={classes.content}
        aria-owns={open ? 'unlimited-library-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </span>
      <Popover
        id="unlimited-library-popover"
        className={classes.popover}
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>{popoverText}</div>
      </Popover>
    </>
  );
}

export default UnlimitedLibraryPopover;
