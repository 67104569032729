import { InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import GridSelect from '../../components/GridSelect';
import ColorPickerPopover from '../../components/colorPicker/ColorPickerPopover';
import overlays from '../../constants/Overlays';
import { getItemEditProps, trackEvent } from '../../events/sendEvents';
import { PROPERTIES_PANEL } from '../../events/tags';
import useActiveItem from '../../hooks/useActiveItem';
import useRenderer from '../../hooks/useRenderer';
import { itemUpdated } from '../../slices/storyboardSlice';
import PropertiesPanelHeading from './PropertiesPanelHeading';
import PropertiesCard from './cards/PropertiesCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > div:last-child': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function AnimationOverlayPropertiesPanel() {
  const activeItem = useActiveItem();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { renderer } = useRenderer();

  const handleSelectChange = (event, value) => {
    dispatch(
      itemUpdated({ id: activeItem.id, changes: { animationPreset: value } })
    );
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'animationPreset', value)
    );
    renderer.playItemOnce(activeItem);
  };

  const handleColorChange = (value) => {
    dispatch(
      itemUpdated({ id: activeItem.id, changes: { primaryColor: value } })
    );
    trackEvent(
      PROPERTIES_PANEL.ITEM_EDIT,
      getItemEditProps(activeItem, 'primaryColor', value)
    );
  };

  return (
    <>
      <PropertiesPanelHeading>Overlay Properties</PropertiesPanelHeading>
      <PropertiesCard title="Overlays">
        <div className={classes.root}>
          <InputLabel>Color</InputLabel>
          <ColorPickerPopover
            color={activeItem.primaryColor}
            onChange={handleColorChange}
            showNoneOption={false}
          />
          <GridSelect
            color={activeItem.primaryColor}
            getOptionThumbnail={(option) => option.thumbnailSquare}
            options={overlays}
            onChange={handleSelectChange}
            value={activeItem.animationPreset}
          />
        </div>
      </PropertiesCard>
    </>
  );
}
