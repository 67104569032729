/**
 * Returns the category name given the categoryId.
 *
 * @param {SearchCategory[]} categories - Categories from API
 * @param {number} categoryId
 * @returns {string | undefined} Category name
 */
export function findLabel(categories = [], categoryId) {
  return categories.find(({ id }) => `${id}` === categoryId)?.name;
}
