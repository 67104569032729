import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { format, parseJSON } from 'date-fns';
import { take } from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { EDIT_PATH, PROJECTS_PATH } from '../../constants/Urls';
import useProjects from '../../hooks/useProjects';
import PaddedAspectBox from '../PaddedAspectBox';
import VideoPreview from '../VideoPreview';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    zIndex: 1,
  },
  heading: {
    margin: theme.spacing(2, 'auto'),
  },
  projectLabel: {
    width: '100%',
    fontWeight: 400,
  },
  projectLabelInfo: {
    position: 'absolute',
    fontWeight: 400,
    color: theme.palette.grey[600],
  },
  projectLabelTitle: {
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
  projectsLink: {
    display: 'inline-block',
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  projectsLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  projectPreview: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      boxShadow:
        '0 8px 16px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-1px)',
    },
  },
  container: {
    margin: theme.spacing(2, 'auto', 4),
  },
  textTruncate: {
    flexBasis: 'auto',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const MAX_RECENT_PROJECTS = 3;

function RecentProjects() {
  const history = useHistory();
  const { myProjects } = useProjects();

  const classes = useStyles();

  const hasProjects = myProjects?.length > 0;

  if (!hasProjects) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h4" component="h2" className={classes.heading}>
          Recent Projects
        </Typography>
        <Grid container spacing={2}>
          {take(myProjects, MAX_RECENT_PROJECTS).map((project) => {
            const { uid: projectId, preview_url: previewUrl } = project;
            return (
              <Grid item xs={3} key={projectId}>
                <Link
                  component={RouterLink}
                  color="inherit"
                  className={classes.projectPreview}
                  to={{
                    pathname: `${EDIT_PATH}/${projectId}`,
                    search: history.location.search,
                  }}
                >
                  <PaddedAspectBox aspectRatio={16 / 9}>
                    <VideoPreview source={previewUrl} />
                  </PaddedAspectBox>
                </Link>
                <div className={classes.projectLabel}>
                  <div
                    className={clsx(
                      classes.projectLabelTitle,
                      classes.textTruncate
                    )}
                  >
                    {project.name}
                  </div>
                  <div
                    className={clsx(
                      classes.projectLabelInfo,
                      classes.textTruncate
                    )}
                  >
                    {format(parseJSON(project.updated_at), "MMM d' at 'p")}
                  </div>
                </div>
              </Grid>
            );
          })}
          <Grid item xs={3}>
            <PaddedAspectBox
              containerClass={classes.projectsLinkContainer}
              aspectRatio={16 / 9}
            >
              <Link
                component={RouterLink}
                color="secondary"
                className={classes.projectsLink}
                to={{
                  pathname: PROJECTS_PATH,
                  search: history.location.search,
                }}
              >
                See All Projects →
              </Link>
            </PaddedAspectBox>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default RecentProjects;
