import { useDndContext } from '@dnd-kit/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import log from 'loglevel';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TIMELINE_ITEM } from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { TIMELINE } from '../../events/tags';
import { setActiveItemId } from '../../slices/editorSlice';
import { translateX } from '../../utils/cssUtils';
import { checkMediaItem } from '../../utils/mediaUtils';
import TimelineItemAnimationOverlay from './TimelineItemAnimationOverlay';
import TimelineItemErrorOverlay from './TimelineItemErrorOverlay';
import TimelineItemFadeOverlay from './TimelineItemFadeOverlay';
import TimelineItemLabel from './TimelineItemLabel';
import TimelineItemMedia from './TimelineItemMedia';
import TimelineItemTint from './TimelineItemTint';
import TimelineItemVolumeIndicator from './TimelineItemVolumeIndicator';

const useStyles = makeStyles((theme) => ({
  timelineItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.common.white,
    boxShadow: `0 0 2px 1px ${alpha(theme.palette.common.black, 0.2)}`,
    userSelect: 'none',

    '&:not($selected):hover > $highlight': {
      boxShadow: `${alpha(
        theme.palette.common.white,
        0.6
      )} 0px 0px 0px 2px inset`,
    },
  },
  selected: {
    // ensures selected item is ordered on top if multiple items overlap
    zIndex: 1,
  },
  highlight: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('box-shadow', {
      delay: 25,
      duration: 75,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  highlightSelected: {
    boxShadow: `${theme.palette.pink[500]} 0px 0px 0px 2px inset`,
  },
}));

export default function TimelineItemView(props) {
  const {
    children,
    item = {},
    minimized = false,
    offset = 0,
    selected = false,
    startTimeOffset = 0,
    width = 0,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  // Scroll timeline item into view when interacting with the item on project canvas
  const { active } = useDndContext();
  const ref = useRef();
  useEffect(() => {
    if (
      selected &&
      !active &&
      // We only want to scroll when clicking on items in the project canvas
      document.activeElement?.tagName?.toLocaleLowerCase() === 'canvas'
    ) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [active, selected]);

  const {
    fadeIn,
    fadeOut,
    filters = {},
    includesAudio,
    mediaType,
    source,
    text,
    volume,
  } = item;

  const handleError = useCallback(
    (error) => {
      setError(true);
      log.warn('TimelineItem is not valid; showing it in an error state', {
        error,
        source,
      });
    },
    [source]
  );

  const { isAnimation, isAudio, isText, isVideo } = checkMediaItem(item);
  const isMuted = volume === 0;

  const showMedia = source;
  const showLabel = isText && text;
  const showAnimationOverlay = isAnimation;
  const showFadeOverlay = !!fadeIn || !!fadeOut;
  const showTint = !!filters.tint;

  // show volume icon on audio items only when they are muted
  // show volume icon on video items only if they include an audio track
  const showVolume =
    ((isAudio && isMuted) || isVideo) && !!includesAudio && !minimized;

  const transform = translateX(offset);
  const style = { width: Math.max(width, 1), transform };

  const handleClick = (e, item) => {
    dispatch(setActiveItemId(item.id));
    trackEvent(TIMELINE.ITEM_SELECT, item);
    // this prevents the click event from moving the timeline cursor
    e.stopPropagation();
  };

  return (
    <div
      className={clsx(classes.timelineItem, selected && classes.selected)}
      ref={ref}
      style={style}
      onClick={(e) => handleClick(e, item)}
      data-testid={TIMELINE_ITEM}
    >
      {showMedia && (
        <TimelineItemMedia
          item={item}
          onError={handleError}
          startTimeOffset={startTimeOffset}
        />
      )}
      {showLabel && <TimelineItemLabel text={text} />}
      {showAnimationOverlay && (
        <TimelineItemAnimationOverlay minimized={minimized} />
      )}
      {showFadeOverlay && (
        <TimelineItemFadeOverlay fadeIn={fadeIn} fadeOut={fadeOut} />
      )}
      {showTint && <TimelineItemTint tint={filters.tint} />}
      {showVolume && <TimelineItemVolumeIndicator volume={volume} />}
      {error && <TimelineItemErrorOverlay mediaType={mediaType} />}
      {children}
      <div
        className={clsx(
          classes.highlight,
          selected && classes.highlightSelected
        )}
      />
    </div>
  );
}

TimelineItemView.propTypes = {
  item: PropTypes.object,
  minimized: PropTypes.bool,
  offset: PropTypes.number,
  selected: PropTypes.bool,
  startTimeOffset: PropTypes.number,
  width: PropTypes.number,
};
