import parseStoryboardResponse from '../utils/parseStoryboardResponse';
import stringifyStoryboardRequest from '../utils/stringifyStoryboardRequest';
import AbstractAPI from './AbstractAPI';

export default class ExportsAPI extends AbstractAPI {
  createExport(exportData) {
    return this.post('exports', exportData);
  }

  getExportAsAdmin(exportId) {
    return this.get(`exports/${exportId}`);
  }

  transformResponse(data) {
    return parseStoryboardResponse(data);
  }

  transformRequest(data) {
    return stringifyStoryboardRequest(data);
  }

  getSharedExport(shareId) {
    return this.get(`exports/share/${shareId}`);
  }
}
