import { alpha, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { clamp } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useRef, useState } from 'react';
import { DraggableCore } from 'react-draggable';
import { useSelector } from 'react-redux';

import { MINIMUM_ITEM_DURATION } from '../../constants/Storyboard';
import { selectZoom } from '../../slices/editorSlice';
import { pixelsToSeconds } from '../../utils/timelineUtils';

const useStyles = makeStyles((theme) => ({
  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: 'pointer',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      delay: 25,
      duration: 75,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      opacity: 1,
    },
  },
  active: {
    opacity: 0,
    '&:hover': {
      opacity: 0,
    },
    cursor: 'grabbing',
  },
  handle: {
    position: 'absolute',
    top: 2,
    bottom: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(2),
    backgroundColor: alpha(theme.palette.common.black, 0.3),
    cursor: 'ew-resize',

    '&::before': {
      content: '""',
      height: theme.spacing(2),
      width: theme.spacing(0.5),
      borderColor: 'currentColor',
      borderStyle: 'solid',
      borderLeftWidth: 1,
      borderRightWidth: 1,
    },
  },
  handleMinimized: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 6,
    width: theme.spacing(1),
    cursor: 'ew-resize',
  },
  leftTrim: {
    left: 2,
    borderTopLeftRadius: theme.shape.borderRadius / 2,
    borderBottomLeftRadius: theme.shape.borderRadius / 2,
  },
  rightTrim: {
    right: 2,
    borderTopRightRadius: theme.shape.borderRadius / 2,
    borderBottomRightRadius: theme.shape.borderRadius / 2,
  },
  leftTrimMinimized: {
    left: 0,
    transform: 'translateX(-50%)',
  },
  rightTrimMinimized: {
    right: 0,
    transform: 'translateX(50%)',
  },
}));

export default function TimelineItemTrimControls(props) {
  const {
    item = {},
    minimized = false,
    onStart = () => {},
    onStop = () => {},
    onTrimLeft = () => {},
    onTrimRight = () => {},
    precedingGap = null,
  } = props;
  const zoom = useSelector(selectZoom);
  const classes = useStyles();

  const { duration, maxDuration, startTime, trimStart } = item;

  /**
   * Memoized min and max trim values for performance
   */
  const minTrimLeft = useMemo(
    () =>
      Math.max(
        maxDuration ? -trimStart : Number.NEGATIVE_INFINITY,
        precedingGap ? precedingGap.startTime - startTime : 0
      ),
    [maxDuration, precedingGap, startTime, trimStart]
  );
  const maxTrimLeft = useMemo(
    () => duration - MINIMUM_ITEM_DURATION,
    [duration]
  );

  const minTrimRight = useMemo(
    () => MINIMUM_ITEM_DURATION - duration,
    [duration]
  );
  const maxTrimRight = useMemo(
    () =>
      maxDuration
        ? maxDuration - trimStart - duration
        : Number.POSITIVE_INFINITY,
    [duration, maxDuration, trimStart]
  );

  const dragStart = useRef(null);
  const [active, setActive] = useState(false);

  const getDragTimeDelta = (screenX) => {
    return pixelsToSeconds(screenX - dragStart.current, zoom);
  };

  const handleStart = (event) => {
    const { screenX } = event;
    dragStart.current = screenX;
    onStart(event);
    setActive(true);
  };

  const handleTrimLeft = ({ screenX }) => {
    const timeDelta = clamp(
      getDragTimeDelta(screenX),
      minTrimLeft,
      maxTrimLeft
    );

    onTrimLeft(timeDelta);
  };

  const handleTrimRight = ({ screenX }) => {
    const timeDelta = clamp(
      getDragTimeDelta(screenX),
      minTrimRight,
      maxTrimRight
    );

    onTrimRight(timeDelta);
  };

  const handleStop = (event) => {
    dragStart.current = null;
    onStop(event);
    setActive(false);
  };

  const stopPropagation = (event) => event.stopPropagation();

  return (
    <div className={clsx(classes.controls, active && classes.active)}>
      <DraggableCore
        axis="x"
        position={{ x: 0, y: 0 }}
        onStart={handleStart}
        onDrag={handleTrimLeft}
        onStop={handleStop}
      >
        <div
          className={clsx('left-handle', {
            [classes.handle]: !minimized,
            [classes.handleMinimized]: minimized,
            [classes.leftTrim]: !minimized,
            [classes.leftTrimMinimized]: minimized,
          })}
          role="button"
          aria-label="left trim"
          onClick={stopPropagation}
        />
      </DraggableCore>
      <DraggableCore
        axis="x"
        position={{ x: 0, y: 0 }}
        onStart={handleStart}
        onDrag={handleTrimRight}
        onStop={handleStop}
      >
        <div
          className={clsx('right-handle', {
            [classes.handle]: !minimized,
            [classes.handleMinimized]: minimized,
            [classes.rightTrim]: !minimized,
            [classes.rightTrimMinimized]: minimized,
          })}
          role="button"
          aria-label="right trim"
          onClick={stopPropagation}
        />
      </DraggableCore>
    </div>
  );
}

TimelineItemTrimControls.propTypes = {
  item: PropTypes.object,
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  onTrimLeft: PropTypes.func,
  onTrimRight: PropTypes.func,
  minimized: PropTypes.bool,
};
