import { trackLegacyNonReduxEvent } from '../events/sendEvents';

/**
 * @deprecated
 * Enables sending non redux events to our various event
 * endpoints, while keeping the object shape for Redux
 * and external events the same.
 *
 * @param event {{type: {string}, payload: {any}?}}
 */
export function sendNonReduxEvent(event) {
  trackLegacyNonReduxEvent(event);
}
