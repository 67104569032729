import { isEqual, reject, take, uniqWith } from 'lodash';

/**
 * Adds items to an array as a circular buffer of size `n`.
 * All items in the array are unique.
 *
 * @param {Array} array - The array to add an item to
 * @param {*} item - The item to add to the array
 * @param {number} [size=5]
 * @returns {Array} Returns a new array with the item added
 */
export function addRecentItem(array = [], item, size = 5) {
  return take(uniqWith([item, ...array], isEqual), size);
}

/**
 * Removes items from an array by a matched property.
 *
 * @param {Array} array - The array to remove an item from
 * @param {*} item - The item to remove from the array
 * @param {string} [matchProperty="id"]
 * @returns {Array} Returns a new array with the item removed
 */
export function removeRecentItem(array = [], item, matchProperty = 'id') {
  return reject(array, [matchProperty, item[matchProperty]]);
}
