import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import ReduxThunk from 'redux-thunk';

import { authLogout } from '../actions/user';
import AbstractAPI from '../api/AbstractAPI';
import { REDUX_PERSIST_CONFIG } from '../config/redux';
import { IS_PROD } from '../constants/Constants';
import sentryBreadcrumbs from '../logger/sentryBreadcrumbs';
import eventPersistor from '../middlewares/eventPersistor';
import projectPersistor from '../middlewares/projectPersistor';
import rootReducer from '../reducers';

const middleware = [
  ReduxThunk,
  projectPersistor,
  eventPersistor,
  sentryBreadcrumbs,
];

const persistedReducer = persistReducer(REDUX_PERSIST_CONFIG, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

if (!IS_PROD && module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept(() => {
    const nextReducer = rootReducer.default;
    store.replaceReducer(persistReducer(REDUX_PERSIST_CONFIG, nextReducer));
  });
}

AbstractAPI.onUnauthenticated = () => {
  store.dispatch(authLogout());
};

export default store;
