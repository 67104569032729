import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  selectFolderId,
  selectKeywords,
  setFolderId,
} from '../../features/assetDrawer/assetDrawerSlice';
import useContentToken from '../../hooks/useContentToken';
import useFolders from '../../hooks/useFolders';
import includes from '../../utils/includes';
import LoaderContainer from '../LoaderContainer';
import FolderDetail from './FolderDetail';
import FolderHeader from './FolderHeader';
import FolderOverview from './FolderOverview';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    overflowY: 'scroll',
  },
}));

function filterFolders(folders = [], query = '') {
  if (!query) {
    return folders;
  }

  return folders.filter((folder) => includes(folder.name, query));
}

export default function FoldersDrawer(props) {
  const { onSelect } = props;
  const dispatch = useDispatch();
  const folderId = useSelector(selectFolderId);
  const keywords = useSelector(selectKeywords);
  const [mediaType, setMediaType] = useState(MediaTypes.VIDEO);
  const { contentToken } = useContentToken();

  const { data, isLoading } = useFolders(contentToken, folderId);
  const currentFolder = folderId ? data : {};
  const classes = useStyles();

  const handleFolderOpen = (folderId) => {
    dispatch(setFolderId(folderId));
    setMediaType(MediaTypes.VIDEO);
    trackEvent(SIDE_DRAWER.FOLDER_OPEN, { folderId });
  };

  const handleFolderItemClick = async (event, item) => {
    onSelect(event, { ...item });
  };

  return (
    <div className={classes.drawer}>
      <FolderHeader
        folderId={currentFolder.id}
        folderItemCount={currentFolder.items?.length}
        folderName={currentFolder.name}
        mediaType={mediaType}
        onMediaTypeChange={setMediaType}
      />
      <div className={classes.content}>
        {isLoading && <LoaderContainer />}
        {!isLoading && folderId && (
          <FolderDetail
            isLoading={isLoading}
            items={currentFolder.items}
            mediaType={mediaType}
            onItemClick={handleFolderItemClick}
          />
        )}
        {!isLoading && !folderId && (
          <FolderOverview
            folders={filterFolders(data, keywords)}
            onFolderClick={handleFolderOpen}
          />
        )}
      </div>
    </div>
  );
}
