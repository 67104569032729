import { CssBaseline } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AdminIndexPage from './AdminIndexPage';
import AdminTemplatePage from './AdminTemplatePage';

function Admin() {
  const { path } = useRouteMatch();
  return (
    <>
      <CssBaseline />
      <Switch>
        <Route exact path={path}>
          <AdminIndexPage />
        </Route>
        <Route exact path={`${path}/template`}>
          <AdminTemplatePage />
        </Route>
        <Route path={`${path}/template/:templateId`}>
          <AdminTemplatePage />
        </Route>
      </Switch>
    </>
  );
}

export default Admin;
