import { makeStyles } from '@material-ui/core/styles';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import {
  FOLDER_PREVIEW,
  FOLDER_PREVIEW_THUMBNAIL,
} from '../../constants/TestSelectors';
import PaddedAspectBox from '../PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  folderPreview: {
    paddingBottom: theme.spacing(1),
    // needed to prevent blowout with text overflow
    // https://css-tricks.com/preventing-a-grid-blowout
    minWidth: 0,
  },
  thumbnailContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderRadius: 4,
    backgroundColor: theme.palette.grey[300],
    overflow: 'hidden',
    cursor: 'pointer',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iconWrapper: {
    fontSize: '3rem',
    color: theme.palette.common.white,
  },
  name: {
    marginTop: 4,
    marginBottom: 4,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16 / 14,
    fontWeight: 700,
    color: theme.palette.grey[800],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  count: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16 / 14,
    color: theme.palette.grey[600],
  },
}));

function formatItemCount(count = 0) {
  return `${count} Item${count === 1 ? '' : 's'}`;
}

export default function FolderPreview(props) {
  const { itemCount = 0, mediaType, name, thumbnailUrl, onClick } = props;
  const isAudio = mediaType === MediaTypes.AUDIO;
  const isImage = mediaType === MediaTypes.IMAGE;
  const isVideo = mediaType === MediaTypes.VIDEO;
  const classes = useStyles();

  return (
    <div className={classes.folderPreview}>
      <PaddedAspectBox aspectRatio={16 / 9}>
        <div
          className={classes.thumbnailContainer}
          onClick={onClick}
          data-testid={FOLDER_PREVIEW}
        >
          {thumbnailUrl ? (
            <img
              className={classes.thumbnail}
              src={thumbnailUrl}
              alt=""
              draggable="false"
              data-testid={FOLDER_PREVIEW_THUMBNAIL}
            />
          ) : (
            <div className={classes.iconWrapper}>
              {isAudio && <LibraryMusicIcon fontSize="inherit" />}
              {isImage && <PhotoLibraryIcon fontSize="inherit" />}
              {(isVideo || !mediaType) && (
                <VideoLibraryIcon fontSize="inherit" />
              )}
            </div>
          )}
        </div>
      </PaddedAspectBox>
      <div className={classes.name}>{name}</div>
      <div className={classes.count}>{formatItemCount(itemCount)}</div>
    </div>
  );
}

FolderPreview.propTypes = {
  itemCount: PropTypes.number,
  mediaType: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
};
