import { makeStyles } from '@material-ui/core/styles';
import { clamp } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure, useScroll, useWindowSize } from 'react-use';

import { TIMELINE_LEFT_PADDING } from '../../constants/Constants';
import { TIMELINE } from '../../constants/IntercomSelectors';
import { TIMELINE_CONTAINER } from '../../constants/TestSelectors';
import useRenderer from '../../hooks/useRenderer';
import { selectIsLoading, selectZoom } from '../../slices/editorSlice';
import {
  selectIsStoryboardEmpty,
  selectStoryboardDuration,
} from '../../slices/storyboardSlice';
import {
  getTimelineDuration,
  pixelsToSeconds,
  secondsToPixels,
} from '../../utils/timelineUtils';
import TimelineCursor from './TimelineCursor';
import TimelineEmptyState from './TimelineEmptyState';
import TimelineLayers from './TimelineLayers';
import TimelineMaxMarker from './TimelineMaxMarker';
import TimelineRuler from './TimelineRuler';
import TimelineToolbar from './TimelineToolbar';

const MARKER_OFFSET_PX = 8;

const useStyles = makeStyles((theme) => ({
  timeline: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    outline: 'none',
  },
  outerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  innerContainer: {
    position: 'relative',
    height: '100%',
  },
  scrollContainer: {
    // resets layout (needed for horizontal scroll in Firefox)
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    overscrollBehavior: 'none',
    touchAction: 'pan-x pan-y',
    '&::-webkit-scrollbar': {
      backgroundColor: theme.palette.grey[900],
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.grey[900],
    },
    '&::-webkit-scrollbar-thumb': {
      border: '4px solid transparent',
      borderRadius: 16,
      backgroundColor: theme.palette.grey[700],
      backgroundClip: 'content-box',
    },
  },
}));

export default function Timeline() {
  const { width: windowWidth = 0 } = useWindowSize();
  const classes = useStyles();

  const isLoading = useSelector(selectIsLoading);
  const isStoryboardEmpty = useSelector(selectIsStoryboardEmpty);
  const storyboardDuration = useSelector(selectStoryboardDuration);
  const zoom = useSelector(selectZoom);

  const scrollRef = useRef(null);
  const { timestamp, renderer } = useRenderer();
  const [playing, setPlaying] = useState(renderer.playing);

  const timelineDuration = getTimelineDuration(
    storyboardDuration,
    windowWidth,
    zoom
  );
  const timelineWidth = secondsToPixels(timelineDuration, zoom);

  const [innerContainerRef, { height: timelineHeight }] = useMeasure();
  const { x: scrollX, y: scrollY } = useScroll(scrollRef);

  useEffect(() => {
    const scroll = scrollRef.current;
    const markerPosition = secondsToPixels(timestamp, zoom);

    // scroll if marker is out of view
    const markerScreenPx = markerPosition - scroll.scrollLeft;
    const rightBoundary = scroll.offsetWidth - MARKER_OFFSET_PX;
    const isMarkerPositionOutOfBounds =
      markerScreenPx < 0 || markerScreenPx > rightBoundary;

    if (isMarkerPositionOutOfBounds) {
      scroll.scrollLeft = markerPosition;
    }

    if (playing !== renderer.playing) {
      setPlaying(renderer.playing);
    }
  }, [playing, renderer, timestamp, zoom]);

  useEffect(() => {
    if (isStoryboardEmpty && renderer) renderer.seek(0);
  }, [isStoryboardEmpty, renderer]);

  const handleMarkerChanged = (event) => {
    if (isLoading || isStoryboardEmpty) return;
    const { clientX, currentTarget } = event;
    const rect = currentTarget.getBoundingClientRect();
    const x = clientX - rect.left + scrollX - TIMELINE_LEFT_PADDING;
    const position = clamp(x, 0, timelineWidth);
    const timestamp = pixelsToSeconds(position, zoom);
    renderer.seek(timestamp);
  };

  return (
    <div
      className={classes.timeline}
      data-intercom-target={TIMELINE}
      tabIndex={0}
    >
      <TimelineToolbar />
      <div
        className={classes.outerContainer}
        onClick={handleMarkerChanged}
        data-testid={TIMELINE_CONTAINER}
      >
        {!isStoryboardEmpty && (
          <TimelineRuler
            duration={timelineDuration}
            scrollX={scrollX}
            zoom={zoom}
          />
        )}
        <div className={classes.innerContainer} ref={innerContainerRef}>
          <div className={classes.scrollContainer} ref={scrollRef}>
            {isStoryboardEmpty ? (
              <TimelineEmptyState />
            ) : (
              <TimelineLayers
                scrollX={scrollX}
                scrollY={scrollY}
                visibleHeight={timelineHeight}
                timelineWidth={timelineWidth}
              />
            )}
          </div>
        </div>
        <TimelineMaxMarker
          duration={storyboardDuration}
          scrollX={scrollX}
          zoom={zoom}
        />
        {!isStoryboardEmpty && <TimelineCursor scrollX={scrollX} />}
      </div>
    </div>
  );
}
