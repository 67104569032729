import { isEmpty, isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  loadProject,
  loadTemplateAsProject,
  unloadProject,
} from '../actions/projects';
import LoaderContainer from '../components/LoaderContainer';
import Toast from '../components/Toast';
import Editor from '../components/editor/Editor';
import ExportDrawer from '../components/exportDrawer/ExportDrawer';
import { EDITOR_PAGE } from '../constants/AnalyticsEvents';
import { DEFAULT_STYLES } from '../constants/StylesConstants';
import { HOME_PATH } from '../constants/Urls';
import { trackEvent } from '../events/sendEvents';
import { BRANDS } from '../events/tags';
import {
  closeAllDialogs,
  closeDialog,
  openConfirmationDialog,
  selectIsExportDrawerOpen,
} from '../features/ui/uiSlice';
import useBillingConfirmation from '../hooks/useBillingConfirmation';
import useBrand from '../hooks/useBrand';
import useBrands from '../hooks/useBrands';
import useContentToken from '../hooks/useContentToken';
import useProject from '../hooks/useProject';
import useTemplate from '../hooks/useTemplate';
import useToast from '../hooks/useToast';
import {
  selectAreFontsAvailable,
  selectErrorMessage,
  selectIsProjectLoaded,
} from '../selectors/workspace';
import { setIsEditingTemplate } from '../slices/editorSlice';
import { selectStyles, stylesApplied } from '../slices/storyboardSlice';
import { sendNonReduxEvent } from '../utils/EventsHelper';

export default function EditorContainer() {
  const { projectId, templateId } = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const { search, state: locationState } = useLocation();
  const { project, setProjectBrand } = useProject(projectId);
  const { template, setTemplateBrand } = useTemplate(templateId);
  const brandUid = project?.brand_uid ?? template?.brand_uid;
  const updatedAt = project?.updated_at ?? template?.updated_at;
  const { brand, isLoading: isBrandLoading } = useBrand(brandUid);
  const { brands } = useBrands();
  const { closeToast, message, open, severity, openToast } = useToast();
  const { contentToken } = useContentToken();

  const isExportDrawerOpen = useSelector(selectIsExportDrawerOpen);
  const isProjectLoaded = useSelector(selectIsProjectLoaded);
  const areFontsAvailable = useSelector(selectAreFontsAvailable);
  const errorMessage = useSelector(selectErrorMessage);
  const styles = useSelector(selectStyles);

  const defaultBrand = brands?.filter((b) => b.is_default);
  const stylesOutOfDate =
    styles && brand?.detail?.styles && !isEqual(styles, brand?.detail?.styles);
  const showBrandsModals = brandUid && !isBrandLoading;
  const editorMode = !!templateId ? 'template' : 'project';

  const handleExportDrawerClose = () => {
    dispatch(closeDialog());
  };

  useBillingConfirmation();

  // TODO: Move pageview tracking to LocationListener
  useEffect(() => {
    sendNonReduxEvent({ type: EDITOR_PAGE });
  }, []);

  useEffect(() => {
    if (templateId) {
      dispatch(loadTemplateAsProject(templateId));
      dispatch(setIsEditingTemplate(true));
    } else if (projectId) {
      dispatch(loadProject(projectId, contentToken));
      dispatch(setIsEditingTemplate(false));
    }
  }, [dispatch, projectId, templateId, contentToken]);

  const handleSetBrand = useCallback(
    (brandUid) => {
      projectId
        ? setProjectBrand(projectId, brandUid)
        : setTemplateBrand(templateId, brandUid);
      dispatch(stylesApplied(defaultBrand?.detail?.styles ?? DEFAULT_STYLES));
    },
    [
      projectId,
      setProjectBrand,
      setTemplateBrand,
      templateId,
      dispatch,
      defaultBrand,
    ]
  );

  useEffect(() => {
    if (showBrandsModals) {
      if (isEmpty(brand)) {
        dispatch(
          openConfirmationDialog({
            title: 'Heads Up',
            text: (
              <>
                This {editorMode} was using a brand that was deleted by your
                admin. Don’t worry, the elements in your timeline have not been
                changed, but you may want to apply a new brand.
              </>
            ),
            showClose: true,
            confirmButtonText: 'Got it',
            onExit: () => {
              handleSetBrand(defaultBrand?.uid ?? null);
              dispatch(closeAllDialogs());
            },
          })
        );
      } else if (brand.updated_at > updatedAt && stylesOutOfDate) {
        dispatch(
          openConfirmationDialog({
            title: 'Update Brand Styles?',
            text: (
              <>
                This {editorMode} is using out-of-date styles from the brand "
                {brand?.detail?.name ?? ''}". Click "Apply Updated Brand" to use
                the most recent styles in your project.
              </>
            ),
            confirmButtonText: 'Apply Updated Brand',
            onConfirm: () => {
              dispatch(stylesApplied(brand.detail.styles));
              trackEvent(BRANDS.ACCEPT_UPDATES, {
                brandUid,
                projectId,
                templateId,
              });
              dispatch(closeAllDialogs());
            },
            showCancel: true,
            showClose: true,
            cancelButtonText: "Don't Update",
            onCancel: () => {
              trackEvent(BRANDS.REJECT_UPDATES, {
                brandUid,
                projectId,
                templateId,
              });
              handleSetBrand(defaultBrand?.uid ?? null);
              dispatch(closeAllDialogs());
            },
          })
        );
      }
    }
  }, [
    projectId,
    dispatch,
    defaultBrand,
    showBrandsModals,
    brand,
    handleSetBrand,
    updatedAt,
    brandUid,
    templateId,
    stylesOutOfDate,
    editorMode,
  ]);

  useEffect(() => {
    if (locationState?.toastMessage) {
      openToast(locationState.toastMessage);
      history.replace({
        search,
        state: { toastMessage: null },
      });
    }
  }, [history, locationState, openToast, search]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(unloadProject());
      history.replace({ pathname: HOME_PATH, search });
    }
  }, [dispatch, errorMessage, history, search]);

  if (!isProjectLoaded || !areFontsAvailable) {
    return <LoaderContainer />;
  }

  return (
    <>
      <Toast open={open} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <Editor />
      <ExportDrawer
        open={isExportDrawerOpen}
        onClose={handleExportDrawerClose}
      />
    </>
  );
}
