import { createContext, useMemo, useState } from 'react';

export const AspectRatioPreviewContext = createContext(null);

export default function AspectRatioPreviewProvider({ children }) {
  const [hoverRatio, setHoverRatio] = useState('');

  const contextValue = useMemo(
    () => ({
      hoverRatio,
      setHoverRatio,
    }),
    [hoverRatio, setHoverRatio]
  );

  return (
    <AspectRatioPreviewContext.Provider value={contextValue}>
      {children}
    </AspectRatioPreviewContext.Provider>
  );
}
