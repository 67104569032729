import { Box, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const DEFAULT_MAX = 1;
const DEFAULT_MIN = 0;
const DEFAULT_STEP = 0.01;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16 / 14,
  },
  value: {
    marginLeft: 'auto',
    fontFamily: 'Lato',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function SliderField(props) {
  const {
    formatValue = (value) => Math.round(value * 100),
    hideLabel = false,
    id,
    label = '',
    max = DEFAULT_MAX,
    min = DEFAULT_MIN,
    onChange = () => {},
    step = DEFAULT_STEP,
    value,
  } = props;

  const [fieldValue, setFieldValue] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleChange = (_, newValue) => {
    setFieldValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Box display="flex">
        <label htmlFor={id} className={hideLabel ? 'invisible' : ''}>
          {label}
        </label>
        <div className={classes.value}>{formatValue(fieldValue)}</div>
      </Box>
      <Box display="flex" alignItems="center">
        <Slider
          color="secondary"
          id={id}
          max={max}
          min={min}
          onChange={handleChange}
          onChangeCommitted={(_, newValue) => onChange(newValue)}
          step={step}
          value={fieldValue}
        />
      </Box>
    </div>
  );
}

SliderField.propTypes = {
  formatValue: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};
