import parseStoryboardResponse from '../utils/parseStoryboardResponse';
import stringifyStoryboardRequest from '../utils/stringifyStoryboardRequest';
import AbstractAPI from './AbstractAPI';

export const TEMPLATES_FETCH_URL = '/templates?page_size=9999&with_custom'; // no pagination needed at this time

export default class CustomTemplatesAPI extends AbstractAPI {
  /**
   * @param {Template} data
   * @returns {Promise<TemplatePostResponse>}
   */
  postTemplate(data) {
    return this.post('templates', data);
  }

  /**
   * @param {string} templateUid
   * @returns {Promise<Template>}
   */
  getTemplate(templateUid) {
    return this.get(`templates/${templateUid}`);
  }

  /**
   * @param {Template} template
   * @returns {Promise<TemplatePutResponse>}
   */
  putTemplate(template) {
    return this.put(`templates/${template.uid}`, template);
  }

  /**
   * @param {string} templateUid
   * @returns {Promise<any>}
   */
  deleteTemplate(templateUid) {
    return this.delete(`templates/${templateUid}`);
  }

  /**
   * @param {string} templateUid
   * @returns {Promise<TemplatePostResponse>}
   */
  duplicateTemplate(templateUid) {
    return this.post(`templates/${templateUid}/duplicate`);
  }

  /**
   * @param {string} templateUid
   * @param {boolean} setTeamAccess
   * @returns {Promise<Template>}
   */
  setTemplateTeamAccess(templateUid, setTeamAccess) {
    return this.patch(`templates/${templateUid}/team-access`, {
      team_access: setTeamAccess,
    });
  }

  /**
   * @param {string} templateUid
   * @param {string} brandUid
   * @returns {Promise<Template>}
   */
  setTemplateBrand(templateUid, brandUid) {
    return this.patch(`templates/${templateUid}/brand`, {
      brand_uid: brandUid,
    });
  }

  /**
   * @param {string[]} templateUids
   */
  reorderTemplates(templateUids) {
    return this.put('templates/reorder', { ids: templateUids });
  }

  transformResponse(data) {
    return parseStoryboardResponse(data);
  }

  transformRequest(data) {
    return stringifyStoryboardRequest(data);
  }
}
