import { makeStyles } from '@material-ui/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';

import AudioPreview from '../AudioPreview';
import ImagePreview from '../ImagePreview';
import PaddedAspectBox from '../PaddedAspectBox';

const useStyles = makeStyles((theme) => ({
  dragPreview: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'grabbing',
    minWidth: 0,
    opacity: 0.4,
    overflow: 'hidden',
  },
}));

export default function DragPreview(props) {
  const { children, mediaType, previewUrl, thumbnailUrl } = props;
  const classes = useStyles();

  const isAudio = mediaType === MediaTypes.AUDIO;
  const isImage = mediaType === MediaTypes.IMAGE;
  const isVideo = mediaType === MediaTypes.VIDEO;

  return (
    <div className={classes.dragPreview}>
      {thumbnailUrl && (
        <PaddedAspectBox aspectRatio={16 / 9}>
          <ImagePreview source={thumbnailUrl} title="" />
        </PaddedAspectBox>
      )}

      {isImage && !thumbnailUrl && (
        <PaddedAspectBox aspectRatio={16 / 9}>
          <ImagePreview source={previewUrl} title="" />
        </PaddedAspectBox>
      )}

      {isAudio && !thumbnailUrl && (
        <PaddedAspectBox aspectRatio={16 / 9}>
          <AudioPreview />
        </PaddedAspectBox>
      )}

      {isVideo && !thumbnailUrl && (
        <PaddedAspectBox aspectRatio={16 / 9}>
          <video alt="" preload="auto" role="presentation" src={previewUrl} />
        </PaddedAspectBox>
      )}

      {!thumbnailUrl && !previewUrl && children}
    </div>
  );
}
