import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import { NEW_BRAND_UID } from '../../constants/StylesConstants';
import useBrands from '../../hooks/useBrands';
import { selectIsOrgAdmin } from '../../selectors/user';
import BrandsEmptyState from '../BrandsEmptyState';
import CreateNewBrandButton from '../CreateNewBrandButton';
import LoaderContainer from '../LoaderContainer';
import BrandCard from '../cards/BrandCard';
import ResultsGrid from './ResultsGrid';

const useStyles = makeStyles((theme) => ({
  grid: {
    gap: theme.spacing(2.5),
    marginTop: theme.spacing(3),
  },
  header: {
    margin: theme.spacing(2, 0, 3),
  },
}));

export default function BrandsOverview({
  appliedBrandUid = () => {},
  navigateToBrand = () => {},
  onApply = () => {},
  onDelete = () => {},
}) {
  const classes = useStyles();
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const { brands, isLoading } = useBrands();

  const buildBrandCard = (brand) => {
    return (
      <BrandCard
        key={brand.uid ?? NEW_BRAND_UID}
        brand={brand}
        isDrawer={true}
        isDefault={!!brand.is_default ?? false}
        isOrgAdmin={isOrgAdmin}
        onClick={() => navigateToBrand(brand.uid)}
        onApply={() => onApply(brand)}
        onViewDetails={() => navigateToBrand(brand.uid)}
        onEdit={() => navigateToBrand(brand.uid, false, true)}
        onDelete={() => onDelete(brand.uid)}
        isBrandApplied={brand.uid === appliedBrandUid}
      />
    );
  };

  return (
    <>
      <Typography variant="h3" element="h2" className={classes.header}>
        Brands {isLoading ? '' : `(${brands.length || 0})`}
      </Typography>
      {isLoading ? (
        <LoaderContainer />
      ) : brands.length ? (
        <>
          {isOrgAdmin && (
            <CreateNewBrandButton
              navigateToBrand={navigateToBrand}
              variant="outlined"
              color="default"
              width="wide"
            />
          )}
          <ResultsGrid className={classes.grid}>
            {brands.map(buildBrandCard)}
          </ResultsGrid>
        </>
      ) : (
        <BrandsEmptyState isBrandsDrawer navigateToBrand={navigateToBrand} />
      )}
    </>
  );
}
