import { CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PauseCircleFilledRoundedIcon from '@material-ui/icons/PauseCircleFilledRounded';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useDebounce } from 'use-debounce';

import { PLAY_PAUSE_BUTTON } from '../constants/TestSelectors';

const BUTTON_SIZE = 40;
const LOADING_DEBOUNCE_MS = 200;

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    fontSize: theme.typography.pxToRem(BUTTON_SIZE),
    color: theme.palette.pink[500],
  },
  progress: {
    color: theme.palette.pink[500],
  },
}));

const PlayPauseButton = forwardRef((props, ref) => {
  const { isLoading = false, isPlaying = false, onClick } = props;
  const [debouncedIsLoading] = useDebounce(isLoading, LOADING_DEBOUNCE_MS);
  const classes = useStyles();

  const buttonIcon = isPlaying ? (
    <PauseCircleFilledRoundedIcon viewBox="2 2 20 20" fontSize="inherit" />
  ) : (
    <PlayCircleFilledRoundedIcon viewBox="2 2 20 20" fontSize="inherit" />
  );

  return (
    <IconButton
      className={classes.button}
      data-testid={PLAY_PAUSE_BUTTON}
      aria-label={isPlaying ? 'pause' : 'play'}
      onClick={!isLoading ? onClick : null}
      onKeyUp={(event) => {
        // prevents space bar activation (overlaps with keyboard shortcuts)
        event.preventDefault();
      }}
      ref={ref}
    >
      {debouncedIsLoading ? (
        <CircularProgress className={classes.progress} size={BUTTON_SIZE} />
      ) : (
        buttonIcon
      )}
    </IconButton>
  );
});

PlayPauseButton.propTypes = {
  isLoading: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PlayPauseButton;
