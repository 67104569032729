import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  paddedBox: {
    position: 'relative',
    height: 0,
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

export default function PaddedAspectBox({
  aspectRatio = 1,
  children,
  className,
  containerClass,
  ...rest
}) {
  const paddingBottom = `${(1 / aspectRatio) * 100}%`;
  const style = { paddingBottom };
  const classes = useStyles();
  return (
    <div className={clsx(classes.paddedBox, className)} style={style} {...rest}>
      <div className={clsx(classes.container, containerClass)}>{children}</div>
    </div>
  );
}
