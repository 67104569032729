import log from 'loglevel';
import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import pusher from '../pusher';
import { selectUserId } from '../selectors/user';

export const UserChannelContext = createContext(null);

export default function UserChannelProvider({ children }) {
  const [userChannel, setUserChannel] = useState(null);

  const userId = useSelector(selectUserId);
  const channelName = userId && `private-maker.user.${userId}`;

  useEffect(() => {
    const channel = channelName && pusher.subscribe(channelName);
    setUserChannel(channel);

    channel?.bind('pusher:subscription_error', (error) => {
      log.error('Unable to subscribe to private user pusher channel', {
        error,
      });
    });
    channel?.bind('pusher:subscription_succeeded', () => {
      log.info('Successfully subscribed to private user pusher channel');
    });

    return () => {
      channelName && pusher.unsubscribe(channelName);
      setUserChannel(null);
    };
  }, [channelName]);

  return (
    <UserChannelContext.Provider value={userChannel}>
      {children}
    </UserChannelContext.Provider>
  );
}
